import queryWithToken, { findExport } from "./index";
import { useQuery } from "react-query";
import { get } from "lodash-es";
import fileDownload from "js-file-download";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "synchronisation_errors";
//

export function useQuerySynchronisationErrors() {
  const { data, ...rest } = useQuery(
    ressourceKey,
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export async function downloadSynchronisationErrors() {
  const data = await findExport(ressourceKey, {});
  fileDownload(data, "export_erreurs_syncronisation_ged.csv");
}
