import React from "react";
import { Helmet } from "react-helmet";
import { map, size } from "lodash-es";
import Spinner from "../components/Spinner";
import { useDesktop } from "../hooks/useDesktop";
import { useQuerySynchronisationErrors } from "../api/synchronisationError";
import Block from "../components/Block";
import Alert from "../components/Alert";
import { HeaderBar } from "../components/HeaderBar";

function CustomizeLabel({ error }) {
  let customLabel = error.label.split(error.identifier);
  if (customLabel.length !== 2) {
    return <div>{error.label}, identifiant : {error.identifier}</div>;
  } else {
    return (
      <div>
        {customLabel[0]}
        <span className={"font-bold"}>{error.identifier}</span>
        {customLabel[1]}
      </div>
    );
  }
}

function SynchronisationErrors() {
  const isDesktop = useDesktop();
  const { data } = useQuerySynchronisationErrors();
  return (
    <div>
      <Helmet>
        <title>Synchronisation GED</title>
      </Helmet>
      <HeaderBar>Synchronisation GED</HeaderBar>
      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="px-8 mb-48 mt-4">
          <React.Suspense fallback={<Spinner />}>
            {size(data) > 0 ? (
              <Block>
                {map(data, (error, index) => {
                  return (
                    <div key={index} className={`pb-2`}>
                      <div
                        className={`${
                          index === 0 ? "" : "border-t-2 border-solid pt-2"
                        }`}
                      >
                        <div>{`Erreur ${index + 1}`}</div>
                        <CustomizeLabel error={error} />
                      </div>
                    </div>
                  );
                })}
              </Block>
            ) : (
              <div className="mt-12">
                <Alert
                  type="warning"
                  message="aucune erreur de synchronisation"
                />
              </div>
            )}
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}
export default SynchronisationErrors;
