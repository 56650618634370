import React, { useState } from "react";
import { useQueryCanBeEvaluate } from "../../api/evaluator";
import { flatMap, join, map, size } from "lodash-es";
import Spinner from "../Spinner";
import Alert from "../Alert";
import InfiniteScroll from "react-infinite-scroller";
import { useDesktop } from "../../hooks/useDesktop";
import InlineBLockContentSortable, { InlineBLockContent } from "../InlineBlockContentSortable";
import ExpansionPanel from "../ExpansionPanel";
import {
  getFirstnameLastname,
  getFirstnameLastnameJob,
  getOnlyJob,
  getOnlyWork,
} from "../../utils/names";
import { DisplayInput } from "../Input";

export default function SkillEvaluatorListTable({ query }) {
  
   const [orderFilter, setOrderFilter] = useState({ "order[evaluators.lastname]": "asc" });     
   const { data, fetchMore, canFetchMore, isFetching } = useQueryCanBeEvaluate({
    orSearch_evaluator: query,
    pagination: true,
    itemsPerPage: 20,
    active: "true",
    ...orderFilter,
  });
  const isDesktop = useDesktop();
  const nbEvaluators = size(data);

  if (size(data) <= 0 && isFetching) {
    return (
      <div key={0} className=" relative">
        <Spinner />
      </div>
    );
  }
  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message={`aucun évaluateur`} />
      </div>
    );
  }
  const gridTemplateColumn = `20% 20% 20% 20% 20%`;
  const evaluators = flatMap(data, (page) => page["hydra:member"]);
  return (
    <>
       <div className="absolute -top-16 py-4 right-0">
         {nbEvaluators} Evaluateur{nbEvaluators > 1 ? "s" : ""}
        </div>
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="evaluators.lastname"
          >
          Évaluateur
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="lastname"
            >
            Collaborateur évalué
            </InlineBLockContentSortable>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"agencies.label"}
          >
          Site(s)
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"currentContract.job.label"}
          >Emploi
          </InlineBLockContentSortable>
          <InlineBLockContent>Poste</InlineBLockContent>
        </div>
      )}
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {map(evaluators, (node) =>
          isDesktop ? (
            <div
              key={node.id}
              className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2`}
              style={{
                gridTemplateColumns: gridTemplateColumn,
              }}
            >
              <InlineBLockContent>
                {getFirstnameLastname(node.evaluators[0])}
              </InlineBLockContent>
              <InlineBLockContent>
                {getFirstnameLastname(node)}
              </InlineBLockContent>
              <InlineBLockContent>
                {map(node.agencies, (agency, key) => (
                  <div key={key} className="mr-2">
                    {agency.label.toLowerCase()}
                  </div>
                ))}
              </InlineBLockContent>
              <InlineBLockContent>{getOnlyJob(node)}</InlineBLockContent>
              <InlineBLockContent>{getOnlyWork(node)}</InlineBLockContent>
            </div>
          ) : (
            <EvaluatorMobileCard key={node.id} node={node} />
          ),
        )}
      </InfiniteScroll>
    </>
  );
}

function EvaluatorMobileCard({ node }) {
  return (
    <ExpansionPanel key={node.id} title={getFirstnameLastnameJob(node)}>
      {({ isOpen }) => (
        <div>
          <DisplayInput
            label="Évaluateur"
            value={getFirstnameLastname(node.evaluators[0])}
          />
          <DisplayInput
            label="Collaborateur évalué"
            value={getFirstnameLastname(node)}
          />
          <DisplayInput
            label="Site(s)"
            value={join(map(node.agencies, "label"), " ,")}
          />
          <DisplayInput label="Emploi" value={getOnlyJob(node)} />
          <DisplayInput label="Poste" value={getOnlyWork(node)} />
        </div>
      )}
    </ExpansionPanel>
  );
}
