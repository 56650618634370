import React from "react";
import { get } from "lodash-es";
import HistoryItem from "./HistoryItem";
import { ReactComponent as StarIcon } from "../../svgs/star.svg";
import CreateEvent from "../../forms/CreateEvent";

export default function EventHistoryItem({
  event = {},
  date,
  collaborator,
  isLeft,
}) {
  return (
    <HistoryItem
      title={
        get(event, "label", "") !== ""
          ? `Événement - ${get(event, "label", "")}`
          : "Événement"
      }
      Icon={StarIcon}
      backgroundIcon="bg-yellow-500"
      date={date ? new Date(date).toLocaleDateString() : ""}
      itemId={event.id}
      isToggle={true}
      textColor={"text-yellow-500"}
      iconStyle={{ stroke: "white", strokeWidth: "1,25" }}
      isLeft={isLeft}
    >
      <CreateEvent event={event} collaborator={collaborator} />
    </HistoryItem>
  );
}
