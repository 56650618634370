import React, { useState } from "react";
import { flatMap, map, size } from "lodash-es";
import { useDebounce } from "react-use";
import Alert from "../components/Alert";
import ExpansionPanel from "../components/ExpansionPanel";
import { Helmet } from "react-helmet";
import { ReactComponent as PlusIcon } from "../svgs/plus.svg";
import Spinner from "../components/Spinner";
import Modal from "../components/Modal";
import EvaluatorForm from "../forms/EvaluatorForm";
import { getFirstnameLastnameJob } from "../utils/names";
import { useQueryEvaluators } from "../api/evaluator";
import InfiniteScroll from "react-infinite-scroller";
import Button from "../components/Button";
import SkillEvaluatorListTable from "../components/Skill/SkillEvaluatorListTable";

function EvaluatorMobileCard({ evaluator }) {
  return (
    <ExpansionPanel
      key={evaluator.id}
      title={getFirstnameLastnameJob(evaluator)}
    >
      {({ isOpen }) => (
        <React.Suspense fallback={<Spinner />}>
          <EvaluatorForm evaluator={evaluator} isOpen={isOpen} />
        </React.Suspense>
      )}
    </ExpansionPanel>
  );
}

function EvaluatorsList({ query }) {
  const { data, fetchMore, canFetchMore, isFetching } = useQueryEvaluators({
    "order[lastname]": "asc",
    orSearch_fullname: query,
    pagination: true,
    itemsPerPage: 20,
    active: "true",
  });
  if (size(data) <= 0 && isFetching) {
    return (
      <div key={0} className=" relative">
        <Spinner />
      </div>
    );
  }
  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message={`aucun évaluateur`} />
      </div>
    );
  }
  const evaluators = flatMap(data, (page) => page["hydra:member"]);
  return (
    <>
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {map(evaluators, (node) => (
          <EvaluatorMobileCard key={node.id} evaluator={node} />
        ))}
      </InfiniteScroll>
    </>
  );
}

function CreateEvaluatorModal({ isPreEvaluator = false }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <button
        className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <PlusIcon className="w-4 h-4" />
      </button>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title={`Création d'un évaluateur`}
      >
        <EvaluatorForm
          preEvaluatorForm={isPreEvaluator}
          onSuccess={() => {
            setIsOpen(false);
          }}
          isModal
        />
      </Modal>
    </>
  );
}

function EvaluatorAdminPage() {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [isViewTable, setIsViewTable] = useState(false);
  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query],
  );

  return (
    <div>
      <Helmet>
        <title>Administration des évaluateurs</title>
      </Helmet>

      <div className="bg-purple-600 text-white shadow">
        <div className="relative px-8">
          <div className="py-4 h-48 flex flex-col justify-center">
            <div className="text-3xl leading-none font-bold mb-5">
              Administration des évaluateurs
            </div>
            <div>
              <input
                type="text"
                name="query"
                value={query}
                className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
                placeholder={`Rechercher un évaluateur`}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
          <CreateEvaluatorModal />
        </div>
      </div>

      <div>
        <div className="px-8 mb-48 mt-16">
          <div className="flex gap-4 mb-5">
            <Button
              className={`p-1 px-2 ${
                isViewTable ? "btn--outline" : "btn--outline--reversed"
              }`}
              onClick={() => {
                setIsViewTable(false);
              }}
            >
              Vue édition
            </Button>
            <Button
              className={`p-2 px-4 ${
                !isViewTable ? "btn--outline" : "btn--outline--reversed"
              }`}
              onClick={() => {
                setIsViewTable(true);
              }}
            >
              Vue tableau
            </Button>
          </div>
          <React.Suspense fallback={<Spinner />}>
            {!isViewTable && <EvaluatorsList query={debouncedQuery} />}
            {isViewTable && <SkillEvaluatorListTable query={debouncedQuery} />}
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default EvaluatorAdminPage;
