import { get } from "lodash-es";
import { CollaboratorContext } from "../../contexts/collaborator";
import { ReactComponent as ContractIcon } from "../../svgs/file.svg";
import HistoryItem from "./HistoryItem";
import React from "react";
import ContractForm from "../../forms/ContractForm";

export default function ContractHistoryItem({ contract = {}, isLeft }) {
  const {
    collaborator: { data: collaborator },
  } = React.useContext(CollaboratorContext);
  const type = get(contract, "type.label", "");
  const endDate = get(contract, "endDate", "");

  return (
    <HistoryItem
      title={
        get(contract, "job.label", "") !== ""
          ? `Contrat - ${get(contract, "job.label", "")}`
          : "Contrat"
      }
      Icon={ContractIcon}
      subTitle={
        type
          ? `${type}${
              endDate
                ? ` - jusqu'au ${new Date(endDate).toLocaleDateString()}`
                : ""
            }`
          : ""
      }
      backgroundIcon="bg-pink-950"
      date={
        contract.startDate
          ? new Date(contract.startDate).toLocaleDateString()
          : ""
      }
      isToggle={true}
      textColor={"text-pink-950"}
      iconStyle={{ stroke: "white", strokeWidth: "0.25", textColor: "white" }}
      isLeft={isLeft}
    >
      <ContractForm
        contract={contract}
        readOnly={true}
        collaboratorIRI={get(collaborator, "@id")}
      />
    </HistoryItem>
  );
}
