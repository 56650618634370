import queryWithToken from "./index";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "domains";
//

export function useQuerySynchroniseDocumentsByDomainId() {
  return useMutation(
    ({ domainId }) => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${domainId}/synchronize`,
        {
          method: "GET",
        }
      );
    },
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de la synchronisation");
      },
    }
  );
}
