import React, { useState } from "react";
import { flatMap, map, size, slice, join } from "lodash-es";
import { useDebounce, useToggle } from "react-use";
import Alert from "../../components/Alert";
import ExpansionPanel from "../../components/ExpansionPanel";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import Spinner from "../../components/Spinner";
import { useDesktop } from "../../hooks/useDesktop";
import { useQueryInfiniteMessagesPrevention } from "../../api/messages";
import Modal from "../../components/Modal";
import MessageForm from "../../forms/MessageForm";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {getFirstnameLastname, getOnlyJob} from "../../utils/names";
import { HeaderBar } from "../../components/HeaderBar";
import InlineBLockContentSortable from "../../components/InlineBlockContentSortable";
import { ReactComponent as ApplauseIcon } from "../../svgs/applause.svg";
import { ReactComponent as ThinkingIcon } from "../../svgs/thinking.svg";
import InfoTooltip from "../../components/InfoTooltip";
import {CSVLink} from "react-csv";
import { WysiwygTextEditorNoForm} from "../../components/WysiwygTextEditor";

function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}

function MessagePreventionMobileCard({ message }) {
  return (
    <ExpansionPanel
      key={message.id}
      title={getFirstnameLastname(message, "author.collaborator")}
      subtitle={
        message.createdAt
          ? new Date(message.createdAt).toLocaleDateString()
          : ""
      }
    >
      <MessageForm message={message} isDisabled={true} isPrevention />
    </ExpansionPanel>
  );
}

function MessagesPreventionList({ query, dateRange: { endDate, startDate } }) {
  const isDesktop = useDesktop();
  const filter =
    endDate !== null
      ? {
          "createdAt[after]": new Date(startDate),
          "createdAt[before]": new Date(endDate),
          approved: true,
        }
      : {
          approved: true,
        };
  const [orderFilter, setOrderFilter] = useState({
    "order[createdAt]": "desc",
  });

  const { data, fetchMore, canFetchMore } = useQueryInfiniteMessagesPrevention({
      pagination: false,
    orSearch_author_recipient_fullname: query,
    "type.prevention": true,
    ...filter,
    ...orderFilter,
  });
  const [currentMessage, setCurrentMessage] = React.useState(null);
  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="Aucun message de prévention" />
      </div>
    );
  }
  const messages = flatMap(data, (page) => page["hydra:member"]);
  const gridTemplateColumn = "8% 12% 10% 15% 5% 50%";

  const csvHeaders = [
      {label: "Date", key: "dateFr"},
      {label: "Auteur", key: "authorFullName"},
      {label: "Emploi", key: "authorJob"},
      {label: "Matricule", key: "authorRegistrationNum"},
      {label: "Site", key: "authorAgencies"},
      {label: "Destinataires", key: "destinataires"},
      {label: "Message", key: "commentFix"},
      {label: "Gratitude", key: "positive"},
  ];

  const csvData = map(messages, (item) => {
      const authorAgencies = map(item.author.collaborator.agencies, (agency) => (agency.label)).sort();
      return {
          ...item,
          dateFr: item.createdAt
              ? new Date(item.createdAt).toLocaleDateString()
              : "",
          authorFullName: getFirstnameLastname(item, "author.collaborator"),
          authorJob: getOnlyJob(item, "author.collaborator"),
          authorRegistrationNum: item.author.collaborator.registrationNumber,
          authorAgencies: authorAgencies.join('/'),
          destinataires: map(
              item.recipients,
              ({ collaborator: { firstname, lastname } }) =>
                  `${lastname} ${firstname}`
          ),
          commentFix: item.comment.replaceAll('"', '').replace( /(<([^>]+)>)/ig, '').replaceAll("&nbsp;"," "),
          positive: item.positive?"Encouragement":"Occasion de faire grandir",
      };
  });

  return (
      <div className="relative">
          <div className="absolute -top-16 py-4 right-0">
              <CSVLink
                  filename="messages_prevention.csv"
                  className="btn-export"
                  headers={csvHeaders}
                  data={csvData}
              >
                  Exporter
              </CSVLink>
          </div>
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
              colName={"createdAt"}
              setOrderFilter={setOrderFilter}
          >
            Date
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"author.collaborator.lastname"}
            setOrderFilter={setOrderFilter}
          >
            Auteur
          </InlineBLockContentSortable>
          <InlineBLockContent>
            Site
          </InlineBLockContent>
          <InlineBLockContentSortable
            colName={"recipients.collaborator.lastname"}
            setOrderFilter={setOrderFilter}
          >
            Destinataire
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
              colName={"positive"}
              setOrderFilter={setOrderFilter}
          >
            Icone
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"comment"}
            setOrderFilter={setOrderFilter}
          >
            Message
          </InlineBLockContentSortable>
        </div>
      )}

      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {isDesktop
          ? map(messages, (node) => {
              const firstRecipients = map(
                slice(node.recipients, 0, 2),
                ({ collaborator: { firstname, lastname } }) =>
                  `${lastname} ${firstname}`
              );
              return (
                <div
                  onClick={() => {
                    setCurrentMessage(node);
                  }}
                  key={node.id}
                  className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2 cursor-pointer`}
                  style={{
                    gridTemplateColumns: gridTemplateColumn,
                  }}
                >
                  <InlineBLockContent>
                    {node.createdAt
                        ? new Date(node.createdAt).toLocaleDateString()
                        : ""}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {getFirstnameLastname(node, "author.collaborator")}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {size(node.author.collaborator.agencies)>1? "MULTISITES" : map(node.author.collaborator.agencies, (agency, key) => (
                        <div key={key} className="mr-2">
                          {agency.label.toLowerCase()}
                        </div>
                      ))}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {join(firstRecipients, ", ")}
                    {node.recipients.length > 2 ? ", ..." : ""}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {node.positive ? (<ApplauseIcon/>) : (<ThinkingIcon/>)}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    <InfoTooltip
                      message={node.comment.replace( /(<([^>]+)>)/ig, '').replaceAll("&nbsp;"," ")}
                      left
                      onHover
                    >
                      <WysiwygTextEditorNoForm
                              readOnly={true}
                              value={node.comment}
                          />
                    </InfoTooltip>
                  </InlineBLockContent>
                </div>
              );
            })
          : map(messages, (node) => (
              <MessagePreventionMobileCard key={node.id} message={node} />
            ))}
      </InfiniteScroll>
      <Modal
        title="Message de prévention"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={!!currentMessage}
        onRequestClose={() => setCurrentMessage(null)}
      >
        <MessageForm message={currentMessage} isDisabled={true} />
      </Modal>
    </div>
  );
}

function MessagesPrevention() {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [filtersOpen, toggleFilters] = useToggle(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: null,
  });
  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );

  React.useEffect(() => {
            var _paq = window._paq = window._paq || [];
            _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
       
            var u="//matomo.kdix.pockost.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    }, [])

  return (
    <div>
      <Helmet>
        <title>Messages de prévention</title>
      </Helmet>
      <HeaderBar
        title={
          <>
            Messages de prévention
          </>
        }
        sticky={true} smaller={true}
      >
        <input
          type="text"
          name="query"
          value={query}
          className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
          placeholder="Rechercher un message de prévention"
          onChange={(e) => setQuery(e.target.value)}
        />
      </HeaderBar>

      <div>
        <div className="px-8 mt-8 mb-4">
          <button
            className="flex items-center"
            onClick={() => toggleFilters(true)}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>
          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            isOpen={filtersOpen}
            onRequestClose={() => toggleFilters(false)}
          >
            <div className="font-black mb-3">Sélectionner une période</div>
            <hr className="my-2" />
            <div className="-mx-4 px-5 text-center">
              <DatePicker
                selected={dateRange.startDate}
                onChange={([startDate, endDate]) => {
                  setDateRange({
                    startDate: startDate,
                    endDate: endDate,
                  });
                }}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                selectsRange
                inline
              />
            </div>

            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  setDateRange({
                    startDate: new Date(),
                    endDate: null,
                  });
                }}
              >
                Supprimer les filtres
              </div>
            </div>

            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <MessagesPreventionList
              query={debouncedQuery}
              dateRange={dateRange}
            />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default MessagesPrevention;
