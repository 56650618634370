import { useField } from "formik";
import React from "react";
import Alert from "./Alert";
import ImageUploading from "react-images-uploading";
import { ReactComponent as PhotoIcon } from "../svgs/photo.svg";
import { get, isEmpty } from "lodash-es";

function Image({ url, size }) {
  return (
    <img
      src={url ? url : "//placehold.it/160x160"}
      className={`${size ? size : "w-full h-full"} object-center object-cover`}
      alt=""
    />
  );
}

function OverImage() {
  return (
    <div className="absolute inset-0 opacity-0 hover:opacity-75 justify-center items-center bg-gray-400 w-full flex-col flex items-center justify-center">
      <PhotoIcon className="text-white fill-current h-5 w-5" />
      <div className="text-white">Choisir une image</div>
    </div>
  );
}

export default function UploadImage({
  currentImage,
  label,
  readOnly = false,
  ...props
}) {
  const maxMbFileSize = 1 * 1024 * 1024; // 5Mb
  const [, meta, { setValue, setTouched, setError }] = useField(props);
  return (
    <div>
      <div className="relative overflow-hidden">
        <div className="block text-black text-sm font-bold">{label}</div>
      </div>
      <ImageUploading
        onChange={(imageList) => {
          setError(false);
          setValue(get(imageList, "0.file"));
          setTouched(true);
        }}
        maxNumber={2}
        maxFileSize={maxMbFileSize}
        acceptType={["jpg", "jpeg", "gif", "png", "JPG", "GIF", "PNG", "JPEG"]}
      >
        {({ imageList, onImageUpload, onImageRemoveAll, errors }) => {
          return (
            <div className="mb-6">
              <button
                type="button"
                disabled={readOnly}
                onClick={() => {
                  if (imageList && imageList.length > 1) {
                    onImageRemoveAll();
                  }
                  onImageUpload();
                }}
                className={`block w-64 ${readOnly ? "cursor-default" : ""}`}
              >
                {!isEmpty(imageList) &&
                  imageList.map((image) => (
                    <div
                      key={image.key}
                      className="w-full flex justify-center relative"
                    >
                      <Image url={image.dataURL} />
                      {!readOnly && <OverImage />}
                    </div>
                  ))}

                {isEmpty(imageList) && (
                  <>
                    {currentImage ? (
                      <div className="relative">
                        <Image url={currentImage} />
                        {!readOnly && <OverImage />}
                      </div>
                    ) : (
                      <div className="flex justify-center items-center bg-gray-100 w-full h-32 flex-col">
                        <PhotoIcon className="text-black fill-current h-5 w-5" />
                        <div>Choisir une image</div>
                      </div>
                    )}
                  </>
                )}
              </button>
              {errors.maxNumber || errors.acceptType || errors.maxFileSize ? (
                <div className="mt-4">
                  {errors.maxNumber && (
                    <Alert
                      type="warning"
                      message="Number of selected images exceed maxNumber"
                    />
                  )}
                  {errors.acceptType && (
                    <Alert
                      type="warning"
                      message="Extension de fichier non autorisé. (jpg, gif, png)"
                    />
                  )}
                  {errors.maxFileSize && (
                    <Alert
                      type="warning"
                      message="le fichier sélectionné depasse la taille maximum de 1mo"
                    />
                  )}
                </div>
              ) : null}

              {meta.error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(meta.error, "data.hydra:title")}
                    details={get(meta.error, "data.hydra:description")}
                  />
                </div>
              ) : null}
            </div>
          );
        }}
      </ImageUploading>
    </div>
  );
}
