import * as Yup from "yup";

import { Form, Formik } from "formik";
import { get, map } from "lodash-es";

import {
    dateCompare,
} from "../regex/date";

import Alert from "../components/Alert";
import Input, { DateTimePickerInput } from "../components/Input";
import React from "react";
import { usePermissionsChecker } from "../contexts/permissions";
import Button from "../components/Button";
import { useCreateEventVehicle } from "../api/eventVehicle";
import { useQueryTypeEventVehicles } from "../api/typeEventVehicle";
import Select from "../components/Select";

const validation = Yup.object().shape({
  mileage: Yup.number().integer().min(0),
  mileageNextEvent: Yup.number().integer().min(0).test(
          "mileage-more",
          "Doit être supérieur au kilométrage actuel",
          function (value) {
                if(!value || value === 0) {
                    return true;
                }
                return (this.parent.mileage < value);
            }
          ),
  date: Yup.date().nullable().required("Requis"),
  dateNextEventOfThisType: Yup.date().nullable().test(
            "date-match", 
            "Doit être postérieure à la date de réalisation",
            function (value) {
                if(!value || value === 0) {
                    return true;
                }
                return dateCompare(this.parent.date, value, true);
            }
          ),
  typeVehicleEvent: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
});

export default function CreateEventVehicle({ vehicleIRI, onSuccess }) {
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.vehicle.edit",
      "kdix.actions.vehicle.edit.agency",
      "kdix.actions.vehicle.edit.department",
      "kdix.actions.vehicle.edit.service",
      "kdix.actions.vehicle.edit.own",
    ],
  });
  const [createEvent, { error }] = useCreateEventVehicle();
  const { data: typesEvent } = useQueryTypeEventVehicles();
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          mileage: "",
          mileageNextEvent: "",
          comment: "",
          date: null,
          dateNextEventOfThisType: null,
          typeVehicleEvent: {
            value: "",
            label: "",
          },
        }}
        validationSchema={validation}
        onSubmit={async (values, actions) => {
          try {
            await createEvent({
              mileage: values.mileage === "" ? null : values.mileage,
              mileageNextEvent:
                values.mileageNextEvent === "" ? null : values.mileageNextEvent,
              comment: values.comment,
              vehicle: vehicleIRI,
              date: values.date === "" ? null : values.date,
              dateNextEventOfThisType:
                values.dateNextEventOfThisType === ""
                  ? null
                  : values.dateNextEventOfThisType,

              type: values.typeVehicleEvent.value,
            });
            actions.setSubmitting(false);
            onSuccess();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, errors: err, values }) => {
          return (
            <Form>
              <>
                <Select
                  name="typeVehicleEvent"
                  placeholder="Type d'action *"
                  options={map(typesEvent, (typeEvent) => ({
                    label: typeEvent.label,
                    value: typeEvent["@id"],
                  }))}
                  value={
                    values.typeVehicleEvent.value !== ""
                      ? values.typeVehicleEvent
                      : null
                  }
                  isLoading={!typesEvent}
                  readOnly={readOnly}
                />
              </>
              <DateTimePickerInput
                fullWidth={true}
                name="date"
                label="Date de réalisation"
                value={values.dateTrafficRelease}
                readOnly={readOnly}
              />
              <DateTimePickerInput
                fullWidth={true}
                name="dateNextEventOfThisType"
                label="Date de la prochaine action"
                readOnly={readOnly}
              />
              <Input
                type="number"
                name="mileage"
                label="Kilométrage actuel"
                readOnly={readOnly}
              />
              <Input
                type="number"
                name="mileageNextEvent"
                label="Kilométrage de la prochaine action"
                readOnly={readOnly}
              />
              <Input
                type="text"
                name="comment"
                label="Commentaire"
                readOnly={readOnly}
              />

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 w-full block`}
                readOnly={readOnly}
                disabled={isSubmitting}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Créer l'action"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
