import React from "react";
import Block from "../components/Block";
import { GoBackBtnHistory } from "../components/GoBackBtn";
import OvertimeImportForm from "../forms/OvertimeImportForm";

export default function OvertimeImport() {
  return (
    <div className="p-8 pb-24 flex flex-col">
      <div className="p-8 pb-24">
        <Block>
          <GoBackBtnHistory className="flex items-center">
            <div className="font-bold">Retour</div>
          </GoBackBtnHistory>
          <div className="mt-4">
            <Block>
              <div className="font-bold text-xl leading-tight mb-8">
                Importer les heures supplémentaires
              </div>
              <OvertimeImportForm />
            </Block>
          </div>
        </Block>
      </div>
    </div>
  );
}
