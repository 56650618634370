import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { ModuleChecker, PermissionChecker, useDomain } from "../contexts/permissions";
import Spinner from "../components/Spinner";
import { DateExportLine, SimpleExportLine } from "../components/ExportLine";
import Export from "../components/Export";
import { downloadVehicleExport } from "../api/vehicle";
import {downloadCollaboratorExport, downloadLeaveDaysExport, downloadAccidentologyExport, downloadContractExport, downloadApprentiExport} from "../api/collaborator";
import { downloadMedicalVisitExport } from "../api/medicalVisit";
import { downloadAbsenceExport } from "../api/absence";
import { downloadRequestsExport } from "../api/requests";
import { downloadMessagesExport } from "../api/messages";
import { downloadHomeMessagesExport } from "../api/homeMessages";
import { downloadWorkAccidentExport } from "../api/workAccident";
import { downloadAffectedAccreditationExport } from "../api/affectedAccreditation";
import { downloadAffectedFormationExport } from "../api/affectedFormation";
import { downloadSynchronisationErrors } from "../api/synchronisationError";
import { downloadMoodExport } from "../api/mood";
import { downloadEventExport } from "../api/events";
import { downloadAlertsExport } from "../api/alerts";
import { downloadPermissionExport } from "../api/permissionAction";
import { HeaderBar } from "../components/HeaderBar";
import { downloadOvertimesExport } from "../api/overtimes";
import { downloadSalaryExport } from "../api/salary";

function getFilterActive(active, filterName = "active") {
  if (active === 2) {
    return {};
  }
  return active === 1
    ? {
        [filterName]: true,
      }
    : {
        [filterName]: false,
      };
}
export default function ExportsAdmin() {
  const [activeCollaborator, setActiveCollaborator] = useState(1);
  const [activeVehicle, setActiveVehicle] = useState(1);
  const currentDomainId = useDomain();
  
  const dt = new Date();
  dt.setHours(0, 0, 0, 0);

  React.useEffect(() => {
          var _paq = window._paq = window._paq || [];
          _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
      
          var u="//matomo.kdix.pockost.com/";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', '1']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);

    }, [])

  return (
    <div>
      <Helmet>
        <title>Exports</title>
      </Helmet>
      <HeaderBar>Exports</HeaderBar>

      <div className="p-8 pb-24">
        <React.Suspense fallback={<Spinner />}>
          <div className="flex justify-center">
            <PermissionChecker
              permissions={[
                "kdix.actions.collaborator.export",
                "kdix.actions.collaborator.export.agency",
                "kdix.actions.collaborator.export.department",
                "kdix.actions.collaborator.export.service",
                "kdix.actions.collaborator.export.own",
                "kdix.actions.message.view",
                "kdix.actions.message.view.agency",
                "kdix.actions.message.view.department",
                "kdix.actions.mood.view",
                "kdix.actions.mood.view.agency",
                "kdix.actions.mood.view.department",
                "kdix.actions.work_accident.edit",
                "kdix.actions.work_accident.edit.agency",
                "kdix.actions.work_accident.edit.department",
               
              ]}
            >
              <Export
                label="Collaborateurs"
                active={activeCollaborator}
                setActive={setActiveCollaborator}
              >
                <PermissionChecker
                  permissions={[
                    "kdix.actions.collaborator.export",
                    "kdix.actions.collaborator.export.agency",
                    "kdix.actions.collaborator.export.department",
                    "kdix.actions.collaborator.export.service",
                    "kdix.actions.collaborator.export.own",
                    "kdix.actions.mood.view",
                    "kdix.actions.mood.view.agency",
                    "kdix.actions.mood.view.department",
                    "kdix.actions.work_accident.edit",
                    "kdix.actions.work_accident.edit.agency",
                    "kdix.actions.work_accident.edit.department",
                  ]}
                >
                  
                  <ModuleChecker module="collaborator">
                  <PermissionChecker
                  permissions={[
                    "kdix.actions.collaborator.export",
                    "kdix.actions.collaborator.export.agency",
                    "kdix.actions.collaborator.export.department",
                    ]}
                  >
                    <SimpleExportLine
                      label="Données des collaborateurs"
                      handleDownload={() =>
                        downloadCollaboratorExport({
                          "order[lastname]": "asc",
                          "order[firstname]": "asc",
                          ...getFilterActive(activeCollaborator),
                        })
                      }
                    />
                    <DateExportLine
                      label="Alertes"
                      dateFilter="reminderDate"
                      handleDownload={(filters) =>
                        downloadAlertsExport({
                          "order[reminderDate]":"desc",
                          "exists[reminderDate]":"true",
                          ...filters,
                        })
                      }
                    />
                    </PermissionChecker>
                    
                    <PermissionChecker
                      permissions={[
                        "kdix.actions.work_accident.edit",
                        "kdix.actions.work_accident.edit.agency",
                        "kdix.actions.work_accident.edit.department",
                      ]}
                    >
                    <ModuleChecker module="prevention">
                    <SimpleExportLine
                      label="Accidentologie des collaborateurs"
                      handleDownload={() =>
                        downloadAccidentologyExport({
                          "order[lastname]": "asc",
                          "order[firstname]": "asc",
                          ...getFilterActive(activeCollaborator),
                        })
                      }
                    />
                    </ModuleChecker>
                   </PermissionChecker>
                    
                    <PermissionChecker
                    permissions={[
                        "kdix.actions.leave_days.export",
                        "kdix.actions.leave_days.export.agency",
                        "kdix.actions.leave_days.export.department",
                    ]}
                    >
                      <SimpleExportLine
                          label="Compteurs CP et RTT"
                          handleDownload={() =>
                              downloadLeaveDaysExport({
                                "order[lastname]": "asc",
                                "order[firstname]": "asc",
                                ...getFilterActive(activeCollaborator),
                              })
                          }
                      />
                    </PermissionChecker>

                    <PermissionChecker
                      permissions={[
                        "kdix.actions.mood.view",
                        "kdix.actions.mood.view.agency",
                        "kdix.actions.mood.view.department",
                      ]}
                    >
                      <DateExportLine
                        label="Météo"
                        dateFilter="createdAt"
                        handleDownload={(filters) =>
                          downloadMoodExport({
                            "order[createdAt]": "desc",
                            "exists[user.collaborator]": "true",
                            "user.domain": currentDomainId,
                            ...filters,
                            ...getFilterActive(
                              activeCollaborator,
                              "user.active"
                            ),
                          })
                        }
                      />
                      <DateExportLine
                        label="Evènements"
                        dateFilter="createdAt"
                        handleDownload={(filters) =>
                          downloadEventExport({
                            "order[createdAt]": "desc",
                            ...filters,
                          })
                        }
                      />
                    </PermissionChecker>
                    
                    <PermissionChecker
                      permissions={[
                        "kdix.actions.medical_visit.view",
                        "kdix.actions.medical_visit.view.agency",
                        "kdix.actions.medical_visit.view.department",
                      ]}
                    >
                    <DateExportLine
                      label="Visites médicales"
                      dateFilter="dateNextMedicalVisit"
                      handleDownload={(filters) =>
                        downloadMedicalVisitExport({
	                      "order[dateNextMedicalVisit]": "desc",
                          ...filters,
                          ...getFilterActive(
                            activeCollaborator
                          ),
                        })
                      }
                    />
                    </PermissionChecker>

                    <PermissionChecker
                      permissions={[
                        "kdix.actions.personal_information.view",
                        "kdix.actions.personal_information.view.agency",
                        "kdix.actions.personal_information.view.department",
                      ]}
                    >
                    <SimpleExportLine
                      label="Contrats et Salaires courants"
                      handleDownload={(filters) =>
                        downloadContractExport({
	                "order[lastname]": "asc",
                        "order[firstname]": "asc",
                          ...filters,
                          ...getFilterActive(
                            activeCollaborator
                          ),
                        })
                      }
                    />
                    <DateExportLine
                      label="Contrats apprentis"
                      dateFilter="startDate"
                      handleDownload={(filters) =>
                        downloadApprentiExport({
	                "order[lastname]": "asc",
                        "order[firstname]": "asc",
                        ...filters,
                        ...getFilterActive(
                            activeCollaborator
                          ),
                        })
                      }
                    />
                    <DateExportLine
                      label="Historique des salaires"
                      dateFilter="date"
                      handleDownload={(filters) =>
                        downloadSalaryExport({
                        "order[contract.collaborator.lastname]": "asc",
                        "order[contract.collaborator.firstname]": "asc",
                        "order[date]": "desc",
                        "exists[date]":"true",
                          ...filters,
                          ...getFilterActive(
                            activeCollaborator,
                            "contract.collaborator.active"
                          ),
                        })
                      }
                    />
                    </PermissionChecker>
                  </ModuleChecker>
                  <PermissionChecker
                    permissions={[
                      "kdix.actions.absence.view",
                      "kdix.actions.absence.view.agency",
                      "kdix.actions.absence.view.department",
                    ]}
                  >
                    <DateExportLine
                      label="Absences"
                      dateFilter="startDate"
                      handleDownload={(filters) =>
                        downloadAbsenceExport({
                          ...filters,
                          ...getFilterActive(
                            activeCollaborator,
                            "collaborator.active"
                          ),
                        })
                      }
                    />
                  </PermissionChecker>
                  <ModuleChecker module="overtime">
                  <PermissionChecker
                    permissions={[
                      "kdix.actions.overtime.view",
                      "kdix.actions.overtime.view.agency",
                      "kdix.actions.overtime.view.department",
                    ]}
                  >
                  <DateExportLine
                    label="Heures supplémentaires"
                    dateFilter="date"
                    handleDownload={(filters) =>
                      downloadOvertimesExport({
                        "order[collaborator.lastname]": "asc",
                        "order[collaborator.firstname]": "asc",
                        "order[date]": "desc",
                        ...filters,
                        ...getFilterActive(
                          activeCollaborator,
                          "collaborator.active"
                        ),
                      })
                    }
                  />
                  </PermissionChecker>
                  </ModuleChecker>
                  <ModuleChecker module="requests">
                  <PermissionChecker
                      permissions={[
                        "kdix.actions.personal_information.view",
                        "kdix.actions.personal_information.view.agency",
                        "kdix.actions.personal_information.view.department",
                      ]}
                    >
                    <DateExportLine
                      label="Demandes"
                      dateFilter="createdAt"
                      handleDownload={(filters) =>
                        downloadRequestsExport({
                          ...filters,
                          ...getFilterActive(
                            activeCollaborator,
                            "createdBy.active"
                          ),
                        })
                      }
                    />
                  </PermissionChecker>
                  </ModuleChecker>
                </PermissionChecker>
                <PermissionChecker
                  permissions={[
                    "kdix.actions.message.view",
                    "kdix.actions.message.view.agency",
                    "kdix.actions.message.view.department",
                  ]}
                >
                  <ModuleChecker module="messages">
                    <DateExportLine
                      label="Messages"
                      dateFilter="createdAt"
                      handleDownload={(filters) =>
                        downloadMessagesExport({
                          ...filters,
                          ...getFilterActive(
                            activeCollaborator,
                            "author.active"
                          ),
                          approved: true,
                        })
                      }
                    />
                  </ModuleChecker>
                </PermissionChecker>
                <PermissionChecker
                  permissions={[
                    "kdix.actions.home_message.view",
                  ]}
                >
                <DateExportLine
                  label="Messages du dirigeant"
                  dateFilter="createdAt"
                  handleDownload={(filters) =>
                    downloadHomeMessagesExport({
                      "order[createdAt]": "desc",
                      ...filters,
                    })
                  }
                />
                </PermissionChecker>
                <PermissionChecker
                  permissions={[
                    "kdix.actions.work_accident.edit",
                    "kdix.actions.work_accident.edit.agency",
                    "kdix.actions.work_accident.edit.department",
                  ]}
                >
                  <ModuleChecker module="prevention">
                    <DateExportLine
                      label="Accidents du travail reconnus"
                      handleDownload={(filters) =>
                        downloadWorkAccidentExport(
                          {
                            ...filters,
                            ...getFilterActive(
                              activeCollaborator,
                              "victim.active"
                            ),
                            accepted: true,
                          },
                          "export_accidents_reconnus"
                        )
                      }
                    />
                    <DateExportLine
                      label="Accidents du travail requalifiés"
                      handleDownload={(filters) =>
                        downloadWorkAccidentExport(
                          {
                            ...filters,
                            ...getFilterActive(
                              activeCollaborator,
                              "victim.active"
                            ),
                            accepted: false,
                          },
                          "export_accidents_requalifies"
                        )
                      }
                    />
                  </ModuleChecker>
                </PermissionChecker>
                <ModuleChecker module="accreditation">
                <PermissionChecker
                  permissions={[
                    "kdix.actions.accreditation.manage",
                    "kdix.actions.accreditation.manage.agency",
                    "kdix.actions.accreditation.manage.department",
                    "kdix.actions.accreditation.manage.service",
                    "kdix.actions.accreditation.manage.own",
                  ]}
                >
                  <DateExportLine
                    label="Habilités"
                    dateFilter="dateObtained"
                    handleDownload={(filters) =>
                      downloadAffectedAccreditationExport({
                        ...filters,
                        ...getFilterActive(
                          activeCollaborator,
                          "collaborator.active"
                        ),
                      })
                    }
                  />
                  </PermissionChecker>
                </ModuleChecker>

                <ModuleChecker module="formation">
                <PermissionChecker
                  permissions={[
                    "kdix.actions.formation.view",
                  ]}
                >
                  <DateExportLine
                    label="Formations"
                    dateFilter="dateObtained"
                    handleDownload={(filters) =>
                      downloadAffectedFormationExport({
                        ...filters,
                        ...getFilterActive(
                          activeCollaborator,
                          "collaborator.active"
                        ),
                      })
                    }
                  />
                 </PermissionChecker>
                </ModuleChecker>
                
                <PermissionChecker
                  permissions={[
                    "kdix.actions.collaborator.export",
                    "kdix.actions.collaborator.export.agency",
                    ]}
                  >
                <SimpleExportLine
                  label="Droits d'accès des profils utilisateurs"
                  handleDownload={(filters) =>
                    downloadPermissionExport({ 
                        "order[permission.label]": "asc",
                        "order[action.module.label]": "asc",
                        "order[action.label]": "asc",
                        ...filters,
                        })
                  }
                />
               </PermissionChecker>
                   
              </Export>
            </PermissionChecker>
          </div>

          <div className="flex justify-center pt-8">
            <PermissionChecker
              permissions={[
                "kdix.actions.vehicle.edit",
                "kdix.actions.vehicle.edit.agency",
                "kdix.actions.vehicle.edit.department",
                "kdix.actions.vehicle.edit.service",
              ]}
            >
              <ModuleChecker module="vehicles">
                <Export
                  label="Véhicules"
                  active={activeVehicle}
                  setActive={setActiveVehicle}
                >
                  <SimpleExportLine
                    label="Données des véhicules"
                    handleDownload={() =>
                      downloadVehicleExport(getFilterActive(activeVehicle))
                    }
                  />
                </Export>
              </ModuleChecker>
            </PermissionChecker>
          </div>

            <PermissionChecker
            permissions={["kdix.actions.synchronisation_error.view"]}
          >
            <div className="flex justify-center pt-8">
              <Export label="Synchronisation de GED" hasFilters={false}>
                <SimpleExportLine
                  label="Erreurs de synchronisation de GED"
                  handleDownload={() => downloadSynchronisationErrors()}
                />
              </Export>
            </div>
          </PermissionChecker>
        </React.Suspense>
      </div>
    </div>
  );
}
