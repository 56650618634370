import { useQueryTypeRentals } from "../../api/typeRental";
import { get, map } from "lodash-es";
import Radio from "../../components/Radio";
import React from "react";

export function ListTypeRental({
  iriRental,
  setIriRental,
  type,
  error = null,
  readOnly,
}) {
  const { data: rentals } = useQueryTypeRentals();
  if (iriRental === undefined) {
    //Gestion des anciennes données qui n'avaient pas de type, on les force à LOA
    //Il faudra quand même que l'utilisateur valide le formulaire pour pousser en base
    setIriRental(rentals[0]["@id"]);
  }
  return (
    <div>
      <div className={`flex ${error ? "text-red-500" : ""}`}>
        {map(rentals, (rental) => (
          <Radio
            key={rental.id}
            label={rental.label}
            type="checkbox"
            name="type"
            value={get(rental, "@id")}
            className="pr-8"
            checked={iriRental === get(rental, "@id")}
            readOnly={readOnly}
            onChange={(e) => {
              const checked = e.target.checked;
              if (checked) {
                setIriRental(get(rental, "@id"));
              }
            }}
          />
        ))}
        {type === "location" ? null : (
          <Radio
            label="Achat"
            type="checkbox"
            name="type"
            value={0}
            className="pr-8"
            checked={iriRental === 0}
            readOnly={readOnly}
            onChange={(e) => {
              const checked = e.target.checked;
              if (checked) {
                setIriRental(0);
              }
            }}
          />
        )}
      </div>
      {error && <p className="text-red-500 text-xs italic mb-2">Requis</p>}
    </div>
  );
}
