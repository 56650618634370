import {
    useInfiniteQuery,
    useQuery
} from "react-query";
import queryWithToken, { find } from "./index";

import {get} from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "lokoa_requests";
//

export function useQueryLokoaRequests(filters) {
  const { data, ...rest } = useQuery(
      [ressourceKey, filters],
      () =>
          queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
            method: "GET",
            params: {
              page: null,
              pagination: false,
              ...filters,
            },
          }),
      {
        refetchOnWindowFocus: false,
      }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQueryInfiniteLokoaRequestsPrevention(filters) {
  return useInfiniteQuery(["lokoa_requests/prevention", filters], find, {
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}
