import React from "react";

export default function ExternalLink({ children, ...props }) {
  return (
    <a
      href={props.to}
      target="_blank"
      rel="noopener noreferrer"
      className={props.className ? props.className : ""}
    >
      {children}
    </a>
  );
}
