import React, { useState } from "react";

import { useTimeoutFn } from "react-use";

const types = {
  basic: "bg-white",
  success: "bg-green-600 text-white",
  warning: "bg-purple-200 text-purple-900",
  error: "bg-red-800 text-white",
};

function Error({
  type = "basic",
  className,
  message,
  details,
  canBeHidden = false,
  hideAfter = 0,
}) {
  const [visible, setVisible] = useState(true);

  function hideAfterFn() {
    if (hideAfter > 0) {
      setVisible(false);
    }
  }

  useTimeoutFn(hideAfterFn, hideAfter);

  if (canBeHidden && !visible) return null;

  return (
    <div className={`px-8 py-3 shadow ${className} ${types[type]}`}>
      <div className="flex">
        <div className="py-1">
          <svg
            className="fill-current h-6 w-6 mr-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
          </svg>
        </div>
        <div className="flex justify-center flex-col">
          <div className="font-bold">{message}</div>
          <div className="text-sm">{details}</div>
        </div>
        {canBeHidden ? (
          <div className="ml-auto pt-2" onClick={() => setVisible(false)}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
              className="fill-current cursor-pointer"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9063 14.1182L14.1182 12.9064L9.27087 8.05912L14.1182 3.21182L12.9063 2L8.05904 6.8473L3.21174 2L1.99992 3.21182L6.84722 8.05912L1.99992 12.9064L3.21174 14.1182L8.05904 9.27095L12.9063 14.1182Z"
                strokeWidth="1.5"
              />
            </svg>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Error;
