import React from "react";
import { useField } from "formik";
import { map, noop } from "lodash-es";

export function DisplayCheckbox({
  name,
  value,
  checked,
  onChange,
  label,
  className = "",
  readOnly = false,
}) {
  return (
    <label
      className={`flex ${
        readOnly ? "cursor-default" : "cursor-pointer"
      } py-3 ${className}`}
      onClick={(e) => {
        if (readOnly) {
          e.preventDefault();
        }
      }}
    >
      <div className="border-2 border-gray-300 rounded w-6 h-6 overflow-hidden flex justify-center items-center mr-2 flex-shrink-0">
        <input
          type="checkbox"
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          className="hidden"
          readOnly={readOnly}
        />
        <svg
          className={`${
            checked ? "" : "hidden"
          } w-full h-full text-green-600 bg-white pointer-events-none`}
          viewBox="0 0 172 172"
        >
          <g
            fill="none"
            strokeWidth="none"
            strokeMiterlimit="10"
            fontFamily="none"
            fontWeight="none"
            fontSize="none"
            textAnchor="none"
          >
            <path d="M0 172V0h172v172z" />
            <path
              d="M145.433 37.933L64.5 118.8658 33.7337 88.0996l-10.134 10.1341L64.5 139.1341l91.067-91.067z"
              fill="currentColor"
              strokeWidth="1"
            />
          </g>
        </svg>
      </div>
      <span className="select-none">{label}</span>
    </label>
  );
}

function Checkbox({ onChange = noop, readOnly = false, ...props }) {
  const [field, meta, { setValue, setTouched }] = useField(props);
  return (
    <>
      <DisplayCheckbox
        checked={field.value}
        onBlur={() => setTouched(true)}
        readOnly={readOnly}
        onChange={(value) => {
          setValue(!field.value);
          onChange(field.value);
        }}
        {...props}
        {...field}
      />
      {meta.touched && meta.error ? (
        <CheckboxError errors={meta.error} />
      ) : null}
    </>
  );
}

function CheckboxError({ errors }) {
  if (typeof errors === "string") {
    return <p className="text-red-500 text-xs italic mt-2">{errors}</p>;
  }
  return map(errors, (error, index) => (
    <p key={index} className="text-red-500 text-xs italic mt-2">
      {error}
    </p>
  ));
}

export default Checkbox;
