import React from "react";
import LokoaImportForm from "../forms/LokoaImportForm";
import {useDomain} from "../contexts/permissions";
import Block from "../components/Block";

function LokoaAdmin() {
    const domain = useDomain();

    return (
        <>
            <div className="p-8 pb-24">
                <Block>
                    <LokoaImportForm
                        domain={domain}
                    />
                </Block>
            </div>
        </>
    );
}

export default LokoaAdmin;
