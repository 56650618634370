import React, { useState } from "react";
import { map, size } from "lodash-es";
import { useToggle } from "react-use";
import Alert from "../../components/Alert";
import ExpansionPanel from "../../components/ExpansionPanel";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import Spinner from "../../components/Spinner";
import { useDesktop } from "../../hooks/useDesktop";
import Modal from "../../components/Modal";
import MessageForm from "../../forms/MessageForm";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { HeaderBar } from "../../components/HeaderBar";
import InlineBLockContentSortable from "../../components/InlineBlockContentSortable";
import {useInfiniteQueryHomeMessages} from "../../api/homeMessages";

function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}

function MessageDirigeantPreventionMobileCard({ message }) {
  return (
    <ExpansionPanel
      key={message.id}
      title={
        message.createdAt
          ? new Date(message.createdAt).toLocaleDateString()
          : ""
      }
    >
      <MessageForm message={message} isDisabled={true} isPrevention />
    </ExpansionPanel>
  );
}

function MessagesDirigeantPreventionList({ dateRange: { endDate, startDate } }) {
  const isDesktop = useDesktop();
  const filter =
    endDate !== null
      ? {
          "createdAt[after]": new Date(startDate),
          "createdAt[before]": new Date(endDate),
        }
      : null;
  const [orderFilter, setOrderFilter] = useState({
    "order[createdAt]": "desc",
  });

  const { messages, fetchMore, canFetchMore } = useInfiniteQueryHomeMessages({
    "prevention": true,
    ...filter,
    ...orderFilter,
  });

  if (size(messages) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="Aucun message de prévention" />
      </div>
    );
  }
  const gridTemplateColumn = "10% 90%";

  return (
    <div className={`mt-8`}>
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
              colName={"createdAt"}
              setOrderFilter={setOrderFilter}
          >
            Date
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"message"}
            setOrderFilter={setOrderFilter}
          >
            Message
          </InlineBLockContentSortable>
        </div>
      )}

      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {isDesktop
          ? map(messages, (node) => {
              return (
                <div
                  key={node.id}
                  className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2 cursor-pointer`}
                  style={{
                    gridTemplateColumns: gridTemplateColumn,
                  }}
                >
                  <InlineBLockContent>
                    {node.createdAt
                        ? new Date(node.createdAt).toLocaleDateString()
                        : ""}
                  </InlineBLockContent>
                  <InlineBLockContent>
                      {node.message}
                  </InlineBLockContent>
                </div>
              );
            })
          : map(messages, (node) => (
              <MessageDirigeantPreventionMobileCard key={node.id} message={node} />
            ))}
      </InfiniteScroll>
    </div>
  );
}

function MessagesDirigeantPrevention() {
  const [filtersOpen, toggleFilters] = useToggle(false);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: null,
  });

  return (
    <div>
      <Helmet>
        <title>Messages de prévention du dirigeant</title>
      </Helmet>
      <HeaderBar
        title={
          <>
            Messages de prévention du dirigeant
          </>
        }
        sticky={true} smaller={true}
      >
          <div></div>
      </HeaderBar>

      <div>
        <div className="px-8 mt-8 mb-4">
          <button
            className="flex items-center"
            onClick={() => toggleFilters(true)}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>
          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            isOpen={filtersOpen}
            onRequestClose={() => toggleFilters(false)}
          >
            <div className="font-black mb-3">Sélectionner une période</div>
            <hr className="my-2" />
            <div className="-mx-4 px-5 text-center">
              <DatePicker
                selected={dateRange.startDate}
                onChange={([startDate, endDate]) => {
                  setDateRange({
                    startDate: startDate,
                    endDate: endDate,
                  });
                }}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                selectsRange
                inline
              />
            </div>

            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  setDateRange({
                    startDate: new Date(),
                    endDate: null,
                  });
                }}
              >
                Supprimer les filtres
              </div>
            </div>

            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <MessagesDirigeantPreventionList
              dateRange={dateRange}
            />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default MessagesDirigeantPrevention;
