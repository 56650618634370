import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Illustration } from "../svgs/404.svg";

function NotFound() {
  return (
    <div className="bg-purple-800 text-white inset-0 absolute w-full h-full text-center flex items-center justify-center">
      <div>
        <Illustration className="mx-auto" />

        <div className="text-5xl font-bold mt-8">404</div>
        <div className="text-xl font-bold mt-2">Oups...</div>
        <div className="">
          La page que vous recherchez <br />
          est introuvable
        </div>

        <Link
          to="/"
          className="btn inline-block mt-12"
          style={{
            minWidth: "203px",
          }}
        >
          Accueil
        </Link>
      </div>
    </div>
  );
}

export default NotFound;
