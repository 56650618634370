import Block from "../Block";
import React, { useEffect, useState } from "react";
import { useUserId } from "../../contexts/permissions";
import { useGetUserHomeMessage } from "../../api/user";
import { WysiwygTextEditorNoForm } from "../WysiwygTextEditor";
import { forEach } from "lodash-es";

function DirectorWord() {
  const userId = useUserId();
  const { data } = useGetUserHomeMessage(userId);

  //to change message
  const [messageId, setMessageId] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageId(messageId + 1);
    }, 10000);

    return () => clearInterval(interval);
  });

  let message =
    data.length > 0 && data[messageId % data.length]
      ? data[messageId % data.length].message
      : null;
  let messageDate =
    data.length > 0 && data[messageId % data.length]
      ? new Date(data[messageId % data.length].createdAt).toLocaleDateString()
      : "";
  let messageAuthor =
    data.length > 0 &&
    data[messageId % data.length] &&
    data[messageId % data.length].createdBy
      ? data[messageId % data.length].createdBy.collaborator
        ? data[messageId % data.length].createdBy.collaborator.firstname +
          " " +
          data[messageId % data.length].createdBy.collaborator.lastname +
          " - "
        : data[messageId % data.length].createdBy.username
        ? data[messageId % data.length].createdBy.username + " - "
        : ""
      : "";

  let maxlines = 1;
  if (message && data.length > 0) {
    forEach(data, (msg) => {
      if (msg.message.split(/\n/).length > maxlines) {
        maxlines = msg.message.split(/\n/).length;
      }
    });
  }

  if (message && message.split(/\n/).length < maxlines) {
    let messagelines = message.split(/\n/).length;
    for (let i = 0; i < maxlines - messagelines; i++) {
      message = message + "\n";
    }
  }

  return message && data.length > 0 ? (
    <Block
      className={`relative ${
        messageId % 2 === 0 ? "bg-purple-600" : "bg-green-900"
      } text-white text-center`}
      customStyle = "bg-white p-4 shadow mb-4 xl:mb-3 h-64 overflow-y-scroll"
    >
      <blockquote className="relative py-4">
        <div className="">
          <WysiwygTextEditorNoForm
            readOnly={true}
            value={message + "<em> (" + messageAuthor + messageDate + ")</em>"}
          />
        </div>
      </blockquote>
    </Block>
  ) : null;
}

//function transformMessage(message) {
//  const regex = /(https{0,1}:\/\/\S+?\.\S+)/gm;
//  const subst = `<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>`;
//
//  const result = message.replace(regex, subst);
//
//  return result.split("\n");
//}

export default DirectorWord;
