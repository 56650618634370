import { queryCache, useMutation } from "react-query";
import queryWithToken from "./index";
import { toast } from "react-toastify";

const ressourceKey = "api_silaes";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation

export function useCreateOrUpdateApiSilae() {
  return useMutation(
    ({ domainId, data }) => {
      if (typeof data.id !== "undefined") {
        //Create
        return queryWithToken(
          `${process.env.REACT_APP_API_URL}/${ressourceKey}/${data.id}`,
          {
            method: "PATCH",
            data,
          }
        );
      }

      if (typeof data.id === "undefined") {
        //update
        return queryWithToken(
          `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
          {
            method: "POST",
            data,
          }
        );
      }
    },
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de l'envoi");
      },
      onSuccess: () => {
        queryCache.refetchQueries("domains");
      },
    }
  );
}

export function useDeleteApiSilae() {
  return useMutation(
    ({ domainId, data }) => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${data.id}`,
        {
          method: "DELETE",
        }
      );
    },
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de l'envoi");
      },
      onSuccess: () => {
        queryCache.refetchQueries("ressourceKey");
        queryCache.refetchQueries("domains");
      },
    }
  );
}
