import {queryCache, useMutation, useQuery} from "react-query";
import queryWithToken, {updateById} from "./index";

import {get} from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "lokoa_request_answers";
//

export function useQueryLokoaRequestAnswers(filters) {
  const { data, ...rest } = useQuery(
      [ressourceKey, filters],
      () =>
          queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
            method: "GET",
            params: {
              page: null,
              pagination: false,
              ...filters,
            },
          }),
      {
        refetchOnWindowFocus: false,
      }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useUpdateLokoaRequestAnswer() {
    return useMutation(updateById.bind(null, ressourceKey), {
        onSuccess: () => {
            queryCache.refetchQueries([ressourceKey]);
            queryCache.refetchQueries("lokoa_requests");
            queryCache.refetchQueries("history");
        },
    });
}