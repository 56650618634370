import { create, find, findById, updateOrCreate } from "./index";
import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "personal_informations";
//

export function useQueryPersonalInformationById(id) {
  return useQuery(id && [ressourceKey, id], findById, {
    refetchOnWindowFocus: false,
  });
}

export function useQueryPersonalInformations(searchQuery, filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    refetchOnWindowFocus: false,
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}

export function useCreatePersonalInformation() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useUpdatePersonalInformation() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: (newPersonalInformation = null) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.refetchQueries(["personal_informations", data.id]);
      if (typeof data.collaborator === "string") {
        const id = data.collaborator.split("/")[3];
        if (id !== undefined) {
          queryCache.refetchQueries(["collaborators", parseInt(id)]);
        }
      }
    },
  });
}
