import React from "react";
import { Helmet } from "react-helmet";

import Block from "../../components/Block";
import GoBackBtn from "../../components/GoBackBtn";
import CreateWorkAccidentForm from "../../forms/CreateWorkAccidentForm";
import { useDesktop } from "../../hooks/useDesktop";

function CreateWorkAccident() {
  const isDesktop = useDesktop();
  return (
    <div className="p-8 pb-0">
      <Helmet>
        <title>Ajout d'un nouvel accident</title>
      </Helmet>
      <Block>
        <GoBackBtn
          to="/prevention/accidents-travail"
          className="flex items-center"
        >
          <div className="font-bold">Retour</div>
        </GoBackBtn>
        <div className={`max-w-lg mx-auto`}>
          <div className="text-2xl font-bold mb-8 mt-8">
            Ajouter un nouvel accident
          </div>
        </div>
        <div className={`${isDesktop ? "max-w-5xl" : "max-w-lg"}  mx-auto`}>
          <CreateWorkAccidentForm />
        </div>
      </Block>
    </div>
  );
}

export default CreateWorkAccident;
