import fileDownload from "js-file-download";
import { findExport } from "./index";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "moods";
//

export async function downloadMoodExport(filters) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, "export_meteo.csv");
}
