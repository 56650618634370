import React, { useEffect } from "react";
import { useQueryJobs } from "../../api/jobs";
import { useQueryAgencies } from "../../api/agencies";
import { useQueryDepartments } from "../../api/department";
import { useQueryServices } from "../../api/services";
import { useMap, useToggle } from "react-use";
import { map, reduce } from "lodash-es";
import { Filters } from "../../forms/ValidatorForm";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";

export default function CollaboratorFilters({
  readOnly = false,
  isModal = false,
  onChange,
}) {
  const { data: jobs } = useQueryJobs();
  const { data: agencies } = useQueryAgencies();
  const { data: departments } = useQueryDepartments();
  const { data: services } = useQueryServices();
  const [filterOn, filterToggle] = useToggle(false);
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({});

  useEffect(() => {
    //Ajout des filtres
    const formatedFilters = {};

    for (const key in filters) {
      // check also if property is not inherited from prototype
      if (filters.hasOwnProperty(key)) {
        formatedFilters[key] = reduce(
          filters[key],
          (result, f) => {
            result.push(f.value);
            return result;
          },
          [],
        );
      }
    }
    onChange(formatedFilters);
  }, [filters, onChange]);

  const filtersList = [
    {
      label: "Site",
      key: "agencies",
      options: map(agencies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Départements",
      key: "departments",
      options: map(departments, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Service",
      key: "service",
      options: map(services, (node) => ({
        label: node.code,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Emploi",
      key: "currentContract.job",
      options: map(jobs, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
  ];
  return (
    <div>
      <button
        className={`${
          !readOnly ? "flex" : "hidden"
        } items-center focus:outline-none mb-4`}
        onClick={filterToggle}
      >
        <FilterIcon />
        <span className="ml-4">Filtres</span>
      </button>
      <div className={`${!readOnly && filterOn ? "block" : "hidden"}`}>
        <Filters
          AvailableFilters={filtersList}
          setFilter={setFilter}
          removeFilter={removeFilter}
          resetFilters={resetFilters}
          currentFilter={filters}
          toggleClose={filterToggle}
          isModal={isModal}
        />
      </div>
    </div>
  );
}
