import { Form, Formik } from "formik";
import Input from "../components/Input";
import React from "react";
import * as Yup from "yup";
import { get, noop } from "lodash-es";
import { useCreateOrUpdateSkillDomain } from "../api/skillDomains";

const validateNewProcess = Yup.object().shape({
  label: Yup.string().required("Requis"),
});

export default function SkillDomainForm({
  skillDomain = null,
  afterSubmit = noop,
}) {
  const [createSkillDomain] = useCreateOrUpdateSkillDomain();
  return (
    <div className="mt-8">
      <Formik
        initialValues={{
          label: get(skillDomain, "label", ""),
        }}
        validationSchema={validateNewProcess}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await createSkillDomain({
            id: get(skillDomain, "id", null),
            data: {
              label: values.label,
            },
          });
          setSubmitting(false);
          resetForm();
          afterSubmit();
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div className="sm:flex mb-8 items-start">
                <Input
                  name="label"
                  placeholder="Nom du domaine de compétence"
                  className="mb-0 w-full mr-4"
                />

                <button
                  type="submit"
                  className="w-full sm:w-auto mt-4 sm:mt-0 flex-no-shrink btn btn--sm rounded-none whitespace-no-wrap"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div className="loader h-8 w-8 mx-auto" />
                  ) : skillDomain ? (
                    "Modifier"
                  ) : (
                    "Créer un nouveau domaine"
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
