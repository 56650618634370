import React from "react";
import { Helmet } from "react-helmet";

import Block from "../../components/Block";
import GoBackBtn from "../../components/GoBackBtn";
import { useDesktop } from "../../hooks/useDesktop";
import CreateCampaignForm from "../../forms/CreateCampaign";

export default function CreateCampaign() {
  const isDesktop = useDesktop();
  return (
    <div className="p-8 pb-0">
      <Helmet>
        <title>Lancement d'évaluations</title>
      </Helmet>
      <Block>
        <GoBackBtn
          to="/rh/competences/suivi_campagnes"
          className="flex items-center"
        >
          <div className="font-bold">Retour</div>
        </GoBackBtn>
        <div className={`max-w-lg mx-auto`}>
          <div className="text-2xl font-bold mb-8 mt-8">
            Lancer des évaluations
          </div>
        </div>
        <div className={`${isDesktop ? "max-w-5xl" : "max-w-lg"}  mx-auto`}>
          <CreateCampaignForm />
        </div>
      </Block>
    </div>
  );
}
