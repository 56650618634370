import * as Yup from "yup";
import { Form, Formik } from "formik";
import { get, map, noop } from "lodash-es";
import Alert from "../components/Alert";
import React from "react";
import { usePermissionsChecker } from "../contexts/permissions";
import { useUpdateCollaborator } from "../api/collaborator";
import { useQueryWorks } from "../api/works";
import { toast } from "react-toastify";
import Button from "../components/Button";
import Select from "../components/Select";
import SelectCollaborator from "../components/SelectCollaborator";
import { getFirstnameLastnameJob } from "../utils/names";

const validation = Yup.object().shape({});

export default function EvaluationCollaboratorForm({ collaborator, isEvaluator=false, afterSubmit = noop }) {
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.collaborator.edit",
      "kdix.actions.collaborator.edit.agency",
      "kdix.actions.collaborator.edit.department",
      "kdix.actions.collaborator.edit.service",
      "kdix.actions.collaborator.edit.own",
    ],
  }) && !isEvaluator ;
  const [updateCollaborator, { error }] = useUpdateCollaborator();
  const { data: works } = useQueryWorks();
  
  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={validation}
        initialValues={{
          work: {
          label: get(collaborator, "work.label", false),
          value: get(collaborator, "work[@id]", false),
        },
        evaluators: map(collaborator.evaluators, (evaluator) => {
            // on récupère les infos au niveau evaluator
            if (typeof evaluator === "string") {
              if (evaluator === get(collaborator, "@id", "")) {
                return {
                  value: get(collaborator, "@id", ""),
                  label: collaborator
                    ? getFirstnameLastnameJob(collaborator)
                    : "",
                };
              }
            } else {
              return {
                value: evaluator["@id"],
                label: getFirstnameLastnameJob(evaluator),
              };
            }
          }),
        }}
        onSubmit={async (values, actions) => {
          try {
           if(values.work.value && values.work.value !== "") {
            await updateCollaborator({
              id: collaborator.id,
              data: {   
                work: values.work.value,
                evaluators: map(
                  values.evaluators,
                  (evaluator) => evaluator.value
                ),
              },
            });
            toast.success("Mise à jour effectuée avec succès");
           }
           else {
             toast.error("Veuillez renseigner le poste évalué.");
           }
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          afterSubmit();
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <Select
                label="Poste évalué"
                name="work"
                placeholder={"Sélectionner..."}
                options={map(works, (node) => ({
                  label: node.label,
                  value: node["@id"],
                }))}
                value={values.work.value !== "" ? values.work : null}
                isDisabled={readOnly}
                isLoading={!works}
              />
              <SelectCollaborator
                type="text"
                name="evaluators"
                label="Evaluateur"
                value={values.evaluators}
                isDisabled={readOnly}
                isMulti
              />
              
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 ml-auto block`}
                readOnly={readOnly}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
