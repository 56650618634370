import queryWithToken  from "./index";
import mime from "mime-types";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "avatars";
//

export async function downloadAvatarImage(
  id,
  label = "avatar",
  extension = "png"
) {
  const blob = await queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/download/${id}`,
    {
      method: "GET",
      responseType: "blob",
    }
  );
  if (blob) {
    const type = mime.lookup(extension);
    const file = new Blob([blob], { type });
    const fileURL = URL.createObjectURL(file);
    let tempLink = document.createElement('a');
    tempLink.href = fileURL;
    tempLink.setAttribute('download', 'avatar.' + extension);
    tempLink.click()
    //window.open(fileURL);
  }
}


