import * as Yup from "yup";
import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import { useCreateOrUpdateContract, useDeleteContract } from "../api/contract";
import Alert from "../components/Alert";
import Button from "../components/Button";
import EditButton from "../components/HistoryItem/EditButton";
import Input, { DateTimePickerInput } from "../components/Input";
import React from "react";
import Select from "../components/Select";
import mapFormValues from "../utils/mapFormValues";
import { useQueryStatusContracts } from "../api/statusContracts";
import { useQueryTypeContracts } from "../api/typeContracts";
import { formErrorHandler } from "../utils/formErrorHandler";
import { useQueryJobs } from "../api/jobs";
import { useQueryTypeContractCategories } from "../api/typeContractCategory";
import { dateCompare } from "../regex/date";
import InfoTooltip from "../components/InfoTooltip";

const validation = Yup.object().shape({
  startDate: Yup.date().required("Requis"),
  endDate: Yup.date()
    .nullable()
    .test("date-match", "Doit être postérieure à la date de début", function (
      value
    ) {
      return dateCompare(this.parent.startDate, value);
    }),
  job: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
});

function ContractForm({
  contract,
  collaboratorIRI,
  onSuccess = () => {},
  readOnly = false,
}) {
  const [isEditing, setIsEditing] = React.useState(!contract);
  const { data: jobs } = useQueryJobs();
  const { data: typeContracts } = useQueryTypeContracts();
  const { data: statusContracts } = useQueryStatusContracts();
  const [update, { error }] = useCreateOrUpdateContract({ resetHistory: true });
  const [deleteContract] = useDeleteContract(true);
  const { data: categories } = useQueryTypeContractCategories();

  if (!collaboratorIRI) {
    return null;
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        job: {
          label: get(contract, "job.label", ""),
          value: get(contract, "job.@id", ""),
        },
        coefficient: get(contract, "coefficient", ""),
        category: {
          label: get(contract, "category.label", ""),
          value: get(contract, 'category["@id"]', ""),
        },
        level: get(contract, "level", ""),
        position: get(contract, "position", ""),
        type: {
          label: get(contract, "type.label", ""),
          value: get(contract, "type.@id", ""),
        },
        status: {
          label: get(contract, "status.label", ""),
          value: get(contract, "status.@id", ""),
        },
        startDate: get(contract, "startDate")
          ? new Date(get(contract, "startDate"))
          : "",
        endDate: get(contract, "endDate")
          ? new Date(get(contract, "endDate"))
          : "",
        endDateProbation: get(contract, "endDateProbation")
          ? new Date(get(contract, "endDateProbation"))
          : "",
        causeContract: get(contract, "causeContract", ""),
        reasonContract: get(contract, "reasonContract", ""),
        percentageActivity: get(contract, "percentageActivity", 0),
        amendment: get(contract, "amendment", 0),
        calculFTE: get(contract, "calculFTE", 0),
        realizedSchedules: get(contract, "realizedSchedules", ""),
      }}
      validationSchema={validation}
      onSubmit={async (values, actions) => {
        try {
          let prepareValues = mapFormValues(values);
          prepareValues = {
            ...prepareValues,
            startDate: prepareValues.startDate,
            endDate: prepareValues.endDate,
            endDateProbation: prepareValues.endDateProbation,
            level: prepareValues.level ? prepareValues.level.toString() : "",
            position: prepareValues.position
              ? prepareValues.position.toString()
              : "",
            coefficient: prepareValues.coefficient
              ? prepareValues.coefficient.toString()
              : "",
            collaborator: collaboratorIRI,
          };
          const response = await update({
            id: get(contract, "id", null),
            data: prepareValues,
          });
          /*
          job,startDate, category, coefficient, position, level,endDate, type, reasonContract
           */
          setIsEditing(false);
          actions.setSubmitting(false);
          onSuccess(response);
        } catch (error) {
          formErrorHandler(error, actions);
          actions.setSubmitting(false);
        }
      }}
    >
      {({ values, isSubmitting, resetForm, setSubmitting }) => {
        return (
          <Form>
            <div className={"flex flex-wrap"}>
              <div className={"w-full lg:w-1/3 lg:pr-2"}>
                <InfoTooltip
                  key={"emploi" + get(contract, "id", 0)}
                  message={values.job.label}
                  onHover={true}
                  parentClass={"w-full"}
                  tooltipClass={"transform -translate-y-8"}
                  left={true}
                  disabled={isEditing || values.job.label === ""}
                >
                  <Select
                    label="Emploi"
                    name="job"
                    options={map(jobs, (node) => ({
                      label: node.label,
                      value: node["@id"],
                    }))}
                    value={values.job.value !== "" ? values.job : null}
                    isDisabled={!isEditing}
                    isLoading={!jobs}
                    isClearable
                  />
                </InfoTooltip>
                <DateTimePickerInput
                  name="startDate"
                  label="Début de contrat"
                  readOnly={!isEditing}
                />
                <Input
                  type="text"
                  label="Position"
                  name="position"
                  readOnly={!isEditing}
                />
                <Input
                  type="number"
                  label="Avenant"
                  name="amendment"
                  readOnly={!isEditing}
                />
                <Input
                  type="number"
                  label="Calcul ETP"
                  name="calculFTE"
                  readOnly={!isEditing}
                />
                <InfoTooltip
                  key={"reason" + get(contract, "id", 0)}
                  message={values.reasonContract}
                  onHover={true}
                  parentClass={"w-full"}
                  tooltipClass={"transform -translate-y-8"}
                  left={true}
                  disabled={isEditing || values.reasonContract === ""}
                >
                  <Input
                    type="text"
                    label="Raison de fin de contrat"
                    name="reasonContract"
                    readOnly={!isEditing}
                  />
                </InfoTooltip>
              </div>
              <div className={"w-full lg:w-1/3 lg:px-2"}>
                <InfoTooltip
                  key={"type" + get(contract, "id", 0)}
                  message={values.type.label}
                  onHover={true}
                  parentClass={"w-full"}
                  tooltipClass={"transform -translate-y-8"}
                  left={true}
                  disabled={isEditing || values.type.label === ""}
                >
                  <Select
                    label="Type de contrat"
                    name="type"
                    options={map(typeContracts, (node) => ({
                      label: node.label,
                      value: node["@id"],
                    }))}
                    value={values.type.value !== "" ? values.type : null}
                    isDisabled={!isEditing}
                    isLoading={!typeContracts}
                    isClearable
                  />
                </InfoTooltip>
                <DateTimePickerInput
                  name="endDate"
                  label="Fin de contrat"
                  readOnly={!isEditing}
                />
                <Input
                  type="text"
                  label="Niveau"
                  name="level"
                  readOnly={!isEditing}
                />
                <Select
                  name="category"
                  isDisabled={!isEditing}
                  placeholder="Catégorie"
                  label="Catégorie"
                  options={map(categories, (category) => ({
                    label: category.label,
                    value: category["@id"],
                  }))}
                  value={values.category.value !== "" ? values.category : null}
                  isLoading={!categories}
                  isClearable
                />
                <Input
                  type="string"
                  label="Horaires réalisés"
                  name="realizedSchedules"
                  readOnly={!isEditing}
                />
              </div>
              <div className={"w-full lg:w-1/3 lg:pl-2"}>
                <Input
                  type="text"
                  label="Motif du CDD"
                  name="causeContract"
                  readOnly={!isEditing}
                />
                <DateTimePickerInput
                  name="endDateProbation"
                  label="Fin de période d'essai"
                  readOnly={!isEditing}
                />
                 <Input
                  type="text"
                  label="Coefficient"
                  name="coefficient"
                  readOnly={!isEditing}
                />
                <Select
                  label="Statut"
                  name="status"
                  options={map(statusContracts, (node) => ({
                    label: node.label,
                    value: node["@id"],
                  }))}
                  value={values.status.value !== "" ? values.status : null}
                  isDisabled={!isEditing}
                  isLoading={!statusContracts}
                  isClearable
                />
                <Input
                  type="number"
                  label="% activité"
                  name="percentageActivity"
                  readOnly={!isEditing}
                />
                
              </div>
            </div>

            {error ? (
              <div className="my-2">
                <Alert
                  type="error"
                  message={get(error, "title")}
                  details={get(error, "description")}
                />
              </div>
            ) : null}

            {!readOnly ? (
              contract ? (
                <EditButton
                  isSubmitting={isSubmitting}
                  isEditing={isEditing}
                  onDelete={() => {
                    setSubmitting(true);
                    deleteContract(contract.id);
                  }}
                  onCancel={() => {
                    resetForm();
                    setIsEditing(!isEditing);
                  }}
                  onClick={() => {
                    setIsEditing(!isEditing);
                  }}
                  labelEdit={"Valider"}
                />
              ) : (
                <Button
                  className={`btn mt-6 w-full block`}
                  readOnly={false}
                  isSubmitting={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Créer"
                />
              )
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
}

export default ContractForm;
