import { Helmet } from "react-helmet";
import React, { useState } from "react";
import StartedProcessInOutList from "../components/Process/StartedProcessInOutList";
import { PermissionChecker, useUserId } from "../contexts/permissions";
import { ReactComponent as FilterIcon } from "../svgs/filters.svg";
import Modal from "../components/Modal";
import Spinner from "../components/Spinner";
import { useMap, useToggle } from "react-use";
import { get, map, pull, size } from "lodash-es";
import CheckboxGroup from "../components/CheckboxGroup";
import { useDesktop } from "../hooks/useDesktop";
import { useParams } from "react-router-dom";
import StartedProcessInOutDetail from "../components/Process/StartedProcessInOutDetail";
import { DisplaySelect } from "../components/Select";
import { useQueryStartedProcessInOutById } from "../api/startedProcessInOut";
import { getLocaleDateString } from "../utils/date";
import { HeaderBar } from "../components/HeaderBar";

function StartedProcessPageDetail({ processId }) {
  const isDesktop = useDesktop();
  const { data: process } = useQueryStartedProcessInOutById(processId);
  const [order, setOrder] = useState("ASC");
  const [filtersOpen, toggleFilters] = useToggle(false);
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({ completed: [false] });
  const filtersList = [
    {
      label: "État",
      key: "completed",
      options: [
        {
          label: "Non traité",
          value: false,
          id: false,
        },
        {
          label: "Traité",
          value: true,
          id: true,
        },
      ],
    },
  ];
  const filterOptions = [
    { label: "croissante", value: "ASC" },
    { label: "décroissante", value: "DESC" },
  ];
  return (
    <div>
      <Helmet>
        <title>{get(process, "processInOut.label", "")}</title>
      </Helmet>
      <HeaderBar>
        <div className="text-3xl">
          {get(process, "processInOut.label", "Détail processus")}
        </div>
        <div className="text-xl pt-4">
          {get(process, "processInOut.isOut", false)
            ? "Départ"
            : "Arrivée"}{" "}
          {get(process, "associatedCollaborator.firstname", "")}{" "}
          {get(process, "associatedCollaborator.lastname", "")}
        </div>
        <div className="text-xl font-normal pt-1">
          {get(process, "processInOut.isOut", false)
            ? "Date de départ"
            : "Date d'arrivée"}{" "}
          : {getLocaleDateString(process, "date")}
        </div>
      </HeaderBar>

      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="mt-8 mb-4 flex">
          <button
            className="flex items-center"
            onClick={() => toggleFilters(true)}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>
          <DisplaySelect
            label="Trier par échéance"
            options={filterOptions}
            value={filterOptions.find((e) => e.value === order)}
            onChange={(selected) => {
              setOrder(selected.value);
            }}
            className={"ml-auto w-48"}
          />
          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            isOpen={filtersOpen}
            onRequestClose={() => toggleFilters(false)}
          >
            <div className="-mx-4 px-5">
              {map(filtersList, ({ key, label, options }) => {
                return (
                  <CheckboxGroup
                    key={key}
                    label={label}
                    options={options}
                    className="py-4 odd:bg-purple-50 -mx-4 px-5"
                    selected={get(filters, key, [])}
                    onAdd={(option) => {
                      setFilter(key, [...get(filters, key, []), option]);
                    }}
                    onRemove={(option) => {
                      const newFilters = pull(get(filters, key, []), option);
                      if (size(newFilters) === 0) {
                        removeFilter(key);
                        return;
                      }
                      setFilter(key, newFilters);
                    }}
                  />
                );
              })}
            </div>
            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  resetFilters();
                }}
              >
                Supprimer les filtres
              </div>
            </div>
            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="mt-8 mb-4">
          <StartedProcessInOutDetail
            filters={{
              ...filters,
              "order[date]": order,
              startedProcessInOut: process["@id"],
            }}
            order={order}
          />
        </div>
      </div>
    </div>
  );
}
function StartedProcessPageSuivi() {
  const userId = useUserId();
  const isDesktop = useDesktop();
  const [filtersOpen, toggleFilters] = useToggle(false);
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({ completed: [false] });
  const filtersList = [
    {
      label: "Type de processus",
      key: "processInOut.isOut",
      options: [
        {
          label: "Arrivée",
          value: false,
          id: false,
        },
        {
          label: "Départ",
          value: true,
          id: true,
        },
      ],
    },
    {
      label: "Statut",
      key: "completed",
      options: [
        {
          label: "En cours",
          value: false,
          id: false,
        },
        {
          label: "Terminé",
          value: true,
          id: true,
        },
      ],
    },
  ];
  return (
    <>
      <div>
        <Helmet>
          <title>Suivi des processus</title>
        </Helmet>
        <HeaderBar>Suivi des processus</HeaderBar>

        <div
          className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0"}
          style={isDesktop ? { maxWidth: "900px" } : {}}
        >
          <div className="mt-8 mb-4 flex">
            <button
              className="flex items-center"
              onClick={() => toggleFilters(true)}
            >
              <FilterIcon />
              <span className="ml-4">Filtres</span>
            </button>
            <Modal
              title="Filtres"
              handleClose={(e) => {
                e.stopPropagation();
                toggleFilters(false);
              }}
              isOpen={filtersOpen}
              onRequestClose={() => toggleFilters(false)}
            >
              <div className="-mx-4 px-5">
                {map(filtersList, ({ key, label, options }) => {
                  return (
                    <CheckboxGroup
                      key={key}
                      label={label}
                      options={options}
                      className="py-4 odd:bg-purple-50 -mx-4 px-5"
                      selected={get(filters, key, [])}
                      onAdd={(option) => {
                        setFilter(key, [...get(filters, key, []), option]);
                      }}
                      onRemove={(option) => {
                        const newFilters = pull(get(filters, key, []), option);
                        if (size(newFilters) === 0) {
                          removeFilter(key);
                          return;
                        }
                        setFilter(key, newFilters);
                      }}
                    />
                  );
                })}
              </div>
              <div>
                <div
                  className="mt-3 underline cursor-pointer"
                  onClick={() => {
                    resetFilters();
                  }}
                >
                  Supprimer les filtres
                </div>
              </div>
              <button
                className="btn mt-5 w-full"
                type="button"
                onClick={() => toggleFilters(false)}
              >
                Appliquer les filtres
              </button>
            </Modal>
          </div>
        </div>
      </div>
      <div className={"bg-white"}>
        <React.Suspense fallback={<Spinner />}>
          <StartedProcessInOutList
            filters={filters}
            userId={userId}
            isDesktop={isDesktop}
          />
        </React.Suspense>
      </div>
    </>
  );
}

export default function StartedProcessInOutSuivi() {
  const { id } = useParams();
  return (
    <React.Suspense fallback={<Spinner />}>
      {id ? (
        <PermissionChecker
          permissions={[
            "kdix.actions.process_in_out.view",
            "kdix.actions.process_in_out.view.agency",
            "kdix.actions.process_in_out.view.department",
            "kdix.actions.process_in_out.view.service",
            "kdix.actions.process_in_out.view.own",
          ]}
        >
          <StartedProcessPageDetail processId={id} />
        </PermissionChecker>
      ) : (
        <StartedProcessPageSuivi />
      )}
    </React.Suspense>
  );
}
