import { queryCache, useMutation } from "react-query";
import { updateById } from "./index";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "displayed_alerts";
//

export function useUpdateDisplayedAlert() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(["users", "alerts"]);
      queryCache.refetchQueries(["users", "archived_alerts"]);
    },
  });
}
