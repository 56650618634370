import { queryCache, useMutation, useQuery } from "react-query";

import queryWithToken, { findExport } from "./index";
import { toast } from "react-toastify";
import { get } from "lodash-es";
import fileDownload from "js-file-download";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "overtimes";
//

export function useImportOvertime() {
  return useMutation(
    ({ file, objectId }) => {
      const data = new FormData();
      data.append("file", file);

      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
        {
          method: "POST",
          data,
        }
      );
    },
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de l'envoi");
      },
      onMutate: () => {
        queryCache.cancelQueries("overtimes");
      },
      onSuccess: () => {
        queryCache.refetchQueries("overtimes");
      },
    }
  );
}

export function useQueryOvertimes(filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          "order[date]": "desc",
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export async function downloadOvertimesExport(filters) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, "export_heures_supplemantaires.csv");
}
