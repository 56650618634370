import { queryCache, useMutation, useQuery } from "react-query";
import queryWithToken, {
  create,
  deleteById,
  findById,
  updateById,
  updateOrCreate,
} from "./index";
import { get } from "lodash-es";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "process_tasks";
//

/*const mutateDefaultOptions = {
  throwOnError: true,
  // When mutate is called:
  onMutate: () => {
    queryCache.cancelQueries(ressourceKey);
  },
};*/

export function useQueryProcessTask(filter) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filter],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filter,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQueryProcessTaskById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useCreateProcessTask() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useUpdateProcessTask(processId = "") {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries(["process_in_outs"]);
      queryCache.refetchQueries(["process_in_outs", processId]);
    },
  });
}

export function useCreateOrUpdateProcessTask(processId = "") {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries(["process_in_outs"]);
      queryCache.refetchQueries(["process_in_outs", processId]);
    },
  });
}

export function useDeleteProcessTask(processId = "") {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries(["process_in_outs"]);
      queryCache.refetchQueries(["process_in_outs", processId]);
    },
    onError: (error) => {
      toast.error("Erreur lors de la suppression : " + error.description);
    },
  });
}
