import React from "react";
import { get } from "lodash-es";
import { getLocaleDateString } from "../../utils/date";
import { getFirstnameLastnameJob } from "../../utils/names";

export function alertTitleBadge(alert) {
 return {
    title: get(alert, "typeAlert.label", ""),
    subtitles: [
      {
        label: "Date de fin de badge",
        value: `${
          get(alert, "badge.endDate")
            ? getLocaleDateString(alert, "badge.endDate")
            : ""
        }`,
      },
      {
        label: "Collaborateur",
        value: getFirstnameLastnameJob(
          alert,
          "badge.collaborator"
        ),
      },
    ],
  };
}

function AlertBadgeItem({ alert }) {
    return (
        <>
            {get(alert, "typeAlert.comment") && (
                <p>
                    <span className="font-bold">Commentaire : </span>
                    {get(alert, "typeAlert.comment")}
                </p>
            )}
        </>
    );
}

export default AlertBadgeItem;
