import React, { forwardRef } from "react";
import { useField } from "formik";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import { noop } from "lodash-es";
import "react-datepicker/dist/react-datepicker.css";

function getBorderColor(meta, isEditing) {
  if (meta.touched && meta.error) {
    return "border-red-500 border-b";
  } else if (isEditing) {
    return "border-black border-b";
  } else {
    return "border-gray-100 border-b";
  }
}

function Label({ meta, label }) {
  return (
    <label
      className={`block ${
        meta.touched && meta.error ? "text-red-500" : `text-black`
      } text-sm font-bold`}
    >
      {label}
    </label>
  );
}

function Input({
  label,
  className = "",
  inputClassName = "",
  color = "black",
  isEditing = false,
  disabled,
  ...props
}) {
  const [field, meta] = useField(props);
  if (props.type === "color") {
    //TODO: Refactor input
    return (
      <div className={`${className ? className : "mb-8"}`}>
        <Label meta={meta} label={label} />
        <input {...field} {...props} />
      </div>
    );
  }
  return (
    <div className={`${className ? className : "mb-8"}`}>
      {label && <Label meta={meta} label={label} />}
      <input
        {...field}
        {...props}
        className={`${inputClassName} appearance-none bg-transparent ${getBorderColor(
          meta,
          !props.readOnly
        )}
           w-full py-2 text-${
             props.readOnly ? "gray-400" : color
           } leading-tight focus:outline-none focus:border-red`}
      />

      {meta.touched && meta.error ? (
        <p className="text-red-500 text-xs italic mt-2">{meta.error}</p>
      ) : null}
    </div>
  );
}

export function DisplayInput({ label, value = "", className = "" }) {
  return (
    <div className={`${className ? className : "mb-8"}`}>
      <label className={`block text-black text-sm font-bold`}>{label}</label>
      <div
        className={`appearance-none bg-transparent border-b border-black w-full py-2 text-black leading-tight focus:outline-none focus:border-red`}
        style={{
          minHeight: "37px",
        }}
      >
        {value}
      </div>
    </div>
  );
}

export function TimePickerInput({
  label,
  className = "",
  inputClassName = "",
  color = "black",
  isEditing = false,
  fullWidth = false,
  onChange = noop,
  disabled,
  ...props
}) {
  const [{ value, ...field }, meta, { setValue }] = useField(props);
  const ref = React.createRef();
  let selected = value;
  if (typeof value === "string" && value !== "") {
    selected = new Date(value);
  }
  return (
    <div className={`${className ? className : "mb-8"}`}>
      <Label meta={meta} label={label} />
      <DatePicker
        {...field}
        {...props}
        onChange={(value) => {
          setValue(value || undefined);
          onChange(value);
        }}
        selected={selected}
        showTimeSelect
        showTimeSelectOnly
        dateFormat={"HH:mm"}
        timeFormat="HH:mm"
        timeIntervals={30}
        disabled={disabled}
        autoComplete={"off"}
        wrapperClassName="w-full"
        className={`${inputClassName} appearance-none bg-transparent ${getBorderColor(
          meta,
          !props.readOnly
        )}
           w-full py-2 text-${color} leading-tight focus:outline-none focus:border-red`}
        customInput={
          <CustomTimePicker ref={ref} autoComplete={"off"} {...props} />
        }
      />
      {meta.touched && meta.error ? (
        <p className="text-red-500 text-xs italic mt-2">{meta.error}</p>
      ) : null}
    </div>
  );
}

export function DateTimePickerInput({
  label,
  className = "",
  inputClassName = "",
  color = "black",
  isEditing = false,
  fullWidth = false,
  disableClock = true,
  onChange = noop,
  disabled,
  customError,
  ...props
}) {
  const [{ value, ...field }, meta, { setValue, setTouched }] = useField(props);
  const ref = React.createRef();
  let selected = value;
  if (typeof value === "string" && value !== "") {
    selected = new Date(value);
  }
  return (
    <div className={`${className ? className : "mb-8"}`}>
      <Label meta={meta} label={label} />
      <DatePicker
        {...field}
        {...props}
        onChange={(value) => {
          setTouched(true);
          setValue(value || undefined);
          onChange(value);
        }}
        selected={selected}
        showTimeSelect={!disableClock}
        dateFormat={disableClock ? "dd/MM/yyyy" : "dd/MM/yyyy HH:mm"}
        timeFormat="HH:mm"
        disabled={disabled}
        autoComplete={"off"}
        wrapperClassName="w-full"
        className={`${inputClassName} appearance-none bg-transparent ${getBorderColor(
          meta,
          !props.readOnly
        )}
           w-full py-2 text-${color} leading-tight focus:outline-none focus:border-red`}
        customInput={
          <CustomDatePicker
            ref={ref}
            disableClock={disableClock}
            autoComplete={"off"}
            {...props}
          />
        }
      />
      {meta.touched && meta.error ? (
        <p className="text-red-500 text-xs italic mt-2">{meta.error}</p>
      ) : null}
     {customError ? (
         <p className="text-red-500 text-xs italic mt-2">{customError}</p>
     ) : null}
    </div>
  );
}

const CustomDatePicker = forwardRef(
  ({ onClick, value, onChange, onKeyDown, disableClock, ...props }, _ref) => {
    const mask = disableClock
      ? [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
      : [
          /\d/,
          /\d/,
          "/",
          /\d/,
          /\d/,
          "/",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          " ",
          /\d/,
          /\d/,
          ":",
          /\d/,
          /\d/,
        ];
    return (
      <MaskedInput
        type="text"
        onClick={onClick}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={_ref}
        value={value}
        mask={mask}
        {...props}
      />
    );
  }
);

const CustomTimePicker = forwardRef(
  ({ onClick, value, onChange, onKeyDown, ...props }, _ref) => {
    const mask = [/\d/, /\d/, ":", /\d/, /\d/];
    return (
      <MaskedInput
        type="text"
        onClick={onClick}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={_ref}
        value={value}
        mask={mask}
        {...props}
      />
    );
  }
);

export default Input;
