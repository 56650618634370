import React from "react";
import { size } from "lodash-es";
import { Link } from "react-router-dom";
import Block from "../Block";
import Button from "../Button";
import { useQuerySynchronisationErrors } from "../../api/synchronisationError";

function SynchronisationErrors() {
  const { data } = useQuerySynchronisationErrors();
  const nbError = size(data);
  if (nbError > 0) {
    return (
      <div className={"w-full md:w-1/3 p-2 h-64"}>
        <Block className="text-center flex-grow bg-white">
          <Link
            to={`/synchronisation-errors`}
            className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
          >
            <div
              className={`bg-red-400 inline-flex text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
            >
              {nbError}
            </div>
            <div className="mt-3 text-xl font-black">
              {`Erreur${nbError > 1 ? "s" : ""}`}
            </div>
            <div className="mt-2 text-base font-light">
              {`de synchronisation de GED`}
            </div>
            <Button
              className={`font-bold p-1 focus:outline-none btn--outline--red--reversed w-full block`}
            >
              Résoudre
            </Button>
          </Link>
        </Block>
      </div>
    );
  }
  return null;
}

export default SynchronisationErrors;
