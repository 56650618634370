import React, { useState } from "react";
import { get, map, reduce, size } from "lodash-es";
import { useDebounce } from "react-use";
import Alert from "../components/Alert";
import { Helmet } from "react-helmet";
import { PermissionChecker } from "../contexts/permissions";
import { ReactComponent as PlusIcon } from "../svgs/plus.svg";
import Spinner from "../components/Spinner";
import { useQueryUsers } from "../api/user";
import UserForm from "../forms/UserForm";
import Modal from "../components/Modal";
import {
  useCreateOrUpdatePermission,
  useDeletePermission,
  useQueryPermissions,
} from "../api/permissions";
import { getFirstnameLastname } from "../utils/names";
import { ReactComponent as PenIcon } from "../svgs/pen.svg";
import { ReactComponent as DuplicateIcon } from "../svgs/docs-entreprise.svg";
import CreateProfile from "../forms/CreateProfile";
import NavBar from "./Administration/NavBarPermissions";
import { ReactComponent as TrashIcon } from "../svgs/trash.svg";
import ProfileForm from "../ProfileForm";
import Dropdown from "../components/Dropdown";
import { HeaderBar } from "../components/HeaderBar";

function UsersList({ currentProfile, onDelete }) {
  const { data, totalItems } = useQueryUsers({
    permission:
      currentProfile && currentProfile["@id"] ? currentProfile["@id"] : null,
    "exists[permission]": currentProfile["@id"] !== undefined,
    "collaborator.active": "true",
  });

  return (
    <>
      <div className="flex justify-between items-center mb-5">
        <div>
          <div className="mb-1 flex">
            <ProfileForm profile={currentProfile} />
          </div>
          <div className="flex justify-between">
            <div className="uppercase g">{totalItems} personnes</div>
            <CreateUserModal profile={currentProfile} />
          </div>
        </div>
        <div className="flex gap-2">
          {currentProfile && currentProfile["@id"] ? (
            <PermissionChecker permissions={["kdix.actions.user.manage"]}>
              <DuplicatePermission profile={currentProfile} />
              <DeletePermissionButton
                profile={currentProfile}
                onDelete={onDelete}
              />
            </PermissionChecker>
          ) : null}
        </div>
      </div>
      {size(data) <= 0 ? (
        <div className="mt-12">
          <Alert
            type="warning"
            message={`aucun utilisateur "${currentProfile?.label}"`}
          />
        </div>
      ) : (
        <div>
          {map(data, (node) => (
            <UserLine key={node.id} user={node} />
          ))}
        </div>
      )}
    </>
  );
}

function DeletePermissionButton({ profile, onDelete }) {
  const [deletePermission] = useDeletePermission();
  return (
    <div>
      <button
        className="bg-red-500 hover:bg-red-600 text-white rounded-full w-12 h-12 flex justify-center items-center focus:outline-none"
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          const res = window.confirm("La suppression sera définitive");
          if (res) {
            try {
              await deletePermission(profile.id);
              onDelete();
            } catch (error) {
              console.warn(error);
            }
          }
        }}
      >
        <TrashIcon className="w-6 h-6 text-white fill-current" />
      </button>
    </div>
  );
}

function UsersListSearching({ query }) {
  const { data } = useQueryUsers({
    orSearch_fullname: query,
    "order[username]": "ASC",
    "collaborator.active": "true",
  });
  const sortUsers = reduce(
    data,
    (result, user) => {
      const permissionLabel = get(
        user,
        "permission.label",
        "Utilisateurs sans profil"
      );
      (result[permissionLabel] || (result[permissionLabel] = [])).push(user);
      return result;
    },
    {}
  );
  return (
    <>
      <div className="text-3xl font-bold">Résultat de la recherche</div>
      {map(sortUsers, (users, profile) => (
        <div className="mt-10">
          <div className="flex justify-between items-center mb-5">
            <div>
              <div className="text-2xl font-bold mb-1">Profil {profile}</div>
              <div className="uppercase g">{users.length} personnes</div>
            </div>
          </div>
          <div>
            {map(users, (node) => (
              <UserLine key={node.id} user={node} />
            ))}
          </div>
        </div>
      ))}
    </>
  );
}

function UserLine({ user }) {
  return (
    <div
      key={user.id}
      className="grid grid-cols-5 py-4 text-lg border-solid border-b border-gray-200 first:border-t"
    >
      <div className="col-span-2 flex gap-4 items-center">
        <span>
          {user.collaborator
            ? getFirstnameLastname(user, "collaborator")
            : user.username}
        </span>
        {user.collaborator?.currentContract?.job?.label && (
          <>
            <span>-</span>
            <span className="text-sm">
              {user.collaborator?.currentContract?.job?.label}
            </span>
          </>
        )}
      </div>
      <div className="flex col-span-2">{user.username}</div>
      <div className="flex justify-end">
        <PermissionChecker permissions={["kdix.actions.user.manage"]}>
          <EditUserModal user={user} />
        </PermissionChecker>
      </div>
    </div>
  );
}

function DuplicatePermission({ profile }) {
  const [isOpen, setIsOpen] = useState(false);
  const [createOrUpdatePermission] = useCreateOrUpdatePermission();
  return (
    <div>
      <button
        className="bg-purple-600 hover:bg-purple-800 text-white rounded-full w-12 h-12 flex justify-center items-center focus:outline-none"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <DuplicateIcon className="fill-current text-white w-6 h-6" />
      </button>

      <Modal
        title={`Dupliquer le profil "${get(profile, "label")}"`}
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
      >
        <CreateProfile
          duplicate={true}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await createOrUpdatePermission({
              data: {
                label: values.label,
                permissionActions: [
                  ...map(
                    get(profile, "permissionActions", []),
                    ({ "@id": uri, "@type": type, id, ...permissionAction }) =>
                      permissionAction
                  ),
                ],
              },
            });
            setSubmitting(false);
            setIsOpen(false);
            resetForm();
          }}
        />
      </Modal>
    </div>
  );
}

function EditUserModal({ user }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <button
        className="bg-orange-400 hover:bg-orange-500 text-white rounded-full w-10 h-10 flex justify-center items-center focus:outline-none"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <PenIcon className="w-4 h-4 fill-current text-white" />
      </button>
      <Modal
        className="outline-none w-full max-w-full lg:max-w-xl max-h-full"
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title="Modification d'un utilisateur"
      >
        {!user.collaborator && (
          <div className="mb-2">
            Cet utilisateur n'est pas lié à un collaborateur
          </div>
        )}
        <UserForm
          user={user}
          onSuccess={() => {
            setIsOpen(false);
          }}
        />
      </Modal>
    </>
  );
}

function Header({ setQuery, query }) {
  return (
    <HeaderBar title="Gestion des profils" buttons={<CreateDropdown />}>
      <input
        type="text"
        name="query"
        value={query}
        className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
        placeholder="Rechercher un utilisateur"
        onChange={(e) => setQuery(e.target.value)}
      />
    </HeaderBar>
  );
}

export function CreateOrUpdateProfilModal({ modalIsOpen, setIsOpen, isEditing = false, values = null }) {
  const [createOrUpdatePermission] = useCreateOrUpdatePermission();
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title={isEditing ? "Modification d'un profil" : "Création d'un profil"}
      >
        <CreateProfile
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await createOrUpdatePermission({
                id: values.id ? values.id : null,
                data: {
                    label: values.label,
                    isAdmin: values.isAdmin
                },
            });
            setSubmitting(false);
            resetForm();
            setIsOpen(false);
          }}
          isEditing={isEditing}
          values={values}
        />
      </Modal>
    </>
  );
}

function CreateUserModal({ modalIsOpen, setIsOpen }) {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      title="Création d'un utilisateur"
      className="outline-none w-full max-w-full lg:max-w-xl max-h-full"
    >
      <UserForm
        onSuccess={() => {
          setIsOpen(false);
        }}
      />
    </Modal>
  );
}

function CreateDropdown() {
  const permissionsProcess = ["kdix.actions.user.manage"];
  const [modalProfileOpen, setProfileOpen] = React.useState(false);
  const [modalUserOpen, setUserOpen] = React.useState(false);
  return (
    <PermissionChecker permissions={permissionsProcess}>
      <div className="cursor-pointer">
        <Dropdown
          label={
            <div className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none z-10">
              <PlusIcon className="w-4 h-4" />
            </div>
          }
          arrow={false}
          childrenClassName="bg-gray-700 text-white shadow-lg z-10 mx-2"
          childrenStyle={{ minWidth: "280px", right: "2rem" }}
          relative={false}
        >
          <PermissionChecker permissions={["kdix.actions.user.manage"]}>
            <div
              className={"text-white flex p-4 hover:text-black hover:bg-white"}
              onClick={() => {
                setProfileOpen(true);
              }}
            >
              <span>Créer un nouveau profil</span>
            </div>
            <div
              className={"text-white flex p-4 hover:text-black hover:bg-white"}
              onClick={() => {
                setUserOpen(true);
              }}
            >
              <span>Créer un nouvel utilisateur</span>
            </div>
          </PermissionChecker>
        </Dropdown>
      </div>
      <CreateOrUpdateProfilModal
        setIsOpen={setProfileOpen}
        modalIsOpen={modalProfileOpen}
      />
      <CreateUserModal modalIsOpen={modalUserOpen} setIsOpen={setUserOpen} />
    </PermissionChecker>
  );
}

function UserAdminPage() {
  const [query, setQuery] = useState("");
  const { data: permissions } = useQueryPermissions({ "order[label]": "ASC" });
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );
  const [currentProfilePath, setCurrentProfilePath] = useState(0);

  const profiles = [
    ...permissions,
    { id: null, label: "Utilisateurs sans profil" },
  ];
  const currentProfile = get(profiles, currentProfilePath);

  return (
    <div>
      <Helmet>
        <title>Gestion des profils</title>
      </Helmet>
      <Header setQuery={setQuery} query={query} />
      <div className="px-8">
        <NavBar
          profiles={profiles}
          setCurrentProfile={(profile) => {
            setQuery("");
            setCurrentProfilePath(profile);
          }}
          currentProfile={debouncedQuery.length === 0 ? currentProfile : null}
        />
      </div>
      <div className="px-8 mb-48 mt-16 flex justify-center">
        <div className="md:w-2/3 w-full">
          <React.Suspense fallback={<Spinner />}>
            {debouncedQuery.length === 0 ? (
              <UsersList
                currentProfile={currentProfile}
                onDelete={() => {
                  setCurrentProfilePath(0);
                }}
              />
            ) : (
              <UsersListSearching query={debouncedQuery} />
            )}
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default UserAdminPage;
