import {
  useCreateTypeVehicle,
  useDeleteTypeVehicle,
  useQueryTypeVehicle,
  useUpdateTypeVehicle
} from "../api/typeVehicle";

import ManageResource from "../components/ManageResource";
import React from "react";

function TypeVehicleAdmin() {
  return (
    <ManageResource
      resourceLabel="Types de véhicules"
      createLabel="Ajouter un type de véhicule"
      createHook={useCreateTypeVehicle}
      queryHook={useQueryTypeVehicle}
      deleteHook={useDeleteTypeVehicle}
      updateHook={useUpdateTypeVehicle}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label"
        }
      }}
    />
  );
}

export default TypeVehicleAdmin;
