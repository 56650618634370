import { useMedia } from "react-use";

export const useDesktop = () => {
  return useMedia("(min-width: 1280px)");
};

export const useMobile = () => {
  return useMedia("(max-width: 640px)");
};

export const testIsPortrait = () => {
  return !window.screen.orientation.angle;
};
