import * as Yup from "yup";
import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import Alert from "../components/Alert";
import { DateTimePickerInput } from "../components/Input";
import React from "react";
import { usePermissionsChecker } from "../contexts/permissions";
import Button from "../components/Button";
import Select from "../components/Select";
import { useQueryAgencies } from "../api/agencies";
import SelectCollaborator from "../components/SelectCollaborator";

const validation = Yup.object().shape({
  agency: Yup.object(),
  date: Yup.date().required("Requis"),
  collaborator: Yup.object(),
});

function VehicleAllocationFormBase({ initialValues, onSubmit, error }) {
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.vehicle.edit",
      "kdix.actions.vehicle.edit.agency",
      "kdix.actions.vehicle.edit.department",
      "kdix.actions.vehicle.edit.service",
      "kdix.actions.vehicle.edit.own",
    ],
  });

  const { data: agencies } = useQueryAgencies();

  return (
    <div>
      <Formik
        validationSchema={validation}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, submitCount }) => {
          return (
            <Form>
              <>
                <Select
                  name="agency"
                  label="Site"
                  isDisabled={readOnly}
                  options={map(agencies, (agency) => ({
                    label: `${agency.label}`,
                    value: agency["@id"],
                  }))}
                  value={values.agency.value !== "" ? values.agency : null}
                  isLoading={!agencies}
                />
              </>
              <>
                <SelectCollaborator
                  isDisabled={readOnly}
                  name="collaborator"
                  label="Conducteur"
                  value={values.collaborator}
                  addOptions={[{
                    label: "Aucun",
                    value: null,
                  }]}
                />
              </>
              <DateTimePickerInput
                disabled={readOnly}
                name="date"
                label="Attribué depuis le"
              />

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 w-full block`}
                readOnly={readOnly}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default VehicleAllocationFormBase;
