import React from "react";
import { get } from "lodash-es";
import { getLocaleDateString } from "../../utils/date";
import { getFirstnameLastnameJob } from "../../utils/names";

export function alertTitleResidencePermitExpiration(alert) {
  return {
    title: get(alert, "typeAlert.label", ""),
    subtitles: [
      {
        label: "Date de rappel",
        value: getLocaleDateString(alert, "reminderDate"),
      },
      {
        label: "Date de fin de validité",
        value: `${
          get(alert, "personalInformation.residencePermitExpirationDate")
            ? getLocaleDateString(
                alert,
                "personalInformation.residencePermitExpirationDate"
              )
            : ""
        }`,
      },
      {
        label: "Collaborateur",
        value: getFirstnameLastnameJob(
          alert,
          "personalInformation.collaborator"
        ),
      },
    ],
  };
}

function AlertResidencePermitExpirationItem({ alert }) {
  return (
    <>
      {get(alert, "typeAlert.comment") && (
        <p>
          <span className="font-bold">Commentaire : </span>
          {get(alert, "typeAlert.comment")}
        </p>
      )}
    </>
  );
}

export default AlertResidencePermitExpirationItem;
