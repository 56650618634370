import { queryCache, useMutation } from "react-query";

import queryWithToken from "./index";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import mime from "mime-types";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "medical_visit_certificates";
//

export async function downloadDocument(
  id,
  label = "document",
  extension = "pdf"
) {
  const blob = await queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/document`,
    {
      method: "GET",
      responseType: "blob",
    }
  );
  if (blob) {
    const type = mime.lookup(extension);
    const file = new Blob([blob], { type });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}

export async function previewDocument(id, label = "document") {
  const blob = await queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/document`,
    {
      method: "GET",
      responseType: "blob",
    }
  );
  if (blob) {
    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  }
}

export function useCreateDocument() {
  return useMutation(
    ({ file, objectId }) => {
      const data = new FormData();
      data.append("file", file);
      data.append("medicalVisitId", objectId);

      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
        {
          method: "POST",
          data,
        }
      );
    },
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de l'envoi");
      },
      onMutate: () => {
        queryCache.cancelQueries("folders");
      },
      onSuccess: () => {
        queryCache.refetchQueries("folders");
      },
    }
  );
}
export function useDeleteDocument() {
  return useMutation(
    (id) =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}`, {
        method: "DELETE",
      }),
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de la suppression");
      },
      onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
      },
      onSuccess: () => {
        queryCache.refetchQueries(ressourceKey);
      },
    }
  );
}

export async function downloadMedicalVisitCertificate(
  id,
  label = "certificat_visite_medicale"
) {
  const blob = await queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/document`,
    {
      method: "GET",
      responseType: "blob",
    }
  );
  if (blob) {
    saveAs(blob, label);
  }
}

export function useCreateMedicalVisitCertificate() {
  return useMutation(
    ({ file, medicalVisitId }) => {
      const data = new FormData();
      data.append("file", file);
      data.append("medicalVisitId", medicalVisitId);

      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
        {
          method: "POST",
          data,
        }
      );
    },
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de l'envoi");
      },
      onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
      },
      onSuccess: () => {
        queryCache.refetchQueries("personal_informations");
      },
    }
  );
}
