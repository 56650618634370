import React from "react";
import { Form, Formik } from "formik";
import { get, intersectionBy, map } from "lodash-es";
import { usePermissionsChecker } from "../../contexts/permissions";
import {
  useCreateOrUpdateProcessTask,
  useDeleteProcessTask,
} from "../../api/processTask";
import GoBackBtn from "../GoBackBtn";
import Input from "../Input";
import TextArea from "../TextArea";
import { toast } from "react-toastify";
import { useToggle } from "react-use";
import ExpansionPanel from "../ExpansionPanel";
import Select from "../Select";
import { useQueryPermissions } from "../../api/permissions";
import * as Yup from "yup";
import Button from "../Button";
import Block from "../Block";

const validation = Yup.object().shape({
  delay: Yup.number()
    .required("Requis")
    .integer("Doit être un nombre entier positif ou négatif"),
  label: Yup.string().required("Requis"),
  associatedPermissions: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
  url: Yup.string().url("Doit être une url valide"),
});

function ProcessTask({
  updateHook,
  deleteHook,
  task,
  canEdit,
  permissions,
  newFormToggle,
  isOpen = false,
}) {
  return (
    <ExpansionPanel
      className={"mt-2"}
      open={isOpen}
      title={get(task, "label", "Nouvelle tâche")}
    >
      <Formik
        initialValues={{
          label: get(task, "label", ""),
          delay: get(task, "delay", 0),
          url: get(task, "url", ""),
          comment: get(task, "comment", ""),
          associatedPermissions: {
            label: get(
              task,
              "associatedPermissions[0].label",
              "sélectionner ..."
            ),
            value: get(task, "associatedPermissions[0][@id]", ""),
          },
        }}
        validationSchema={validation}
        onSubmit={async (values, actions) => {
          try {
            await updateHook({
              id: get(task, "id", ""),
              data: {
                ...values,
                processInOut: get(task, "processInOut"),
                associatedPermissions: values.associatedPermissions.value
                  ? [values.associatedPermissions.value]
                  : [],
              },
            });
            if (get(task, "id", "") === "") {
              actions.resetForm();
              newFormToggle();
            }
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          } finally {
            actions.setSubmitting(false);
          }
          toast.success("Mise à jour effectuée avec succès");
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div
                className={
                  "flex flex-wrap justify-between sm:flex-row flex-col"
                }
              >
                <Input
                  type="text"
                  label="Nom de la tâche"
                  name="label"
                  readOnly={!canEdit}
                  className={"flex-1 mr-8 mb-4 flex-grow-3"}
                />
                <Input
                  name="delay"
                  label="Délai du rappel"
                  type="number"
                  readOnly={!canEdit}
                  className={"flex-1 mr-8 mb-4"}
                />
                <Select
                  label={"Type de profil"}
                  key={"associatedPermissions"}
                  readOnly={!canEdit}
                  name={"associatedPermissions"}
                  options={map(permissions, (node) => ({
                    label: node.label,
                    value: get(node, "@id"),
                  }))}
                  transformResourceValue={(value) => {
                    return map(
                      intersectionBy(permissions, value, "@id"),
                      (item) => ({
                        label: item.label,
                        value: item["@id"],
                      })
                    );
                  }}
                  className="mb-4 flex-1 flex-grow-2"
                  isClearable={true}
                />
              </div>
              <Input name="url" label="url" className={"mb-4"} />
              <TextArea
                label="Description"
                name="comment"
                textareaClassName="comment"
                readOnly={!canEdit}
              />
              <div className={`${canEdit ? "text-center mt-2" : "hidden"}`}>
                {canEdit ? (
                  <Button
                    className={`btn--outline inline-block mx-2`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="submit"
                    textLabel={
                      get(task, "id", "") !== "" ? "Enregistrer" : "Créer"
                    }
                  />
                ) : null}
                {canEdit ? (
                  <Button
                    className={`btn--error inline-block mx-2`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="button"
                    textLabel="Supprimer"
                    onClick={() => {
                      const res = window.confirm(
                          "Attention, la suppression du processus entrainera la suppression de ceux de ce type qui sont démarrés. Continuer ?"
                      );
                      if (res) {
                        deleteHook(get(task, "id"))
                      }
                    }}
                  />
                ) : null}
                {get(task, "id", "") === "" ? (
                  <Button
                    className={`bg-orange-500 hover:bg-orange-700 inline-block mx-2`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="button"
                    textLabel="Annuler"
                    onClick={newFormToggle}
                  />
                ) : null}
              </div>
            </Form>
          );
        }}
      </Formik>
    </ExpansionPanel>
  );
}

export default function ProcessDetail({ process }) {
  const { data: permissions } = useQueryPermissions();
  const [createOrUpdateTask] = useCreateOrUpdateProcessTask(
    process.id.toString()
  );
  const [deleteHook] = useDeleteProcessTask(process.id.toString());
  const [newFormOn, newFormToggle] = useToggle(false);
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.process_in_out.edit",
      "kdix.actions.process_in_out.edit.agency",
      "kdix.actions.process_in_out.edit.department",
      "kdix.actions.process_in_out.edit.service",
      "kdix.actions.process_in_out.edit.own",
    ],
  });

  const { isOut } = process;
  const tasks = process.tasks.sort((a, b) => {
    return b.delay - a.delay;
  });

  return (
    <div>
      <div className={"w-20"}>
        <GoBackBtn
          to={`/processus/${isOut ? "sortie" : "entree"}`}
          className="flex items-center"
        >
          <div className="font-bold">Retour</div>
        </GoBackBtn>
      </div>

      <div className={"mt-1"}>
        {map(tasks, (task) => {
          return (
            <ProcessTask
              key={task.id}
              task={{ ...task, processInOut: process["@id"] }}
              canEdit={canEdit}
              updateHook={createOrUpdateTask}
              deleteHook={deleteHook}
              permissions={permissions}
            />
          );
        })}
      </div>

      {newFormOn ? (
        <ProcessTask
          updateHook={createOrUpdateTask}
          permissions={permissions}
          task={{ processInOut: process["@id"] }}
          canEdit={canEdit}
          newFormToggle={newFormToggle}
          isOpen={true}
        />
      ) : (
        <div onClick={newFormToggle}>
          <Block
            customStyle={
              "text-center border-dashed border-2 border-gray-400 cursor-pointer"
            }
          >
            <Button
              className={`btn inline-block my-3`}
              isForm={false}
              type="button"
            >
              Ajouter une tâche
            </Button>
          </Block>
        </div>
      )}
    </div>
  );
}
