import React from "react";
import { ReactComponent as StarIcon } from "../svgs/star-fill.svg";
import { times } from "lodash-es";

export default function StarScore({
  score,
  maxScore = 5,
  colorStar = "text-yellow-500",
}) {
  if (!score) return null;
  return (
    <div className="flex gap-1">
      {times(maxScore, (n) => (
        <StarIcon
          key={n}
          className={`w-6 h-6 fill-current ${
            n < score ? colorStar : "text-gray-100"
          }`}
        />
      ))}
    </div>
  );
}
