import axios from "axios";
import { get } from "lodash-es";
import { logout, refresh } from "../graphql";
import { isTokenValid, refreshTokenExpiration } from "../api";

function downloadWithToken(url, options = {}) {
  return queryWithToken(url, options, true);
}

export default async function queryWithToken(
  url,
  options = {},
  isDownload = false
) {
  try {
    const token = window.localStorage.getItem("token");
    const headers = isDownload
      ? {
          "content-type": "application/octet-stream",
        }
      : {
          "content-type": "application/ld+json",
        };
    const { data } = await axios(url, {
      ...options,
      headers: {
        Authorization: `bearer ${token}`,
        ...headers,
        ...options.headers,
      },
    });

    return data;
  } catch (error) {
    if (
      get(error, "response.data.message") === "Expired JWT Token" ||
      get(error, "response.data.message") === "Invalid JWT Token"
    ) {
      const token = window.localStorage.getItem("token");

      const refreshToken = window.localStorage.getItem("refreshToken");
      if (token && !isTokenValid(token) && refreshToken) {
        try {
          const { token, refreshToken: newRefreshToken } =
            await refreshTokenExpiration(refreshToken);
          if (token && newRefreshToken) {
            refresh(token, newRefreshToken);
            return;
          }
        } catch (e) {
          logout();
        }
      }
      logout();
      return;
    }
    if (error.response?.data) {
      const errorObj = {
        title: get(error, "response.data.hydra:title"),
        description: get(error, "response.data.hydra:description"),
        violations: get(error, "response.data.violations"),
        fromServer: true,
      };

      throw errorObj;
    } else {
      throw error.message;
    }
  }
}

export const create = (key, data) =>
  queryWithToken(`${process.env.REACT_APP_API_URL}/${key}`, {
    method: "POST",
    data,
  });

export const find = (key, filters, page) => {
  return queryWithToken(`${process.env.REACT_APP_API_URL}/${key}`, {
    method: "GET",
    params: {
      page: page ? page : null,
      ...filters,
    },
  });
};

export const findById = (key, id) =>
  queryWithToken(`${process.env.REACT_APP_API_URL}/${key}/${id}`, {
    method: "GET",
  });

export const updateById = (key, { id, data }) => {
  return queryWithToken(`${process.env.REACT_APP_API_URL}/${key}/${id}`, {
    method: "PATCH",
    data,
  });
};

export const putById = (key, { id, data }) => {
  return queryWithToken(`${process.env.REACT_APP_API_URL}/${key}/${id}`, {
    method: "PUT",
    data,
  });
};

export const updateOrCreate = (key, { id, data }) => {
  return id ? updateById(key, { id, data }) : create(key, data);
};

export const putOrCreate = (key, { id, data }) => {
  return id ? putById(key, { id, data }) : create(key, data);
};

export const deleteById = (key, id) => {
  return   queryWithToken(`${process.env.REACT_APP_API_URL}/${key}/${id}`, {
    method: "DELETE",
  });
}

export const duplicateById = (key, id) => {
  return   queryWithToken(`${process.env.REACT_APP_API_URL}/${key}/duplicate/${id}`, {
    method: "GET",
  });
}

export const findExport = (key, filters) => {
  return downloadWithToken(`${process.env.REACT_APP_API_URL}/${key}/export`, {
    method: "GET",
    params: {
      ...filters,
    },
  });
};
