import { Form, Formik } from "formik";
import React, { useState } from "react";
import { get, map } from "lodash-es";
import Alert from "../Alert";
import EditButton from "../HistoryItem/EditButton";
import HistoryItem from "../HistoryItem/HistoryItem";
import Input, { DateTimePickerInput } from "../Input";
import { ReactComponent as StarIcon } from "../../svgs/star.svg";
import Select from "../Select";
import { useQueryTypeEventVehicles } from "../../api/typeEventVehicle";
import {
  useDeleteEventVehicle,
  useUpdateEventVehicle,
} from "../../api/eventVehicle";
import { toast } from "react-toastify";
import TextArea from "../TextArea";

export default function EventVehicleHistoryItem({ event = {}, date, isLeft }) {
  const [isEditing, setIsEditing] = useState(false);
  const [update, { error }] = useUpdateEventVehicle();
  const [deleteEvent, { status }] = useDeleteEventVehicle(true);
  const { data: typesEvent } = useQueryTypeEventVehicles();
  if (status === "success") return null;
  const currentAction = get(event, "type", false);
  return (
    <Formik
      initialValues={{
        comment: event.comment || "",
        mileage: event.mileage || "",
        mileageNextEvent: event.mileageNextEvent || "",
        date: get(event, "date") ? new Date(get(event, "date")) : null,
        dateNextEventOfThisType: get(event, "dateNextEventOfThisType")
          ? new Date(get(event, "dateNextEventOfThisType"))
          : null,
        type: currentAction
          ? {
              label: currentAction.label,
              value: currentAction["@id"],
            }
          : {
              label: "",
              value: "",
            },
      }}
      onSubmit={async (values, actions) => {
        try {
          await update({
            id: event.id,
            data: {
              ...values,
              date: values.date ? values.date : null,
              dateNextEventOfThisType: values.dateNextEventOfThisType
                ? values.dateNextEventOfThisType
                : null,
              type: values.type.value,
              mileage: parseInt(values.mileage, 10),
              mileageNextEvent: parseInt(values.mileageNextEvent, 10),
            },
          });
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
        } finally {
          actions.setSubmitting();
          setIsEditing(false);
          toast.success("Mise à jour effectuée avec succès");
        }
      }}
    >
      {({ isSubmitting, resetForm, setSubmitting, values }) => {
        return (
          <Form>
            <HistoryItem
              title={
                get(event, "type.label", "") !== ""
                  ? `Évènement - ${get(event, "type.label", "")}`
                  : "Évènement"
              }
              Icon={StarIcon}
              backgroundIcon="bg-yellow-500"
              date={`${date ? new Date(date).toLocaleDateString() : ""}`}
              itemId={event.id}
              isToggle={true}
              textColor={"text-yellow-500"}
              isLeft={isLeft}
            >
              <div className={"flex flex-wrap"}>
                <div className={"w-full lg:w-1/2 lg:pr-2"}>
                  <Select
                    name="type"
                    label="Type d'action"
                    placeholder="Type d'action *"
                    options={map(typesEvent, (typeEvent) => ({
                      label: typeEvent.label,
                      value: typeEvent["@id"],
                    }))}
                    isLoading={!typesEvent}
                    isDisabled={!isEditing}
                  />
                  <DateTimePickerInput
                    name="date"
                    label="Date de réalisation"
                    readOnly={!isEditing}
                  />
                  <DateTimePickerInput
                    name="dateNextEventOfThisType"
                    label="Date de la prochaine action"
                    readOnly={!isEditing}
                  />
                </div>
                <div className={"w-full lg:w-1/2 lg:pl-2"}>
                  <Input
                    type="number"
                    name="mileage"
                    label="Kilométrage actuel"
                    readOnly={!isEditing}
                  />
                  <Input
                    type="number"
                    name="mileageNextEvent"
                    label="Kilométrage de la prochaine action"
                    readOnly={!isEditing}
                  />
                </div>
              </div>
              <div className={"pb-4"}>
                <TextArea
                  label="Commentaire"
                  name="comment"
                  textareaClassName="comment"
                  readOnly={!isEditing}
                />
              </div>
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <EditButton
                onDelete={() => {
                  setSubmitting(true);
                  deleteEvent(event.id);
                }}
                isSubmitting={isSubmitting}
                isEditing={isEditing}
                onCancel={() => {
                  resetForm();
                  setIsEditing(!isEditing);
                }}
                permissionsEdit={[
                  "kdix.actions.vehicle.edit",
                  "kdix.actions.vehicle.edit.agency",
                  "kdix.actions.vehicle.edit.department",
                  "kdix.actions.vehicle.edit.service",
                  "kdix.actions.vehicle.edit.own",
                ]}
                onClick={() => {
                  setIsEditing(!isEditing);
                }}
              />
            </HistoryItem>
          </Form>
        );
      }}
    </Formik>
  );
}
