import queryWithToken from "./index";
import { useQuery } from "react-query";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "statistics";
//

export function useQueryStatisticsByDomainId(id) {
  return useQuery(
    id && [ressourceKey, id],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/domains/${id}/statistics`,
        {
          method: "GET",
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
}
