import React from "react";

import Alert from "./Alert";

function NotAllowed() {
  return (
    <div className="container px-8 mt-24 mx-auto">
      <Alert
        type="error"
        message="Ressource indisponible"
        details={`Votre niveau d'accès ne vous permet pas de consulter cette ressource`}
      />
    </div>
  );
}

export default NotAllowed;
