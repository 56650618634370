import React from "react";

/**
 * Return the formatted percentage between two value
 */
export default function VariationBetweenYear({ previous = 0, next = 0 }) {
  const percentage =
    previous === 0 ? (next === 0 ? 0 : 100) : Math.round(((next - previous) / previous) * 100);

  if (!Number.isInteger(percentage) || percentage === 0) {
    return <div className="text-gray-300">+ 0% par rapport à l'an dernier</div>;
  }
  const sign = Math.sign(percentage);

  if (sign > 0) {
    return (
      <div className="text-green-900">
        + {Math.abs(percentage)}% par rapport à l'an dernier
      </div>
    );
  } else {
    return (
      <div className="text-red-900">
        - {Math.abs(percentage)}% par rapport à l'an dernier
      </div>
    );
  }
}
