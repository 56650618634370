import React from "react";
import { get, map } from "lodash-es";
import Block from "../components/Block";
import Alert from "../components/Alert";
import ExpansionPanel from "../components/ExpansionPanel";
import { useQueryTypeAbsences } from "../api/typeAbsences";
import TypeAbsenceForm from "../forms/TypeAbsenceForm";

export default function AbsencesAdmin() {
  const { data, error } = useQueryTypeAbsences();
  if (error) {
    return (
      <Alert
        type="error"
        message={get(error, "title", "Erreur")}
        details={get(
          error,
          "description",
          `Récupération des Types d'absences impossible`
        )}
      />
    );
  }

  return (
    <>
      <div className="p-8 pb-24">
        <Block>
          <div className="font-bold text-xl leading-tight mb-8">
            Administration types d'absences
          </div>
          <Block>
            <div className="font-bold text-lg leading-tight mb-8">
              Ajouter un type d'absence
            </div>
            <TypeAbsenceForm />
          </Block>
          {map(data, (typeAbsence, index) => {
            return (
              <ExpansionPanel key={index} title={typeAbsence.label} customStyle={typeAbsence.active ? "default" : "gray100"}>
                <TypeAbsenceForm typeAbsence={typeAbsence} />
              </ExpansionPanel>
            );
          })}
        </Block>
      </div>
    </>
  );
}
