const VALID = "Validé";
const REFUSED = "Refusé";
const PENDING = "En attente";

export function getLabelCpamByValue(accepted) {
  return accepted === true ? VALID : accepted === false ? REFUSED : PENDING;
}

export function getCpamValueForApi(value) {
  return value === 1 ? true : value === 0 ? false : null;
}

export function getCpamValueForSelect(value) {
  return value === true ? 1 : value === false ? 0 : -1;
}

export function getSelectValues() {
  return [
    {
      value: 1,
      label: VALID
    },
    {
      value: 0,
      label: REFUSED
    },
    {
      value: -1,
      label: PENDING
    }
  ];
}
