import Block from "../Block";
import React from "react";
import { useToggle } from "react-use";
import { noop } from "lodash-es";
import { useDesktop } from "../../hooks/useDesktop";

function HistoryItemHeader({
  title,
  subTitle,
  children,
  isToggle,
  toggle,
  on,
  textColor,
  titleColor,
}) {
  return (
    <div
      className={`flex items-center bg-gray-100 bg-opacity-50 p-6 -mx-4 -my-4 ${
        isToggle ? "cursor-pointer" : ""
      }`}
      onClick={isToggle ? toggle : noop}
    >
      <div className="w-full">
        <div className="flex justify-between">
          <div>
            <div className={`text-xl font-bold ${titleColor}`}>{title}</div>
            {subTitle ? <div className="">{subTitle}</div> : null}
          </div>
          {children ? children : null}
        </div>
      </div>
      {isToggle ? (
        <div className={`ml-auto`}>
          <svg
            className={`transform fill-current ${textColor} ${
              on ? "rotate-180" : ""
            }`}
            width="20"
            height="12"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
            />
          </svg>
        </div>
      ) : null}
    </div>
  );
}

export default function HistoryItem({
  title,
  date,
  subTitle,
  backgroundIcon,
  Icon,
  extraHeader,
  children,
  isToggle = false,
  textColor,
  titleColor = "",
  iconStyle = { stroke: "white", strokeWidth: "0.25" },
  isLeft = true,
}) {
  const isDesktop = useDesktop();
  const [on, toggle] = useToggle(false);
  return (
    <div className={"lg:relative lg:py-2"}>
      <div className={`${isDesktop ? "history-timeline-info" : ""}`}>
        <div className={`flex justify-around my-3 lg:my-0`}>
          {Icon ? (
            <div
              className={` ${
                backgroundIcon ? backgroundIcon : ""
              } rounded-full p-3`}
            >
              <Icon className={`w-6 h-6 text-white`} style={iconStyle} />
            </div>
          ) : null}
        </div>
      </div>

      <div
        className={`flex justify-between ${
          !isDesktop ? "flex-col" : isLeft ? "flex-row-reverse" : "flex-row"
        }`}
      >
        <div className={`flex my-3 ${isDesktop ? "history-card" : ""}`}>
          {date ? (
            <div
              className={`bg-gray-50 ${
                isDesktop && isLeft ? "mr-auto" : "ml-auto"
              }`}
            >
              {date}
            </div>
          ) : null}
        </div>
        <Block className={`xl:mb-6 mb-6 ${isDesktop ? "history-card" : ""}`}>
          <HistoryItemHeader
            title={title}
            subTitle={subTitle}
            children={extraHeader}
            toggle={toggle}
            isToggle={isToggle}
            on={on}
            textColor={textColor}
            titleColor={titleColor}
          />
          {children ? (
            <div className={`pt-8 ${on ? "block" : "hidden"}`}>{children}</div>
          ) : null}
        </Block>
      </div>
    </div>
  );
}
