import {
  useCreateTypeWorkAccident,
  useDeleteTypeWorkAccident,
  useQueryTypeWorkAccident,
  useUpdateTypeWorkAccident
} from "../api/typeWorkAccident";

import ManageResource from "../components/ManageResource";
import React from "react";

function TypeWorkAccidentAdmin() {
  return (
    <ManageResource
      resourceLabel="Types d'accidents"
      createLabel="Ajouter un type d'accident"
      createHook={useCreateTypeWorkAccident}
      queryHook={useQueryTypeWorkAccident}
      deleteHook={useDeleteTypeWorkAccident}
      updateHook={useUpdateTypeWorkAccident}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label"
        }
      }}
    />
  );
}

export default TypeWorkAccidentAdmin;
