import { find, get, map } from "lodash-es";
import {
  useCreateTypeRequest,
  useUpdateTypeRequest,
  useQueryTypeRequests,
} from "../api/typeRequest";

import ManageResource from "../components/ManageResource";
import React from "react";
import { useQueryPermissions } from "../api/permissions";
import * as Yup from "yup";
import {has, mapValues, size} from "lodash";

const validation = Yup.object().shape({
  label: Yup.string().required("Requis"),
  assignedPermission: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
});

function specificMapFormValues(formValues) {
  return mapValues(formValues, (formValue, key) => {
    if (key === "permissions") {
      return [formValue.value];
    }

    if (key === "slug") {
      if (formValue === true) {
        return "prevention";
      }
      return "";
    }

    //This means that formValue is a result of a select
    if (has(formValue, "label") && has(formValue, "value")) {
      return formValue.value === "" ? null : formValue.value;
    }
    if (size(formValue) > 0 && typeof formValue === "object") {
      return specificMapFormValues(formValue);
    }
    return formValue === "" ? null : formValue;
  });
}

function TypeRequestsAdmin() {
  const { data: permissions } = useQueryPermissions({
    pagination: false,
    page: null,
  });

  return (
    <ManageResource
      resourceLabel="Types de Demandes"
      createLabel="Ajouter un type de demande"
      noElementsLabel="Aucun type de demande"
      createHook={useCreateTypeRequest}
      queryHook={useQueryTypeRequests}
      updateHook={useUpdateTypeRequest}
      canDeactivate={true}
      canDelete={false}
      denominationLabel="Type de demande"
      toastMessage="Type de demande créé avec succès"
      validation={validation}
      SpecificMapFormValues={(values) => {
        return specificMapFormValues(values);
      }}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
        assignedPermission: {
          label: "Profil qui recevra la demande",
          type: "select",
          resourceValueKey: "assignedPermission",
          initialValue: { label: "", value: "" },
          options: map(permissions, (node) => ({
            label: node.label,
            value: get(node, "@id"),
          })),
          transformResourceValue: (value) => {
            const permission = find(permissions, function (permission) {
              return permission["@id"] === value["@id"];
            });
            return {
              label: permission?.label || "",
              value: permission?.["@id"] || "",
            };
          },
        },
        slug: {
          label: "Type prévention ?",
          type: "checkbox",
          resourceValueKey: "slug",
          transformResourceValue: (value) => {
            return "prevention" === value;
          },
        },
      }}
    />
  );
}

export default TypeRequestsAdmin;
