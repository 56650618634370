import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import {
  PermissionChecker,
  useDomain,
  usePermissionsChecker,
} from "../../contexts/permissions";
import Spinner from "../../components/Spinner";
import { useDesktop } from "../../hooks/useDesktop";
import { HeaderBar } from "../../components/HeaderBar";
import {
  useDeleteSkillDomain,
  useDuplicateSkillDomain,
  useQuerySkillDomainsNames,
  useQuerySkillDomainsById,
} from "../../api/skillDomains";
import SkillDomainForm from "../../forms/SkillDomainForm";
import SimpleList from "../../components/SimpleList";
import { get } from "lodash-es";
import SkillDomainDetail from "../../components/Skill/SkillDomainDetail";
import Dropdown from "../../components/Dropdown";
import { ReactComponent as PlusIcon } from "../../svgs/plus.svg";
import Modal from "../../components/Modal";
import SkillsImportForm from "../../forms/SkillsImportForm";

function DomainDetailPage({ domainId }) {
  const isDesktop = useDesktop();
  const { data: domain } = useQuerySkillDomainsById(domainId);
  return (
    <div>
      <Helmet>
        <title>{get(domain, "label", "")}</title>
      </Helmet>
      <HeaderBar>
        {get(domain, "label", "Détail Domaine de compétence")}
      </HeaderBar>
      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="px-8 mt-8 mb-4">
          <SkillDomainDetail domain={domain} />
        </div>
      </div>
    </div>
  );
}

function CreateDropdown() {
  const permissionsProcess = ["kdix.actions.skill_evaluation.edit_create"];
  const [modalImportOpen, setImportOpen] = React.useState(false);
  const [modalHelpOpen, setModalHelpOpen] = React.useState(false);
  return (
    <PermissionChecker permissions={permissionsProcess}>
      <div className="cursor-pointer">
        <Dropdown
          label={
            <div className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none z-10">
              <PlusIcon className="w-4 h-4" />
            </div>
          }
          arrow={false}
          childrenClassName="bg-gray-700 text-white shadow-lg z-10 mx-2 text-base"
          childrenStyle={{ minWidth: "280px", right: "3rem", top: "6rem" }}
          relative={false}
        >
          <div
            className={"text-white flex p-4 hover:text-black hover:bg-white"}
            onClick={() => {
              setImportOpen(true);
            }}
          >
            <span>Importer des compétences (CSV)</span>
          </div>
          <div
            className={"text-white flex p-4 hover:text-black hover:bg-white"}
            onClick={() => {
              setModalHelpOpen(true);
            }}
          >
            <span>Aide import CSV</span>
          </div>
        </Dropdown>
      </div>
      <ImportSkillsModal
        setIsOpen={setImportOpen}
        modalIsOpen={modalImportOpen}
      />
      <HelpSkillsImportModal
        modalIsOpen={modalHelpOpen}
        setIsOpen={setModalHelpOpen}
      />
    </PermissionChecker>
  );
}
function ImportSkillsModal({ modalIsOpen, setIsOpen }) {
  const domain = useDomain();
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title={"Importer des compétences (CSV)"}
      >
        <SkillsImportForm domain={domain} onSuccess={() => setIsOpen(false)} />
      </Modal>
    </>
  );
}

function HelpSkillsImportModal({ modalIsOpen, setIsOpen }) {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title={"Aide import CSV"}
      >
        <div>
          <p className="mb-2">
            <strong>Format du CSV pour l'import</strong>
          </p>
          <ul>
            <li>Le séparateur de champ est ; ou tabulation</li>
            <li>Les colonnes ne doivent pas avoir d'entête</li>
            <li>L'extension du fichier doit être .csv ou .txt</li>
          </ul>
          <p className="mt-2 mb-2">
            <strong>Liste des colonnes du CSV :</strong>
          </p>
          <ul>
            <li>Colonne 1 : Domaine de compétences</li>
            <li>Colonne 2 : Compétence</li>
            <li>Colonne 3 : Description du niveau 0</li>
            <li>Colonne 4 : Description du niveau 1</li>
            <li>Colonne 5 : Description du niveau 2</li>
            <li>Colonne 6 : Description du niveau 3</li>
            <li>Colonne 7 : Description du niveau 4</li>
          </ul>
        </div>
      </Modal>
    </>
  );
}

function SkillDomainsListPage() {
  const isDesktop = useDesktop();
  const { data: domains } = useQuerySkillDomainsNames();
  const [deleteDomain] = useDeleteSkillDomain();
  const [duplicateDomain] = useDuplicateSkillDomain();
  const canDelete = usePermissionsChecker({
    permissions: ["kdix.actions.skill_evaluation.edit_create"],
  });
  return (
    <div>
      <Helmet>
        <title>Référentiel Compétences</title>
      </Helmet>
      {isDesktop ? 
      <HeaderBar buttons={<CreateDropdown />}>
        Référentiel Domaines de Compétences
      </HeaderBar>
      :
      <HeaderBar>
        Référentiel Compétences
      </HeaderBar>
      }

      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="mt-8 mb-4">
          <PermissionChecker
            permissions={["kdix.actions.skill_evaluation.edit_create"]}
          >
            <SkillDomainForm />
          </PermissionChecker>
          <SimpleList
            createForm={({ afterSubmit, o }) => (
              <SkillDomainForm skillDomain={o} afterSubmit={afterSubmit} />
            )}
            objects={domains}
            canDelete={canDelete}
            deleteObject={deleteDomain}
            duplicateObject={duplicateDomain}
            linkTo={(id) => `/rh/competences/domaine/${id}`}
          />
        </div>
      </div>
    </div>
  );
}

export default function SkillDomains() {
  const { id } = useParams();
  return (
    <React.Suspense fallback={<Spinner />}>
      {id ? <DomainDetailPage domainId={id} /> : <SkillDomainsListPage />}
    </React.Suspense>
  );
}
