import Block from "../Block";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { size } from "lodash-es";
import { useGetUserStartedProcessTask } from "../../api/user";

function ProcessBlock({ userId }) {
  const now = new Date();
  now.setHours(23, 59, 59, 999);
  const filter = { completed: false, "date[before]": now };
  const { data: tasks } = useGetUserStartedProcessTask(userId, filter);
  const nbTasks = size(tasks);
  return (
    <>
      <Block className="text-center flex-grow bg-white">
        <Link
          to={`/taches`}
          className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
        >
          <div
            className={`inline-flex bg-green-900 text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
          >
            {nbTasks}
          </div>
          <div className="mt-3 text-xl font-black">{`Arrivées/Départs`}</div>
          <div className="mt-2 text-base font-light">&nbsp;</div>
          <Button
            className={`font-bold p-1 focus:outline-none text-white w-full block`}
          >
            voir tout
          </Button>
        </Link>
      </Block>
    </>
  );
}

export default ProcessBlock;
