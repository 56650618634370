import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { isEmpty, map, reduce, remove, without } from "lodash-es";
import { useDebounce } from "react-use";
import { useDeepEffect } from "../../hooks/useDeepEffect";

const onDragEnd = (result, columns, setColumns) => {
  return new Promise((resolve, reject) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);

      destItems.splice(destination.index, 0, removed);
      const newColumns = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
      setColumns(newColumns);
      resolve({
        destColumn: { ...destColumn, id: destination.droppableId },
        item: removed,
        newColumns: newColumns,
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  });
};

function deleteItem(deleteItems, columns, setColumns, setDeleteItem) {
  const newColumns = { ...columns };
  const deleteCopied = [...deleteItems];
  setDeleteItem([]);
  reduce(
    newColumns,
    (result, col) => {
      remove(col.items, (i) => {
        return deleteCopied.includes(i.id);
      });
    },
    {}
  );
  setColumns(newColumns);
}

export default function ({ columnsFromBackend, ItemStyle, onChange }) {
  const [columns, setColumns] = useState();
  const [itemsDropped, setItemsDropped] = useState([]);
  const [deleteItems, setDeleteItem] = useState([]);
  const [,] = useDebounce(
    () => {
      if (!isEmpty(deleteItems)) {
        deleteItem(deleteItems, columns, setColumns, setDeleteItem);
      }
    },
    700,
    [deleteItems, columns]
  );
  useDeepEffect(() => {
    setColumns(columnsFromBackend);
  }, [columnsFromBackend]);

  return (
    <div className="flex gap-4 justify-center h-full">
      <DragDropContext
        onDragStart={(start) => {
          setItemsDropped(without(itemsDropped, start.draggableId));
        }}
        onDragEnd={(result) => {
          onDragEnd(result, columns, setColumns).then((resDragEnd) => {
            setTimeout(() => {
              setItemsDropped((oldArray) => [...oldArray, result.draggableId]);
            }, 1000);
            onChange(resDragEnd);
          });
        }}
      >
        {map(columns, (column, id) => {
          return (
            <div key={id} className="flex-1 flex flex-col items-center">
              <div className="font-bold text-xl leading-tight my-4">
                {column.name}
              </div>
              <div className="w-full">
                <Droppable key={id} droppableId={id}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ minHeight: 500 }}
                        className={`${
                          snapshot.isDraggingOver ? "bg-green-100" : "bg-white"
                        } p-2`}
                      >
                        {map(column.items, (item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={`select-none`}
                                    style={{
                                      ...provided.draggableProps.style,
                                    }}
                                  >
                                    <ItemStyle
                                      isDragging={snapshot.isDragging}
                                      isDropped={itemsDropped.includes(item.id)}
                                      column={column.code}
                                      {...item.props}
                                    />
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
    </div>
  );
}
