import * as Yup from "yup";
import { Form, Formik } from "formik";
import { get, map, size } from "lodash-es";
import Button from "../components/Button";
import DownloadOrUpload from "../components/DownloadOrUpload";
import ExpansionPanel from "../components/ExpansionPanel";
import Input, { DateTimePickerInput } from "../components/Input";
import React, { useState } from "react";
import {
  PermissionChecker,
  usePermissionsChecker,
  useUseExternalDocumentManagement,
} from "../contexts/permissions";
import { useCreateOrUpdateAffectedAccreditation } from "../api/affectedAccreditation";
import { useQueryAccreditations } from "../api/accreditation";
import Select from "../components/Select";
import {
  downloadAccreditationCertificate,
  useCreateAccreditationCertificate,
} from "../api/accreditationCertificate";
import { ReactComponent as FileIcon } from "../svgs/download-file.svg";
import Modal from "../components/Modal";
import EditButton from "../components/HistoryItem/EditButton";

const validationCreate = Yup.object().shape({
  organismComment: Yup.string().required("Requis"),
  dateObtained: Yup.date().nullable(),
  dateExpiration: Yup.date().nullable(),
  accreditation: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
  file: Yup.mixed().test(
    "fileFormat",
    "Formats autorisés: pdf, jpg, png",
    (value) => {
      const authorized = ["application/pdf", "image/jpeg", "image/png"];
      return value === undefined || (value && authorized.includes(value.type));
    }
  ),
});

export function CreateAccreditationCollaboratorForm({
  collaborator,
  affectedAccreditation = null,
  readOnly = false,
  handleSubmit = () => {},
}) {
  const permissionsEdit = [
    "kdix.actions.accreditation.manage",
    "kdix.actions.accreditation.manage.agency",
    "kdix.actions.accreditation.manage.department",
    "kdix.actions.accreditation.manage.service",
    "kdix.actions.accreditation.manage.own",
  ];
  const canEdit = usePermissionsChecker({
    permissions: permissionsEdit,
  });
  const { data: dataAccreditations } = useQueryAccreditations();
  const [createUpdate] = useCreateOrUpdateAffectedAccreditation();
  const [createCertificate] = useCreateAccreditationCertificate();
  const useExternalDocumentManagement = useUseExternalDocumentManagement();
  const [isEditing, setIsEditing] = useState(!affectedAccreditation);

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={validationCreate}
        initialValues={{
          dateObtained: get(affectedAccreditation, "dateObtained")
            ? new Date(get(affectedAccreditation, "dateObtained"))
            : null,
          dateExpiration: get(affectedAccreditation, "dateExpiration")
            ? new Date(get(affectedAccreditation, "dateExpiration"))
            : null,
          organismComment: get(affectedAccreditation, "organismComment", ""),
          accreditation: {
            label: get(affectedAccreditation, "accreditation.label", ""),
            value: get(affectedAccreditation, "accreditation[@id]", ""),
          },
          file: "",
        }}
        onSubmit={async ({ accreditation, file, ...values }, actions) => {
          try {
            const { id: newId } = await createUpdate({
              id: get(affectedAccreditation, "id", null),
              data: {
                collaborator: collaborator["@id"],
                accreditation: accreditation.value,
                ...values,
              },
            });
            if (newId && file) {
              await createCertificate({
                file: file,
                affectedAccreditationId: newId,
              });
            }
            //Dans le cas où c'est un formulaire d'ajout, on le nettoie
            //Si c'est une modification, on veut garder les nouvelles données saisies
            if (get(affectedAccreditation, "id", null) === null) {
              actions.resetForm();
            }
            handleSubmit();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, resetForm, isSubmitting }) => {
          return (
            <Form>
              <Select
                name="accreditation"
                placeholder="Habilitation *"
                options={map(dataAccreditations, (accreditation) => ({
                  label: accreditation.label,
                  value: accreditation["@id"],
                }))}
                value={
                  values.accreditation.value !== ""
                    ? values.accreditation
                    : null
                }
                isDisabled={!canEdit || readOnly || !isEditing}
                isLoading={!dataAccreditations}
              />
              <DateTimePickerInput
                label="Date d'obtention"
                name="dateObtained"
                fullWidth={true}
                disabled={!canEdit || readOnly || !isEditing}
              />
              <DateTimePickerInput
                label="Date d'expiration"
                name="dateExpiration"
                fullWidth={true}
                disabled={!canEdit || readOnly || !isEditing}
              />
              <Input
                name="organismComment"
                label="Organisme et commentaire *"
                readOnly={!canEdit || readOnly || !isEditing}
              />

              {get(affectedAccreditation, "certificate", false) ? (
                <div className="mb-4 hover:text-green-600">
                  <label className="block text-black text-sm font-bold">
                    Certificat
                  </label>
                  <div
                    onClick={() => {
                      if (get(affectedAccreditation, "certificate.id")) {
                        downloadAccreditationCertificate(
                          get(affectedAccreditation, "certificate.id"),
                          get(affectedAccreditation, "certificate.label")
                        );
                      }
                    }}
                    className="cursor-pointer border-black border-b
           w-full py-2 leading-tight flex items-center"
                  >
                    <FileIcon className="fill-current text-black h-4 w-4 mr-4" />
                    <span>Télécharger le document</span>
                  </div>
                </div>
              ) : (
                ""
              )}
              {canEdit &&
                !readOnly &&
                isEditing &&
                !useExternalDocumentManagement && (
                  <DownloadOrUpload
                    name="file"
                    accept="image/png, image/jpeg, application/pdf"
                    isSubmitting={isSubmitting}
                    submitButton={false}
                    downloadFn={
                      !!get(affectedAccreditation, "certificate", false)
                    }
                  />
                )}

              <div className={"flex ml-auto"}>
                {canEdit &&
                  !readOnly &&
                  (affectedAccreditation ? (
                    <EditButton
                      permissionsEdit={permissionsEdit}
                      isSubmitting={isSubmitting}
                      isEditing={isEditing}
                      onCancel={() => {
                        resetForm();
                        setIsEditing(!isEditing);
                      }}
                      onClick={() => {
                        setIsEditing(!isEditing);
                      }}
                    />
                  ) : (
                    <Button
                      className={`ml-auto`}
                      readOnly={false}
                      isSubmitting={isSubmitting}
                      isForm={true}
                      type="submit"
                      textLabel="Créer"
                    />
                  ))}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

function isExpired(dateExpiration) {
  if (dateExpiration && new Date(dateExpiration) < new Date()) {
    return true;
  }
  return false;
}

function AccreditationCollaboratorForm({ collaborator }) {
  const [modalAccreditationOpen, setModalAccreditationOpen] = useState(false);

  return (
    <div>
      <PermissionChecker
        permissions={[
          "kdix.actions.accreditation.manage",
          "kdix.actions.accreditation.manage.agency",
          "kdix.actions.accreditation.manage.department",
          "kdix.actions.accreditation.manage.service",
          "kdix.actions.accreditation.manage.own",
        ]}
        notAllowed={null}
      >
        <div className="flex items-end">
          <div
            className="btn flex items-center justify-center leading-none focus:outline-none focus:shadow-none ml-auto"
            onClick={() => setModalAccreditationOpen(true)}
          >
            Ajouter une habilitation
          </div>
        </div>
        <hr className={"my-8"} />
      </PermissionChecker>
      {size(collaborator.affectedAccreditations) > 0 ? (
        <div className="">
          {map(collaborator.affectedAccreditations, (affectedAccreditation) => (
            <ExpansionPanel
              title={get(affectedAccreditation, "accreditation.label")}
              customStyle={
                isExpired(get(affectedAccreditation, "dateExpiration"))
                  ? "alert"
                  : "default"
              }
              subtitle={
                get(affectedAccreditation, "dateExpiration")
                  ? `Date d'expiration : ${new Date(
                      get(affectedAccreditation, "dateExpiration")
                    ).toLocaleDateString()}`
                  : ""
              }
              key={affectedAccreditation.id}
            >
            <div className="mb-8">
              <CreateAccreditationCollaboratorForm
                affectedAccreditation={affectedAccreditation}
                collaborator={collaborator}
              />
            </div>
            </ExpansionPanel>
          ))}
        </div>
      ) : (
        <div>Aucune habilitation</div>
      )}

    <PermissionChecker
        permissions={[
          "kdix.actions.accreditation.manage",
          "kdix.actions.accreditation.manage.agency",
          "kdix.actions.accreditation.manage.department",
          "kdix.actions.accreditation.manage.service",
          "kdix.actions.accreditation.manage.own",
        ]}
        notAllowed={null}
      >
            <Modal
              title="Ajouter une habilitation"
              handleClose={(e) => {
                e.stopPropagation();
              }}
              isOpen={modalAccreditationOpen}
              onRequestClose={() => setModalAccreditationOpen(false)}
            >
              <div className="bg-white">
                <CreateAccreditationCollaboratorForm
                  collaborator={collaborator}
                  handleSubmit={() => setModalAccreditationOpen(false)}
                />
              </div>
            </Modal>
      </PermissionChecker>
    </div>
  );
}

export default AccreditationCollaboratorForm;
