import { Helmet } from "react-helmet";
import React, { useState } from "react";
import AlertsList from "../components/AlertsList";
import { useUserId } from "../contexts/permissions";
import { ReactComponent as FilterIcon } from "../svgs/filters.svg";
import Modal from "../components/Modal";
import DatePicker from "react-datepicker";
import Spinner from "../components/Spinner";
import { useMap, useToggle } from "react-use";
import { get, map, pull, size } from "lodash-es";
import CheckboxGroup from "../components/CheckboxGroup";
import { useQueryAlertClassnames } from "../api/alertClassnames";
import { DisplaySelect } from "../components/Select";
import { useDesktop } from "../hooks/useDesktop";
import { HeaderBar } from "../components/HeaderBar";

function Alerts() {
  const userId = useUserId();
  const isDesktop = useDesktop();
  const [filtersOpen, toggleFilters] = useToggle(false);
  const [order, setOrder] = useState("DESC");
  const { data: alertsClassnames } = useQueryAlertClassnames();
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: null,
  });
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({ archived: [false], report: [false] });
  const filtersList = [
    {
      label: "Type d'alerte",
      key: "type",
      options: map(alertsClassnames, (node) => ({
        label: node.label,
        value: get(node, "@id"),
        id: node["@id"],
      })),
    },
    {
      label: "État",
      key: "archived",
      options: [
        {
          label: "Non traités",
          value: false,
          id: false,
        },
        {
          label: "Traités",
          value: true,
          id: true,
        },
      ],
    },
    {
      label: "Reportées",
      key: "reminderDate",
      options: [
        {
          label: "Date de rappel future",
          value: true,
          id: true,
        },
        {
          label: "Date de rappel passée",
          value: false,
          id: false,
        },
      ],
    },
  ];
  const filterOptions = [
    { label: "croissante", value: "ASC" },
    { label: "décroissante", value: "DESC" },
  ];
  return (
    <div>
      <Helmet>
        <title>Alertes</title>
      </Helmet>
      <HeaderBar>Alertes</HeaderBar>
      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="px-8 mt-8 mb-4 flex">
          <button
            className="flex items-center"
            onClick={() => toggleFilters(true)}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>
          <DisplaySelect
            label="Trier par date"
            options={filterOptions}
            value={filterOptions.find((e) => e.value === order)}
            onChange={(selected) => {
              setOrder(selected.value);
            }}
            className={"ml-auto w-48"}
          />
          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            className="outline-none w-full max-w-full lg:max-w-xl max-h-full overflow-visible overflow-y-auto"
            isOpen={filtersOpen}
            onRequestClose={() => toggleFilters(false)}
          >
            <div className="-mx-4 px-5 text-center">
              <DatePicker
                selected={dateRange.startDate}
                onChange={([startDate, endDate]) => {
                  setDateRange({
                    startDate: startDate,
                    endDate: endDate,
                  });
                }}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                selectsRange
                inline
              />
            </div>
            <div className="-mx-4 px-5">
              {map(filtersList, ({ key, label, options }) => {
                return (
                  <CheckboxGroup
                    key={key}
                    label={label}
                    options={options}
                    className="py-4 odd:bg-purple-50 -mx-4 px-5"
                    selected={get(filters, key, [])}
                    onAdd={(option) => {
                      setFilter(key, [...get(filters, key, []), option]);
                    }}
                    onRemove={(option) => {
                      const newFilters = pull(get(filters, key, []), option);
                      if (size(newFilters) === 0) {
                        removeFilter(key);
                        return;
                      }
                      setFilter(key, newFilters);
                    }}
                  />
                );
              })}
            </div>
            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  setDateRange({
                    startDate: new Date(),
                    endDate: null,
                  });
                  resetFilters();
                }}
              >
                Supprimer les filtres
              </div>
            </div>
            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="px-8">
          <React.Suspense fallback={<Spinner />}>
            <AlertsList
              order={order}
              filters={filters}
              dateRange={dateRange}
              userId={userId}
            />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default Alerts;
