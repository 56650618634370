import React, { useEffect } from "react";
import { useMap, useToggle } from "react-use";
import Modal from "../../components/Modal";
import { get, map, pull, size } from "lodash-es";
import CheckboxGroup from "../CheckboxGroup";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";

export default function ClassicFilters({
  filtersList,
  onChange,
  scroll = false,
}) {
  const [FiltersOpen, toggleFilters] = useToggle(false);
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({});

  useEffect(() => {
    onChange(filters);
  }, [filters, onChange]);

  return (
    <>
      <button className="flex items-center" onClick={() => toggleFilters(true)}>
        <FilterIcon />
        <span className="ml-4">Filtres</span>
      </button>
      <Modal
        title="Filtres"
        handleClose={(e) => {
          e.stopPropagation();
          toggleFilters(false);
        }}
        isOpen={FiltersOpen}
        onRequestClose={() => toggleFilters(false)}
        className={`outline-none w-full max-w-full lg:max-w-xl max-h-full overflow-visible ${
          scroll ? "overflow-y-auto" : ""
        }`}
      >
        <div className="-mx-4 px-5">
          {map(filtersList, ({ key, label, options, unique }) => {
            return (
              <CheckboxGroup
                key={key}
                label={label}
                options={options}
                className="py-4 odd:bg-purple-50 -mx-4 px-5"
                selected={
                  unique
                    ? get(filters, key)
                      ? [get(filters, key)]
                      : []
                    : get(filters, key, [])
                }
                onAdd={(option) => {
                  setFilter(
                    key,
                    unique ? option : [...get(filters, key, []), option],
                  );
                }}
                onRemove={(option) => {
                  const newFilters = unique
                    ? []
                    : pull(get(filters, key, []), option);
                  if (size(newFilters) === 0) {
                    removeFilter(key);
                    return;
                  }
                  setFilter(key, newFilters);
                }}
              />
            );
          })}
        </div>
        <div>
          <div
            className="mt-3 underline cursor-pointer"
            onClick={() => {
              resetFilters();
            }}
          >
            Supprimer les filtres
          </div>
        </div>

        <button
          className="btn mt-5 w-full"
          type="button"
          onClick={() => toggleFilters(false)}
        >
          Appliquer les filtres
        </button>
      </Modal>
    </>
  );
}
