import { Form, Formik } from "formik";
import React, { useState } from "react";

import Alert from "./Alert";
import Avatar from "./Avatar";
import ImageUploading from "react-images-uploading";
import Modal from "./Modal";
import { ReactComponent as PhotoIcon } from "../svgs/photo.svg";
import { get } from "lodash-es";
import { updateAvatar } from "../api";
import { PermissionChecker } from "../contexts/permissions";
import {downloadAvatarImage} from "../api/avatar";

function getUrlExtension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
}

function UploadAvatar({ avatar: currentAvatar, avatarInfos, collaboratorId, domainLinkId, companyId,...props }) {
  const [avatar, setAvatar] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const maxMbFileSize = 1 * 1024 * 1024; // 5Mb
  const [isLoading, setLoading] = React.useState(false);
  return (
    <div>
      <div className="relative rounded-full overflow-hidden">
        <div className="w-20 h-20">
          <Avatar avatar={avatar || currentAvatar} title={domainLinkId || companyId ? "Choisir un logo" : ""} />
        </div>
        {collaboratorId || domainLinkId || companyId ? (
          <PermissionChecker
            permissions={[
              "kdix.actions.collaborator.edit",
              "kdix.actions.collaborator.edit.agency",
              "kdix.actions.collaborator.edit.department",
              "kdix.actions.collaborator.edit.service",
              "kdix.actions.collaborator.edit.own",
            ]}
          >
            <div
              onClick={() => setIsOpen(true)}
              className="bg-black bg-opacity-50 opacity-0 hover:opacity-100 z-10 absolute inset-0 cursor-pointer flex items-center justify-center"
            >
              <PhotoIcon className="text-white fill-current h-5 w-5" />
            </div>
          </PermissionChecker>
        ) : null}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title={collaboratorId ? "Modifier l'image de profil" : "Modifier le logo"}
      >
          {currentAvatar ? (
              <div className="text-center">
                  <div
                      className="btn"
                      aria-disabled={isLoading}
                      onClick={() => {
                          setLoading(true);
                          downloadAvatarImage(avatarInfos.id, null, getUrlExtension(avatarInfos.contentUrl)).then(() => {
                              setLoading(false);
                          })
                      }}>
                      Télécharger
                  </div>
              </div>
          ) : null}
        <Formik
          initialValues={{
            file: null,
          }}
          onSubmit={async (values, actions) => {
            try {
              const res = await updateAvatar(values.file, collaboratorId, domainLinkId, companyId);
              actions.setSubmitting(false);
              setIsOpen(false);
              setAvatar(res.contentUrl);
            } catch (error) {
              actions.setFieldError("file", error);
              actions.setSubmitting(false);
            }
          }}
        >
          {({
            isSubmitting,
            setFieldValue,
            setFieldTouched,
            setFieldError,
            errors: formikErrors,
            touched,
          }) => {
            return (
              <Form>
                <ImageUploading
                  onChange={(imageList) => {
                    setFieldError("file", false);
                    setFieldValue("file", get(imageList, "0.file"));
                    setFieldTouched("file");
                  }}
                  maxNumber={2}
                  maxFileSize={maxMbFileSize}
                  acceptType={[
                    "jpg",
                    "jpeg",
                    "gif",
                    "png",
                    "JPG",
                    "GIF",
                    "PNG",
                    "JPEG",
                  ]}
                >
                  {({ imageList, onImageUpload, onImageRemoveAll, errors }) => {
                    return (
                      <div className="text-center">
                        <div
                          onClick={() => {
                            if (imageList && imageList.length > 1) {
                              onImageRemoveAll();
                            }
                            onImageUpload();
                          }}
                          className="btn my-6"
                        >
                          Choisir une image
                        </div>

                        {imageList.map((image) => (
                          <div
                            key={image.key}
                            className="mb-6 w-20 h-20 flex justify-center mx-auto"
                          >
                            <Avatar avatar={image.dataURL} />
                          </div>
                        ))}

                        {errors.maxNumber ||
                        errors.acceptType ||
                        errors.maxFileSize ? (
                          <div className="mt-4">
                            {errors.maxNumber && (
                              <Alert
                                type="warning"
                                message="Le nombre d'images sélectionné est trop important."
                              />
                            )}
                            {errors.acceptType && (
                              <Alert
                                type="warning"
                                message="Extension de fichier non autorisée. (jpg, gif, png)"
                              />
                            )}
                            {errors.maxFileSize && (
                              <Alert
                                type="warning"
                                message="le fichier sélectionné dépasse la taille maximum de 1Mo"
                              />
                            )}
                          </div>
                        ) : null}

                        {formikErrors.file ? (
                          <div className="my-2">
                            <Alert
                              type="error"
                              message={get(
                                formikErrors.file,
                                "data.hydra:title"
                              )}
                              details={get(
                                formikErrors.file,
                                "data.hydra:description"
                              )}
                            />
                          </div>
                        ) : null}

                        <div className="mt-12">
                          <button
                            className={`btn min-w-sm ${
                              !imageList || imageList.length === 0
                                ? "cursor-not-allowed opacity-50"
                                : ""
                            }`}
                            type="submit"
                            onClick={(e) => {
                              if (!touched.file) {
                                e.preventDefault();
                              }
                            }}
                          >
                            {isSubmitting ? (
                              <div className="loader h-8 w-8 mx-auto"></div>
                            ) : (
                              "Valider"
                            )}
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </ImageUploading>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}

export default UploadAvatar;
