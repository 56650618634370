import { ReactComponent as ActiviteIcon } from "../../svgs/activite.svg";
import Block from "../Block";
import { Link } from "react-router-dom";
import React from "react";
import { useCollaboratorId } from "../../contexts/permissions";
import Button from "../Button";

function MyActivities() {
  const collaboratorId = useCollaboratorId();
  return (
    <Block className="text-center flex-grow flex flex-col">
      <Link
        to={`/rh/collaborateurs/${collaboratorId}/activite`}
        className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
      >
        <div className="text-xl font-black">Mon relevé d'activité</div>
        <div className="mx-auto mt-3">
          <div className="p-4">
            <ActiviteIcon className={"w-32 h-20"} />
          </div>
        </div>
        <Button
          className={`font-bold p-1 focus:outline-none btn--outline--reversed w-full block`}
        >
          voir tout
        </Button>
      </Link>
    </Block>
  );
}

export default MyActivities;
