import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery
} from "react-query";
import queryWithToken, {
  deleteById,
  find,
  findById,
  updateOrCreate
} from "./index";
import { get } from "lodash-es";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "accreditations";
//

export function useQueryAccreditationById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQueryAccreditationsInfinite(filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    getFetchMore: lastGroup => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    }
  });
}

export function useQueryAccreditations(filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          "order[label]": "ASC",
          ...filters
        }
      }),
    {
      refetchOnWindowFocus: false
    }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest
  };
}

export function useCreateOrUpdateAccreditation() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    onSuccess: () => {
      toast.success("Mise à jour effectuée avec succès");
      queryCache.refetchQueries(ressourceKey);
    },
    throwOnError: true
  });
}

export function useDeleteAccreditation() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    }
  });
}
