import React from "react";
import { useToggle } from "react-use";
import Block from "./Block";

const styles = {
  default: {
    block: "p-4",
    children: "p-4",
    topDiv: "bg-white shadow mb-4 xl:mb-3",
  },
  home: {
    block: "shadow p-4 bg-white",
    children: "",
    topDiv: "mb-4",
  },
  gray: {
    block: "p-4 bg-gray-50",
    children: "p-4",
    topDiv: "bg-gray-50 shadow mb-4 xl:mb-3",
  },
  gray100: {
    block: "p-4 bg-gray-100",
    children: "p-4",
    topDiv: "bg-gray-100 shadow mb-4 xl:mb-3",
  },
  alert: {
    block: "p-4 bg-red-100",
    children: "p-4",
    topDiv: "bg-red-100 shadow mb-4 xl:mb-3",
  },
  synchronisationError: {
    block: "py-4 bg-red-700",
    children: "py-4",
    topDiv: "border-b border-white last:border-0",
    arrow: "text-white",
  },
};

function ExpansionPanel({
  title,
  image,
  subtitle,
  children,
  handleClick,
  forceOpen = false,
  open = false,
  count,
  className = "",
  customStyle = "default",
  buttons,
}) {
  const [on, toggle] = useToggle(open);
  const style = styles[customStyle];
  return (
    <div className={style["topDiv"]}>
      <Block
        className={`${handleClick ? "hover:shadow-md" : ""} ${className}`}
        customStyle={style["block"]}
      >
        <div
          className="flex items-center cursor-pointer"
          onClick={handleClick ? handleClick : toggle}
        >
          {image ? (
            <div className="w-12 h-12 mr-4">
              <img
                src={image}
                className="object-cover rounded-full h-full w-full"
                alt="avatar"
              />
            </div>
          ) : null}
          <div>
            <div className="font-bold text-lg leading-tight relative">
              {title}
              {count ? (
                <div className="absolute bg-red-700 rounded-full text-white text-xs inline-flex items-center justify-center w-5 h-5 ml-1 -mt-1">
                  {count}
                </div>
              ) : null}
            </div>
            {subtitle ? <div>{subtitle}</div> : null}
          </div>
          {buttons ? <div className={`ml-auto`}>{buttons}</div> : null}
          {children && !forceOpen ? (
            <div className={`${buttons ? "" : "ml-auto"}`}>
              <svg
                className={`transform fill-current ${
                  style["arrow"] ? style["arrow"] : "text-green-600"
                } ${handleClick ? "-rotate-90" : on ? "rotate-180" : ""}`}
                width="20"
                height="12"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
                />
              </svg>
            </div>
          ) : null}
        </div>
      </Block>
      {children ? (
        <div className={`${forceOpen || on ? "block" : "hidden"}`}>
          <div className={style["children"]}>
            {typeof children === "function"
              ? children({ isOpen: on })
              : children}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default ExpansionPanel;
