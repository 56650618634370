import React from "react";

function Radio({
  name,
  value,
  checked,
  onChange,
  label,
  color = "green-600",
  className = "",
  readOnly = false,
  Icon = null,
  title = "",
}) {
  return (
    <label
      className={`flex cursor-pointer py-3 items-center ${className}`}
      onClick={(e) => {
        if (readOnly) {
          e.preventDefault();
        }
      }}
    >
      {Icon ? (
        <div
          className={`${
            checked ? `bg-${color}` : ""
          } rounded-full h-8 w-8 overflow-hidden flex justify-center items-center mr-2 flex-shrink-0`}
        >
          <input
            type="radio"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            className="hidden"
            readOnly={readOnly}
          />
          {title !== "" ? (
            <Icon
              className={`${
                checked ? "text-white" : "text-gray-300"
              } w-5 h-5 fill-current`}
              title={title}
            />
            ) : (
            <Icon
            className={`${
              checked ? "text-white" : "text-gray-300"
            } w-5 h-5 fill-current`}
          />
          )}
        </div>
      ) : (
        <div
          className={`${
            checked ? `border-${color}` : "border-gray-300"
          } border-2 rounded-full h-5 w-5 overflow-hidden flex justify-center items-center mr-2 flex-shrink-0`}
        >
          <input
            type="radio"
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            className="hidden"
            readOnly={readOnly}
          />
          <div
            className={`${
              checked ? "" : "hidden"
            } bg-green-600 rounded-full innerRadio`}
          />
        </div>
      )}

      {label && (
        <span className="select-none text-base font-normal">{label}</span>
      )}
    </label>
  );
}

export default Radio;
