import { get } from "lodash-es";

export function getLocaleDateString(object, path) {
  return get(object, path)
    ? new Date(get(object, path)).toLocaleDateString()
    : "";
}

export function getDaysBetweenDate(date1, date2) {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  return Math.round(Math.abs((date1 - date2) / oneDay));
}

export function getMonthsBetweenDate(date1, date2) {
 return date1.getMonth() - date2.getMonth() + 
   (12 * (date1.getFullYear() - date2.getFullYear()));
}

export function getYearsBetweenDate(date1, date2) {
 return (date1.getFullYear() - date2.getFullYear());
}

function setHoursToZero(date) {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
}

export function isDate1BeforeDate2(date1, date2, strict = true) {
  setHoursToZero(date1);

  return strict ? +date1 < +date2 : +date1 <= +date2;
}

export function isDate1AfterDate2(date1, date2, strict = true) {
  setHoursToZero(date1);
  setHoursToZero(date2);
  return strict ? +date1 > +date2 : +date1 >= +date2;
}

export function isDate1EqualDate2(date1, date2) {
  setHoursToZero(date1);
  setHoursToZero(date2);
  return +date1 === +date2;
}

export function minutesToHoursString(minutes) {
  return Math.floor(minutes / 60) + ":" + ("0" + (minutes % 60)).slice(-2);
}
