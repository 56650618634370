import React from "react";
import { get, map, reduce } from "lodash-es";
import { usePermissionsChecker } from "../../contexts/permissions";
import {
  useQueryStartedProcessTask,
  useUpdateStartedProcessTask,
} from "../../api/startedProcessTask";
import GoBackBtn from "../GoBackBtn";
import StartedProcessTask from "./StartedProcessTask";
import { useQueryUsers } from "../../api/user";
import { getFirstnameLastnameJob } from "../../utils/names";

const transformSelect = (r) => {
  return {
    value: get(r, "@id"),
    label: getFirstnameLastnameJob(r, "collaborator"),
  };
};
export default function StartedProcessInOutDetail({ filters }) {
  const [updateHook] = useUpdateStartedProcessTask();
  const { data: tasks } = useQueryStartedProcessTask({
    ...filters,
  });
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.process_in_out.view",
      "kdix.actions.process_in_out.view.agency",
      "kdix.actions.process_in_out.view.department",
      "kdix.actions.process_in_out.view.service",
    ],
  });
  const { data: users } = useQueryUsers({
    "exists[collaborator]": "true",
    "order[collaborator.lastname]": "asc",
    "collaborator.active": "true",
  });
  const userSelect = reduce(
    users,
    (result, user) => {
      result.push(transformSelect(user));
      return result;
    },
    []
  );

  return (
    <div>
      {filters.startedProcessId ? (
        <div className={"w-20"}>
          <GoBackBtn to={`/processus/liste`} className="flex items-center">
            <div className="font-bold">Retour</div>
          </GoBackBtn>
        </div>
      ) : null}
      <div className={"mt-1"}>
        {map(tasks, (task) => {
          return (
            <StartedProcessTask
              key={task.id}
              task={{ ...task, processInOut: process["@id"] }}
              canEdit={canEdit}
              updateHook={updateHook}
              userSelect={userSelect}
              showCollaborator={false}
            />
          );
        })}
      </div>
    </div>
  );
}
