import React from "react";
import { PermissionChecker } from "../../contexts/permissions";
import Button from "../Button";

function EditButton({
  isEditing,
  isSubmitting,
  onClick,
  onCancel,
  onDelete,
  labelEdit = "Enregistrer",
  labelDelete = "Supprimer",
  permissionsEdit = [
    "kdix.actions.collaborator.edit",
    "kdix.actions.collaborator.edit.agency",
    "kdix.actions.collaborator.edit.department",
    "kdix.actions.collaborator.edit.service",
  ],
  canEdit = true,
}) {
  return (
    <PermissionChecker permissions={permissionsEdit}>
      <div className="flex ml-auto">
        {isEditing ? (
          <Button
            type="submit"
            className="btn btn--sm mr-4 ml-auto z-10"
            textLabel={labelEdit}
            isForm={true}
            isSubmitting={isSubmitting}
            loaderClassName={"loader h-6 w-6 mx-auto"}
          />
        ) : null}
        {canEdit ? (
          <Button
              disabled={isSubmitting}
              type="button"
              className={`btn btn--sm ${
                  isEditing
                      ? "bg-orange-500 hover:bg-orange-800 z-10"
                      : " btn--outline ml-auto"
              }`}
              onClick={isEditing ? onCancel : onClick}
              isForm={true}
              isSubmitting={isSubmitting}
              textLabel={isEditing ? "Annuler" : "Modifier"}
              loaderClassName={"loader h-6 w-6 mx-auto"}
          />
        ) : null}
        {onDelete ? (
          <Button
            onClick={onDelete}
            className="ml-4 btn btn--sm bg-red-500 hover:bg-red-800"
            type="button"
            isForm={true}
            isSubmitting={isSubmitting}
            textLabel={labelDelete}
            loaderClassName={"loader h-6 w-6 mx-auto"}
          />
        ) : null}
      </div>
    </PermissionChecker>
  );
}

export function EditAndValidButton({
  isEditing,
  isSubmitting,
  onClick,
  onCancel,
  onDelete,
  labelValid = "Valider",
  labelDelete = "Supprimer",
  permissionsEdit = [
    "kdix.actions.collaborator.edit",
    "kdix.actions.collaborator.edit.agency",
    "kdix.actions.collaborator.edit.department",
    "kdix.actions.collaborator.edit.service",
  ],
}) {
  return (
    <PermissionChecker permissions={permissionsEdit}>
      <div className="flex items-center gap-2">
        {!!onCancel && (
          <Button
            disabled={isSubmitting}
            type="button"
            className={`btn btn--sm ${
              isEditing ? "bg-orange-500 hover:bg-orange-800" : " btn--outline"
            }`}
            onClick={isEditing ? onCancel : onClick}
            isForm={true}
            isSubmitting={isSubmitting}
            textLabel={isEditing ? "Annuler" : "Modifier"}
            loaderClassName={"loader h-6 w-6 mx-auto"}
          />
        )}

        <Button
          type="submit"
          className="btn btn--sm"
          textLabel={labelValid}
          isForm={true}
          isSubmitting={isSubmitting}
          loaderClassName={"loader h-6 w-6 mx-auto"}
        />
        {onDelete ? (
          <Button
            onClick={onDelete}
            className="btn btn--sm bg-red-500 hover:bg-red-800"
            type="button"
            isForm={true}
            isSubmitting={isSubmitting}
            textLabel={labelDelete}
            loaderClassName={"loader h-6 w-6 mx-auto"}
          />
        ) : null}
      </div>
    </PermissionChecker>
  );
}

export default EditButton;
