import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import queryWithToken, {
  find,
  findById,
  findExport,
  updateOrCreate,
} from "./index";
import { toast } from "react-toastify";
import { get } from "lodash-es";
import fileDownload from "js-file-download";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "affected_accreditations";
//

export function useQueryAffectedAccreditationById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQueryAffectedAccreditations(filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQueryAffectedAccreditationsInfinite(filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
    refetchOnWindowFocus: false,
  });
}

export function useCreateOrUpdateAffectedAccreditation() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    onSuccess: () => {
      toast.success("Mise à jour effectuée avec succès");
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries(["collaborators"]);
    },
    throwOnError: true,
  });
}

export async function downloadAffectedAccreditationExport(
  filters,
  filename = "export_habilites"
) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, filename + ".csv");
}
