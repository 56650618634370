import React from "react";

type LegendItemProps = {
  label: string;
  bgColor: string;
};

export default function LegendItem({ bgColor, label }: LegendItemProps) {
  return (
    <div className="flex items-center gap-2">
      <div className={`rounded w-2 h-2 ${bgColor}`} />
      <span>{label}</span>
    </div>
  );
}
