import {reduce} from "lodash-es";
import { isDate1AfterDate2, isDate1BeforeDate2 } from "./date";

export function getCurrentsBadges(badges) {
  return reduce(
    badges,
    (result, badge) => {
      const today = new Date();
      const startDate = new Date(badge.startDate);
      
      var yesterday = new Date();
      yesterday.setDate(yesterday.getDate()-1);
      
      const endDate = badge.endDate ? new Date(badge.endDate) : yesterday;

      if (
        isDate1AfterDate2(today, startDate, false) && isDate1BeforeDate2(yesterday, endDate, false) 
      ) {
        result.currents.push(badge);
      } else {
        result.history.push(badge);
        if (
          result.lastHistory === null ||
          isDate1BeforeDate2(new Date(result.lastHistory.endDate), endDate)
        ) {
          result.lastHistory = badge;
        }
      }
      return result;
    },
    { currents: [], history: [], lastHistory: null }
  );
}
