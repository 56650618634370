import Alert from "./Alert";
import { ReactComponent as ArrowDownRightIcon } from "../svgs/ArrowDownRight.svg";

import HistoryItem from "./HistoryItem/HistoryItem";
import React from "react";
import { get, map } from "lodash-es";
import AllocationHistoryItem from "./HistoryVehicleItem/AllocationHistoryItem";
import RentalHistoryItem from "./HistoryVehicleItem/RentalHistoryItem";
import EventVehicleHistoryItem from "./HistoryVehicleItem/EventVehicleHistoryItem";

function ActionsHistoryVehicle({ datePurchase, items }) {
  if (items && items.length === 0 && !datePurchase) {
    return (
      <Alert type="warning" details="Aucun historique pour ce véhicule" />
    );
  }

  //La date d'achat n'est pas une action en base, on l'injecte au bon endroit dans les items, tout comme la date du jour
  let historique = [];
  if (datePurchase) {
    historique = [
      ...items,
      { type: "Purchase", date: datePurchase, event: { "@id": "purchase" } },
      {
        date: new Date().toISOString(),
        type: "NOW",
        event: { "@id": "now" },
      },
    ];
  } else {
    historique = [...items, { date: new Date().toISOString(), type: "NOW" }];
  }
  historique = historique.sort((a, b) => {
    if (a.date === undefined) {
      return 1;
    }
    if (b.date === undefined) {
      return -1;
    }
    return a.date === b.date ? 0 : a.date < b.date ? 1 : -1;
  });
  let isLeft = false;

  return (
    <div
      style={{
        background: historique
          ? "linear-gradient(90deg, rgba(0,0,0,0) calc(50% - 1px), rgba(0,0,0,1) calc(50%), rgba(0,0,0,0) calc(50% + 1px))"
          : "",
      }}
    >
      {map(historique, (item, index) => {
        isLeft = !isLeft;
        switch (item.type) {
          case "NOW":
            const isFirst = index === 0;
            const isLast = index === historique.length - 1;
            //On rechange la valeur de isLeft car cet élément est sur toute la ligne
            isLeft = !isLeft;
            return (
              <div key={get(item, "event.@id", index)}>
                {!isFirst && !isLast && (
                  <div className={"xl:mb-6 mb-6 todaySeparator"} />
                )}
              </div>
            );
          case "App\\Entity\\VehicleAttributionHistory":
            return (
              <AllocationHistoryItem
                key={get(item, "event.@id", index)}
                allocation={item.event}
                date={item.date}
                isLeft={isLeft}
              />
            );
          case "App\\Entity\\Rental":
            return (
              <RentalHistoryItem
                key={get(item, "event.@id", index)}
                rental={item.event}
                date={item.date}
                isLeft={isLeft}
              />
            );
          case "App\\Entity\\VehicleEvent":
            return (
              <EventVehicleHistoryItem
                key={get(item, "event.@id", index)}
                date={item.date}
                event={item.event}
                isLeft={isLeft}
              />
            );
          case "Purchase":
            const date = new Date(datePurchase);
            return (
              <HistoryItem
                key={get(item, "event.@id", index)}
                title="Achat"
                date={`${date.toLocaleDateString()}`}
                Icon={ArrowDownRightIcon}
                backgroundIcon="bg-blue-500"
                isLeft={isLeft}
              />
            );
          default:
            return (
              <HistoryItem
                key={get(item, "event.@id", index)}
                {...item}
                isLeft={isLeft}
              />
            );
        }
      })}
    </div>
  );
}

export default React.memo(ActionsHistoryVehicle);
