import React, { useEffect, useState } from "react";
import { reduce, get, isEmpty, map } from "lodash-es";
import { Helmet } from "react-helmet";
import { useQueryPermissions } from "../../api/permissions";
import {
  useCreateOrUpdatePermissionAction,
  useDeletePermissionAction,
} from "../../api/permissionAction";
import { useQueryModulesDomain } from "../../api/module";
import NavBar from "./NavBarPermissions";
import Toggle from "../../components/Toogle";
import { DisplaySelect } from "../../components/Select";
import { scopeOptions } from "../../utils/actionScopes";

import type { ParsedPermission } from "../../types/permission";
import type { Module } from "../../types/module";
import type { Dispatch, SetStateAction } from "react";
import type { Action } from "../../types/action";
import { HeaderBar } from "../../components/HeaderBar";

type SideBarModuleProps = {
  modules: Module[];
  setCurrentModule: Dispatch<SetStateAction<Module | null>>;
  currentModule: Module | null;
};

type PermissionsListProps = {
  currentModule: Module | null;
  currentProfile: ParsedPermission | null;
};
type ActionLineProps = {
  action: Action;
  profile: ParsedPermission;
};

function PermissionsList(props: PermissionsListProps) {
  const { currentModule, currentProfile } = props;
  const actions = get(currentModule, "actions", []);
  return (
    <div className="w-2/3 px-8">
      <div>
        <div className="font-bold">
          Liste des droits du module "{currentModule?.label}"
        </div>

        {currentProfile !== null
          ? map(actions, (node) => (
              <ActionLine
                key={node.id}
                action={node}
                profile={currentProfile}
              />
            ))
          : null}
      </div>
    </div>
  );
}

function ActionLine(props: ActionLineProps) {
  const { action, profile } = props;
  const [
    createOrUpdateAction,
    { status },
  ] = useCreateOrUpdatePermissionAction();
  const [deleteAction, { status: deleteStatus }] = useDeletePermissionAction();
  const permissionAction = get(
    profile,
    `permissionActions['${action.code}']`,
    null
  );

  return (
    <div
      key={action.id}
      className="flex  justify-between items-center py-4 border-solid border-b border-gray-200 first:border-t"
    >
      <div className="pr-6">{action.label}</div>
      <div>
        {action.hasScope ? (
          <DisplaySelect
            label=""
            // @ts-ignore TODO à supprimer quand DisplaySelect sera typé
            value={scopeOptions.find(
              (e) =>
                e.value ===
                get(profile.permissionActions[action.code], "scope", null)
            )}
            options={scopeOptions}
            onChange={async (selected) => {
              if (selected.value === null) {
                await deleteAction(get(permissionAction, "id", null));
              } else {
                await createOrUpdateAction({
                  id: get(permissionAction, "id", null),
                  data: {
                    permission: profile["@id"],
                    action: action["@id"],
                    scope: selected.value,
                  },
                });
              }
            }}
            className={"ml-auto w-48"}
          />
        ) : (
          <Toggle
            value={get(permissionAction, "@id", false)}
            label={""}
            name={action.code}
            checked={get(permissionAction, "@id", false)}
            onChange={async (e: Event) => {
              const element = e.target as HTMLInputElement;
              if (element.checked) {
                await createOrUpdateAction({
                  id: get(permissionAction, "id", null),
                  data: {
                    permission: profile["@id"],
                    action: action["@id"],
                    scope: "all",
                  },
                });
              } else {
                await deleteAction(get(permissionAction, "id", null));
              }
            }}
            submitting={status === "loading" || deleteStatus === "loading"}
          />
        )}
      </div>
    </div>
  );
}

function PermissionAdminPage() {
  const { data: profiles } = useQueryPermissions({ "order[label]": "ASC" });
  const { data: modules } = useQueryModulesDomain( );
  const [currentProfile, setCurrentProfile] = useState<number>(-1);
  const [currentModule, setCurrentModule] = useState<Module | null>(null);
  const [profileActions, setProfileActions] = useState<ParsedPermission[]>([]);

  useEffect(() => {
    if (!currentModule && !isEmpty(modules)) {
      setCurrentModule(get(modules, "0"));
    }
  }, [currentModule, modules]);
  useEffect(() => {
    if (currentProfile === -1 && !isEmpty(profileActions)) {
      setCurrentProfile(0);
    }
  }, [currentProfile, profileActions]);
  useEffect(() => {
    const profileActions = [];
    for (const profile of profiles) {
      profileActions.push({
        ...profile,
        permissionActions: reduce(
          profile.permissionActions,
          (permissionActions, permissionAction) => {
            return {
              ...permissionActions,
              [permissionAction.action.code]: permissionAction,
            };
          },
          {}
        ),
      });
    }
    setProfileActions(profileActions);
  }, [profiles]);

  return (
    <div>
      <Helmet>
        <title>Gestion des droits</title>
      </Helmet>
      <HeaderBar>Gestion des droits</HeaderBar>
      <div className="px-8">
        <NavBar
          profiles={profiles}
          setCurrentProfile={(profile) => {
            setCurrentProfile(profile);
          }}
          currentProfile={get(profiles, currentProfile)}
        />
      </div>
      <div className="px-8 mb-48 mt-16 flex justify-center">
        <div className="w-full flex">
          <SideBarModule
            setCurrentModule={setCurrentModule}
            currentModule={currentModule}
            modules={modules}
          />
          {currentProfile !== null ? (
            <PermissionsList
              currentProfile={get(profileActions, currentProfile, null)}
              currentModule={currentModule}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

function SideBarModule(props: SideBarModuleProps) {
  const { modules, setCurrentModule, currentModule } = props;
  if (isEmpty(modules) || !currentModule) return null;
  return (
    <div className="w-1/3 bg-white text-lg">
      {map(modules, (module) => (
        <button
          className={`p-4 hover:bg-gray-50 flex w-full focus:outline-none ${
            currentModule?.id === module.id
              ? "font-bold border-l-4 border-green-600"
              : ""
          }`}
          key={module.id}
          onClick={() => {
            setCurrentModule(module);
          }}
        >
          {module.label}
        </button>
      ))}
    </div>
  );
}

export default PermissionAdminPage;
