import { useFormikContext } from "formik";
import { isEmpty, map } from "lodash-es";

import Button from "../components/Button";
import Input, { DateTimePickerInput } from "../components/Input";
import React from "react";
import { usePermissionsChecker } from "../contexts/permissions";

function HealthAssurance() {
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.collaborator.edit",
      "kdix.actions.collaborator.edit.agency",
      "kdix.actions.collaborator.edit.department",
      "kdix.actions.collaborator.edit.service",
      "kdix.actions.collaborator.edit.own",
    ],
  });
  const { values, setFieldValue } = useFormikContext();

  return (
    <div className="mt-12">
      <div className="flex justify-between">
        <div className="font-bold text-lg leading-tight relative">
          Ayant(s) droit(s) :
        </div>

        {!readOnly && (
          <Button
            className="btn btn--sm"
            type="button"
            onClick={() => {
              setFieldValue("beneficiaries", [
                {
                  firstname: "",
                  lastname: "",
                  birthDate: "",
                  affiliationDate: "",
                },
                ...values.beneficiaries,
              ]);
            }}
          >
            Ajouter un ayant droit
          </Button>
        )}
      </div>

      {isEmpty(values.beneficiaries) && (
        <div className="mt-5">
          <p>Aucun ayant droit</p>
        </div>
      )}

      <div className="grid md:grid-cols-2  -mx-8 pb-8">
        {map(values.beneficiaries, (beneficiary, index) => (
          <div key={index} className="px-8 my-8">
            <Input name={`beneficiaries[${index}].firstname`} label="Prénom" />
            <Input name={`beneficiaries[${index}].lastname`} label="Nom" />
            <DateTimePickerInput
              name={`beneficiaries[${index}].birthDate`}
              label="Date de naissance"
            />
            <DateTimePickerInput
              name={`beneficiaries[${index}].affiliationDate`}
              label="Date d'affiliation"
            />
            {!readOnly && ( <div className={"flex"}>
              <div
                className="btn btn--sm btn--error ml-auto"
                onClick={() => {
                  setFieldValue(
                    "beneficiaries",
                    values.beneficiaries.filter((c, idx) => index !== idx)
                  );
                }}
              >
                Supprimer
              </div>
            </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HealthAssurance;
