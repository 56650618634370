import Input from "../components/Input";
import React from "react";
import { usePermissionsChecker } from "../contexts/permissions";

export default function EmergencyContact() {
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.collaborator.edit",
      "kdix.actions.collaborator.edit.agency",
      "kdix.actions.collaborator.edit.department",
      "kdix.actions.collaborator.edit.service",
      "kdix.actions.collaborator.edit.own",
    ],
  });

  return (
    <div className="">
      <Input
        type="text"
        label="Prénom"
        name="emergencyContact.firstname"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Nom"
        name="emergencyContact.lastname"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Téléphone"
        name="emergencyContact.phoneNumber"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Nature du contact"
        name="emergencyContact.status"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Adresse"
        name="emergencyContact.address.street"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Complément d'adresse"
        name="emergencyContact.address.complement"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Code Postal"
        name="emergencyContact.address.postCode"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Ville"
        name="emergencyContact.address.city"
        readOnly={readOnly}
      />
    </div>
  );
}
