import React, { createContext, ReactNode, useEffect } from "react";
import { FiltersContextType } from "../types/filtersContext";
import { useLocalStorage, useSetState } from "react-use";

const emptyContext = {
  collaboratorsFilters: { active: [1] },
  setCollaboratorsFilters: () => {},
};

export const CollaboratorFiltersContext = createContext<FiltersContextType>({
  ...emptyContext,
});

export default function CollaboratorFiltersProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [state, setState] = useSetState<FiltersContextType>({
    ...emptyContext,
  });
  const [collaboratorsFilters, setCollaboratorsFilters] = useLocalStorage(
    "filtersCollaborator",
    JSON.parse(window.localStorage.getItem("filtersCollaborator") || "[]"),
    {
      raw: true,
    }
  );

  useEffect(() => {
    if (collaboratorsFilters && typeof collaboratorsFilters === "string") {
      setState({
        collaboratorsFilters: JSON.parse(collaboratorsFilters),
      });
    } else {
      setState({
        collaboratorsFilters: { active: [1] },
      });
    }
  }, [setState, collaboratorsFilters]);

  return (
    <CollaboratorFiltersContext.Provider
      value={{
        ...state,
        setCollaboratorsFilters,
      }}
    >
      {children}
    </CollaboratorFiltersContext.Provider>
  );
}
