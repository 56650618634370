import { queryCache, useMutation, useQuery } from "react-query";
import queryWithToken, { create, updateById, deleteById } from "./index";
import { get } from "lodash-es";
import {toast} from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "vehicle_attribution_histories";
//

export function useQueryVehicleAttributionHistory() {
  const { data, ...rest } = useQuery(
    ressourceKey,
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false
        }
      }),
    {
      refetchOnWindowFocus: false
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest
  };
}
export function useQueryLastVehicleAttributionHistory(vehicleId) {
  const { data } = useQuery(
    ressourceKey,
    (_, __, params) => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
        {
          method: "GET",
          params: {
            "order[createdAt]": "desc",
            itemsPerPage: 1,
            vehicle: vehicleId
          }
        }
      );
    },
    {
      refetchOnWindowFocus: false
    }
  );
  return get(data, "hydra:member[0]");
}

export function useCreateVehicleAttributionHistory() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onSuccess: data => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries(["vehicles", get(data, "vehicle.id")]);
    }
  });
}

export function useDeleteVehicleAttributionHistory() {
    return useMutation(deleteById.bind(null, ressourceKey), {
        throwOnError: true,
        onSuccess: data => {
            queryCache.refetchQueries(ressourceKey);
            queryCache.refetchQueries('vehicles');
            queryCache.refetchQueries("history");
        },
        onError: () => {
            toast.error("Erreur lors de la suppression");
        },
    });
}

export function useUpdateVehicleAttributionHistory() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: (newVehicle = null) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: data => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries([ressourceKey, data.id]);
    }
  });
}
