import {
  ModuleChecker,
  PermissionChecker,
  useCollaboratorId,
  useDisplayName,
  useDomainName,
  useDomain,
  useIsImpersonate,
  useUseExternalDocumentManagement,
  useInsuranceTel,
  useInsuranceContract,
  useGedlink
} from "../contexts/permissions";
import Dropdown from "./Dropdown";
import Hamburger from "./Hamburger";
import { Link } from "react-router-dom";
import { ReactComponent as LockIcon } from "../svgs/lock.svg";
import { ReactComponent as LogoutIcon } from "../svgs/logout.svg";
import Menu from "./Menu";
import PrivateRoute from "./PrivateRoute";
import { ReactComponent as ProfileIcon } from "../svgs/profile.svg";
import { ReactComponent as RequestOutlineIcon } from "../svgs/request-outline.svg";
import { ReactComponent as RequestSendIcon } from "../svgs/request-send.svg";
import { ReactComponent as CongesIcon } from "../svgs/conges.svg";
import { ReactComponent as CalendarIcon } from "../svgs/calendrier.svg";
import { ReactComponent as HelpIcon } from "../svgs/aide.svg";
import { ReactComponent as LightBulbIcon } from "../svgs/light-bulb.svg";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { logout } from "../graphql";
import menuConfig from "../menuconfig";
import HelpModal from "./HelpModal";
import Modal from "./Modal";
import ExternalLink from "./ExternalLink";
import { useQuerySynchroniseDocumentsByDomainId } from "../api/synchroniseDocuments";
import { useDesktop } from "../hooks/useDesktop";


export default function Header({ isOpen, toggleMenu }) {
  const collaboratorId = useCollaboratorId();
  const insuranceTel = useInsuranceTel();
  const insuranceContract = useInsuranceContract();
  const getLinkUrl = useGedlink();
  
  const displayName = useDisplayName();
  const domainName = useDomainName();
  const impersonate = useIsImpersonate();
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [vehicleModalOpen, setVehicleModalOpen] = useState(false);
  
  const useExternalDocumentManagement = useUseExternalDocumentManagement();
  const [isSynchronizing, setIsSynchronizing] = React.useState(false);
  const [
    synchroniseDocumentsByDomainId,
  ] = useQuerySynchroniseDocumentsByDomainId();
  const domainId = useDomain();
  const insurancePhoneLink = 'tel:' + insuranceTel;
  const isDesktop = useDesktop();
    
  return (
    <PrivateRoute>
      <header className="sticky font-title top-0 z-50 bg-gray-600 flex items-center px-6">
        <div className="mr-4">
          <Hamburger open={isOpen} handleClick={toggleMenu} />
        </div>

        <Link
          to="/"
          className="text-white text-2xl font-black leading-none py-5"
        >
          {domainName}
        </Link>

        <div
          className={`ml-auto py-5 px-5 ${impersonate ? "bg-purple-600" : ""}`}
        >
          <Dropdown
            label={displayName || "Mon profil"}
            arrow
            childrenClassName="bg-gray-700 text-white shadow-lg top-0 mt-16 right-0"
            childrenStyle={{ minWidth: "280px" }}
            relative={false}
          >
            {collaboratorId ? (
              <Link
                to={`/rh/collaborateurs/${collaboratorId}`}
                className="flex items-center px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              >
                <ProfileIcon className="w-5 h-5 text-white fill-current" />
                <div className="ml-3">Accéder au profil</div>
              </Link>
            ) : null}
            <ModuleChecker module={"messages"}>
              <Link
                to="/envoyer-message"
                className="flex items-center px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              >
                <RequestOutlineIcon className="w-5 h-5 text-white" />
                <div className="ml-3">Envoyer un message</div>
              </Link>
            </ModuleChecker>
            
            <ModuleChecker module={"requests"}>
            <Link  
                to="/envoyer-demande"
                className="flex items-center px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              >
                <RequestSendIcon className="w-5 h-5 text-white" />
                <div className="ml-3">Envoyer une demande</div>
              </Link>
            </ModuleChecker>

            <PermissionChecker
              permissions={[
                "kdix.actions.absence.edit",
                "kdix.actions.absence.edit.agency",
                "kdix.actions.absence.edit.department",
                "kdix.actions.absence.edit.service",
                "kdix.actions.absence.edit.own",
              ]}
              notAllowed={null}
            >
              <Link
                to="/creer-absence"
                className="flex items-center px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              >
                <CongesIcon className="w-5 h-5 text-white fill-current" />
                <div className="ml-3">Demande d’absence</div>
              </Link>
            </PermissionChecker>
            <PermissionChecker
              permissions={[
                "kdix.actions.ged_link.view",
              ]}
              notAllowed={null}
            >
             {useExternalDocumentManagement ? (
               <>
              <div
               className="flex items-center px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
               onClick={async () => {
                 if(!isSynchronizing)
                 {
                  setIsSynchronizing(true);
                  toast.info(
                          `Synchronisation des documents en cours sur le domaine "${domainName}"`
                        );
                  const {
                    documents_synchronized,
                    organization_documents_synchronized,
                  } = await synchroniseDocumentsByDomainId({
                   domainId: domainId,
                  });
                  setIsSynchronizing(false);
                  toast.success(
                    `${documents_synchronized} documents synchronisés`
                  );
                  if (organization_documents_synchronized) {
                    toast.success(
                     `${organization_documents_synchronized} documents entreprise synchronisés`
                    );
                  }
                 }
                }}
              >
              <ProfileIcon className="w-5 h-5 text-white fill-current" />
              <div className="ml-3">Synchronisation coffre fort RH</div>
             </div>
             {getLinkUrl ? (
                 <ExternalLink to={ `${getLinkUrl}` } className="flex items-center px-4 py-3 text-white hover:bg-green-600 cursor-pointer">
                   <ProfileIcon className="w-5 h-5 text-white fill-current" />
                   <div className="ml-3">Accès coffre fort RH</div>
                 </ExternalLink>
               ) : null }
             </>
             ) : null}
            </PermissionChecker>
            <PermissionChecker
              permissions={["kdix.actions.overtime.manage"]}
              notAllowed={null}
            >
              <Link
                to="/importer-heures-supplementaires"
                className="flex items-center px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              >
                <CalendarIcon className="w-5 h-5 text-white fill-current" />
                <div className="ml-3">Importer les heures sup.</div>
              </Link>
            </PermissionChecker>
            <Link
              to="/changement-mdp"
              className="flex items-center px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
            >
              <LockIcon className="w-5 h-5 text-white fill-current" />
              <div className="ml-3">Changement du mot de passe</div>
            </Link>
            
            <ModuleChecker module={"vehicles"}>
                {collaboratorId && (insuranceTel || insuranceContract) ? (       
                    <div
                      className="flex items-center px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
                      onClick={() => {
                         setVehicleModalOpen(true);
                      }}
                    >
                   <HelpIcon className="w-5 h-5 text-white fill-current" />
              <div className="ml-3">Aide véhicules</div>
            </div>
                ) : null }
            </ModuleChecker>
            
            <div
              className="flex items-center px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              onClick={() => {
                setHelpModalOpen(true);
              }}
            >
              <HelpIcon className="w-5 h-5 text-white fill-current" />
              <div className="ml-3">Aide</div>
            </div>
            <div
              className="flex items-center px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              onClick={() => {
                logout();
              }}
            >
              <LogoutIcon className="w-5 h-5 text-white fill-current" />
              <div className="ml-3">Déconnexion</div>
            </div>
          </Dropdown>
        </div>

        <Menu isOpen={isOpen} toggleMenu={toggleMenu} tree={menuConfig} />
        <ExternalLink
          to="https://docs.google.com/forms/d/e/1FAIpQLScfvbvrZi4vD9wKA7C5KfElmBrkhpTyGbrBk0SDNoQy0gk__Q/viewform?usp=sf_link"
          className="flex items-center justify-center cursor-pointer"
        >
          <LightBulbIcon className="w-5 h-5 text-white fill-current" />
        </ExternalLink>

      </header>
      <HelpModal
        helpModalOpen={helpModalOpen}
        setHelpModalOpen={setHelpModalOpen}
      />
      <Modal
        isOpen={vehicleModalOpen}
        onRequestClose={() => setVehicleModalOpen(false)}
        title="Informations assurance véhicule"
        handleClose={(e) => {
        e.stopPropagation();
      }}
      >
        <p>Ci-dessous, les informations concernant l'assurance des véhicules :</p>
        <div className="mt-5 px-5"> 
          <div className="font-bold p-4">
                  N° tel assurance : 
          </div>
          <div className="pl-4 pt-2">
                 { insuranceTel ? insuranceTel : ""}
          </div>
          {insuranceTel && !isDesktop ? (
            <a href={insurancePhoneLink} className={`btn btn--sm mb-4 ml-auto block w-fit`}>
                APPELER
            </a>
           ) : null}
          <div className="font-bold p-4">
            N° contrat assurance : 
          </div>
          <div className="pl-4 pt-2">
            {insuranceContract ? insuranceContract : ""}
          </div>
        </div>
      </Modal>
     
    </PrivateRoute>
  );
}

