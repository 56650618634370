import queryWithToken from "./index";
import mime from "mime-types";
import { useQuery } from "react-query";
import { get } from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "organization_documents";
//

export async function downloadDocument(
  id,
  label = "document",
  extension = "pdf"
) {
  const blob = await queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}`,
    {
      method: "GET",
      responseType: "blob",
    }
  );
  if (blob) {
    const type = mime.lookup(extension);
    const file = new Blob([blob], { type });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}

export async function previewDocument(
  id,
  label = "document",
  extension = "pdf"
) {
  const blob = await queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}`,
    {
      method: "GET",
      responseType: "blob",
    }
  );
  if (blob) {
    const type = mime.lookup(extension);
    const file = new Blob([blob], { type });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}

export function useQueryOrganizationDocuments(filters) {
  const { data, ...rest } = useQuery(
      [ressourceKey, filters],
      () =>
          queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
            method: "GET",
            params: {
              page: null,
              pagination: false,
              ...filters,
            },
          }),
      {
        refetchOnWindowFocus: false,
      }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}
