import { Form, Formik } from "formik";
import { get, map, toString } from "lodash-es";
import { useCreateOrUpdateSalary, useDeleteSalary } from "../api/salary";

import Alert from "../components/Alert";
import Button from "../components/Button";
import { DisplayCheckbox } from "../components/Checkbox";
import EditButton from "../components/HistoryItem/EditButton";
import Input, { DateTimePickerInput } from "../components/Input";
import React from "react";
import Select from "../components/Select";
import mapFormValues from "../utils/mapFormValues";
import { useQueryTypeCalculationMethods } from "../api/typeCalculationMethod";
import { useQueryTypeReasons } from "../api/typeReasons";
import { useQueryTypeWeeklyWorkingDurations } from "../api/typeWeeklyWorkingDuration";
import * as Yup from "yup";

function SalariesForm({
  salary,
  contractIRI,
  className = "",
  handleSubmit,
  readOnly = false,
}) {
  const [isEditing, setIsEditing] = React.useState(!salary);
  const { data: typeReasons } = useQueryTypeReasons();
  const {
    data: typeWeeklyWorkingDurations,
  } = useQueryTypeWeeklyWorkingDurations();
  const { data: typeCalculationMethods } = useQueryTypeCalculationMethods();

  const [update, { error }] = useCreateOrUpdateSalary();
  const [deleteSalary] = useDeleteSalary();

  //Un salaire est forcément lié à un contrat, si on n'a pas de contrat on ne peut pas ajouter de salaire
  if (!contractIRI) return null;

  //En mode synchro avec Silae on ne peut pas créer de nouveau salaire dans l'interface,
  // donc s'il n'y en a pas d'existant on n'affiche pas le formulaire => désactivé pour le moment
  // if (isSilae && salary === null) {
  //   return null;
  // }

  const validation = Yup.object().shape({
    date: Yup.date().required("Requis"),
  });

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validation}
      initialValues={{
        rate: get(salary, "rate", 0),
        compensation: get(salary, "compensation", ""),
        abatement: get(salary, "abatement", false),
        daysWorkedWeekly: get(salary, "daysWorkedWeekly", 0),
        daysWorkedYearly: get(salary, "daysWorkedYearly", 0),
        typeCalculationMethod: {
          label: get(salary, "typeCalculationMethod.label", ""),
          value: get(salary, "typeCalculationMethod.@id", ""),
        },
        typeWeeklyWorkingDuration: {
          label: get(salary, "typeWeeklyWorkingDuration.label", ""),
          value: get(salary, "typeWeeklyWorkingDuration.@id", ""),
        },
        typeReason: {
          label: get(salary, "typeReason.label", ""),
          value: get(salary, "typeReason.@id", ""),
        },
        monthlyHours: get(salary, "monthlyHours", 0),
        baseSalary: get(salary, "baseSalary", 0),
        rubricCode: get(salary, "rubricCode", ""),
        date: get(salary, "date") ? new Date(get(salary, "date")) : "",
      }}
      onSubmit={async ({ rate, date, ...values }, actions) => {
        try {
          const prepareValues = {
            ...mapFormValues({
              rate: toString(rate),
              date: date ? date : null,
              ...values,
            }),
            contract: contractIRI,
          };
          await update({
            id: get(salary, "id", null),
            data: prepareValues,
          });
          setIsEditing(false);
          actions.setSubmitting(false);
          handleSubmit();
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
          actions.setSubmitting(false);
        }
      }}
    >
      {({ values, setFieldValue, isSubmitting, resetForm, touched }) => {
        return (
          <Form className={className}>
            <div className={"flex flex-wrap"}>
              <div className={"w-full lg:w-1/2 lg:pr-2"}>
                <Input
                  type="number"
                  name="rate"
                  label="Taux salarial ou Horaire"
                  readOnly={!isEditing}
                />
                <DateTimePickerInput
                  label="Date"
                  name="date"
                  readOnly={!isEditing}
                />
                <Input
                  type="number"
                  name="compensation"
                  label="Indemnité compensatrice"
                  readOnly={!isEditing}
                />
                <Input
                  type="number"
                  name="daysWorkedWeekly"
                  label="Nombre de jours travaillés par semaine"
                  readOnly={!isEditing}
                />
                <Input
                  type="number"
                  name="daysWorkedYearly"
                  label="Nombre de jours travaillés par an"
                  readOnly={!isEditing}
                />
                <Input
                  type="number"
                  name="monthlyHours"
                  label="Nombre d'heures mensuelles"
                  readOnly={!isEditing}
                />
              </div>
              <div className={"w-full lg:w-1/2 lg:pl-2"}>
                <Select
                  label="Type de rémunération"
                  name="typeCalculationMethod"
                  options={map(typeCalculationMethods, (node) => ({
                    label: node.label,
                    value: node["@id"],
                  }))}
                  value={values.salaries?.typeCalculationMethod}
                  isDisabled={!isEditing}
                  isLoading={!typeCalculationMethods}
                />
                <Select
                  label="Nombre d'heures hebdomadaires"
                  name="typeWeeklyWorkingDuration"
                  options={map(typeWeeklyWorkingDurations, (node) => ({
                    label: node.label,
                    value: node["@id"],
                  }))}
                  value={values.salaries?.typeWeeklyWorkingDuration}
                  isDisabled={!isEditing}
                  isLoading={!typeWeeklyWorkingDurations}
                />
                <Select
                  label="Type d'évolution salariale"
                  name="typeReason"
                  options={map(typeReasons, (node) => ({
                    label: node.label,
                    value: node["@id"],
                  }))}
                  value={values.salaries?.typeReason}
                  isDisabled={!isEditing}
                  isLoading={!typeReasons}
                />
                <Input
                  type="number"
                  name="baseSalary"
                  label="Salaire de base (avec heures sup. mens.)"
                  readOnly={!isEditing}
                />
                <Input
                  name="rubricCode"
                  label="Code rubrique salaire"
                  readOnly={!isEditing}
                />
                <DisplayCheckbox
                  label="Abattement"
                  name="abatement"
                  value="true"
                  id="abatement"
                  checked={values.abatement}
                  readOnly={!isEditing}
                  className="mb-4"
                  onChange={(e) => {
                    setFieldValue("abatement", e.target.checked);
                  }}
                />
              </div>
            </div>
            {error ? (
              <div className="my-2">
                <Alert
                  type="error"
                  message={get(error, "title")}
                  details={get(error, "description")}
                />
              </div>
            ) : null}

            {!readOnly ? (
              salary ? (
                <div className={"ml-auto"}>
                  <EditButton
                    isSubmitting={isSubmitting}
                    isEditing={isEditing}
                    onDelete={() => deleteSalary(salary.id)}
                    onCancel={() => {
                      resetForm();
                      setIsEditing(!isEditing);
                    }}
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                  />
                </div>
              ) : (
                <Button
                  className={`btn ml-4`}
                  readOnly={false}
                  isSubmitting={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Créer"
                />
              )
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
}

export default SalariesForm;
