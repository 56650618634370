import { Helmet } from "react-helmet";
import React from "react";
import Spinner from "../components/Spinner";
import { useDesktop } from "../hooks/useDesktop";
import AbsenceNotificationsList from "../components/AbsenceNotificationsList";
import { HeaderBar } from "../components/HeaderBar";

function AbsenceNotifications() {
  const isDesktop = useDesktop();
  return (
    <div>
      <Helmet>
        <title>Congés et Absences</title>
      </Helmet>
      <HeaderBar>Congés et Absences</HeaderBar>
      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="px-8 mt-4">
          <React.Suspense fallback={<Spinner />}>
            <AbsenceNotificationsList />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}
export default AbsenceNotifications;
