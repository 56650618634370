import React from "react";

/**
 * Use with YUP to validate user input
 */
function DateFormat({ date, twoDigit }) {
  if (!date || new Date(date).toString() === "Invalid Date") return null;
  return (
    <>
      {new Date(date).toLocaleDateString(
        undefined,
        !twoDigit
          ? undefined
          : {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            }
      )}
    </>
  );
}

export default DateFormat;
