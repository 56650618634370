import Block from "../Block";
import React from "react";
import {useQueryCollaboratorRtts} from "../../api/collaboratorRtt";
import {forEach} from "lodash-es";

function getTotalRttsFromData(data) {
  let total = 0;

  const currentMonth = (new Date()).getMonth() + 1;
  forEach(data, (rtt) => {
    if (rtt.year !== null) {
      total = parseFloat(rtt.value);
      return parseFloat(rtt.value);
    }

    if (parseInt(rtt.month) === currentMonth) {
      total = parseFloat(rtt.value);
    }
  })

  return total;
}

export default function RttsBlock({ collaboratorId }) {
  const { data } = useQueryCollaboratorRtts({
    collaborator: collaboratorId ? collaboratorId : 0
  });

  const nbRtts = data? getTotalRttsFromData(data) : 0;
  const plural = nbRtts > 1 ? "s" : "";
  return nbRtts >= 0 ? (
    <div className={"w-full md:w-1/3 p-2 h-64"}>
      <Block className="text-center flex-grow bg-white">
        <div
          className={`bg-purple-400 inline-flex text-white text-2xl rounded-full font-bold w-16 h-16 items-center justify-center mb-4`}
        >
          {nbRtts.toLocaleString("fr-FR")}
        </div>
        <div className="mt-3 text-xl font-black">
          {`Jour${plural} de RTT restant${plural}`}
        </div>
      </Block>
    </div>
  ) : null;
}
