import React from "react";
import { ReactComponent as Replier } from "../svgs/replier.svg";
import { ReactComponent as Deplier } from "../svgs/deplier.svg";

function Hamburger({ open, handleClick }) {
  return (
    <div className={`text-white cursor-pointer`} onClick={handleClick}>
      {open ? <Replier /> : <Deplier />}
    </div>
  );
}

export default Hamburger;
