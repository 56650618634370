import { Form, Formik } from "formik";
import Input from "../components/Input";
import React from "react";
import { useCreateOrUpdateProcess } from "../api/process";
import * as Yup from "yup";
import { get, noop } from "lodash-es";

const validateNewProcess = Yup.object().shape({
  label: Yup.string().required("Requis"),
});

export default function ProcessForm({
  isOut,
  process = null,
  afterSubmit = noop,
}) {
  const [createProcess] = useCreateOrUpdateProcess();
  return (
    <div className="mt-8">
      <Formik
        initialValues={{
          label: get(process, "label", ""),
        }}
        validationSchema={validateNewProcess}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await createProcess({
            id: get(process, "id", null),
            data: {
              label: values.label,
              isOut: process ? process.isOut : isOut,
            },
          });
          setSubmitting(false);
          resetForm();
          afterSubmit();
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div className="sm:flex mb-8 items-start">
                <Input
                  name="label"
                  placeholder="Nom du processus"
                  className="mb-0 w-full mr-4"
                />

                <button
                  type="submit"
                  className="w-full sm:w-auto mt-4 sm:mt-0 flex-no-shrink btn btn--sm rounded-none whitespace-no-wrap"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <div className="loader h-8 w-8 mx-auto" />
                  ) : process ? (
                    "Modifier"
                  ) : (
                    "Créer un nouveau processus"
                  )}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
