import Alert from "./Alert";
import React from "react";

export default function Error({ message, details }) {
  return (
    <div className="mb-48">
      <Alert type="error" message={message} details={details} />
    </div>
  );
}
