import Block from "./Block";
import Modal from "react-modal";
import React from "react";
import { useLockBodyScroll } from "react-use";

function BasicModal({
  isOpen,
  onRequestClose = () => {},
  title,
  className = "outline-none w-full max-w-full lg:max-w-xl max-h-full overflow-visible",
  children,
  ...props
}) {
  useLockBodyScroll(isOpen);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={{
        base: className,
        afterOpen: "",
        beforeClose: "",
      }}
      overlayClassName={{
        base: "fixed bg-gray-900 bg-opacity-50 inset-0 z-40 flex items-start justify-center p-8 h-fullWithoutMenuHeight customPopUpPos",
        afterOpen: "",
        beforeClose: "",
      }}
      bodyOpenClassName={null}
      parentSelector={() =>
        document.getElementById("main-content") || document.body
      }
      {...props}
    >
      <Block>
        <div className="flex justify-between items-center mb-5">
          <div className="text-2xl">{title}</div>
          <button onClick={onRequestClose}>
            <svg
              className="w-5 h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
            </svg>
          </button>
        </div>
        <div>{children}</div>
      </Block>
    </Modal>
  );
}

export default BasicModal;
