export const absenceStatus = {
  WORKFLOW_STATUS_CREATED: "created",
  WORKFLOW_STATUS_PREVALIDATED: "prevalidated",
  WORKFLOW_STATUS_VALIDATED: "validated",
  WORKFLOW_STATUS_REFUSED: "refused",
};
export const absenceTransition = {
  WORKFLOW_STATUS_PREVALIDATE: "prevalidate",
  WORKFLOW_STATUS_VALIDATE: "validate",
  WORKFLOW_STATUS_REFUSE: "refuse",
};

export const absenceStatusLabel = {
  STATUS_NEED_VALIDATION: "Demande en attente",
  STATUS_ACCEPTED: "Demande validée",
  STATUS_REFUSED: "Demande refusée",
};

export const periodLabels = {
  morning: "le matin",
  afternoon: "l'après-midi",
  night: "la nuit",
};

export function getStatusLabel(name, type) {
  switch (name) {
    case "created":
      if (type.needPreValidation) {
        return "En attente de pré-validation";
      } else {
        return "En attente de validation";
      }
    case "prevalidated":
      return "En attente de validation";
    case "validated":
      return "Validée";
    case "refused":
      return "Refusée";
    default:
      return "NC";
  }
}

export function getTransitionLabel(name) {
  switch (name) {
    case "prevalidate":
      return "Pré validée";
    case "validate":
      return "Validée";
    case "refuse":
      return "Refusée";
    default:
      return "NC";
  }
}
export function getMessageLabel(name, notification) {
  switch (name) {
    case "absence_notification.absence_is_close":
      switch (notification.absence?.status) {
        case "created":
          return [
            ["L'absence aura bientôt lieu", "En attente"],
            ["warning", "warning"],
          ];
        case "prevalidated":
          return [
            ["L'absence aura bientôt lieu", "En attente de validation"],
            ["warning", "warning"],
          ];
        case "validated":
          return [
            ["L'absence aura bientôt lieu", "Validée"],
            ["warning", "success"],
          ];
        default:
          return name;
      }
    case "absence_notification.need_prevalidation":
      return ["En attente de pré-validation", "warning"];
    case "absence_notification.need_validation":
      return ["En attente de validation", "warning"];
    case "absence_notification.prevalidated":
      return ["Prévalidée", "success"];
    case "absence_notification.validated":
      return ["Validée", "success"];
    case "absence_notification.need_prevalidation.need_documents":
      return [
        ["En attente de pré-validation", "En attente de documents"],
        ["warning", "warning"],
      ];
    case "absence_notification.need_validation.need_documents":
      return [
        ["En attente de validation", "En attente de documents"],
        ["warning", "warning"],
      ];
    case "absence_notification.prevalidated.need_documents":
      return [
        ["Prévalidée", "En attente de documents"],
        ["success", "warning"],
      ];
    case "absence_notification.validated.need_documents":
      return [
        ["Validée", "En attente de documents"],
        ["success", "warning"],
      ];
    case "absence_notification.refused":
      return ["Refusée", "warning"];
    case "absence_notification.need_documents":
      return ["En attente de documents", "warning"];
    case "absence_notification.delay_prevalidation_has_passed":
      return ["Delai de non-réponse dépassé", "error"];
    case "absence_notification.delay_validation_has_passed":
      return ["Delai de non-réponse dépassé", "error"];
    case "absence_notification.need_prevalidation.has_documents":
      return [
        ["En attente de pré-validation", "Documents disponibles"],
        ["warning", "success"],
      ];
    case "absence_notification.need_validation.has_documents":
      return [
        ["En attente de validation", "Documents disponibles"],
        ["warning", "success"],
      ];
    default:
      return name;
  }
}
