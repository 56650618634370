import { has, mapValues, size } from "lodash";

export default function mapFormValues(formValues) {
  return mapValues(formValues, (formValue, key) => {
    if (key === "permissions") {
      return [formValue.value];
    }
    //This means that formValue is a result of a select
    if (has(formValue, "label") && has(formValue, "value")) {
      return formValue.value === "" ? null : formValue.value;
    }
    if (size(formValue) > 0 && typeof formValue === "object") {
      return mapFormValues(formValue);
    }
    return formValue === "" ? null : formValue;
  });
}
