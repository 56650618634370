import Block from "../Block";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";

function DelegateBlock() {
  const nbDelegate = 8;
  const nbRemainingDelegate = 12;
  return (
    <>
      <Block className="text-center flex-grow bg-white">
        <Link
          to={`#`}
          className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
        >
          <div
            className={`bg-purple-75 inline-flex text-black text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
          >
            {nbDelegate}
          </div>
          <div className="mt-3 text-xl font-black">
            {`Délégation${nbDelegate > 1 ? "s" : ""}`}
          </div>
          <div className="mt-2 text-base font-light">
            {`dont ${nbRemainingDelegate} nouvelle${
              nbRemainingDelegate > 1 ? "s" : ""
            }`}
          </div>
          <Button
            className={`font-bold p-1 focus:outline-none text-black text-white w-full block`}
          >
            voir tout
          </Button>
        </Link>
      </Block>
    </>
  );
}

export default DelegateBlock;
