import React from "react";
import { Form, Formik } from "formik";
import { get, map, filter } from "lodash-es";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Input from "../components/Input";
import Button from "../components/Button";
import TextArea from "../components/TextArea";
import { useAddRemoveSkillDomain, useCreateOrUpdateWork } from "../api/works";
import { useHistory } from "react-router-dom";
import Select from "../components/Select";
import { useQuerySkillDomains } from "../api/skillDomains";
import WorkSkillDomainsList from "../components/Skill/WorkSkillDomainsList";

const validation = Yup.object().shape({
  label: Yup.string().required("Requis"),
});

export default function WorkForm({ work = null }) {
  const [createWork] = useCreateOrUpdateWork();
  const history = useHistory();
  return (
    <Formik
      initialValues={{
        label: get(work, "label", ""),
        description: get(work, "description", ""),
      }}
      validationSchema={validation}
      onSubmit={async (values, actions) => {
        try {
          const newWork = await createWork({
            id: get(work, "id", null),
            data: {
              label: values.label,
              description: values.description,
            },
          });
          if (get(work, "id", "") === "" && newWork) {
            history.push("/rh/fiche_poste/" + newWork.id);
          }
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
        } finally {
          actions.setSubmitting(false);
        }
        toast.success("Mise à jour effectuée avec succès");
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div
            className={"flex flex-wrap justify-between sm:flex-row flex-col"}
          >
            <Input
              type="text"
              label="Nom de la fiche de poste"
              name="label"
              className={"flex-1 mr-8 mb-4 flex-grow-3"}
            />
          </div>

          <TextArea
            label={`Résumé de la fonction`}
            name={`description`}
            rows="10"
          />

          <WorkSkillDomainsList work={work} />

          <div className="text-center">
            <Button
              className={`btn inline-block mt-5`}
              isSubmitting={isSubmitting}
              isForm={true}
              type="submit"
              textLabel={get(work, "id", "") !== "" ? "Enregistrer" : "Créer"}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

const validationAdd = Yup.object().shape({
  skillDomain: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .typeError("Requis")
    .required("Requis"),
});

export function WorkAddDomainForm({ work, onSuccess }) {
  const [addSkillDomain] = useAddRemoveSkillDomain();
  const { data: skillDomains } = useQuerySkillDomains();
  const currentDomains = map(work.skillDomains, "id");
  return (
    <Formik
      initialValues={{
        skillDomain: null,
      }}
      validationSchema={validationAdd}
      onSubmit={async (values, actions) => {
        try {
          await addSkillDomain({
            id: work.id,
            skillDomains: [values.skillDomain?.value],
            type: "add",
          });
          onSuccess();
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
        } finally {
          actions.setSubmitting(false);
        }
        toast.success("Mise à jour effectuée avec succès");
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <div
            className={"flex flex-wrap justify-between sm:flex-row flex-col"}
          >
            <Select
              name="skillDomain"
              label="Domaine de compétence"
              placeholder="Domaine de compétence *"
              options={filter(
                map(skillDomains, (d) => ({
                  label: d.label,
                  value: d.id,
                })),
                (d) => !currentDomains.includes(d.value),
              )}
              isLoading={!skillDomains}
              className="flex-1"
            />
          </div>

          <div className="text-center">
            <Button
              className={`btn inline-block mt-5`}
              isSubmitting={isSubmitting}
              isForm={true}
              type="submit"
              textLabel="Ajouter"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}
