import { isDate } from "lodash-es";

/**
 * Compare two dates. Return true is the first date is before the second, false otherwise
 * @param startDate, the first date to compare
 * @param endDate, the second date to compare
 * @param strict, allow date equality
 * @returns {boolean}
 */
export function dateCompare(startDate, endDate, strict = false) {
  if (!isDate(startDate) || !isDate(endDate)) {
    return true;
  }
  if (strict) {
    return startDate.getTime() < endDate.getTime();
  } else {
    return startDate.getTime() <= endDate.getTime();
  }
}

/**
 * Retourne un chiffre entre 0 et 6 indiquant le jour de la date donnée (0 - Dimanche, 6 - Samedi)
 * @param date
 * @returns {number}
 */
export function dateDay(date) {
  if (!isDate(date)) {
    return -1;
  }

  return date.getDay();
}

/**
 *
 * @param startDate, the first date
 * @param endDate, the second date
 * @returns {number}
 */
export function dateDifference(startDate, endDate) {
  if (!isDate(startDate) || !isDate(endDate)) {
    return 0;
  }

  return (Math.ceil((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)));
}

/**
 *
 * @param startDate, the first date to compare
 * @param endDate, the second date to compare
 * @param startHour, the starting hour to compare
 * @param endHour, the ending hour to compare
 * @returns {boolean}
 */
export function dateCompareWithHours(startDate, endDate, startHour, endHour) {
  if (!isDate(startDate) || !isDate(endDate)) {
    return true;
  }

  if (startDate.getTime() < endDate.getTime())
    return true;

  if (startDate.getTime() > endDate.getTime())
    return false;

  if (!startHour || !endHour)
    return false;

  return startHour.getTime() < endHour.getTime();
}

/**
 *
 * @param startDate, the first date to compare
 * @param endDate, the second date to compare
 * @param startPeriod, the start period
 * @param endPeriod, the end period
 * @param periodList, the period list
 * @returns {boolean}
 */
export function periodCompare(startDate, endDate, startPeriod, endPeriod, periodList) {
  if (!isDate(startDate) || !isDate(endDate)) {
    return true;
  }

  if (startDate.getTime() < endDate.getTime())
    return true;

  if (startDate.getTime() > endDate.getTime())
    return false;

  const getIndex = (period) => {
    return periodList.findIndex(obj => {
      return obj.value === period.value
    });
  }

  return getIndex(endPeriod) > getIndex(startPeriod);
}

/**
 * Chek if a date is in a date range or not
 * @param date the date we want to know is in a date range
 * @param startDateRange the start date of the date range
 * @param endDateRange the end date of the date range
 * @param strict disallow date equality
 * @returns {null|boolean}
 */
export function isDateInDateRange(date, startDateRange, endDateRange, strict = false) {
  if (!isDate(date) || !isDate(startDateRange) || !isDate(endDateRange)) {
    return null;
  }

  const dateTime = date.getTime();

  if (strict) {
    return !(dateTime <= startDateRange.getTime() || dateTime >= endDateRange.getTime());
  }

  return !(dateTime < startDateRange.getTime() || dateTime > endDateRange.getTime());
}