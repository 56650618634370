import React from "react";
import Modal from "../Modal";
import { getFirstnameLastname } from "../../utils/names";
import TextArea from "../TextArea";
import { find, get, indexOf, map } from "lodash-es";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import { EditAndValidButton } from "../HistoryItem/EditButton";
import InfoTooltip from "../InfoTooltip";
import { absenceStatus, absenceTransition, periodLabels } from "../../utils/absenceStatus";
import { useCollaboratorIri } from "../../contexts/permissions";
import { useTransitionAbsence } from "../../api/absence";
import { minutesToHoursString } from "../../utils/date";

function getColorAbsence(absence) {
  if (!absence.type?.needValidation) {
    return "bg-purple-400";
  }
  if (absence.status === absenceStatus.WORKFLOW_STATUS_VALIDATED) {
    return "bg-green-900";
  }
  return "bg-green-600";
}

function needValidation(absence, userCollaboratorIri) {
  if (
    !absence.type?.needValidation ||
    absence.status === absenceStatus.WORKFLOW_STATUS_VALIDATED
  ) {
    return false;
  }
  if (
    absence.status === absenceStatus.WORKFLOW_STATUS_CREATED &&
    absence.type?.needPreValidation
  ) {
    return !!find(
      absence.collaborator?.prevalidators,
      (v) => v["@id"] === userCollaboratorIri
    );
  }
  if (
    absence.status !== absenceStatus.WORKFLOW_STATUS_VALIDATED &&
    absence.type?.needValidation
  ) {
    return !!find(
      absence.collaborator?.validators,
      (v) => v["@id"] === userCollaboratorIri
    );
  }
}

function displayAbsence(absenceState, absence) {
  if (absence.status === absenceStatus.WORKFLOW_STATUS_REFUSED) return false;
  let display = true;
  if (absenceState.length > 0 && absenceState.length < 3) {
    display = false;
    if (indexOf(absenceState, "without_validation") >= 0) {
      display = absence.recurrent || !absence.type?.needValidation;
    }
    if (indexOf(absenceState, absenceStatus.WORKFLOW_STATUS_CREATED) >= 0) {
      display =
        display ||
        (!!absence.type?.needValidation &&
          absence.status !== absenceStatus.WORKFLOW_STATUS_VALIDATED);
    }
    if (indexOf(absenceState, absenceStatus.WORKFLOW_STATUS_VALIDATED) >= 0) {
      display =
        display ||
        (!!absence.type?.needValidation &&
          absence.status === absenceStatus.WORKFLOW_STATUS_VALIDATED);
    }
  }
  return display;
}

function getDaysByType(type, findAbsence, days) {
  if (!findAbsence) return { firstDay: null, lastDay: null };
  const { startDate, endDate } = findAbsence;
  const firstDate =
    type === "quarter" ? days[0].date : days[0].date.startOf("week");
  const firstDay = -1 * firstDate.diff(startDate, "day");
  const lastDay = -1 * firstDate.diff(endDate, "day") - 1;

  return { firstDay, lastDay };
}

export default function AbsenceLine({
  type,
  days,
  maxDays,
  findAbsence,
  absenceState,
}) {
  const userCollaboratorIri = useCollaboratorIri();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { firstDay, lastDay } = getDaysByType(type, findAbsence, days);
  if (null === firstDay && null === lastDay) return null;
  if (!displayAbsence(absenceState, findAbsence)) return null;
  const isEndAfter = lastDay > maxDays;
  const isStartBefore = firstDay <= 0;
  var stringDateEnd = new Date(findAbsence.endDate).toLocaleDateString(
    undefined,
    {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    }
  );
  var stringDateStart = new Date(findAbsence.startDate).toLocaleDateString(
    undefined,
    {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    }
  );

  if(findAbsence.startHour && findAbsence.endHour)
  {
    stringDateStart = new Date(findAbsence.startDate).toLocaleDateString(
    undefined,
    {
      day: "2-digit",
      month: "2-digit",
    }) + " " + minutesToHoursString(findAbsence.startHour);

    stringDateEnd = new Date(findAbsence.endDate).toLocaleDateString(
    undefined,
    {
      day: "2-digit",
      month: "2-digit",
    }) + " " + minutesToHoursString(findAbsence.endHour) ;
  }

  if(findAbsence.startPeriod && findAbsence.endPeriod)
  {
	stringDateStart = new Date(findAbsence.startDate).toLocaleDateString(
    undefined,
    {
      day: "2-digit",
      month: "2-digit",
    }) + " " + periodLabels[findAbsence.startPeriod];
    stringDateEnd = new Date(findAbsence.endDate).toLocaleDateString(
    undefined,
    {
      day: "2-digit",
      month: "2-digit",
    }) + " " + periodLabels[findAbsence.endPeriod];
  }

  const isClickable = needValidation(findAbsence, userCollaboratorIri);
  return (
    <>
      <div
        className={`absolute inset-0 px-1 flex flex-col justify-center ${
          isStartBefore ? "pl-0" : ""
        } ${isEndAfter ? "pr-0" : ""}`}
        style={{
          gridColumnStart: isStartBefore ? 2 : firstDay + 2,
          gridColumnEnd: isEndAfter ? maxDays + 3 : lastDay + 3,
        }}
      >
        <InfoTooltip
          message={`Du ${stringDateStart} reprise le ${stringDateEnd}`}
          left
          onHover
        >
          <div
            className={`inset-0 ${getColorAbsence(
              findAbsence
            )} rounded-full text-center text-white px-3 py-1 text-xs truncate ${
              isClickable ? "cursor-pointer" : ""
            } ${isStartBefore ? "rounded-l-none" : ""} ${
              isEndAfter ? "rounded-r-none" : ""
            }`}
            onClick={() => {
              setIsOpen(isClickable);
            }}
          >
            Reprise le {stringDateEnd}
          </div>
        </InfoTooltip>
      </div>
      {isClickable && (
        <ModalAbsence
          modalIsOpen={modalIsOpen}
          findAbsence={findAbsence}
          setIsOpen={setIsOpen}
          stringDate={{ stringDateStart, stringDateEnd }}
        />
      )}
    </>
  );
}

function ModalAbsence({
  modalIsOpen,
  setIsOpen,
  findAbsence,
  stringDate: { stringDateStart, stringDateEnd },
}) {
  const userCollaboratorIri = useCollaboratorIri();
  const [transitionAbsence] = useTransitionAbsence();
  const canBeValidate = find(
    findAbsence.collaborator?.validators,
    (v) => v["@id"] === userCollaboratorIri
  );
  const newStatus =
    findAbsence.status === absenceStatus.WORKFLOW_STATUS_PREVALIDATED ||
    findAbsence.type.needPreValidation === false ||
    (canBeValidate &&
      !findAbsence.type.needCommentValidation &&
      !findAbsence.type.needCommentPreValidation)
      ? absenceTransition.WORKFLOW_STATUS_VALIDATE
      : absenceTransition.WORKFLOW_STATUS_PREVALIDATE;
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        setIsOpen(false);
      }}
      title="Valider un congé"
    >
      <div className="p-4">
        <div className="bg-gray-60 flex justify-between p-4">
          <div>
            <div className="font-bold">
              {getFirstnameLastname(findAbsence, "collaborator")}
            </div>
            {findAbsence.collaborator?.currentContract?.job.label && (
              <div>{findAbsence.collaborator.currentContract.job.label}</div>
            )}
          </div>
          <div className="font-bold">
            du {stringDateStart} au {stringDateEnd}
          </div>
        </div>
        <div>
          <Formik
            enableReinitialize
            initialValues={{ comment: findAbsence.comment, isRefused: false }}
            onSubmit={async (values, actions) => {
              try {
                await transitionAbsence({
                  id: findAbsence.id,
                  transition: values.isRefused
                    ? absenceTransition.WORKFLOW_STATUS_REFUSE
                    : newStatus,
                  // exceptional: exceptional,
                  commentAbsenceEvent: values.comment,
                });
                toast.success("Alerte modifiée avec succès");
                setIsOpen(false);
              } catch (error) {
                map(get(error, "violations"), (e) => {
                  actions.setFieldError(e.propertyPath, e.message);
                });
              }
            }}
          >
            {({ isSubmitting, submitForm, setFieldValue }) => (
              <Form>
                <div className="my-4">
                  <TextArea
                    label="Commentaire (facultatif)"
                    name="comment"
                    textareaClassName="comment"
                    //readOnly={readOnlyRequest}
                  />
                </div>
                <EditAndValidButton
                  isSubmitting={isSubmitting}
                  isEditing={true}
                  labelDelete="Refuser"
                  permissionsEdit={[
                    "kdix.actions.absence.edit",
                    "kdix.actions.absence.edit.agency",
                    "kdix.actions.absence.edit.department",
                    "kdix.actions.absence.edit.service",
                    "kdix.actions.absence.edit.own",
                  ]}
                  onDelete={() => {
                    setFieldValue("isRefused", true);
                    submitForm();
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
}
