import * as React from "react";

import { useCollaboratorId, usePermissionsChecker } from "./permissions";

import Alert from "../components/Alert";
import ErrorBoundary from "../components/ErrorBoundary";
import Spinner from "../components/Spinner";
import { get } from "lodash-es";
import { useQueryFormationById } from "../api/formation";
import { useQueryCollaboratorById } from "../api/collaborator";
export const FormationContext = React.createContext({
  formation: null,
});

function FormationError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération de l'habilitation"
        details={get(error, "data.hydra:description")}
      />
    </div>
  );
}

function FormationProvider({ id, children }) {
  const hasPermissions = usePermissionsChecker({
    permissions: [
      "kdix.actions.formation.edit",
      "kdix.actions.formation.edit.agency",
      "kdix.actions.formation.edit.department",
      "kdix.actions.formation.edit.service",
      "kdix.actions.formation.edit.own",
    ],
  });
  const userCollaborator = useCollaboratorId();
  const { data: collaborator } = useQueryCollaboratorById(userCollaborator);
  const full =
    hasPermissions || get(collaborator, "formation.id", null) === +id;

  const {
    status: statusFormation,
    error: errorFormation,
    data: formation,
  } = useQueryFormationById(full && id);

  if (errorFormation) {
    return <FormationError error={errorFormation} />;
  }

  return (
    <FormationContext.Provider
      value={{
        formation: {
          data: formation || {},
          status: statusFormation,
          error: errorFormation,
        },
      }}
    >
      {children}
    </FormationContext.Provider>
  );
}

export default (props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<Spinner />}>
      <FormationProvider {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
