import {
  useCreateDomainLink,
  useDeleteDomainLink,
  useQueryDomainLinks,
  useUpdateDomainLink,
} from "../api/domainLinks";
import ManageResource from "../components/ManageResource";
import React from "react";

function LinksAdmin() {
  return (
    <ManageResource
      resourceLabel="liens"
      createLabel="Ajouter un lien"
      createHook={useCreateDomainLink}
      queryHook={useQueryDomainLinks}
      deleteHook={useDeleteDomainLink}
      updateHook={useUpdateDomainLink}
      fieldTitle={"label"}
      imageUpload={true}
      draggable={true}
      params={
        {
            label: {
                label: "Nom",
                type: "text",
                resourceValueKey: "label",
            },
            linkUrl: {
                label: "Lien",
                type: "text",
                resourceValueKey: "linkUrl",
            },
            description: {
                label: "Description",
                type: "text",
                resourceValueKey: "description",
            },
        }
    }
    />
  );
}

export default LinksAdmin;
