import Input from "../components/Input";
import React from "react";
import { Field, useFormikContext } from "formik";
import { usePermissionsChecker } from "../contexts/permissions";

export default function PersonalCoordinate() {

  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.collaborator.edit",
      "kdix.actions.collaborator.edit.agency",
      "kdix.actions.collaborator.edit.department",
      "kdix.actions.collaborator.edit.service",
      "kdix.actions.collaborator.edit.own",
    ],
  });
const { values } = useFormikContext();

  return (
    <div>
      <Input
        type="text"
        label="E-mail personnel"
        name="privateEmail"
        readOnly={readOnly}
      />
	<div className="mb-8 flex justify-between items-center pb-6">
        <label
          htmlFor="privateEmailAlert"
          className={`block text-black text-sm font-bold `}
        >
          Recevoir les alertes sur l'email personnel
        </label>
        <Field
          id="privateEmailAlert"
          type="checkbox"
          name="privateEmailAlert"
          checked={values.privateEmailAlert}
		  readOnly={readOnly}
        />
      </div>
      <Input
        type="text"
        label="Portable personnel"
        name="privateMobileNumber"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Fixe personnel"
        name="privateTelephoneNumber"
        readOnly={readOnly}
      />
    </div>
  );
}
