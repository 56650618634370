import { get, isEmpty, map } from "lodash-es";
import {
    useDeleteMessage,
    useInfiniteQueryHomeMessages,
} from "../../api/homeMessages";

import Alert from "../../components/Alert";
import Block from "../../components/Block";
import DateFormat from "../../components/DateFormat";
import ErrorBoundary from "../../components/ErrorBoundary";
import React from "react";
import Spinner from "../../components/Spinner";
import { ReactComponent as TrashIcon } from "../../svgs/trash.svg";
import { Helmet } from "react-helmet";
import DashboardAdminForm from "../../forms/DashboardAdminForm";
import {useAgencies, usePermissionsChecker} from "../../contexts/permissions";
import { WysiwygTextEditorNoForm} from "../../components/WysiwygTextEditor";

function Message({
  message: {
    id,
    className,
    createdBy,
    updatedAt: date,
    message = "",
    agencies = [],
  },
}) {
  const [deleteMessage] = useDeleteMessage();
  const username = createdBy?.collaborator ? createdBy?.collaborator?.firstname + " " + createdBy?.collaborator?.lastname : createdBy?.username ?? "";

  const agenciesLabel = map(agencies, "label");
  return (
    <div className={`mb-5 ${className}`}>
      <div className="flex justify-between mb-2">
        <div className="font-medium">{username}</div>
        <div className="text-right">
          <DateFormat date={date} />
          {!isEmpty(agencies) && (
            <div>Site(s) concerné(s) : {agenciesLabel.join(", ")}</div>
          )}
        </div>
      </div>
      <div className="relative">
      <Block className="pr-16">
        <WysiwygTextEditorNoForm
            readOnly={true}
            value={message}
        />
      </Block>
        <div className="absolute right-0 inset-y-0 pr-2 pt-2">
          <button
            className="text-red-500 focus:outline-none focus:shadow-none"
            onClick={() => deleteMessage(id)}
          >
            <TrashIcon className="w-6 h-6 text-red-500 fill-current" />
          </button>
        </div>
      </div>
    </div>
  );
}

function getIdFromIri(iri) {
    return iri.split('/')[iri.split('/').length - 1];
}

function MessagesHistory({readOnly}) {
  const userAgencies = useAgencies();
  const agencyFilter = readOnly ? map(userAgencies, (agency) => {
      return getIdFromIri(agency);
  }) : null;

  const {
    status,
    isFetching,
    messages,
    fetchMore,
    canFetchMore,
  } = useInfiniteQueryHomeMessages({ 
    "order[createdAt]": "desc",
    itemsPerPage: 3,
    "orNullSearch_agencies": agencyFilter
  });
  return (
    <div>
      <div
        style={{
          background:
            "linear-gradient(90deg, rgba(0,0,0,0) calc(50% - 1px), rgba(0,0,0,1) calc(50%), rgba(0,0,0,0) calc(50% + 1px))",
        }}
      >
        {map(messages, (item, index) => {
          return (
            <Message
              key={item.id}
              className={index === 0 ? "bg-gray-50" : ""}
              message={item}
            />
          );
        })}
      </div>
      <div className="">
        {status === "loading" || (canFetchMore && isFetching) ? (
          <div className="mt-24 h-24 relative">
            <Spinner />
          </div>
        ) : (
          <div className="text-center">
            {canFetchMore ? (
              <button
                className="btn inline-block"
                onClick={() => {
                  fetchMore();
                }}
              >
                Voir plus de messages
              </button>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

function DashboardAdmin() {
  const readOnly = !usePermissionsChecker({
      permissions: ["kdix.actions.home_message.create"],
  });

  return (
    <div className="p-12 pb-24">
      <Helmet>
        <title>Mot du dirigeant</title>
      </Helmet>

      <div className="text-2xl font-bold pb-8">Mot du dirigeant</div>
      <div>
        {!readOnly ? (
            DashboardAdminForm(readOnly)
        ) : null}
        <div className="pb-24">
          <div className="text-xl font-bold mb-8">Historique</div>
          <ErrorBoundary
            fallback={(error) => (
              <div className="mb-48">
                <Alert
                  type="error"
                  message="Erreur lors de la récupération de l'historique"
                  details={get(error, "description")}
                />
              </div>
            )}
          >
            <React.Suspense fallback={<Spinner />}>
              <MessagesHistory readOnly={readOnly} />
            </React.Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

export default DashboardAdmin;
