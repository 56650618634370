import ApolloClient, { gql } from "apollo-boost";

import PermissionsContext from "./contexts/permissions";
import { get } from "lodash-es";
import history from "./history";
import { useContext } from "react";

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  request: (operation) => {
    const token = localStorage.getItem("token");
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : "",
      },
    });
  },
  onError: (data) => {
    if (get(data, "networkError.result.message")) {
      switch (get(data, "networkError.result.message")) {
        case "Invalid JWT Token":
          logout();
          return;
        case "Expired JWT Token":
          logout();
          return;

        default:
          break;
      }
    }
  },
});

export const logout = (onLogout = () => {}) => {
  onLogout();
  window.localStorage.setItem("token", "");
  window.localStorage.setItem("modules", "");
  window.localStorage.setItem("actions", "");
  window.localStorage.setItem("filtersCollaborator", "");
  window.localStorage.setItem("refreshToken", "");
  window.sessionStorage.clear();
  client.resetStore();
  history.push("/login");
  window.location.reload(true);
};

export const refresh = (token, refreshToken) => {
  window.localStorage.setItem("token", token);
  window.localStorage.setItem("refreshToken", refreshToken);
  client.resetStore();
  window.location.reload(true);
};

export const useLogout = () => {
  const { setToken, setActions, setModules } = useContext(PermissionsContext);

  return {
    logout: () =>
      logout(() => {
        setToken("");
        setActions("");
        setModules("");
      }),
  };
};

export const FIND_COLLABORATORS = gql`
  query FindCollaborator($lastname: String!, $after: String) {
    collaborators(lastname: $lastname, after: $after) {
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        node {
          _id
          id
          firstname
          lastname
          email
          telephoneNumber
          currentContract {
            id
            job {
              id
              label
            }
          }
          service {
            id
            code
          }
          agencies {
            edges {
              node {
                id
                label
              }
            }
          }
        }
      }
    }
  }
`;

export const JOBS = gql`
  {
    jobs {
      edges {
        node {
          id
          label
        }
      }
    }
  }
`;

export const AGENCIES = gql`
  {
    agencies {
      edges {
        node {
          id
          label
        }
      }
    }
  }
`;

export const SERVICES = gql`
  {
    services {
      edges {
        node {
          id
          code
        }
      }
    }
  }
`;

export const TYPE_CONTRACTS = gql`
  {
    typeContracts {
      edges {
        node {
          id
          label
        }
      }
    }
  }
`;

export const STATUS_CONTRACTS = gql`
  {
    statusContracts {
      edges {
        node {
          id
          label
        }
      }
    }
  }
`;

export const SHIRT_SIZES = gql`
  {
    shirtSizes {
      edges {
        node {
          id
          size
        }
      }
    }
  }
`;

export const GLOVES_SIZES = gql`
  {
    gloveSizes {
      edges {
        node {
          id
          size
        }
      }
    }
  }
`;

export const TROUSER_SIZES = gql`
  {
    trouserSizes {
      edges {
        node {
          id
          size
        }
      }
    }
  }
`;

export const SHOE_SIZES = gql`
  {
    shoeSizes {
      edges {
        node {
          id
          size
        }
      }
    }
  }
`;

export const REQUESTS = gql`
  {
    typeRequests {
      edges {
        node {
          id
          label
        }
      }
    }
  }
`;

export const FAMILY_SITUATIONS = gql`
  {
    familySituations {
      edges {
        node {
          id
          _id
          label
        }
      }
    }
  }
`;

export const DEPARTMENTS = gql`
  {
    departments {
      edges {
        node {
          id
          label
          parent {
            id
            label
          }
          children {
            edges {
              node {
                id
                label
                parent {
                  id
                  label
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default client;
