import React, { useState } from "react";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import { useUpdatePermission } from "./api/permissions";
import { ReactComponent as PenIcon } from "./svgs/pen.svg";
import { usePermissionsChecker } from "./contexts/permissions";
import { CreateOrUpdateProfilModal } from "./pages/UserAdmin";

const validation = Yup.object().shape({
  label: Yup.string().required("Requis"),
});

export default function ProfileForm({ profile, onSuccess = () => {} }) {
  const [update] = useUpdatePermission();
  const [editProfile, setEditProfile] = useState(false);
  const canEdit = usePermissionsChecker({
    permissions: ["kdix.actions.user.manage"],
  });

  if (!profile || !profile["@id"]) {
    return (
      <div className="flex">
        <div className="text-2xl font-bold">
          Profil {get(profile, "label", "")}
        </div>
      </div>
    );
  }
  return (
    <>
      <CreateOrUpdateProfilModal
        setIsOpen={setEditProfile}
        modalIsOpen={editProfile}
        isEditing={true}
        values={profile}
      />
      <Formik
        enableReinitialize
        validationSchema={validation}
        initialValues={{
          label: get(profile, "label", ""),
        }}
        onSubmit={async (values, actions) => {
          try {
            await update({
              id: profile.id,
              data: values,
            });
            actions.setSubmitting(false);
            setEditProfile(false);
            onSuccess();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {() => {
          return (
            <Form>
              <div className="flex">
                <div className="text-2xl font-bold">
                  Profil{" "}
                  {get(profile, "isAdmin", false)
                    ? "(administrateur)"
                    : "(non administrateur)"}
                </div>
                {editProfile ? (
                  <></>
                ) : canEdit ? (
                  <div>
                    <button
                      className="w-10 h-10 flex justify-center items-center focus:outline-none"
                      onClick={() => {
                        setEditProfile(true);
                      }}
                    >
                      <PenIcon className="w-4 h-4 fill-current text-orange-400" />
                    </button>
                  </div>
                ) : null}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
