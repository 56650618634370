import React, { useState } from "react";
import { flatMap, get, map, pull, size } from "lodash-es";
import { useDebounce, useMap, useToggle } from "react-use";
import Alert from "../../components/Alert";
import CheckboxGroup from "../../components/CheckboxGroup";
import ExpansionPanel from "../../components/ExpansionPanel";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import Modal from "../../components/Modal";
import { PermissionChecker } from "../../contexts/permissions";
import { ReactComponent as PlusIcon } from "../../svgs/plus.svg";
import Spinner from "../../components/Spinner";
import { useDesktop } from "../../hooks/useDesktop";
import { useQueryVehicles } from "../../api/vehicle";
import { useQueryAgencies } from "../../api/agencies";
import VehicleGeneralInfosForm from "../../forms/VehicleGeneralInfosForm";
import { useQueryTypeVehicle } from "../../api/typeVehicle";
import { getFirstnameLastname } from "../../utils/names";
import { HeaderBar } from "../../components/HeaderBar";
import InlineBLockContentSortable from "../../components/InlineBlockContentSortable";
import {CSVLink} from "react-csv";

function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}

function VehicleMobileCard({ vehicle }) {
  return (
    <ExpansionPanel
      key={vehicle.id}
      title={`${vehicle.brand} ${vehicle.model}`}
      subtitle={get(vehicle, "licencePlate")}
    >
      <VehicleGeneralInfosForm vehicle={vehicle} readOnly />
      <Link
        className="btn mt-6 w-full block text-center"
        to={`/rh/vehicules/${vehicle.id}`}
      >
        Voir la fiche
      </Link>
    </ExpansionPanel>
  );
}

function VehiclesList({ query, filters }) {
  const isDesktop = useDesktop();
  const [orderFilter, setOrderFilter] = useState({ "order[brand]": "asc" });
  const { data, fetchMore, canFetchMore } = useQueryVehicles({
    pagination: false,
    orSearch_fullname: query,
    ...filters,
    ...orderFilter,
  });

  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucun véhicule" />
      </div>
    );
  }
  const nbVehicles = data[0]?.["hydra:totalItems"];
  const vehicles = flatMap(data, (page) => page["hydra:member"]);

  const csvHeaders = [
    {label: "Site", key: "agency.label"},
    {label: "Marque", key: "brand"},
    {label: "Modèle", key: "model"},
    {label: "Conducteur", key: "conducteur"},
    {label: "Immatriculation", key: "licencePlate"},
    {label: "Type de véhicule", key: "type.label"},
    {label: "Nombre de places", key: "numberOfSeats"},
    {label: "Propriétaire", key: "owner"},
  ];

  const csvData = map(vehicles, (item) => {
    return {
      ...item,
      conducteur: `${getFirstnameLastname(item, "collaborator")} ${item.collaborator?.currentContract?.job?.label ? item.collaborator?.currentContract?.job?.label : ""}`,
    };
  });

  const gridTemplateColumn = "8% 14% 15% 15% 10% 14% 10% 14%";
  return (
    <div className="relative">
      <div className="absolute -top-16 py-4 right-0">
        <CSVLink
            filename="vehicules.csv"
            className="btn-export"
            headers={csvHeaders}
            data={csvData}
        >
          Exporter
        </CSVLink>
        {nbVehicles} Voiture{nbVehicles > 1 ? "s" : ""}
      </div>
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
            colName={"agency.label"}
            setOrderFilter={setOrderFilter}
          >
            Site
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"brand"}
            setOrderFilter={setOrderFilter}
          >
            Marque
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"model"}
            setOrderFilter={setOrderFilter}
          >
            Modèle
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"collaborator.lastname"}
            setOrderFilter={setOrderFilter}
          >
            Conducteur
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"licencePlate"}
            setOrderFilter={setOrderFilter}
          >
            Immatriculation
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"type.label"}
            setOrderFilter={setOrderFilter}
          >
            Type de véhicule
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"numberOfSeats"}
            setOrderFilter={setOrderFilter}
          >
            Nombre de places
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            colName={"owner"}
            setOrderFilter={setOrderFilter}
          >
            Propriétaire
          </InlineBLockContentSortable>
        </div>
      )}
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {isDesktop
          ? map(vehicles, (node, index) => {
              return (
                <Link
                  key={node.id}
                  className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2`}
                  to={`/rh/vehicules/${node.id}`}
                  style={{
                    gridTemplateColumns: gridTemplateColumn,
                  }}
                >
                  <InlineBLockContent className="">
                    {get(node, "agency.label")}
                  </InlineBLockContent>
                  <InlineBLockContent className="">
                    {node.brand}
                  </InlineBLockContent>
                  <InlineBLockContent className="">
                    {node.model}
                  </InlineBLockContent>
                  <InlineBLockContent className="">
                    {getFirstnameLastname(node, "collaborator")}
                    <br />
                    {node.collaborator?.currentContract?.job?.label}
                  </InlineBLockContent>
                  <InlineBLockContent className="">
                    {node.licencePlate}
                  </InlineBLockContent>
                  <InlineBLockContent className="">
                    {get(node, "type.label")}
                  </InlineBLockContent>
                  <InlineBLockContent className="">
                    {node.numberOfSeats
                      ? `${node.numberOfSeats} places`
                      : "N/R"}
                  </InlineBLockContent>
                  <InlineBLockContent className="">
                    {node.owner}
                  </InlineBLockContent>
                </Link>
              );
            })
          : map(vehicles, (node, index) => (
              <VehicleMobileCard key={node.id} vehicle={node} />
            ))}
      </InfiniteScroll>
    </div>
  );
}
function ButtonAddVehicle() {
  return (
    <PermissionChecker
      permissions={[
        "kdix.actions.vehicle.edit",
        "kdix.actions.vehicle.edit.agency",
        "kdix.actions.vehicle.edit.department",
        "kdix.actions.vehicle.edit.service",
        "kdix.actions.vehicle.edit.own",
      ]}
      notAllowed={null}
    >
      <Link
        to="/rh/creation-vehicule"
        className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none"
      >
        <PlusIcon className="w-4 h-4" />
      </Link>
    </PermissionChecker>
  );
}
function Vehicles() {
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({
    active: [1],
  });

  const { data: agencies } = useQueryAgencies();
  const { data: typesVehicle } = useQueryTypeVehicle();
  const [FiltersOpen, toggleFilters] = useToggle(false);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const filtersList = [
    {
      label: "Site",
      key: "agency",
      options: map(agencies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Type de véhicule",
      key: "type",
      options: map(typesVehicle, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Flotte de véhicules",
      key: "active",
      options: [
        {
          label: "Véhicules actifs",
          value: 1,
          id: 1,
        },
        {
          label: "Véhicules sortis",
          value: 0,
          id: 0,
        },
      ],
    },
  ];

  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );

  return (
    <div>
      <Helmet>
        <title>Véhicules</title>
      </Helmet>
      <HeaderBar
        title={
          <>
            Flotte des véhicules
          </>
        }
        buttons={<ButtonAddVehicle />}
        smaller={true} sticky={true}
      >
        <input
          type="text"
          name="query"
          value={query}
          className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
          placeholder="Rechercher un véhicule"
          onChange={(e) => setQuery(e.target.value)}
        />
      </HeaderBar>

      <div>
        <div className="px-8 mt-8 mb-4">
          <button
            className="flex items-center"
            onClick={() => toggleFilters(true)}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>
          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            isOpen={FiltersOpen}
            onRequestClose={() => toggleFilters(false)}
          >
            <div className="-mx-4 px-5">
              {map(filtersList, ({ key, label, options }) => {
                return (
                  <CheckboxGroup
                    key={key}
                    label={label}
                    options={options}
                    className="py-4 odd:bg-purple-50 -mx-4 px-5"
                    selected={get(filters, key, [])}
                    onAdd={(option) => {
                      setFilter(key, [...get(filters, key, []), option]);
                    }}
                    onRemove={(option) => {
                      const newFilters = pull(get(filters, key, []), option);
                      if (size(newFilters) === 0) {
                        removeFilter(key);
                        return;
                      }
                      setFilter(key, newFilters);
                    }}
                  />
                );
              })}
            </div>

            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  resetFilters();
                }}
              >
                Supprimer les filtres
              </div>
            </div>

            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <VehiclesList query={debouncedQuery} filters={filters} />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default Vehicles;
