import * as Yup from "yup";

import { Form, Formik } from "formik";
import { get, map } from "lodash-es";

import Alert from "../components/Alert";
import Input, { DateTimePickerInput } from "../components/Input";
import React from "react";
import { usePermissionsChecker } from "../contexts/permissions";
import { useUpdateCollaborator } from "../api/collaborator";
import { toast } from "react-toastify";
import Button from "../components/Button";
import { civilities } from "./formsUtils/civilities";
import RadioGroup from "../components/RadioGroup";
import { dateCompare } from "../regex/date";
import { useDesktop } from "../hooks/useDesktop";

const validation = Yup.object().shape({
  lastname: Yup.string().required("Requis"),
  firstname: Yup.string().required("Requis"),
  birthdate: Yup.date().required("Requis")
                      .test(
                                  "date-match",
                                  "Doit être antérieure à la date du jour -10 ans",
                                  function (value) {
                                      var now10 = new Date();
                                      now10.setDate(now10.getDate()-3650);
                                    return dateCompare(value, now10);
                                  }
                                ),
  telephoneNumber: Yup.string().max(30, "Le téléphone doit faire moins de 30 caractères"),
});

function CollaboratorGeneralInfosForm({
  collaborator,
  readOnly: forceReadOnly,
}) {
  const readOnly =
    !usePermissionsChecker({
      permissions: [
        "kdix.actions.collaborator.edit",
        "kdix.actions.collaborator.edit.agency",
        "kdix.actions.collaborator.edit.department",
        "kdix.actions.collaborator.edit.service",
        "kdix.actions.collaborator.edit.own",
      ],
      userId: collaborator.linkedUser?.id,
      agencies: collaborator.agencies
        ? map(collaborator.agencies, "@id")
        : null,
    }) ||
    forceReadOnly;

  const [updateCollaborator, { error }] = useUpdateCollaborator();
  const collaboratorPhone = get(collaborator, "telephoneNumber", null);
  const collaboratorPhoneLink = 'tel:' + collaboratorPhone;
  const isDesktop = useDesktop();

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={validation}
        initialValues={{
          lastname: get(collaborator, "lastname", ""),
          civility: get(collaborator, "civility", civilities.man),
          firstname: get(collaborator, "firstname", ""),
          birthdate: get(collaborator, "birthdate")
            ? new Date(get(collaborator, "birthdate"))
            : "",
          email: get(collaborator, "email", ""),
          telephoneNumber: get(collaborator, "telephoneNumber", ""),
          registrationNumber: get(collaborator, "registrationNumber", ""),
        }}
        onSubmit={async (values, actions) => {
          try {
            await updateCollaborator({
              id: collaborator.id,
              data: {
                ...values,
                birthdate: values.birthdate,
              },
            });
            toast.success("Mise à jour effectuée avec succès");
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <RadioGroup
                name="civility"
                label="Civilité"
                readOnly={readOnly}
                options={[
                  { label: "Monsieur", value: civilities.man },
                  { label: "Madame", value: civilities.woman },
                ]}
              />
              <Input
                type="text"
                name="lastname"
                label="Nom"
                readOnly={readOnly}
              />
              <Input
                type="text"
                name="firstname"
                label="Prénom"
                readOnly={readOnly}
              />
              <DateTimePickerInput
                name="birthdate"
                label="Date de naissance"
                disabled={readOnly}
              />
              <Input
                type="email"
                name="email"
                label="E-mail Professionnel"
                readOnly={readOnly}
              />
              <Input
                type="text"
                name="telephoneNumber"
                label="Portable Professionnel"
                readOnly={readOnly}
                className={collaboratorPhone ? 'mb-2' : null}
              />
              {collaboratorPhone && !isDesktop ? (
                  <a href={collaboratorPhoneLink} className={`btn btn--sm mb-4 ml-auto block w-fit`}>
                      CLIQUEZ pour appeler
                  </a>
              ) : null}
              <Input
                type="text"
                name="registrationNumber"
                label="Matricule"
                className="mb-0"
                readOnly={readOnly}
              />
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 ml-auto block`}
                readOnly={readOnly}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CollaboratorGeneralInfosForm;
