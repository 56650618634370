import React from "react";
import { useField } from "formik";

export default function Textarea({
  label,
  textareaClassName = "",
  divClassName = "",
  forceError = false,
  ...props
}) {
  const [field, meta] = useField(props);
  return (
    <div className={`${divClassName}`}>
      {label ? (
        <label
          className={`block ${
            (meta.touched && meta.error) || forceError
              ? "text-red-500"
              : `text-black`
          } text-sm font-bold`}
        >
          {label}
        </label>
      ) : null}
      <textarea
        {...field}
        {...props}
        className={` ${
          textareaClassName ? textareaClassName : "block shadow"
        } ${
          (meta.touched && meta.error) || forceError
            ? "border-red-500"
            : `border-transparent`
        }  focus:border-red w-full p-3 appearance-none focus:outline-none`}
      ></textarea>

      {(meta.touched && meta.error) || forceError ? (
        <p className="text-red-500 text-xs italic mt-2">
          {forceError ? forceError : meta.error}
        </p>
      ) : null}
    </div>
  );
}
