import { filter, map, noop } from "lodash-es";
import { ReactComponent as CrossIcon } from "../svgs/cross.svg";
import React from "react";

function CheckboxGroup({
  label,
  options = [],
  selected = [],
  onRemove = noop,
  onAdd = noop,
  className,
}) {
  return (
    <div className={`py-4 ${className}`}>
      <div className="font-black mb-3">{label}</div>
      <div className="-mx-2 flex flex-wrap">
        {map(
          filter(options, (opt) => selected.includes(opt.id)),
          (option) => (
            <div
              key={option.id}
              className="flex items-center bg-purple-100 m-2"
            >
              <div
                onClick={() => {
                  onRemove(option.id);
                }}
                className="px-3 self-stretch flex items-center justify-center  hover:bg-purple-300 text-black cursor-pointer"
              >
                <CrossIcon className="w-4 h-4" />
              </div>
              <span className="py-2 pr-3 pl-1 font-light text-sm">
                {option.label}
              </span>
            </div>
          )
        )}
      </div>
      <hr className="my-2" />
      <div className="-mx-2">
        {map(
          filter(options, (opt) => !selected.includes(opt.id)),
          (option) => (
            <div
              key={option.id}
              onClick={() => {
                onAdd(option.id);
              }}
              className="inline-block bg-purple-100 py-2 px-3 m-2 font-light text-sm hover:bg-purple-300 cursor-pointer"
            >
              {option.label}
            </div>
          )
        )}
      </div>
    </div>
  );
}

export default CheckboxGroup;
