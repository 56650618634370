import { Form, Formik } from "formik";
import { get } from "lodash-es";
import Alert from "../components/Alert";
import Button from "../components/Button";
import React from "react";
import { formErrorHandler } from "../utils/formErrorHandler";
import { useDeleteDomain } from "../api/domain";

function DeleteDomain({ id, onSuccess = () => {} }) {
  const [deleteDomain, { error }] = useDeleteDomain();

  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values, actions) => {
        try {
          const response = await deleteDomain(id);
          actions.setSubmitting(false);
          onSuccess(response);
        } catch (error) {
          formErrorHandler(error, actions);
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <div>Êtes-vous sûr de vouloir supprimer le domaine ?</div>
            <div className="font-bold mt-2">
              Attention cette suppression est définitive.
            </div>
            {error ? (
              <div className="my-2">
                <Alert
                  type="error"
                  message={get(error, "title")}
                  details={get(error, "description")}
                />
              </div>
            ) : null}
            <div className="flex justify-center">
              <Button
                className={`btn mt-6 block`}
                readOnly={false}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Supprimer le domaine"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DeleteDomain;
