import React from "react";
import { Helmet } from "react-helmet";

import Block from "../../components/Block";
import GoBackBtn from "../../components/GoBackBtn";
import CreateWorkAccidentForm from "../../forms/CreateWorkAccidentForm";
import { useDesktop } from "../../hooks/useDesktop";
import { Redirect, useParams } from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundary";
import WorkAccidentProvider, {
  WorkAccidentContext,
} from "../../contexts/workAccident";
import Alert from "../../components/Alert";
import { get } from "lodash-es";
import { usePermissionsChecker } from "../../contexts/permissions";

function WorkAccidentError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération de l'accident"
        details={get(error, "description")}
      />
    </div>
  );
}

function EditWorkAccident() {
  const isDesktop = useDesktop();
  const { workAccident, error } = React.useContext(WorkAccidentContext);
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.work_accident.edit",
      "kdix.actions.work_accident.edit.agency",
      "kdix.actions.work_accident.edit.department",
      "kdix.actions.work_accident.edit.service",
      "kdix.actions.work_accident.edit.own",
    ],
  });
  if (error) {
    return <WorkAccidentError error={error} />;
  }

  return (
    <div className="p-8 pb-0">
      <Helmet>
        <title>Modifier un accident</title>
      </Helmet>
      <Block>
        <GoBackBtn
          to="/prevention/accidents-travail"
          className="flex items-center"
        >
          <div className="font-bold">Retour</div>
        </GoBackBtn>
        <div className={`max-w-lg mx-auto`}>
          <div className="text-2xl font-bold mb-8 mt-8">
            {canEdit ? "Enregistrer" : "Visualisation de"} l'accident
          </div>
        </div>
        <div className={`${isDesktop ? "max-w-5xl" : "max-w-lg"}  mx-auto`}>
          <CreateWorkAccidentForm
            workAccident={workAccident.data}
            readOnly={!canEdit}
          />
        </div>
      </Block>
    </div>
  );
}

function EditWorkAccidentPage() {
  let { id } = useParams();
  id = parseInt(id);
  if (isNaN(id)) {
    return <Redirect to="/prevention/accidents-travail" />;
  }
  return (
    <ErrorBoundary fallback="Récupération de l'accident du travail">
      <WorkAccidentProvider id={id}>
        <EditWorkAccident />
      </WorkAccidentProvider>
    </ErrorBoundary>
  );
}

export default EditWorkAccidentPage;
