import React, { createContext, useContext, useEffect } from "react";
import { get, intersection, map, size, parseInt } from "lodash-es";
import { useLocalStorage, useSetState } from "react-use";
import NotAllowed from "../components/NotAllowed";
import jwtDecode from "jwt-decode";

import type { ReactNode } from "react";
import type {
  CheckPermissionType,
  usePermissionCheckerType,
  PermissionsContextType,
  PermissionCheckerType,
} from "../types/permissionContext";
import type { decodedToken } from "../types/jwt";
import { CollaboratorContext } from "./collaborator";
import {
  ModuleCheckerType,
  useMaxPermissionCheckerType,
} from "../types/permissionContext";
import { WorkAccidentContext } from "./workAccident";
import { Scopes } from "../types/permissionAction";
import { scopesPriority } from "../utils/scopes";

const emptyToken = {
  actions: {},
  modules: {},
  domainId: null,
  domainName: null,
  userId: null,
  userIri: null,
  collaboratorIri: null,
  collaboratorId: null,
  departments: [],
  serviceId: null,
  agencies: [],
  name: "",
  username: "",
  impersonate: false,
  useIsSilae: false,
  useExternalDocumentManagement: false,
  admin: false,
  myLinksUrl: null,
  lokoaUrl: null,
  insuranceTel: null,
  insuranceContract: null,
  gedlink: null,
  companies: [],
  refreshToken: null,
  newDocumentsNumber: null,
  messagesNotRead: null,
} as decodedToken;

const emptyContext = {
  token: window.localStorage.getItem("token"),
  ...emptyToken,
  setToken: () => {},
  setActions: () => {},
  setModules: () => {},
  setRefreshToken: () => {},
};

const splitPermission = (rawPermission: string): [string, string] => {
  if (rawPermission.match(/\.(?:agency|department|service|own)$/) !== null) {
    const permissionArr = rawPermission.split(".");
    const scope = permissionArr.pop() as string; //On sait que ce n'est pas undefined grâce à la regex
    return [permissionArr.join("."), scope];
  }
  return [rawPermission, "all"];
};

export const testScopeSuperior = (scope1: Scopes, scope2: Scopes): boolean => {
  return scopesPriority.indexOf(scope1) < scopesPriority.indexOf(scope2);
};

/**
 * Vérifie que parmi les permissions demandés, il y a au moins une action qui donne accès à la ressource
 * On va vérifier pour chaque permission si on a dans les actions quelque chose de compatible
 * Puis si c'est le cas si les contraintes associés au scope sont ok
 * @param actions
 * @param permissions
 * @param agencies
 * @param currentAgencies
 * @param userId
 * @param currentUserId
 * @param departments
 * @param currentDepartments
 * @param service
 * @param currentService
 */
const checkPermission = ({
  actions,
  permissions,
  agencies,
  currentAgencies,
  userId,
  currentUserId,
  departments,
  currentDepartments,
  service,
  currentService,
}: CheckPermissionType): boolean => {
  for (const rawPermission of permissions) {
    //Récupération de la permission et de son scope
    const [permission, scope] = splitPermission(rawPermission);
    //Récupération du scope disponible parmi les droits de l'utilisateur,
    // si le droit n'est pas du tout présent on aura undefined
    const currentScope = actions[permission];

    if (currentScope !== undefined && currentScope === scope) {
      //On a le droit et le scope est compatible, on teste les contraintes lié au scope
      if (scope === "all") {
        return true;
      } else if (scope === "agency") {
        if(size(currentAgencies) === 1)
        {
          if( (size(agencies) === 1 && size(intersection(agencies, currentAgencies))) || agencies === undefined)
          {
            return true;
          }
        }
        else if (
          size(intersection(agencies, currentAgencies)) ||
          agencies === undefined
        ) {
          return true;
        }
      } else if (scope === "department") {
        if(size(currentDepartments) === 1)
        {
          if( (size(departments) === 1 && size(intersection(departments, currentDepartments))) || departments === undefined)
          {
            return true;
          }
        }
        else if (
            size(intersection(departments, currentDepartments)) ||
            departments === undefined
        ) {
          return true;
        }
      } else if (scope === "service") {
        if (
          (service === currentService && service !== null) ||
          service === undefined
        ) {
          return true;
        }
      } else if (scope === "own") {
        if (
          (userId === currentUserId && userId !== null) ||
          userId === undefined
        ) {
          return true;
        }
      }
    }
  }
  return false;
};

const PermissionsContext = createContext<PermissionsContextType>({
  ...emptyContext,
});

export const PermissionsConsumer = PermissionsContext.Consumer;

export function PermissionsrProvider({ children }: { children: ReactNode }) {
  const [state, setState] = useSetState<PermissionsContextType>({
    ...emptyContext,
  });
  const [token, setToken] = useLocalStorage(
    "token",
    window.localStorage.getItem("token") || "",
    {
      raw: true,
    }
  );
  const [modules, setModules] = useLocalStorage<string>(
    "modules",
    JSON.parse(window.localStorage.getItem("modules") || "[]"),
    {
      raw: true,
    }
  );
  const [actions, setActions] = useLocalStorage<string>(
    "actions",
    JSON.parse(window.localStorage.getItem("actions") || "[]"),
    {
      raw: true,
    }
  );

  const [refreshToken, setRefreshToken] = useLocalStorage<string>(
    "refreshToken",
    window.localStorage.getItem("refreshToken") || "",
    {
      raw: true,
    }
  );

  useEffect(() => {
    if (token) {
      const {
        domainId,
        domainName,
        userId,
        userIri,
        collaboratorIri,
        collaboratorId,
        agencies,
        departments,
        serviceId,
        name,
        username,
        roles,
        useExternalDocumentManagement,
        useIsSilae,
        myLinksUrl,
        lokoaUrl,
        insuranceTel,
        insuranceContract,
        gedlink,
        companies,
        newDocumentsNumber,
        messagesNotRead,
        impersonate = false,
      } = jwtDecode(token);

      setState({
        actions: JSON.parse(actions),
        domainId,
        domainName,
        userId,
        userIri,
        collaboratorIri,
        collaboratorId,
        agencies,
        departments,
        serviceId,
        name,
        username,
        useExternalDocumentManagement,
        useIsSilae,
        impersonate,
        myLinksUrl,
        lokoaUrl,
        insuranceTel,
        insuranceContract,
        gedlink,
        newDocumentsNumber,
        messagesNotRead,
        companies,
        modules: JSON.parse(modules),
        admin: roles && roles.includes("ROLE_SUPER_ADMIN"),
        refreshToken: refreshToken,
      });
    } else {
      setState(emptyToken);
    }
  }, [token, setState, actions, modules, refreshToken]);

  return (
    <PermissionsContext.Provider
      value={{
        token,
        ...state,
        setToken,
        setModules,
        setActions,
        setRefreshToken,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
}

/**
 * Vérifie qu'une des permissions de la liste est disponible pour cet utilisateur
 * @return ReactNode retourne le bloc enfant si le droit est ok, sinon ne renvoie rien ou un bloc d'information
 * @param children
 * @param permissions
 * @param userId
 * @param notAllowed
 * @param agencies
 * @param departments
 * @param service
 */
export function PermissionChecker({
  children = null,
  permissions,
  userId,
  notAllowed = false,
  agencies,
  departments,
  service,
}: PermissionCheckerType): ReactNode {
  const isPermissionOk = usePermissionsChecker({
    permissions,
    userId,
    agencies,
    departments,
    service,
  });
  if (isPermissionOk) {
    return children;
  }
  return notAllowed ? <NotAllowed /> : null;
}

export function ModuleChecker({ module, children }: ModuleCheckerType) {
  const isModuleActive = useIsModuleActive(module);
  if (isModuleActive) {
    return children;
  }
  return null;
}

export function useMaxPermissionChecker({
  permission,
}: useMaxPermissionCheckerType) {
  const { actions } = useContext(PermissionsContext);
  return actions[permission];
}

/**
 * Vérifie qu'une des permissions de la liste est disponible pour cet utilisateur
 * @return boolean true si l'utilisateur a accès, false sinon
 * @param permissions
 * @param userId
 * @param agencies
 * @param departments
 * @param service
 */
export function usePermissionsChecker({
  permissions,
  userId,
  agencies,
  departments,
  service,
}: usePermissionCheckerType): boolean {
  const {
    actions,
    userId: currentUserId,
    admin,
    agencies: currentAgencies,
    departments: currentDepartments,
    serviceId: currentService,
  } = useContext(PermissionsContext);
  const { collaborator } = React.useContext(CollaboratorContext);
  const { collaborator: collaboratorWA } =
    React.useContext(WorkAccidentContext);

  if (admin) return true;

  if (!actions || !currentUserId) return false;

  const collaboratorTest = collaborator || collaboratorWA || null;
  //Si on est dans le contexte d'un collaborateur et que les valeurs ne sont pas définis on les récupère du contexte
  const targetUserId =
    userId === undefined && collaboratorTest !== null
      ? get(collaboratorTest, "data.linkedUser.id", null)
      : userId;
  const targetAgencies =
    agencies === undefined && collaboratorTest !== null
      ? map(get(collaboratorTest, "data.agencies", []), "@id")
      : agencies;
  const targetDepartments =
    departments === undefined && collaboratorTest !== null
      ? map(get(collaboratorTest, "data.departments", []), "@id")
      : departments;
  const targetService =
    !service && collaboratorTest !== null
      ? get(collaboratorTest, "data.service.id", null)
      : service;

  return checkPermission({
    actions,
    permissions,
    agencies: targetAgencies,
    currentAgencies,
    userId: targetUserId,
    currentUserId,
    departments: targetDepartments,
    currentDepartments,
    service: targetService,
    currentService,
  });
}

export function ModeChecker({ children = null, validMode = "" }) {
  const mode = process.env.REACT_APP_MODE;
  return mode === validMode ? children : null;
}

export function useActions() {
  const { actions = [] } = useContext(PermissionsContext);
  return actions;
}
export function useIsAdmin() {
  const { admin } = useContext(PermissionsContext);
  return admin;
}

export function useDomain() {
  const { domainId } = useContext(PermissionsContext);

  return domainId;
}

export function useMyLinkUrl() {
  const { myLinksUrl } = useContext(PermissionsContext);

  return myLinksUrl;
}

export function useLokoaUrl() {
  const { lokoaUrl } = useContext(PermissionsContext);

  return lokoaUrl;
}

export function useInsuranceTel() {
  const { insuranceTel } = useContext(PermissionsContext);

  return insuranceTel;
}

export function useInsuranceContract() {
  const { insuranceContract } = useContext(PermissionsContext);

  return insuranceContract;
}

export function useGedlink() {
	const { gedlink } = useContext(PermissionsContext);
	
	return gedlink;
}

export function useCompanies() {
  const { companies } = useContext(PermissionsContext);

  return companies;
}

export function useAgencies() {
  const { agencies } = useContext(PermissionsContext);

  return agencies;
}

export function useDepartments() {
  const { departments } = useContext(PermissionsContext);

  return departments;
}

export function useServiceId() {
  const { serviceId } = useContext(PermissionsContext);

  return serviceId;
}

export function useDomainName() {
  const { domainName } = useContext(PermissionsContext);

  return domainName;
}

export function useToken() {
  const { token } = useContext(PermissionsContext);

  return token;
}
export function useUserId() {
  const { userId } = useContext(PermissionsContext);

  return userId;
}

export function useUserIri() {
  const { userIri } = useContext(PermissionsContext);

  return userIri;
}
export function useCollaboratorId() {
  const { collaboratorId } = useContext(PermissionsContext);

  return collaboratorId;
}

export function useCollaboratorIri() {
  const { collaboratorIri } = useContext(PermissionsContext);

  return collaboratorIri;
}

export function useUseExternalDocumentManagement() {
  const { useExternalDocumentManagement } = useContext(PermissionsContext);

  return useExternalDocumentManagement;
}

export function useDisplayName() {
  const { name, username } = useContext(PermissionsContext);
  return name || username;
}

export function useIsImpersonate() {
  const { impersonate } = useContext(PermissionsContext);
  return impersonate;
}

export function useIsSilae() {
  const { useIsSilae } = useContext(PermissionsContext);
  return useIsSilae;
}

export function useNewDocumentsNumber() {
  const { newDocumentsNumber } = useContext(PermissionsContext);
  return newDocumentsNumber;
}

export function useMessagesNotRead() {
  const { messagesNotRead } = useContext(PermissionsContext);
  return messagesNotRead;
}

export function useIsModuleActive(moduleName: string) {
  const { modules } = useContext(PermissionsContext);
  return !!modules[moduleName];
}

export function isValidatorOfCollaborator(
  validatorList: Array<any>,
  collaboratorId: number
) {
  for (const validator of validatorList) {
    if (typeof validator === "string") {
      if (parseInt(validator.split("/")[3]) === collaboratorId) {
        return true;
      }
    } else {
      if (validator.id === collaboratorId) {
        return true;
      }
    }
  }

  return false;
}

export default PermissionsContext;
