import queryWithToken, {
  create,
  deleteById,
  updateById,
  updateOrCreate,
} from "./index";
import { queryCache, useMutation, useQuery } from "react-query";
import { get, reduce } from "lodash-es";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "domains";
//

export function useQueryDomain(id) {
  return useQuery(
    id && [ressourceKey, id],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/domains/${id}`, {
        method: "GET",
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useQueryDomains(filters = {}) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}
export function useCreateDomain() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useCreateOrUpdateDomain() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useUpdateDomain() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useDeleteDomain() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useQueryDomainApiSilae(id) {
  return useQuery(
    id && [ressourceKey, id],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/domains/${id}/api_silae`,
        {
          method: "GET",
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export function useSynchronizeCollaborators() {
  return useMutation(
    ({ domainId }) => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${domainId}/api_silae/synchronize`,
        {
          method: "GET",
        }
      );
    },
    {
      throwOnError: false,
      onError: (error) => {
        toast.error(
          "Erreur lors de la synchronisation des collaborateurs : " +
            error.description
        );
      },
      onSuccess: (message) => {
        if (isJsonString(message)) {
          let jsonMessage = JSON.parse(message);
          if (jsonMessage.type === "error") {
            toast.error(jsonMessage.error);
          }
        } else {
          toast.success(`Synchronisation terminée : ${message}"`);
        }
      },
    }
  );
}

export function useSynchronizeCollaboratorsRest() {
  return useMutation(
      ({ domainId }) => {
        return queryWithToken(
            `${process.env.REACT_APP_API_URL}/${ressourceKey}/${domainId}/api_silae_rest/synchronize`,
            {
              method: "GET",
            }
        );
      },
      {
        throwOnError: false,
        onError: (error) => {
          toast.error(
              "Erreur lors de la synchronisation des collaborateurs : " +
              error.description
          );
        },
        onSuccess: (message) => {
          if (isJsonString(message)) {
            let jsonMessage = JSON.parse(message);
            if (jsonMessage.type === "error") {
              toast.error(jsonMessage.error);
            }
          } else {
            toast.success(`Synchronisation terminée : ${message}"`);
          }
        },
      }
  );
}

export function useGetOrganizationFolders(id, companies) {
  const { data, isFetching } = useQuery(
    id && ["folders", id],
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/organization_documents`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            companies: companies,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: reduce(
      get(data, "hydra:member", []),
      (obj, next) => {
        return {
          ...obj,
          [next["@id"]]: next,
        };
      },
      {}
    ),
    isFetching,
  };
}

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
