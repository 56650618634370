import * as Yup from "yup";

import { Form, Formik } from "formik";
import { dateCompare } from "../regex/date";
import { get, map, size } from "lodash-es";

import { ReactComponent as BadgeIcon } from "../svgs/badge.svg";
import Button from "../components/Button";
import DateFormat from "../components/DateFormat";
import { DateTimePickerInput } from "../components/Input";
import React, { useState } from "react";
import Select from "../components/Select";
import { toast } from "react-toastify";
import { usePermissionsChecker } from "../contexts/permissions";
import { useQueryTypeBadges } from "../api/typeBadges";
import { useUpdateCollaborator } from "../api/collaborator";
import { ReactComponent as TrashIcon } from "../svgs/trash.svg";
import { useDeleteBadge } from "../api/badges";
import Modal from "../components/Modal";
import { getCurrentsBadges } from "../utils/badges";
import { ReactComponent as PenIcon } from "../svgs/pen.svg";

const validation = Yup.object().shape({
  startDate: Yup.date().nullable(),
  endDate: Yup.date()
    .nullable()
    .test("date-match", "Doit être postérieure à la date de début", function (
      value
    ) {
      return dateCompare(this.parent.startDate, value);
    }),
  type: Yup.object().test({
    test: ({ value }) => value,
    message: "Requis",
  }),
});
function DeleteButton({ badgeId }) {
  const [deleteBadge] = useDeleteBadge();
  const [isDeleting, setIsDeleting] = React.useState(false);
  return (
    <Button
      type="button"
      superClassName={`w-8 h-8 bg-red-600 rounded-full flex justify-center items-center focus:outline-none`}
      isSubmitting={isDeleting}
      loaderClassName={`loader h-6 w-6 mx-auto`}
      onClick={async () => {
        const res = window.confirm("La suppression sera définitive");
        if (res) {
          try {
            await deleteBadge(badgeId);
          } catch (e) {}
          setIsDeleting(true);
        }
      }}
    >
      <TrashIcon className="w-4 h-4 fill-current text-white" />
    </Button>
  );
}

function BadgesForm({ collaborator }) {
  //TODO, voir pour passer l'ajout d'un badge sur les routes dédiées plutôt que la maj du collab ?
  const { data: typeBadges } = useQueryTypeBadges();
  const [update] = useUpdateCollaborator();

  const [modalBadgeOpen, setModalBadgeOpen] = useState(false);
  const [editId, setEditId] = useState(null);

  const canAdd = usePermissionsChecker({
    permissions: ["kdix.actions.alert_badge.edit"],
  });
  const canDelete = usePermissionsChecker({
    permissions: ["kdix.actions.alert_badge.edit"],
  });
  const badges = getCurrentsBadges(collaborator.badges);
  return (
    <div>
      {canAdd && size(typeBadges) > 0 ? (
        <>
          <div className="flex items-end">
            <div
              className="btn flex items-center justify-center leading-none focus:outline-none focus:shadow-none ml-auto"
              onClick={() => setModalBadgeOpen(true)}
            >
              Ajouter un badge
            </div>
          </div>
          <hr className={"my-8"} />
        </>
      ) : null}
      {size(collaborator?.badges) > 0 ? (
        <div className="mb-8">
          <div className="border-b border-gray-200">&nbsp;</div>
          {map([...badges.currents, badges.lastHistory], (badge) =>
            badge === null ? null : editId !== badge.id ? (
              <div
                key={badge.id}
                className="flex items-center p-2 border-b border-gray-200 justify-between"
              >
                <div
                  style={{ backgroundColor: badge?.type?.color }}
                  className="h-12 w-12 rounded-full inline-flex items-center justify-center"
                >
                  <BadgeIcon className="fill-current text-white w-6 h-6" />
                </div>
                <div className="px-4 font-bold">{badge?.type?.title}</div>
                <div className="text-sm">
                  {get(badge, "startDate") ? (
                    <>
                      <span className="inline-block px-2">à partir du :</span>
                      <DateFormat date={get(badge, "startDate")} />
                    </>
                  ) : null}
                  {get(badge, "startDate") && get(badge, "endDate") ? (
                    <br />
                  ) : null}
                  {get(badge, "endDate") ? (
                    <>
                      <span className="inline-block px-2">jusqu'au :</span>
                      <DateFormat date={get(badge, "endDate")} />
                    </>
                  ) : null}
                </div>
                <div className="flex gap-2 items-center">
                  {canAdd ? (
                    <button
                      type="button"
                      onClick={() => setEditId(badge.id)}
                      className={`w-8 h-8 bg-orange-250 rounded-full flex justify-center items-center focus:outline-none`}
                    >
                      <PenIcon className="w-4 h-4 fill-current text-white" />
                    </button>
                  ) : null}
                  {canDelete ? <DeleteButton badgeId={badge.id} /> : null}
                </div>
              </div>
            ) : (
              <Formik
                key={badge.id}
                validationSchema={validation}
                initialValues={{
                  startDate: badge.startDate ? new Date(badge.startDate) : null,
                  endDate: badge.endDate ? new Date(badge.endDate) : null,
                  type: {
                    label: badge.type.title,
                    value: badge.type["@id"],
                  },
                }}
                onSubmit={async (values, actions) => {
                  try {
                    await update({
                      id: get(collaborator, "id"),
                      data: {
                        badges: [
                          ...map(collaborator.badges, (b) => {
                            if (b.id === badge.id) {
                              return {
                                startDate: values.startDate
                                  ? values.startDate
                                  : null,
                                endDate: values.endDate ? values.endDate : null,
                                type: values.type.value || null,
                              };
                            } else {
                              return {
                                startDate: b.startDate ? b.startDate : null,
                                endDate: b.endDate ? b.endDate : null,
                                type: b.type,
                              };
                            }
                          }),
                        ],
                      },
                    });
                    toast.success("Mise à jour effectuée avec succès");
                  } catch (error) {
                    map(get(error, "violations"), (e) => {
                      actions.setFieldError(e.propertyPath, e.message);
                    });
                    actions.setSubmitting(false);
                  }
                }}
              >
                {({ values, isSubmitting }) => {
                  return (
                    <Form>
                      <div className="text-md font-bold my-6">
                        Modifier un badge
                      </div>
                      <DateTimePickerInput
                        name="startDate"
                        label="Date de début"
                      />
                      <DateTimePickerInput name="endDate" label="Date de fin" />
                      <Select
                        label="Type de badge"
                        name="type"
                        options={map(typeBadges, (node) => ({
                          label: node.title,
                          value: node["@id"],
                        }))}
                        value={values.typeRequest}
                        isLoading={!typeBadges}
                      />
                      <div className={"flex ml-auto"}>
                        <Button
                          className={`btn btn--sm ml-auto`}
                          isSubmitting={isSubmitting}
                          isForm={true}
                          loaderClassName={`loader h-6 w-6 mx-auto`}
                          type="submit"
                          textLabel="Enregistrer"
                        />

                        <Button
                          className={`btn btn--sm bg-orange-500 hover:bg-orange-700 ml-4 align-top`}
                          type="button"
                          textLabel="Annuler"
                          onClick={() => setEditId(null)}
                        >
                          Annuler
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )
          )}
        </div>
      ) : (
        "Aucun badge"
      )}

      <Modal
        title="Ajouter un badge"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={modalBadgeOpen}
        onRequestClose={() => setModalBadgeOpen(false)}
      >
        <div className="bg-white">
          <Formik
            validationSchema={validation}
            initialValues={{
              startDate: "",
              endDate: "",
              type: {
                label: "",
                value: "",
              },
            }}
            onSubmit={async (values, actions) => {
              try {
                await update({
                  id: get(collaborator, "id"),
                  data: {
                    badges: [
                      ...map(collaborator.badges, (b) => ({
                        startDate: b.startDate ? b.startDate : null,
                        endDate: b.endDate ? b.endDate : null,
                        type: b.type,
                      })),
                      {
                        startDate: values.startDate ? values.startDate : null,
                        endDate: values.endDate ? values.endDate : null,
                        type: values.type.value || null,
                      },
                    ],
                  },
                });
                toast.success("Mise à jour effectuée avec succès");
                setModalBadgeOpen(false);
              } catch (error) {
                map(get(error, "violations"), (e) => {
                  actions.setFieldError(e.propertyPath, e.message);
                });
                actions.setSubmitting(false);
              }
            }}
          >
            {({ values, isSubmitting }) => {
              return (
                <Form>
                  <DateTimePickerInput name="startDate" label="Date de début" />
                  <DateTimePickerInput name="endDate" label="Date de fin" />
                  <Select
                    label="Type de badge"
                    name="type"
                    options={map(typeBadges, (node) => ({
                      label: node.title,
                      value: node["@id"],
                    }))}
                    value={values.typeRequest}
                    isLoading={!typeBadges}
                  />
                  <Button
                    className={`btn my-6 w-full block`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="submit"
                    textLabel="Enregistrer"
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
}

export default BadgesForm;
