import {
  useCreateService,
  useDeleteService,
  useQueryServices,
  useUpdateService,
} from "../api/services";

import ManageResource from "../components/ManageResource";
import React from "react";

function ServicesAdmin() {
  return (
    <ManageResource
      resourceLabel="Service"
      createLabel="Ajouter un service"
      createHook={useCreateService}
      queryHook={useQueryServices}
      deleteHook={useDeleteService}
      updateHook={useUpdateService}
      fieldTitle="code"
      params={{
        code: {
          label: "Service",
          type: "text",
          resourceValueKey: "code",
        },
      }}
    />
  );
}

export default ServicesAdmin;
