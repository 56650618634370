import React from "react";
import { Helmet } from "react-helmet";

import Block from "../../components/Block";
import GoBackBtn from "../../components/GoBackBtn";
import { CreateAccreditationCollaboratorForm } from "../../forms/AccreditationCollaboratorForm";
import AffectedAccreditationProvider, {
  AffectedAccreditationContext,
} from "../../contexts/affectedAccreditation";
import Alert from "../../components/Alert";
import { get } from "lodash-es";
import { Redirect, useParams } from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundary";

function AffectedAccreditationError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération de l'habilité"
        details={get(error, "description")}
      />
    </div>
  );
}

function EditAffectedAccreditation() {
  const {
    affectedAccreditation: { data: affectedAccreditation },
    error,
  } = React.useContext(AffectedAccreditationContext);

  if (error) {
    return <AffectedAccreditationError error={error} />;
  }
  return (
    <div className="p-8 pb-0">
      <Helmet>
        <title>Modifier l'habilité</title>
      </Helmet>
      <Block>
        <GoBackBtn
          to="/rh/habilitations/habilites"
          className="flex items-center"
        >
          <div className="font-bold">Retour</div>
        </GoBackBtn>

        <div className="max-w-lg mx-auto">
          <div className="text-2xl font-bold mb-8 mt-8">Modifier</div>
          <CreateAccreditationCollaboratorForm
            affectedAccreditation={affectedAccreditation}
            collaborator={affectedAccreditation.collaborator}
          />
        </div>
      </Block>
    </div>
  );
}

function EditAffectedAccreditationPage() {
  let { id } = useParams();
  id = parseInt(id);
  if (isNaN(id)) {
    return <Redirect to="/rh/habilitations/habilites" />;
  }
  return (
    <ErrorBoundary fallback="Récupération de l'habilité impossible">
      <AffectedAccreditationProvider id={id}>
        <EditAffectedAccreditation />
      </AffectedAccreditationProvider>
    </ErrorBoundary>
  );
}

export default EditAffectedAccreditationPage;
