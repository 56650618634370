import * as React from "react";

import { useCollaboratorId, usePermissionsChecker } from "./permissions";

import Alert from "../components/Alert";
import ErrorBoundary from "../components/ErrorBoundary";
import Spinner from "../components/Spinner";
import { get } from "lodash-es";
import { useQueryVehicleById } from "../api/vehicle";
import { useQueryCollaboratorById } from "../api/collaborator";
export const VehicleContext = React.createContext({
  vehicle: null,
});

function VehicleError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération du véhicule"
        details={get(error, "data.hydra:description")}
      />
    </div>
  );
}

function VehicleProvider({ id, children }) {
  const hasPermissions = usePermissionsChecker({
    permissions: [
      "kdix.actions.vehicle.view",
      "kdix.actions.vehicle.view.agency",
      "kdix.actions.vehicle.view.department",
      "kdix.actions.vehicle.view.service",
      "kdix.actions.vehicle.view.own",
    ],
  });
  const userCollaborator = useCollaboratorId();
  const { data: collaborator } = useQueryCollaboratorById(userCollaborator);
  const full = hasPermissions || get(collaborator, "vehicle.id", null) === +id;

  const {
    status: statusVehicle,
    error: errorVehicle,
    data: vehicle,
  } = useQueryVehicleById(full && id);

  if (errorVehicle) {
    return <VehicleError error={errorVehicle} />;
  }

  return (
    <VehicleContext.Provider
      value={{
        vehicle: {
          data: vehicle || {},
          status: statusVehicle,
          error: errorVehicle,
        },
      }}
    >
      {children}
    </VehicleContext.Provider>
  );
}

export default (props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<Spinner />}>
      <VehicleProvider {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
