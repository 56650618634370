import React from "react";
import { ReactComponent as ArrowIcon } from "../svgs/arrow-back.svg";
import { Link, useHistory } from "react-router-dom";

export default function GoBackBtn({
  to,
  className = "",
  noArrow = false,
  children,
}) {
  return (
    <Link to={to} className={className}>
      {!noArrow ? <ArrowIcon className="fill-current mr-4" /> : null}
      {children}
    </Link>
  );
}

export function GoBackBtnHistory({
  className = "",
  noArrow = false,
  children,
}) {
  const history = useHistory();
  return (
    <button onClick={history.goBack} className={className}>
      {!noArrow ? <ArrowIcon className="fill-current mr-4" /> : null}
      {children}
    </button>
  );
}
