import Input from "../components/Input";
import React from "react";
import { useIsSilae, usePermissionsChecker } from "../contexts/permissions";

export default function PersonalPostalAddressForm() {
  const isSilae = useIsSilae();
  const readOnly = !(
    usePermissionsChecker({
      permissions: [
        "kdix.actions.personal_information.edit",
        "kdix.actions.personal_information.edit.agency",
        "kdix.actions.personal_information.edit.department",
        "kdix.actions.personal_information.edit.service",
        "kdix.actions.personal_information.edit.own",
      ],
    }) || isSilae
  );

  return (
    <div>
      <Input
        type="text"
        label="Adresse"
        name="address.street"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Complément d'adresse"
        name="address.complement"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Code Postal"
        name="address.postCode"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Ville"
        name="address.city"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="Pays"
        name="address.country"
        readOnly={readOnly}
      />
    </div>
  );
}
