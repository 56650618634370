import React from "react";
import { toast } from "react-toastify";
import { get, map, noop } from "lodash-es";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import DownloadOrUpload from "../components/DownloadOrUpload";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { useImportWorks } from "../api/works";

export default function WorkImportForm({ domain = null, onSuccess = noop }) {
  const [importWorks, { error }] = useImportWorks();

  const validation = Yup.object().shape({
    file: Yup.mixed().test("fileFormat", "Formats autorisés: csv", (value) => {
      const authorized = ["text/csv"];
      return value === undefined || (value && authorized.includes(value.type));
    }),
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          file: "",
        }}
        validationSchema={validation}
        onSubmit={async ({ file, ...values }, actions) => {
          try {
            let importDocuments = null;
            if (file) {
              importDocuments = await importWorks({
                file: file,
                domain: domain,
              });
            }
            actions.setSubmitting(false);
            actions.resetForm();
            actions.resetForm();

            if (file) {
              toast.success(
                `Fiches de poste importées avec succès : (${importDocuments?.numberWorkImported} poste(s), ${importDocuments?.numberSkillDomainImported} domaine(s) et ${importDocuments?.numberSkillImported} compétence()s)`,
              );
              onSuccess();
            } else {
              toast.error("Aucun fichier uploadé");
            }
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
    
             <div>
              <p className="mb-2">
               <strong>Format du CSV pour l'import</strong>
              </p>
              <ul>
               <li>Le séparateur de champ est ; ou tabulation</li>
               <li>Les colonnes ne doivent pas avoir d'entête</li>
               <li>L'extension du fichier doit être .csv ou .txt</li>
              </ul>
             <p className="mt-2 mb-2">
              <strong>Liste des colonnes du CSV :</strong>
             </p>
              <ul>
               <li>Colonne 1 : Fiche de poste</li>
               <li>Colonne 2 : Domaine de compétences</li>
               <li>Colonne 3 : Compétence</li>
               <li>Colonne 4 : Description du niveau 0</li>
               <li>Colonne 5 : Description du niveau 1</li>
               <li>Colonne 6 : Description du niveau 2</li>
               <li>Colonne 7 : Description du niveau 3</li>
               <li>Colonne 8 : Description du niveau 4</li>
             </ul>
            </div>
              <div className="mt-5">
                <DownloadOrUpload
                  name="file"
                  accept="text/csv"
                  uploadLabel="Uploader un fichier"
                  isSubmitting={isSubmitting}
                  submitButton={false}
                />
              </div>

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 block w-full`}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Importer"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
