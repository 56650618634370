import { map, size } from "lodash-es";
import { ReactComponent as FileIcon } from "../svgs/file.svg";
import React from "react";
import { ReactComponent as TrashIcon } from "../svgs/trash.svg";
import { useSet } from "react-use";
import InfoTooltip from "./InfoTooltip";

function FileRow({
  label,
  comment,
  date,
  Icon,
  handleClick,
  handleDelete,
  className,
  isLoading,
  canDelete,
  useExternalDocumentManagement = false,
}) {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isDeleted, setIsDeleted] = React.useState(false);

  if (isDeleted) return null;

  return (
    <div
      className={`flex items-center border-b border-gray-200 uppercase hover:bg-gray-100 leading-tight ${className}`}
    >
      <>
        <div
          className={`flex items-center p-2 flex-1 cursor-pointer ${
            useExternalDocumentManagement ? "grid" : ""
          }`}
          style={
            useExternalDocumentManagement
              ? {
                  gridTemplateColumns: "30px 30% 30% 30%",
                }
              : {}
          }
          onClick={handleClick}
        >
          <div className="mr-4">
            {isDeleting || isLoading ? (
              <div className="loader h-4 w-4 mx-auto"></div>
            ) : (
              Icon
            )}
          </div>
          <div className="py-2">
          {comment ? (
                <InfoTooltip
                    message={comment}
                    onHover
                    parentClass="w-full"
                    childClass="w-full truncate"
                >
                  {label}
                </InfoTooltip>
            ) :
            ( 
            <div>{label}</div>
            )}
          </div>
          {useExternalDocumentManagement && (
            <>
              <div className="py-2 px-4">
                {new Date(date).toLocaleDateString()}
              </div>
              <div className="py-2 px-4">{comment}</div>
            </>
          )}
        </div>
        {canDelete && handleDelete && !useExternalDocumentManagement ? (
          <div className="ml-auto pr-4">
            <button
              type="button"
              onClick={async () => {
                const res = window.confirm("La suppression sera définitive");
                if (!res) return;
                try {
                  setIsDeleting(true);
                  await handleDelete();
                  setIsDeleted(true);
                } catch (error) {
                  setIsDeleting(false);
                }
              }}
            >
              <TrashIcon className="w-6 h-6 fill-current text-red-500 hover:text-red-600" />
            </button>
          </div>
        ) : null}
      </>
    </div>
  );
}

export default function DocumentOnlyBrowser({
  queryFolderHook,
  deleteDocumentHook,
  downloadDocumentFn,
  canDelete = false,
  useExternalDocumentManagement = false,
}) {
  const { data, isFetching } = queryFolderHook();

  const [deleteDocument] = deleteDocumentHook();

  const [
    ,
    { add: addDownload, remove: removeDownload, has: hasDownload },
  ] = useSet(new Set([]));

  const currentDocuments = data || [];

  return (
    <div className="w-full">
      <div className="relative w-full">
        {isFetching ? (
          <div
            className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center"
            style={{ minHeight: 50 }}
          ></div>
        ) : null}

        {!size(currentDocuments) ? (
          <div className="py-4">Ce dossier est vide</div>
        ) : (
          ""
        )}

        {useExternalDocumentManagement && (
          <div
            className={`flex  flex-1 items-center border-b text-white divide-white bg-gray-500 shadow uppercase leading-tight`}
          >
            <div
              className="flex items-center p-2 grid flex-1 "
              style={{
                gridTemplateColumns: "30px 30% 30% 30%",
              }}
            >
              <div />
              <div className="py-2">Nom du fichier</div>
              <div className="py-2 px-4">Date</div>
              <div className="py-2 px-4">Commentaire</div>
            </div>
          </div>
        )}
        {map(currentDocuments, (item) => {
          const isFetching = hasDownload(item.id);
          return (
            <FileRow
              label={item.label}
              date={item.date}
              comment={item.comment}
              key={item.id}
              Icon={<FileIcon className="w-4 h-4 fill-current text-black" />}
              isLoading={isFetching}
              handleClick={async () => {
                if (!isFetching) {
                  addDownload(item.id);
                  await downloadDocumentFn(item.id, item.label, item.extension);
                  removeDownload(item.id);
                }
              }}
              handleDelete={() => deleteDocument(item.id)}
              canDelete={canDelete}
              useExternalDocumentManagement={useExternalDocumentManagement}
            />
          );
        })}
      </div>
    </div>
  );
}
