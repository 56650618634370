import React from "react";
import { useCollaboratorId, usePermissionsChecker } from "../contexts/permissions";
import { Helmet } from "react-helmet";
import { useDesktop } from "../hooks/useDesktop";
import { InlineBLockContent } from "../components/InlineBlockContentSortable";
import Alert from "../components/Alert";
import InfoTooltip from "../components/InfoTooltip";
import { map, size } from "lodash-es";
import { useGetEvaluationsToStart, useGetCollaboratorEvaluators } from "../api/collaborator";
import { Link } from "react-router-dom";

import {
  EVALUATION_STATUS,
  EVALUATION_STATUS_CLOSED,
  EVALUATION_STATUS_COMPLETED,
} from "../utils/evaluationStatus";
import { getFirstnameLastname } from "../utils/names";
import { ReactComponent as StatsIcon } from "../svgs/stats.svg";
import { ReactComponent as PenIcon } from "../svgs/pen.svg";
import { ReactComponent as ArrowUpRightIcon } from "../svgs/ArrowUpRight.svg";
import { getLocaleDateString } from "../utils/date";
import { HeaderBar } from "../components/HeaderBar";
import EvaluationCollaboratorForm from "../forms/EvaluationCollaboratorForm";
import Button from "../components/Button";
import Modal from "../components/Modal";


export default function LastEvaluations() {
  const collaboratorId = useCollaboratorId();
  const currentLastYear = new Date();
  currentLastYear.setFullYear(currentLastYear.getFullYear() - 1); // moins 1 an
  
  const { data: evaluations } = useGetEvaluationsToStart(collaboratorId, {
    "createdAt[after]": currentLastYear,
  });
  
  const canViewEvaluation = usePermissionsChecker({
    permissions: [
      "kdix.actions.skill_evaluation.view_evaluations",
    "kdix.actions.skill_evaluation.view_evaluations.agency",
    "kdix.actions.skill_evaluation.view_evaluations.department",
    "kdix.actions.skill_evaluation.view_evaluations.service",
    "kdix.actions.skill_evaluation.view_evaluations.own",
    ],
  });
 

  const isDesktop = useDesktop();
  const canSeeViewButton = (node) =>
    node.status === EVALUATION_STATUS_CLOSED ||
    node.status === EVALUATION_STATUS_COMPLETED;
  const gridTemplateColumn = `12% 12% 12% 12% 20% 20% 12%`;

  if (size(evaluations) <= 0) {
    return (
      <div>
        <Helmet>
          <title>Evaluations en cours</title>
        </Helmet>
        <HeaderBar sticky={true} title={<>Dernières évaluations</>}></HeaderBar>
        <div className="mt-12">
          <Alert type="warning" message="aucune évaluation" />
        </div>
      </div>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Dernières évaluations</title>
      </Helmet>
      <HeaderBar sticky={true} title={<>Evaluations en cours ou à démarrer</>}></HeaderBar>
      <div className="p-8 pb-24">
        {isDesktop && (
          <div
            className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 top-tab-header always-front`}
            style={{
              gridTemplateColumns: gridTemplateColumn,
            }}
          >
            <InlineBLockContent>Début de période</InlineBLockContent>
            <InlineBLockContent>Fin de période</InlineBLockContent>
            <InlineBLockContent>Date de l'auto-évaluation</InlineBLockContent>
            <InlineBLockContent>Statut</InlineBLockContent>
            <InlineBLockContent>Collaborateur</InlineBLockContent>
            <InlineBLockContent>Date de l'évaluation</InlineBLockContent>
            <InlineBLockContent>Actions</InlineBLockContent>
          </div>
        )}

        {map(evaluations, (node) =>
          isDesktop ? (
            <div
              key={node.id}
              className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2`}
              style={{
                gridTemplateColumns: gridTemplateColumn,
              }}
            >
              <InlineBLockContent>
                {getLocaleDateString(node.campaign, "startDate")}
              </InlineBLockContent>
              <InlineBLockContent>
                {getLocaleDateString(node.campaign, "endDate")}
              </InlineBLockContent>
              <InlineBLockContent>
                {node.evaluation? getLocaleDateString(node.evaluation, "createdAt") : ""}
              </InlineBLockContent>
              <InlineBLockContent>
                {node.evaluation?EVALUATION_STATUS[node.evaluation.status] : "à démarrer"}
              </InlineBLockContent>
              <InlineBLockContent>
                {getFirstnameLastname(node.collaborator)}
              </InlineBLockContent>
              <InlineBLockContent>
                {node.evaluation? getLocaleDateString(node.evaluation, "evaluatorDate") : ""}
              </InlineBLockContent>
              
              <InlineBLockContent className="flex justify-center gap-2">
                { (canViewEvaluation && node.evaluation)? 
                ( canViewEvaluation && <EditButton id={node.evaluation.id} />
                ) : (canViewEvaluation && <EditButton collaboratorid={node.collaborator.id} />) }
                { canViewEvaluation && node.evaluation && canSeeViewButton(node.evaluation) && <ViewButton id={node.evaluation.id} />}
                { collaboratorId!==node.collaborator.id && <DelegateButton collaboratorid={node.collaborator.id} /> }
              </InlineBLockContent>
            </div>
          ) : (
            <div></div>
          ),
        )}
      </div>
    </div>
  );
}

function ViewButton({ id }) {
  return (
    <InfoTooltip message={"Voir le rapport comparatif"} onHover={true}>
      <Link
        to={`/last_rapport_evaluation/${id}`}
        className={`w-8 h-8 rounded-full flex justify-center items-center focus:outline-none bg-green-700`}
      >
        <StatsIcon className="w-4 h-4 fill-current text-white" />
      </Link>
    </InfoTooltip>
  );
}

function EditButton({ id, collaboratorid }) {
  return (
    <InfoTooltip message={"Ouvrir l'évaluation"} onHover={true}>
      <Link
        to={ id ? `/evaluation/${id}` : `/rh/collaborateurs/${collaboratorid}/evaluations` }
        className={`w-8 h-8 rounded-full flex justify-center items-center focus:outline-none bg-green-700`}
      >
        <PenIcon className="w-4 h-4 fill-current text-white" />
      </Link>
    </InfoTooltip>
  );
}

function DelegateButton({ collaboratorid }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const { data: evaluators } = useGetCollaboratorEvaluators(
    collaboratorid,
  );

  return (
    <>
    <InfoTooltip message={"Déléguer l'évaluation"} onHover={true}>
      <Button
        type="button"
        superClassName={`w-8 h-8 rounded-full flex justify-center items-center focus:outline-none bg-green-700`}
        loaderClassName={`loader h-6 w-6 mx-auto`}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsOpen(collaboratorid);
        }}
      >
        <ArrowUpRightIcon className="w-4 h-4 fill-current text-white" />
      </Button>
    </InfoTooltip>
    <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title="Déléguer l'évaluation du collaborateur"
      >
        <EvaluationCollaboratorForm collaborator={evaluators} isEvaluator={true} afterSubmit={() => {
              setIsOpen(false);
            }} />  
      </Modal>
      </>
  );
}
