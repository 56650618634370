import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import {
  useCreateDepartment,
  useDeleteDepartment,
  useQueryDepartments,
  useUpdateDepartment,
} from "../api/department";

import Input from "../components/Input";
import ManageResource from "../components/ManageResource";
import React from "react";

function CreateForm() {
  const [createDepartment] = useCreateDepartment();
  return (
    <Formik
      initialValues={{
        label: "",
        children: [],
      }}
      onSubmit={async (values, actions) => {
        try {
          await createDepartment({
            label: values.label,
            children: map(values.children, ({ value }) => value || null),
          });
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ values, isSubmitting }) => {
        return (
          <Form>
            <Input name="label" label="Nom" />
            <div className="text-center">
              <button className={`btn mt-6 inline-block`} type="submit">
                {isSubmitting ? (
                  <div className="loader h-8 w-8 mx-auto"></div>
                ) : (
                  "Créer"
                )}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default function DepartmentsAdmin() {
  return (
    <ManageResource
      resourceLabel="Départements/Pôles"
      createLabel="Ajouter un département/pôle"
      createHook={useCreateDepartment}
      CreateForm={CreateForm}
      queryHook={useQueryDepartments}
      deleteHook={useDeleteDepartment}
      updateHook={useUpdateDepartment}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
      }}
    />
  );
}
