import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "react-toastify/dist/ReactToastify.css";
import "./css/main.css";
import "./css/toast-override.css";
import "./css/custom.css";

import * as Sentry from "@sentry/browser";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import Modal from "react-modal";
import React from "react";
import { render } from "react-dom";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://b83dd0cbea7b4351ad2ddd7640246d46@o324194.ingest.sentry.io/5225882",
  });
}

Modal.setAppElement("#root");

const rootElement = document.getElementById("root");
render(<App />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
