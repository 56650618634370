import React from "react";
import { map } from "lodash-es";

export function ButtonPreviousNext({ text, onClick }) {
  return (
    <button className="p-2 text-lg" onClick={onClick}>
      {text}
    </button>
  );
}

export function SelectTimeGroup({
  options,
  name,
  onChange,
  value: currentValue,
}) {
  return (
    <div className="flex radio-button-group">
      {map(options, ({ label, value }, index) => (
        <div className="flex" key={index}>
          <input
            type="radio"
            name={name}
            value={value}
            id={`${name}-${value}`}
            checked={value === currentValue}
            onChange={onChange}
            className="hidden"
            //readOnly={readOnly}
          />
          <label htmlFor={`${name}-${value}`}>{label}</label>
        </div>
      ))}
    </div>
  );
}
