import { queryCache, useInfiniteQuery, useQuery, useMutation } from "react-query";
import queryWithToken, { find, deleteById } from "./index";
import { get } from "lodash-es";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "campaign_evaluations";

export function useQueryCampaignEvaluations(filter) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filter],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filter,
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQueryCampaignEvaluationsInfinite(filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    refetchOnWindowFocus: false,
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}

export function useDeleteCampaignEvaluations() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
    onError: (error) => {
      toast.error("Erreur lors de la suppression : " + error.description);
    },
  });
}
