import React from "react";

function Button({
  className = "",
  children,
  active,
  isForm = false,
  isSubmitting = false,
  readOnly = false,
  textLabel = "Enregistrer",
  superClassName,
  loaderClassName = "loader h-6 w-6 mx-auto",
  ...props
}) {
  let customChild = "";

  if (isForm === true) {
    customChild = (
      <div className="relative">
        <div className={`${isSubmitting ? "opacity-0" : ""}`}>{textLabel}</div>
        {isSubmitting ? (
          <div className={`absolute inset-0 `}>
            <div className={loaderClassName}></div>
          </div>
        ) : null}
      </div>
    );
  } else {
    customChild = children;
  }
  return (
    <>
      {readOnly === false ? (
        <button
          className={
            superClassName ? superClassName : `btn shadow ${className}`
          }
          {...props}
        >
          <div className="relative">
            <div className={`${isSubmitting ? "opacity-0" : ""}`}>
              {customChild}
            </div>
            {isSubmitting && (
              <div className={`absolute inset-0 `}>
                <div className={loaderClassName}></div>
              </div>
            )}
          </div>
        </button>
      ) : null}
    </>
  );
}

export default Button;
