import React from "react";
import { join, map } from "lodash-es";
import Block from "./Block";
import { getFirstnameLastname } from "../utils/names";

export default function RecipientsList({
  showRecipients,
  recipients,
  copyRecipients,
}) {
  const listRecipients = recipients
    ? map(recipients, (e) => getFirstnameLastname(e, "collaborator"))
    : [];
  const listCopyRecipients = copyRecipients
    ? map(copyRecipients, (e) => getFirstnameLastname(e, "collaborator"))
    : [];
  return (
    <div
      className={`${
        showRecipients ? "" : "hidden"
      } recipientsList cursor-default`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Block className="border-gray-100 border text-sm">
        <div>
          <span className="font-bold">Collaborateurs :</span>{" "}
          {join(listRecipients, ", ")}
        </div>
        <div>
          <span className="font-bold">Collaborateurs en copie :</span>{" "}
          {join(listCopyRecipients, ", ")}
        </div>
      </Block>
    </div>
  );
}
