import {
  useCreateTypeBounty,
  useDeleteTypeBounty,
  useQueryTypeBounties,
  useUpdateTypeBounty,
} from "../api/typeBounties";

import ManageResource from "../components/ManageResource";
import React from "react";

function TypeBountiesAdmin() {
  return (
    <ManageResource
      resourceLabel="Types de primes"
      createLabel="Ajouter un type de prime"
      createHook={useCreateTypeBounty}
      queryHook={useQueryTypeBounties}
      deleteHook={useDeleteTypeBounty}
      updateHook={useUpdateTypeBounty}
      isRecurrentField={true}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
        isRecurrent: {
          label: "Prime récurrente",
          type: "checkbox",
          resourceValueKey: "isRecurrent",
        },
      }}
    />
  );
}

export default TypeBountiesAdmin;