import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import { get, map } from "lodash-es";

import Alert from "./Alert";
import React, { useState } from "react";
import Select from "./Select";
import { useCreateRequest } from "../api";
import { useQueryTypeRequests } from "../api/typeRequest";
import { useToggle } from "react-use";
import { ReactComponent as RequestOutlineIcon } from "../svgs/request-outline.svg";
import { ModuleChecker } from "../contexts/permissions";

const validation = Yup.object({
  type: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
  message: Yup.string().required("Requis"),
});

function Declaration({ isDesktop, isSidebarOpen, keepOpen = false }) {
  const [state, createRequest] = useCreateRequest();
  const { data: requests } = useQueryTypeRequests({ active: true });
  const [isOpen, toggle] = useToggle(keepOpen);
  const [successMessage, setSuccessMessage] = useState("");
  return (
    <ModuleChecker module="requests">
      <div
        className={`mt-16 fixed bottom-0 left-0 always-front ${
          isDesktop && isSidebarOpen
            ? "ml-sidebar-open w-without-sidebar-open"
            : isDesktop
            ? "ml-sidebar w-without-sidebar"
            : "w-full"
        }`}
      >
      
        {keepOpen ? (
              <RequestOutlineIcon />
            ) : (
        <div className="absolute -mt-8 w-full" onClick={() => toggle()}>
          <div className="w-16 h-16 bg-purple-600 rounded-full mx-auto flex items-center justify-center cursor-pointer">
            {isOpen ? (
              <svg
                className="w-5 h-5 fill-current text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
              </svg>
            ) : (
              <RequestOutlineIcon />
            )}
          </div>
        </div>
        )}

        {keepOpen ? (
        <div
          className="w-full text-white text-center bg-purple-800 p-4 pt-10"
        >
          <div className="uppercase font-bold">Vous avez la parole</div>
        </div>
        ) : (
        <div
          className="w-full text-white text-center bg-purple-800 p-4 pt-10"
          onClick={() => toggle()}
        >
          <div className="uppercase font-bold">Vous avez la parole</div>
        </div>
        )}
     
        <div
          className={`max-h-0 transition-maxHeight duration-500 ease-in-out`}
          style={{
            maxHeight: isOpen ? "600px" : 0,
          }}
        >
          <div className={`bg-purple-800 px-8 pb-12`}>
            <Formik
              initialValues={{
                type: {
                  label: "",
                  value: "",
                },
                message: "",
              }}
              validationSchema={validation}
              onSubmit={async (values, actions) => {
                setSuccessMessage("");
                await createRequest({
                  type: values.type.value || null,
                  message: values.message,
                });

                const recipient = requests.find(
                  (item) => item["@id"] === values.type.value
                );
                setSuccessMessage(
                  "Votre demande a bien été envoyée au service " +
                    recipient.assignedPermission.label
                );
                actions.setSubmitting(false);
                actions.resetForm();
              }}
            >
              {({ isSubmitting, values, errors }) => {
                return (
                  <Form>
                    <div className="text-white">
                      <Select
                        label="Choisissez le type de demande :"
                        placeholder="Sélectionnez..."
                        name="type"
                        color="white"
                        options={map(requests, (node) => ({
                          label: node.label,
                          value: node["@id"],
                        }))}
                        value={values.typeRequest}
                        isLoading={!requests}
                      />
                    </div>
                    <div className="mt-6">
                      <Field
                        component="textarea"
                        name="message"
                        cols="30"
                        rows="10"
                        className="p-2 w-full"
                        style={{
                          maxHeight: "calc(50vh - 110px)",
                        }}
                      />
                      {errors.message ? (
                        <p className="text-red-500 text-xs italic mt-2">
                          {errors.message}
                        </p>
                      ) : null}
                    </div>

                    {get(state, "error") ? (
                      <div className="my-2">
                        <Alert
                          type="error"
                          message={get(state, "error.data.hydra:title")}
                        />
                      </div>
                    ) : null}

                    <div className="text-center mt-3">
                      {isSubmitting ? (
                        <button className={`btn`} disabled>
                          <div className="loader h-8 w-8 mx-auto" />
                        </button>
                      ) : (
                        <button className={`btn`} type="submit">
                          Envoyer
                        </button>
                      )}
                    </div>
                    {successMessage !== "" && (
                      <Alert
                        className="mt-8"
                        type="success"
                        message={successMessage}
                        canBeHidden
                      />
                    )}
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </ModuleChecker>
  );
}

export default Declaration;
