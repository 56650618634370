import {useQuery} from "react-query";
import queryWithToken from "./index";
import {get} from "lodash-es";

export function useQueryNationalities() {
    const { data, ...rest } = useQuery(
        ["nationalities"],
        () =>
            queryWithToken(`${process.env.REACT_APP_API_URL}/nationalities`, {
                method: "GET",
                params: {
                    page: null,
                    pagination: false,
                },
            }),
        {
            refetchOnWindowFocus: false,
        }
    );

    return {
        data: get(data, "hydra:member"),
        ...rest,
    };
}
