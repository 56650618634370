import React from "react";
import { useUserId } from "../contexts/permissions";
import CreateEvent from "../forms/CreateEvent";
export default function CreateEventPage() {
  const userId = useUserId();
  return (
    <div className="p-8 pb-24 flex flex-col">
      <CreateEvent userId={userId} />
    </div>
  );
}
