import { useContext, useEffect } from "react";

import PermissionsContext from "../contexts/permissions";
import { logout } from "../graphql";
import { useIdle } from "react-use";

export default function useAutomaticLogout() {
  const { token } = useContext(PermissionsContext);
  const isIdle = useIdle(14400e3);
  useEffect(() => {
    if (isIdle && token && process.env.NODE_ENV === "production") {
      logout();
    }
  }, [isIdle, token]);
}
