import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import PermissionsContext from "../contexts/permissions";

function PrivateRoute({ children, ...rest }) {
  const { token } = useContext(PermissionsContext);
  const isAuthenticated = !!token;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuthenticated === true ? children : <Redirect to="/login" />;
      }}
    />
  );
}

export default PrivateRoute;
