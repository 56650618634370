import GoBackBtn from "../../components/GoBackBtn";
import React from "react";
import Toggle from "../../components/Toogle";
import { Formik, Form } from "formik";
import { get, map } from "lodash-es";
import {useGetNextAndPrevVehicle, useQueryVehiclesListAll, useUpdateVehicle} from "../../api/vehicle";
import { toast } from "react-toastify";
import { PermissionChecker } from "../../contexts/permissions";
import LinkToVehicle from "../../components/LinkToVehicle";
import ReactSelect from "react-select";
import {useHistory} from "react-router";

function Header({ brand, model, licencePlate, vehicleId, active }) {
  const permissionsEdit = [
    "kdix.actions.vehicle.edit",
    "kdix.actions.vehicle.edit.agency",
    "kdix.actions.vehicle.edit.department",
    "kdix.actions.vehicle.edit.service",
    "kdix.actions.vehicle.edit.own",
  ];
  const permissionsView = [
    "kdix.actions.vehicle.view",
    "kdix.actions.vehicle.view.agency",
    "kdix.actions.vehicle.view.department",
    "kdix.actions.vehicle.view.service",
    "kdix.actions.vehicle.view.own",
  ];
  const [updateVehicle] = useUpdateVehicle();
  const {
    data: [previousVehicle, nextVehicle],
  } = useGetNextAndPrevVehicle(vehicleId);

  const { data : vehicles } = useQueryVehiclesListAll({
    active: "true",
  });

  const history = useHistory();
  const currentTab = history.location.pathname.split('/').at(-1);

  return (
    <div className="bg-purple-700 text-white relative">
      <div className="px-8 py-5 flex justify-between absolute w-full top-0">
        <GoBackBtn className="-ml-4" to="/rh/vehicules" />
        <PermissionChecker permissions={permissionsView}>
          <div className="flex items-center">
            {previousVehicle && (
              <LinkToVehicle
                vehicle={previousVehicle}
                className="pr-3 border-r border-white"
              />
            )}
            {(nextVehicle || previousVehicle) && (
                <div className="collaborator-select">
                  <ReactSelect
                      placeholder="Chercher un véhicule"
                      options={map(vehicles, (vehicle) => {
                        return {
                          label: vehicle.brand + ' ' + vehicle.model + ' - ' + vehicle.licencePlate,
                          value: vehicle.id,
                        }
                      })}
                      noOptionsMessage={({ inputValue }) => {
                        return inputValue !== ""
                            ? "Aucun véhicule pour cette recherche"
                            : "Aucun véhicule";
                      }}
                      loadingMessage={() => "Chargement..."}
                      onChange={(vehicle, { action }) => {
                        if (action === "select-option") {

                          const redirectUrl = `/rh/vehicules/${vehicle.value}/${currentTab}`;

                          history.push(redirectUrl);
                        }
                      }}
                      styles={{
                        option: (provided) => ({ ...provided, color: "black" }),
                      }}
                  />
                </div>
            )}
            {nextVehicle && (
              <LinkToVehicle
                  vehicle={nextVehicle}
                  isNext
                  className="pl-3" />
            )}
          </div>
        </PermissionChecker>
      </div>
      <div className="xl:flex">
        <div className="px-8 pt-10 py-6 flex items-center xl:w-3/12 bg-purple-600">
          <div className="leading-6 ml-8">
            <div className="text-xl font-bold">Détail du véhicule</div>
            <div className="text-sm">
              {brand} {model} - {licencePlate}
            </div>
            <PermissionChecker permissions={permissionsEdit}>
              <div>
                <Formik
                  initialValues={{
                    active: active,
                  }}
                  onSubmit={async (values, actions) => {
                    try {
                      await updateVehicle({
                        id: vehicleId,
                        data: {
                          active: values.active,
                        },
                      });

                      toast.success("Mise à jour effectuée avec succès");
                    } catch (error) {
                      map(get(error, "violations"), (e) => {
                        actions.setFieldError(e.propertyPath, e.message);
                        console.log("error", e.message);
                      });

                      actions.setSubmitting(false);
                    }
                  }}
                >
                  {({ values, setFieldValue, submitForm }) => {
                    return (
                      <Form>
                        <Toggle
                          label={values.active ? "Actif" : "Désactivé"}
                          name="active"
                          value={values.active}
                          checked={values.active}
                          onChange={(e) => {
                            setFieldValue("active", e.target.checked);
                            submitForm();
                          }}
                        />
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </PermissionChecker>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
