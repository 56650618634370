import {
  useCreateTypeWorkAccidentBodyArea,
  useDeleteTypeWorkAccidentBodyArea,
  useQueryTypeWorkAccidentBodyArea,
  useUpdateTypeWorkAccidentBodyArea
} from "../api/typeWorkAccidentBodyArea";

import ManageResource from "../components/ManageResource";
import React from "react";

function TypeWorkAccidentBodyAreaAdmin() {
  return (
    <ManageResource
      resourceLabel="Localisations corporelles - Accident"
      createLabel="Ajouter une localisation corporelle"
      createHook={useCreateTypeWorkAccidentBodyArea}
      queryHook={useQueryTypeWorkAccidentBodyArea}
      deleteHook={useDeleteTypeWorkAccidentBodyArea}
      updateHook={useUpdateTypeWorkAccidentBodyArea}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label"
        }
      }}
    />
  );
}

export default TypeWorkAccidentBodyAreaAdmin;
