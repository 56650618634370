import { get, size } from "lodash-es";
import { queryCache, useMutation, useQuery } from "react-query";
import queryWithToken, {
  create,
  deleteById,
  findById,
  updateById,
  updateOrCreate,
  findExport,
} from "./index";
import fileDownload from "js-file-download";

import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "salaries";
//

export function useQuerySalaryById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQuerySalarysbyIds(ids = []) {
  const { data, ...rest } = useQuery(
    size(ids) && [ressourceKey, ids],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          id: ids,
          "order[date]": "desc",
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useCreateSalary(resetHistory = false) {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onError: () => {
      toast.error("Erreur lors de la création");
    },
    onSuccess: (data) => {
      toast.success("Création effectuée avec succès");
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
      if (resetHistory) {
        queryCache.refetchQueries("history");
      }
    },
  });
}

export function useUpdateSalary() {
  return useMutation(updateById.bind(null, ressourceKey), {
    onSuccess: () => {
      toast.success("Mise à jour effectuée avec succès");
    },
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onError: () => {
      toast.error("Erreur lors de la mise à jour");
    },
  });
}

export function useCreateOrUpdateSalary() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    onError: () => {
      toast.error("Erreur lors de la création");
    },
    onSuccess: () => {
      toast.success("Mise à jour effectuée avec succès");
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("history");
      queryCache.refetchQueries("contracts");
    },
  });
}

export function useDeleteSalary() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onError: () => {
      toast.error("Erreur lors de la suppression");
    },
    onSuccess: () => {
      toast.success("Suppression effectuée avec succès");
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("history");
    },
  });
}

export async function downloadSalaryExport(filters) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, "export_historique_salaires.csv");
}
