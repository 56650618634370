import { DisplayInput } from "../Input";
import HistoryItem from "../HistoryItem/HistoryItem";
import React, {useState} from "react";
import { ReactComponent as AllocationIcon } from "../../svgs/profile.svg";
import { get } from "lodash-es";
import { getFirstnameLastnameJob } from "../../utils/names";
import EditButton from "../HistoryItem/EditButton";
import {useDeleteVehicleAttributionHistory} from "../../api/vehicleAttributionHistory";
import {useUpdateVehicle} from "../../api/vehicle";
import {VehicleContext} from "../../contexts/vehicle";
import {toast} from "react-toastify";

function AllocationHistoryItem({ allocation, date, isLeft }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [deleteAllocationHistory] = useDeleteVehicleAttributionHistory(true);
  const [updateVehicle] = useUpdateVehicle();
  const {
      vehicle: { data: vehicle },
  } = React.useContext(VehicleContext);

  return (
    <HistoryItem
      title={
        get(allocation, "collaborator", "") !== ""
          ? `Attribution - ${getFirstnameLastnameJob(
              allocation,
              "collaborator"
            )}`
          : get(allocation, "agency.label", "") !== ""
                ? `Attribution - Site ${get(allocation, "agency.label", "")}`
                : "Attribution"
      }
      Icon={AllocationIcon}
      backgroundIcon="bg-indigo-500"
      date={`${date ? new Date(date).toLocaleDateString() : ""}`}
      itemId={allocation.id}
      isToggle={true}
      textColor={"text-indigo-500"}
      isLeft={isLeft}
    >
      <div className={"flex flex-wrap"}>
        <div className={"w-full lg:w-1/3 lg:pr-2"}>
          <DisplayInput
            label="Site"
            value={get(allocation, "agency.label", "")}
          />
        </div>
        <div className={"w-full lg:w-1/3 lg:px-2"}>
          <DisplayInput
            label="Conducteur depuis le"
            value={
              get(allocation, "date")
                ? new Date(get(allocation, "date")).toLocaleDateString()
                : ""
            }
          />
        </div>
        <div className={"w-full lg:w-1/3 lg:pl-2"}>
          <DisplayInput
            label="Conducteur"
            value={
              get(allocation, "collaborator")
                ? getFirstnameLastnameJob(allocation, "collaborator")
                : ""
            }
          />
        </div>
      </div>
      <EditButton
          onDelete={async () => {
              const res = window.confirm(
                  "Êtes-vous sûr de vouloir supprimer cette attribution ?"
              );

              if (res) {
                  setIsSubmitting(true);
                  if (vehicle.lastVehicleAttributionHistory?.id === allocation.id) {
                      await updateVehicle({
                          id: allocation.vehicle.id,
                          data: {
                              collaborator: null,
                              agency: null,
                          },
                      })
                  }
                 await deleteAllocationHistory(allocation.id);
                 setIsSubmitting(false);
                 toast.success("Mise à jour effectuée avec succès");
              }
          }}
          isSubmitting={isSubmitting}
          permissionsEdit={[
            "kdix.actions.vehicle.edit",
            "kdix.actions.vehicle.edit.agency",
            "kdix.actions.vehicle.edit.department",
            "kdix.actions.vehicle.edit.service",
            "kdix.actions.vehicle.edit.own",
          ]}
          canEdit={false}
      />
    </HistoryItem>
  );
}

export default AllocationHistoryItem;
