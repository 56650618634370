import {
  PermissionChecker,
  usePermissionsChecker,
  useUseExternalDocumentManagement,
  useUserId,
} from "../../contexts/permissions";
import {
  downloadDocument,
  useCreateDocument,
  useDeleteDocument,
  previewDocument,
} from "../../api/vehicleDocument";
import { useCreateFolder, useDeleteFolder } from "../../api/vehicleFolders";
import { useGetVehicleFolders } from "../../api/vehicle";

import DocumentBrowser from "../../components/DocumentBrowser";
import React from "react";
import Spinner from "../../components/Spinner";
import { VehicleContext } from "../../contexts/vehicle";
import { get } from "lodash-es";

function Documents() {
  const permissionsView = [
    "kdix.actions.general_document.view",
    "kdix.actions.general_document.view.agency",
    "kdix.actions.general_document.view.department",
    "kdix.actions.general_document.view.service",
    "kdix.actions.general_document.view.own",
  ];
  const userId = useUserId();
  const { vehicle } = React.useContext(VehicleContext);
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.vehicle.edit",
      "kdix.actions.vehicle.edit.agency",
      "kdix.actions.vehicle.edit.department",
      "kdix.actions.vehicle.edit.service",
      "kdix.actions.vehicle.edit.own",
    ],
  });
  const vehicleId = get(vehicle, "data.id");
  const useExternalDocumentManagement = useUseExternalDocumentManagement();
  return (
    <PermissionChecker permissions={permissionsView} userId={userId}>
      <React.Suspense fallback={<Spinner />}>
        <DocumentBrowser
          queryFolderHook={useGetVehicleFolders.bind(
            null,
            get(vehicle, "data.id")
          )}
          createFolderHook={useCreateFolder}
          deleteFolderHook={useDeleteFolder}
          downloadDocumentFn={downloadDocument}
          createDocumentHook={useCreateDocument}
          previewDocumentFn={previewDocument}
          deleteDocumentHook={useDeleteDocument}
          canCreate={canEdit}
          canEdit={canEdit}
          canDelete={canEdit}
          objectId={vehicleId}
          useExternalDocumentManagement={useExternalDocumentManagement}
        />
      </React.Suspense>
    </PermissionChecker>
  );
}

export default Documents;
