import React from "react";
import { map, size } from "lodash-es";
import Alert from "../components/Alert";
import { Helmet } from "react-helmet";
import Spinner from "../components/Spinner";
import { useActiveModule, useQueryModules } from "../api/module";
import Toggle from "../components/Toogle";
import { HeaderBar } from "../components/HeaderBar";

function ModulesList() {
  const { data } = useQueryModules();
  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucun modules" />
      </div>
    );
  }
  return (
    <div className="flex justify-center">
      <div className="md:w-2/3 w-full">
        {map(data, (node) => (
          <ModuleLine key={node.id} module={node} />
        ))}
      </div>
    </div>
  );
}

function ModuleLine({ module }) {
  const [activeModule, { status }] = useActiveModule();
  const checked = module.isActive;
  return (
    <div
      key={module.id}
      className="flex justify-between items-center py-4 text-lg border-solid border-b border-gray-200 first:border-t"
    >
      <div>{module.label}</div>
      <div>
        <Toggle
          name={module.code}
          checked={checked}
          submitting={status === "loading"}
          onChange={async (e) => {
            const active = e.target.checked;
            await activeModule({
              id: module.id,
              data: { active },
            });
          }}
        />
      </div>
    </div>
  );
}

function ModuleAdminPage() {
  return (
    <div>
      <Helmet>
        <title>Gestion des modules</title>
      </Helmet>
      <HeaderBar>Gestion des modules</HeaderBar>

      <div>
        <div className="px-8 pb-48 mt-16">
          <React.Suspense fallback={<Spinner />}>
            <ModulesList />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default ModuleAdminPage;
