import React from "react";
import { get, map } from "lodash-es";
import { getFirstnameLastname } from "../../utils/names";
import { useCollaboratorId, useUserIri } from "../../contexts/permissions";
import { useGetCollaboratorEvaluators } from "../../api/collaborator";
import { getLocaleDateString } from "../../utils/date";

export function alertTitleCampaign(alert, isClassic) {
  return {
    title: isClassic
      ? "Évaluation(s) à démarrer"
      : get(alert, "typeAlert.label", ""),
    subtitles: [
      {
        label: "Campagne d'évaluations",
        value: get(alert, "campaign.name"),
      },
      {
        label: "Période des évaluations",
        value: "Du " + getLocaleDateString(alert, "campaign.startDate") + " au " + getLocaleDateString(alert, "campaign.endDate"),
      },
      {
        label: "Date alerte",
        value: getLocaleDateString(alert, "reminderDate"),
      },
    ],
  };
}

export default function AlertCampaignItem({ alert }) {
  const userIri = useUserIri();
  const userCollaborator = useCollaboratorId();
  const { data: collaborator } = useGetCollaboratorEvaluators(userCollaborator);
  const nonDelegable = map(get(alert, "nonDelegableUsers"), "@id"); // users évalués
  const canBeEvaluate = map(
    get(collaborator, "collaboratorsCanBeEvaluate"),
    "@id",
  );
  const isCampaignEnd = alert.typeAlert.classname.code === "CampaignEnd";
  const associatedUsers = map(get(alert, "associatedUsers"), "@id"); // evaluateur
  return (
    <>
      { nonDelegable.includes(userIri) && (
        <p>
          <span className="font-bold">Évaluateur(s) : </span>
          {map(collaborator.evaluators, (e, key) => (
            <span key={key}>
              {getFirstnameLastname(e)}
              <br />
            </span>
          ))}
        </p>
      )}
      { (isCampaignEnd || associatedUsers.includes(userIri)) && (
        <p>
          <span className="font-bold">Collaborateur(s) à évaluer : </span>
          {map(alert.campaign.campaignEvaluations, (ce, key) => {
            if (!isCampaignEnd && !canBeEvaluate.includes(ce.collaborator["@id"])) return null;
            return (
              <span
                key={key}
                className={
                  (isCampaignEnd && ce?.evaluation?.status === "closed") ||
                  (!isCampaignEnd && ce?.evaluation)
                    ? "line-through"
                    : ""
                }
              >
                {getFirstnameLastname(ce.collaborator)}
                <br />
              </span>
            );
          })}
        </p>
      )}
    </>
  );
}
