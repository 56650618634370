import React  from "react";

function LokoaErrorPage() {
  return (
    <div
      className="p-8 pb-24 flex flex-col xl:w-8/12 mx-auto"
    >
      <div className="text-2xl font-bold pb-8 text-center">Une erreur est survenue</div>

      <div className="text-xl font-bold mb-8 text-center">
        Erreur lors de la connexion aux services Biloba.
      </div>
      <div className="text-xl font-bold mb-8 text-center">
        Assurez-vous que vos identifiants aient bien été importés et que le nom de votre société soit bien renseigné.
      </div>
    </div>
  );
}

export default LokoaErrorPage;
