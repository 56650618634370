import { queryCache, useMutation, useQuery } from "react-query";
import queryWithToken, { deleteById, updateOrCreate } from "./index";

import { get } from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "mappings";
//

const mutateDefaultOptions = {
  throwOnError: true,
  // When mutate is called:
  onMutate: () => {
    queryCache.cancelQueries(ressourceKey);
  },
};

export function useQueryMappingByDomain(domain) {
  const { data } = useQueryMappings({ domain });
  return data;
}

export function useQueryMappings(filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useCreateOrUpdateMapping() {
  return useMutation(
    updateOrCreate.bind(null, ressourceKey),
    mutateDefaultOptions
  );
}

export function useDeleteMapping() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}
