import React from "react";

function Spinner({ small = false, absolute = false, noPadding = false }) {
  return (
    <div
      className={`${absolute ? "absolute" : ""} top-0 w-full ${
        small ? "h-16" : "h-32"
      } ${noPadding ? "" : "px-8"} flex items-center justify-center`}
    >
      <div
        className={`loader ${
          small ? "h-8 w-8" : "h-16 w-16"
        }  border-green-600`}
      ></div>
    </div>
  );
}

export default Spinner;
