import React from "react";

import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import Alert from "../components/Alert";
import Input from "../components/Input";
import Select from "../components/Select";
import { useCreateVehicle } from "../api/vehicle";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import { useQueryTypeVehicle } from "../api/typeVehicle";
import * as Yup from "yup";

function prepareValues({
  type,
  dateTrafficRelease,
  numberOfSeats,
  engineCylinder,
  insuranceValue,
  fiscalPower,
  ...values
}) {
  return {
    ...values,
    type: type.value,
  };
}

const validation = Yup.object().shape({
  type: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
  brand: Yup.string().required("Requis"),
  model: Yup.string().required("Requis"),
  owner: Yup.string().required("Requis"),
  licencePlate: Yup.string().required("Requis"),
});

function CreateVehicle() {
  const history = useHistory();
  const [createVehicle, { error }] = useCreateVehicle();
  const { data: typesVehicle } = useQueryTypeVehicle();
  return (
    <div>
      <Formik
        initialValues={{
          type: {
            value: "",
            label: "",
          },
          model: "",
          owner: "",
          licencePlate: "",
        }}
        validationSchema={validation}
        onSubmit={async (values, actions) => {
          try {
            const response = await createVehicle(prepareValues(values));
            actions.setSubmitting(false);

            if (!response.status && response.id) {
              history.push(`/rh/vehicules/${response.id}`);
            }
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
        }}
      >
        {({ values, isSubmitting, errors }) => {
          return (
            <Form>
              <>
                <Select
                  name="type"
                  label="Type de véhicule *"
                  options={map(typesVehicle, (typeVehicle) => ({
                    label: typeVehicle.label,
                    value: typeVehicle["@id"],
                  }))}
                  value={values.type.value !== "" ? values.type : null}
                  isLoading={!typesVehicle}
                />
              </>
              <Input
                type="text"
                name="brand"
                label="Marque *"
                value={values.brand}
              />
              <Input
                type="text"
                name="model"
                label="Modèle *"
                value={values.model}
              />
              <Input
                type="text"
                name="owner"
                label="Propriétaire *"
                value={values.owner}
              />
              <Input
                type="text"
                name="licencePlate"
                label="Immatriculation *"
                value={values.licencePlate}
              />
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <div className="text-center">
                <Button
                  className={`btn my-8 px-8`}
                  disabled={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Créer"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CreateVehicle;
