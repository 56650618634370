import React from "react";
import { Form, Formik } from "formik";
import { filter, get, map, reduce } from "lodash-es";
import { toast } from "react-toastify";
import { useQueryUsers } from "../../api/user";
import { useCreateRequestComment } from "../../api/requestComment";
import Select from "../Select";
import { DateTimePickerInput } from "../Input";
import TextArea from "../TextArea";
import Button from "../Button";
import { getFirstnameLastnameJob } from "../../utils/names";
import * as Yup from "yup";
import { dateCompare } from "../../regex/date";
import { useUserIri } from "../../contexts/permissions";

const transformSelect = (r) => {
  return {
    value: get(r, "@id"),
    label: getFirstnameLastnameJob(r, "collaborator"),
  };
};

export function CommentForm({ commentOn, toggleComment, request }) {
  const [addComment] = useCreateRequestComment();

  
  return (
    <div className={`${commentOn ? "block" : "hidden"} mt-2`}>
      <Formik
        initialValues={{
          comment: "",
        }}
        onSubmit={async ({ comment }, actions) => {
          try {
            await addComment({
              comment: comment,
              request: get(request, "@id"),
            });
          
            toast.success("Commentaire ajouté avec succès");
            actions.resetForm();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          toggleComment();
        }}
      >
        {({ isSubmitting, submitForm }) => (
          <Form>
            <TextArea
              label="Commentaire"
              name="comment"
              textareaClassName="comment"
            />
            <div className="mt-4">
              <Button
                className={`btn btn--sm align-top w-half ml-4`}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Commenter"
              />
              <div
                className={`btn btn--sm bg-orange-500 hover:bg-orange-700 w-half ml-4`}
                onClick={toggleComment}
              >
                Annuler
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export function DelegateForm({
  delegateOn,
  toggleDelegate,
  request,
  updateHook,
}) {
  const { data: users } = useQueryUsers({
    "exists[collaborator]": "true",
    "order[collaborator.lastname]": "asc",
    "collaborator.active": "true",
  });

  const userSelect = reduce(
    users,
    (result, user) => {
      result.push(transformSelect(user));
      return result;
    },
    []
  );
  return (
    <div className={`${delegateOn ? "block" : "hidden"} mt-2`}>
      <Formik
        initialValues={{
          associatedUser: get(request, "associatedUser", false)
            ? transformSelect(request.associatedUser)
            : {},
        }}
        onSubmit={async ({ associatedUser }, actions) => {
          try {
            await updateHook({
              id: get(request, "id"),
              data: {
                associatedUser: associatedUser.value,
              },
            });
            toast.success("Demande déléguée avec succès");
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          toggleDelegate();
        }}
      >
        {({ values, isSubmitting, submitForm }) => (
          <Form>
            <Select
              name="associatedUser"
              label="Délégué à"
              options={userSelect}
              value={values.associatedUser ? values.associatedUser : null}
              isLoading={!users}
              isDisabled={isSubmitting}
            />
            <div className="mt-4">
              <Button
                className={`btn btn--sm align-top w-half ml-4`}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Déléguer"
              />
              <div
                className={`btn btn--sm bg-orange-500 hover:bg-orange-700 w-half ml-4`}
                onClick={toggleDelegate}
              >
                Annuler
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

const validationEdit = Yup.object().shape({
  reminderAt: Yup.date()
    .required("Requis")
    .test(
      "date-match-today",
      "Doit être postérieure à la date d'aujourd'hui",
      function (value) {
        return dateCompare(new Date(), value);
      }
    ),
});

export function DelayForm({ delayOn, toggleDelay, request, updateHook }) {
  const userIri = useUserIri();
  const consulted = map(get(request, "consultedBy"), "@id");
  return (
    <div className={`${delayOn ? "block" : "hidden"} mt-2`}>
      <Formik
        validationSchema={validationEdit}
        initialValues={{
          reminderAt: get(request, "reminderAt")
            ? new Date(get(request, "reminderAt"))
            : "",
        }}
        onSubmit={async ({ reminderAt }, actions) => {
          try {
            await updateHook({
              id: get(request, "id"),
              data: {
                reminderAt: reminderAt,
                consultedBy: filter(consulted, (c) => c !== userIri),
              },
            });

            toast.success("Demande reportée avec succès");
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          toggleDelay();
        }}
      >
        {({ values, isSubmitting, submitForm }) => (
          <Form>
            <DateTimePickerInput
              name="reminderAt"
              label="Date de rappel"
              fullWidth={true}
              disabled={isSubmitting}
              required
            />
            <div className="mt-4">
              <Button
                className={`btn btn--sm align-top w-half ml-4`}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Reporter"
              />
              <div
                className={`btn btn--sm bg-orange-500 hover:bg-orange-700 w-half ml-4`}
                onClick={toggleDelay}
              >
                Annuler
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
