import { map } from "lodash-es";
import React from "react";

import type { Permission } from "../../types/permission";
import type { Dispatch, SetStateAction } from "react";

type NavBarProps = {
  profiles: Permission[];
  setCurrentProfile: Dispatch<SetStateAction<number>>;
  currentProfile: Permission;
};

export default function NavBar(props: NavBarProps) {
  const { profiles, setCurrentProfile, currentProfile } = props;
  return (
    <div className="relative mb-6 bg-purple-200 -mx-8 px-8">
      <div className="flex overflow-x-auto">
        {map(profiles, (profile, key) => (
          <button
            key={profile.id}
            onClick={() => {
              setCurrentProfile(key);
            }}
            className={`${
              currentProfile?.id === profile.id
                ? "border-purple-600 font-bold"
                : ""
            } inline-block focus:outline-none border-b-4 border-transparent px-4 py-2 whitespace-no-wrap`}
          >
            {profile.label}
          </button>
        ))}
      </div>
    </div>
  );
}
