import Block from "../Block";
import React from "react";
import {
  useCollaboratorId,
  usePermissionsChecker,
} from "../../contexts/permissions";
import { useQueryOvertimes } from "../../api/overtimes";
import moment from "moment";
import Button from "../Button";
import CustomLink from "../CustomLink";

export default function OvertimesBlock({
  activityPage = false,
  collaboratorIri,
}) {
  const collaboratorId = useCollaboratorId();
  const currentMonthEnd = moment().endOf("month").toDate();
  const previousMonthStart = moment().subtract(1,'months').startOf("month").toDate();
  const hasPermissionsActivity = usePermissionsChecker({
    permissions: [
      "kdix.actions.collaborator.activity.view",
      "kdix.actions.collaborator.activity.view.agency",
      "kdix.actions.collaborator.activity.view.department",
      "kdix.actions.collaborator.activity.view.service",
      "kdix.actions.collaborator.activity.view.own",
    ],
  });
  const { data } = useQueryOvertimes({
    collaborator: collaboratorIri,
    "date[after]": previousMonthStart,
    "date[before]": currentMonthEnd,
  });

  const time = parseFloat(data?.[0]?.time ?? 0);
  const plural = (time > 1 || time < -1) ? "s" : "";

  return ( data && data.length>0 )? (
    <div className={"w-full md:w-1/3 p-2 h-64 flex-1"}>
      <Block className="text-center flex-grow bg-white">
        <CustomLink
          to={`/rh/collaborateurs/${collaboratorId}/activite`}
          disabled={!hasPermissionsActivity || activityPage}
          className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
        >
          <div
            className={`bg-purple-400 inline-flex text-white text-2xl rounded-full font-bold w-16 h-16 items-center justify-center`}
          >
            {time.toLocaleString("fr-FR")}
          </div>
          <div className="mt-3 text-xl font-black">
            {`Heure${plural} Supplémentaire${plural}`}
          </div>
          <div className="mt-2 text-base font-light">
            {" "}
            {activityPage ? "Heures supplémentaires du mois" : "ce mois-ci"}
          </div>
          {hasPermissionsActivity && !activityPage ? (
            <Button
              className={`font-bold p-1 focus:outline-none text-black bg-purple-500 text-white w-full block`}
            >
              voir tout
            </Button>
          ) : (
            <div>&nbsp;</div>
          )}
        </CustomLink>
      </Block>
    </div>
  ) : null;
}
