import * as Yup from "yup";

import { Form, Formik } from "formik";
import { get, map, size } from "lodash-es";
import { useCreateOrUpdateBounty, useDeleteBounty } from "../api/bounty";

import Alert from "../components/Alert";
import Button from "../components/Button";
import EditButton from "../components/HistoryItem/EditButton";
import Input, { DateTimePickerInput } from "../components/Input";
import React from "react";
import Select from "../components/Select";
import mapFormValues from "../utils/mapFormValues";
import { useQueryTypeBounties } from "../api/typeBounties";
import { usePermissionsChecker } from "../contexts/permissions";
import TextArea from "../components/TextArea";

const validation = Yup.object().shape({
  amount: Yup.number().required("Requis"),
  date: Yup.date().required("Requis"),
  type: Yup.object().shape({
    label: Yup.string().required("Requis"),
  }),
});

function BountyForm({
  bounty,
  collaboratorIRI,
  className = "",
  onSuccess = () => {},
}) {
  const [isEditing, setIsEditing] = React.useState(!bounty);
  const { data: typeBounties } = useQueryTypeBounties();
  const [update, { error }] = useCreateOrUpdateBounty();
  const [deleteBounty] = useDeleteBounty();
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.collaborator.edit",
      "kdix.actions.collaborator.edit.agency",
      "kdix.actions.collaborator.edit.department",
      "kdix.actions.collaborator.edit.service",
    ],
  });

  if (!collaboratorIRI) return null;

  return (
    <Formik
      validationSchema={validation}
      initialValues={{
        amount: get(bounty, "amount", ""),
        comment: get(bounty, "comment", ""),
        date: get(bounty, "date") ? new Date(get(bounty, "date")) : "",
        type: {
          label: get(bounty, "type.label", ""),
          value: get(bounty, "type.@id", ""),
        },
        isRecurrent: get(bounty, "isRecurrent", false),
        rubricCode: get(bounty, "rubricCode", ""),
      }}
      onSubmit={async (values, actions) => {
        try {
          await update({
            id: get(bounty, "id", null),
            data: {
              ...mapFormValues(values),
              date: values.date,
              collaborator: collaboratorIRI,
            },
          });
          setIsEditing(false);
          actions.setSubmitting(false);
          onSuccess();
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
          actions.setSubmitting(false);
        }
      }}
    >
      {({ values, setFieldValue, isSubmitting, resetForm, touched }) => {
        return (
          <Form className={className}>
            <div className={"flex flex-wrap"}>
              <div className={`w-full ${isEditing ? "lg:w-1/3" : ""} lg:pr-2`}>
                <DateTimePickerInput
                  name="date"
                  label="Date de prime"
                  readOnly={!isEditing || readOnly}
                />
              </div>
              {isEditing ? (
                <div className={`w-full lg:w-1/3 lg:px-2`}>
                  <Input
                    type="number"
                    name="amount"
                    label="Montant"
                    readOnly={!isEditing || readOnly}
                  />
                </div>
              ) : null}
              {isEditing ? (
                <div className={"w-full lg:w-1/3 lg:pl-2"}>
                  <Select
                    label="Type"
                    name="type"
                    options={map(typeBounties, (node) => ({
                      label: node.label,
                      value: node["@id"],
                    }))}
                    value={values.type}
                    isDisabled={!isEditing || readOnly}
                    isLoading={!typeBounties}
                  />
                </div>
              ) : null}
            </div>
            <div className={"pb-2"}>
              <TextArea
                label="Commentaire"
                name="comment"
                textareaClassName="comment"
                readOnly={!isEditing || readOnly}
              />
            </div>
            {error ? (
              <div className="my-2">
                <Alert
                  type="error"
                  message={get(error, "title")}
                  details={get(error, "description")}
                />
              </div>
            ) : null}

            {bounty ? (
              <EditButton
                isSubmitting={isSubmitting}
                isEditing={isEditing}
                onDelete={() => deleteBounty(bounty.id)}
                onCancel={() => {
                  resetForm();
                  setIsEditing(!isEditing);
                }}
                onClick={() => {
                  setIsEditing(!isEditing);
                }}
              />
            ) : (
              !readOnly && (
                <Button
                  className={`btn btn--sm ml-4 shadow`}
                  readOnly={false}
                  isSubmitting={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Créer"
                  disabled={size(touched) <= 0}
                />
              )
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

export default BountyForm;
