import { queryCache, useMutation, useQuery } from "react-query";
import queryWithToken, {create, deleteById, updateById, updateOrCreate} from "./index";
import { get } from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "rentals";
//

export function useQueryRental() {
  const { data, ...rest } = useQuery(
    ressourceKey,
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}
export function useQueryLastRental(vehicleId) {
  const { data } = useQuery(
    ressourceKey,
    (_, __, params) => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
        {
          method: "GET",
          params: {
            "order[createdAt]": "desc",
            itemsPerPage: 1,
            vehicle: vehicleId,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return get(data, "hydra:member[0]");
}

export function useCreateRental() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("vehicles");
    },
  });
}

export function useUpdateRental() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: (newVehicle = null) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries([ressourceKey, data.id]);
    },
  });
}

export function useCreateOrUpdateRental() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries([ressourceKey, data.id]);
    },
  });
}

export function useDeleteRental() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("history");
      return true;
    },
  });
}
