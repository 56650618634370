import React from "react";

function Avatar({ avatar, size, className = "", title = "" }) {
  return (
    <div className={className}>
      <img
        src={avatar ? avatar : "//placehold.it/160x160"}
        className={`${
          size ? size : "w-full h-full"
        } object-center object-cover rounded-full`}
        alt=""
        title={title}
      />
    </div>
  );
}

export default Avatar;
