import { get, map } from "lodash-es";
import { getLocaleDateString } from "../../utils/date";
import { getFirstnameLastnameJob, getOnlyWork } from "../../utils/names";
import { EVALUATION_STATUS } from "../../utils/evaluationStatus";

export function alertTitleEvaluation(alert) {
  return {
    title: get(alert, "typeAlert.label", ""),
    subtitles: [
      {
        label: `Évaluation de`,
        value: getFirstnameLastnameJob(alert, "evaluation.collaborator"),
      },
      {
        label: "Site(s)",
        value: map(alert.evaluation.collaborator?.agencies, "label").join(" ,"),
      },
      {
        label: "Poste évalué",
        value: getOnlyWork(alert, "evaluation.collaborator"),
      },
      {
        label: "Auto-évaluation démarrée le",
        value: getLocaleDateString(alert, "evaluation.createdAt"),
      },
      {
        label: "État",
        value: EVALUATION_STATUS[alert.evaluation.status],
      },
    ],
  };
}
