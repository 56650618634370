import * as Yup from "yup";
import { Form, Formik } from "formik";
import {
  downloadMedicalVisitCertificate,
  useCreateMedicalVisitCertificate,
} from "../api/medicalVisitCertificates";
import { get, map, size } from "lodash-es";
import {
  useCreateMedicalVisit,
  useUpdateMedicalVisit,
} from "../api/medicalVisit";
import { DisplayCheckbox } from "../components/Checkbox";
import DateFormat from "../components/DateFormat";
import DownloadOrUpload from "../components/DownloadOrUpload";
import ExpansionPanel from "../components/ExpansionPanel";
import { DateTimePickerInput } from "../components/Input";
import React, { useState } from "react";
import {
  PermissionChecker,
  usePermissionsChecker,
  useUseExternalDocumentManagement,
} from "../contexts/permissions";
import { toast } from "react-toastify";
import EditButton from "../components/HistoryItem/EditButton";
import Button from "../components/Button";
import { dateCompare } from "../regex/date";
import FileDownloadLine from "../components/Button/FileDownloadLine";
import Textarea from "../components/TextArea";

const validationEdit = Yup.object().shape({
  able: Yup.boolean(),
  dateNextMedicalVisit: Yup.date()
    .nullable()
    .required("Requis")
    .test(
      "date-match",
      "Doit être postérieure à la date de la visite",
      function (value) {
        return !this.parent.date || dateCompare(this.parent.date, value, true);
      }
    )
    .test(
      "date-match-today",
      "Doit être postérieure à la date d'aujourd'hui",
      function (value) {
        return dateCompare(new Date(), value);
      }
    ),
  date: Yup.date().when("able", {
    is: true,
    then: Yup.date().nullable().required("Requis"),
    otherwise: Yup.date().nullable(),
  }),

  file: Yup.mixed()
    .nullable()
    .test("fileFormat", "Formats autorisés: pdf, jpg, png", (value) => {
      const authorized = ["application/pdf", "image/jpeg", "image/png"];
      return value === undefined || (value && authorized.includes(value.type));
    }),
});

export function EditMedicalVisitForm({
  medicalVisit,
  personalInformationIRI,
  handleSubmit = () => {},
}) {
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.medical_visit.edit",
      "kdix.actions.medical_visit.edit.agency",
      "kdix.actions.medical_visit.edit.department",
      "kdix.actions.medical_visit.edit.service",
      "kdix.actions.medical_visit.edit.own",
    ],
  });

  const permissionsEdit = [
    "kdix.actions.medical_visit.edit",
    "kdix.actions.medical_visit.edit.agency",
    "kdix.actions.medical_visit.edit.department",
    "kdix.actions.medical_visit.edit.service",
    "kdix.actions.medical_visit.edit.own",
  ];
  const [update] = useUpdateMedicalVisit();
  const [create] = useCreateMedicalVisit();
  const [createCertificate] = useCreateMedicalVisitCertificate();
  const certificate = get(medicalVisit, "certificate", null);
  const [isEditing, setIsEditing] = useState(!medicalVisit);
  const useExternalDocumentManagement = useUseExternalDocumentManagement();

  return (
    <div className="mb-8">
      <Formik
        validationSchema={validationEdit}
        initialValues={{
          able: get(medicalVisit, "able", false),
          comment: get(medicalVisit, "comment", ""),
          date: get(medicalVisit, "date")
            ? new Date(get(medicalVisit, "date"))
            : null,
          dateNextMedicalVisit: get(medicalVisit, "dateNextMedicalVisit")
            ? new Date(get(medicalVisit, "dateNextMedicalVisit"))
            : null,
        }}
        onSubmit={async (values, actions) => {
          try {
            let medicalVisitId = get(medicalVisit, "id", false);
            //create or update
            if (!medicalVisitId) {
              const reponse = await create({
                personalInformation: personalInformationIRI,
                able: values.able,
                comment: values.comment,
                date: values.date,
                dateNextMedicalVisit: values.dateNextMedicalVisit,
              });
              medicalVisitId = reponse.id;
            } else {
              await update({
                id: medicalVisit.id,
                data: {
                  able: values.able,
                  comment: values.comment,
                  date: values.date,
                  dateNextMedicalVisit: values.dateNextMedicalVisit,
                },
              });
            }

            if (values.file && !useExternalDocumentManagement) {
              await createCertificate({
                file: values.file,
                medicalVisitId: medicalVisitId,
              });
            }
            if (get(medicalVisit, "id", false)) {
              toast.success("Mise à jour effectuée avec succès");
              setIsEditing(false);
            } else {
              toast.success("Création effectuée avec succès");
              actions.resetForm();
            }
            handleSubmit();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, setFieldValue, resetForm }) => {
          return (
            <Form>
              <DateTimePickerInput
                label="Date de la visite"
                name="date"
                fullWidth={true}
                readOnly={!canEdit || !isEditing}
              />
              <DisplayCheckbox
                label="Apte"
                name="able"
                value={values.able}
                checked={values.able}
                className="mb-4"
                onChange={(e) => {
                  setFieldValue("able", e.target.checked);
                }}
                readOnly={!canEdit || !isEditing}
              />
              <Textarea
                divClassName="mb-4"
                textareaClassName="comment"
                name="comment"
                label="Commentaire"
                readOnly={!canEdit}
              />

              {certificate?.id && (
                <div className="mb-4">
                  <FileDownloadLine
                    label="Certificat médical"
                    downloadFn={downloadMedicalVisitCertificate.bind(
                      null,
                      certificate.id,
                      certificate.label
                    )}
                  />
                </div>
              )}

              {!useExternalDocumentManagement ? (
                <DownloadOrUpload
                  name="file"
                  accept="image/png, image/jpeg, application/pdf"
                  isSubmitting={isSubmitting}
                  submitButton={false}
                />
              ) : null}
              <DateTimePickerInput
                label="Date de la prochaine visite"
                name="dateNextMedicalVisit"
                fullWidth={true}
                readOnly={!canEdit || !isEditing}
              />

              <div className={"ml-auto"}>
                {medicalVisit ? (
                  <EditButton
                    isSubmitting={isSubmitting}
                    isEditing={isEditing}
                    onCancel={() => {
                      resetForm();
                      setIsEditing(!isEditing);
                    }}
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                    permissionsEdit={permissionsEdit}
                  />
                ) : (
                  <PermissionChecker permissions={permissionsEdit}>
                    <Button
                      className={`btn ml-4`}
                      readOnly={false}
                      isSubmitting={isSubmitting}
                      isForm={true}
                      type="submit"
                      textLabel="Créer"
                    />
                  </PermissionChecker>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

function FormatVisitTitle({ dateVisite, dateNextVisite }) {
  return (
    <>
      {dateVisite ? (
        <span>
          Visite médicale effectuée le <DateFormat date={dateVisite} />
        </span>
      ) : (
        <span>
          Visite médicale prévue le <DateFormat date={dateNextVisite} />
        </span>
      )}
    </>
  );
}

function MedicalVisitForm({
  medicalVisits = [],
  personalInformationIRI,
  setModalMedicalOpen,
}) {
  const orderdVisits = medicalVisits.sort((a, b) => {
    let date1 = a.date ? a.date : a.dateNextMedicalVisit;
    let date2 = b.date ? b.date : b.dateNextMedicalVisit;
    return date1 < date2 ? 1 : -1;
  });
  return (
    <div>
      <div className="flex items-end">
        <PermissionChecker
          permissions={[
            "kdix.actions.medical_visit.edit",
            "kdix.actions.medical_visit.edit.agency",
            "kdix.actions.medical_visit.edit.department",
            "kdix.actions.medical_visit.edit.service",
            "kdix.actions.medical_visit.edit.own",
          ]}
        >
          <div
            className="btn flex items-center justify-center leading-none focus:outline-none focus:shadow-none ml-auto"
            onClick={() => setModalMedicalOpen(true)}
          >
            Ajouter une visite médicale
          </div>
        </PermissionChecker>
      </div>
      <hr className={"my-8"} />
      {size(orderdVisits) > 0 ? (
        <div className="">
          {map(orderdVisits, (visit, index) => (
            <ExpansionPanel
              title={
                <FormatVisitTitle
                  dateVisite={visit.date}
                  dateNextVisite={visit.dateNextMedicalVisit}
                />
              }
              key={visit.id}
              open={index === 0}
            >
              <EditMedicalVisitForm
                medicalVisit={visit}
                personalInformationIRI={personalInformationIRI}
              />
            </ExpansionPanel>
          ))}
        </div>
      ) : (
        "Aucune visite médicale"
      )}
    </div>
  );
}

export default MedicalVisitForm;
