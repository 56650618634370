import {
  PermissionChecker,
  usePermissionsChecker,
  useUseExternalDocumentManagement,
} from "../../contexts/permissions";
import {
  downloadDocument,
  useCreateDocument,
  useDeleteDocument,
} from "../../api/confidentialDocuments";
import {
  useCreateFolder,
  useDeleteFolder,
} from "../../api/confidentialFolders";
import { useGetCollaboratorConfidentialFolders } from "../../api/collaborator";

import { CollaboratorContext } from "../../contexts/collaborator";
import DocumentBrowser from "../../components/DocumentBrowser";
import React from "react";
import Spinner from "../../components/Spinner";
import { get, map } from "lodash-es";

function DocumentsConfidentiels() {
  const permissionsView = [
    "kdix.actions.confidential_document.view",
    "kdix.actions.confidential_document.view.agency",
    "kdix.actions.confidential_document.view.department",
    "kdix.actions.confidential_document.view.service",
    "kdix.actions.confidential_document.view.own",
  ];
  const { collaborator } = React.useContext(CollaboratorContext);
  const idUser = get(collaborator, "data.linkedUser.id", null);
  const agencies = map(get(collaborator, "data.agencies", []), "@id");
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.personal_information.edit",
      "kdix.actions.personal_information.edit.agency",
      "kdix.actions.personal_information.edit.department",
      "kdix.actions.personal_information.edit.service",
      "kdix.actions.personal_information.edit.own",
    ],
  });
  const collaboratorId = get(collaborator, "data.id");
  const useExternalDocumentManagement = useUseExternalDocumentManagement();
  return (
    <PermissionChecker
      permissions={permissionsView}
      userId={idUser}
      agencies={agencies}
    >
      <React.Suspense fallback={<Spinner />}>
        <DocumentBrowser
          queryFolderHook={useGetCollaboratorConfidentialFolders.bind(
            null,
            get(collaborator, "data.id")
          )}
          createFolderHook={useCreateFolder.bind(
            null,
            get(collaborator, "data.@id")
          )}
          deleteFolderHook={useDeleteFolder}
          downloadDocumentFn={downloadDocument}
          createDocumentHook={useCreateDocument}
          deleteDocumentHook={useDeleteDocument}
          useExternalDocumentManagement={useExternalDocumentManagement}
          canCreate={canEdit}
          canEdit={canEdit}
          canDelete={canEdit}
          objectId={collaboratorId}
        />
      </React.Suspense>
    </PermissionChecker>
  );
}

export default DocumentsConfidentiels;
