import { DisplayInput } from "./Input";
import React from "react";
import { get } from "lodash-es";
import { Link } from "react-router-dom";

function VehicleGeneralInfosLight({ vehicle }) {
  return (
    <div>
      <DisplayInput label="Marque" value={get(vehicle, "brand", "")} />
      <DisplayInput label="Modèle" value={get(vehicle, "model", "")} />
      <DisplayInput
        label="Conducteur actuel depuis le"
        value={
          get(vehicle, "lastVehicleAttributionHistory.date")
            ? new Date(
                get(vehicle, "lastVehicleAttributionHistory.date")
              ).toLocaleDateString()
            : new Date().toLocaleDateString()
        }
      />
      <DisplayInput label="Type" value={get(vehicle, "type.label", "")} />
      <DisplayInput
        label="Numéro d'immatriculation"
        value={get(vehicle, "licencePlate", "")}
      />
      <div className={"flex ml-auto"}>
        <Link
          className="btn mt-6 block text-center ml-auto"
          to={`/rh/vehicules/${vehicle.id}`}
        >
          Voir le véhicule
        </Link>
      </div>
    </div>
  );
}

export default VehicleGeneralInfosLight;
