import GoBackBtn from "../../components/GoBackBtn";
import React, {useContext, useState} from "react";
import UploadAvatar from "../../components/UploadAvatar";
import Toggle from "../../components/Toogle";
import { Formik, Form } from "formik";
import {get, map} from "lodash-es";
import {
  useGetNextAndPrevCollaborator, useQueryCollaboratorsListAll,
  useUpdateCollaborator,
} from "../../api/collaborator";
import { toast } from "react-toastify";
import PermissionsContext, {
  PermissionChecker,
  useIsImpersonate,
  usePermissionsChecker,
  useUserId,
} from "../../contexts/permissions";
import { ReactComponent as ProcessIcon } from "../../svgs/process.svg";
import { ReactComponent as ImpersonateIcon } from "../../svgs/impersonate.svg";
import { ReactComponent as ArrowIcon } from "../../svgs/arrow-right-side.svg";
import { Link } from "react-router-dom";
import Dropdown from "../../components/Dropdown";
import { useDesktop } from "../../hooks/useDesktop";
import LinkToCollaborator from "../../components/LinkToCollaborator";
import history from "../../history";
import { useImpersonateOnUser } from "../../api/user";
import ReactSelect from "react-select";
import {useHistory} from "react-router";

function Header({
  firstname,
  lastname,
  job,
  avatar,
  avatarInfos,
  collaboratorId,
  active,
  userId,
}) {
  const permissionsEdit = [
    "kdix.actions.collaborator.edit",
    "kdix.actions.collaborator.edit.agency",
    "kdix.actions.collaborator.edit.department",
    "kdix.actions.collaborator.edit.service",
  ];
  const [updateCollaborator] = useUpdateCollaborator();
  const {
    data: [previousCollaborator, nextCollaborator],
  } = useGetNextAndPrevCollaborator(collaboratorId);
  const isDesktop = useDesktop();
  const loggedUserId = useUserId();
  const isImpersonate = useIsImpersonate();
  const permissionsLinkToCollaborator = [
    "kdix.actions.personal_information.view",
    "kdix.actions.collaborator.history.view",
    "kdix.actions.additional_information.view",
    "kdix.actions.general_document.view",
    "kdix.actions.collaborator.activity.view",
  ];
  const canImpersonate =
    usePermissionsChecker({
      permissions: [
        "kdix.actions.user.impersonate",
        "kdix.actions.user.impersonate.agency",
        "kdix.actions.user.impersonate.department",
        "kdix.actions.user.impersonate.service",
      ],
    }) &&
    !isImpersonate &&
    loggedUserId !== userId;

  const [query] = useState("");
  const { data: collaborators } = useQueryCollaboratorsListAll({
    orSearch_fullname: query,
    active: "true",
  });

  const history = useHistory();
  const currentTab = history.location.pathname.split('/').at(-1);

  return (
    <>
      <div className="bg-purple-700 text-white relative">
        <div className="pl-8 pr-4 py-2 flex items-center justify-between absolute text-white w-full top-0 always-top">
          <GoBackBtn className="-ml-4" to="/rh/collaborateurs" />
          <PermissionChecker permissions={permissionsLinkToCollaborator}>
            <div className="flex items-center">
              {previousCollaborator && (
                <LinkToCollaborator
                  isDesktop={isDesktop}
                  collaborator={previousCollaborator}
                  className="pr-3 border-r border-white"
                  currentTabCollab={currentTab}
                />
              )}
              {(nextCollaborator || previousCollaborator) && (
                  <div className="collaborator-select">
                    <ReactSelect
                        placeholder="Chercher un collaborateur"
                        options={map(collaborators, (collaborator) => {
                          return {
                            label: collaborator.firstname + ' ' + collaborator.lastname,
                            value: collaborator.id,
                          }
                        })}
                        noOptionsMessage={({ inputValue }) => {
                          return inputValue !== ""
                              ? "Aucun collaborateur pour cette recherche"
                              : "Aucun collaborateur";
                        }}
                        loadingMessage={() => "Chargement..."}
                        onChange={(collaborator, { action }) => {
                          if (action === "select-option") {
                            
                            const redirectUrl = `/rh/collaborateurs/${collaborator.value}/${currentTab}`;

                            history.push(redirectUrl);
                          }
                        }}
                        styles={{
                          option: (provided) => ({ ...provided, color: "black" }),
                        }}
                    />
                  </div>
              )}
              {nextCollaborator && (
                <LinkToCollaborator
                  isDesktop={isDesktop}
                  collaborator={nextCollaborator}
                  isNext={true}
                  className="pl-3 border-l border-white"
                  currentTabCollab={currentTab}
                />
              )}
            </div>
          </PermissionChecker>
        </div>
        <div className="hidden sm:flex pl-8 pr-4 py-2 items-center justify-between absolute text-white w-full bottom-0 z-10 mb-2 ">
          <div />
          <div className={"flex items-center gap-3"}>
            <ProcessButton collaboratorId={collaboratorId} />
            {canImpersonate && (
              <>
                <div className={"border-l border-white"}>&nbsp;</div>
                <Impersonate userId={userId} />
              </>
            )}
          </div>
        </div>
        <div className="xl:flex">
          <div className="px-8 pt-10 py-6 flex items-center xl:w-3/12 bg-purple-600 relative">
            <UploadAvatar collaboratorId={collaboratorId} avatar={avatar} avatarInfos={avatarInfos}/>
            <div className="leading-6 ml-8 z-10">
              <div className="text-xl font-bold">
                {firstname} {lastname}
              </div>
              <div className="text-sm">{job}</div>
              <PermissionChecker permissions={permissionsEdit}>
                <div>
                  <Formik
                    initialValues={{
                      active: active,
                    }}
                    onSubmit={async (values, actions) => {
                      try {

                        if(!values.active)
                        {
                           toast.info("Attention, vérifiez que ce collaborateur n'est pas un valideur de congés.");
                        }

                        await updateCollaborator({
                          id: collaboratorId,
                          data: {
                            active: values.active,
                          },
                        });

                        if(values.active)
                        {
                           toast.success("Collaborateur activé avec succès");
                        }
                        else {
                           toast.success("Collaborateur desactivé avec succès");
                        }
                      } catch (error) {
                        map(get(error, "violations"), (e) => {
                          actions.setFieldError(e.propertyPath, e.message);
                          console.log("error", e.message);
                        });

                        actions.setSubmitting(false);
                      }
                    }}
                  >
                    {({ values, setFieldValue, submitForm }) => {
                      return (
                        <Form>
                          <Toggle
                            label={values.active ? "Actif" : "Désactivé"}
                            name="active"
                            value={values.active}
                            checked={values.active}
                            onChange={(e) => {
                              setFieldValue("active", e.target.checked);
                              submitForm();
                            }}
                          />
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </PermissionChecker>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          "flex overflow-x-auto items-center gap-3 sm:hidden flex bg-purple-700 p-4 text-white w-full whitespace-no-wrap"
        }
      >
        <ProcessButton collaboratorId={collaboratorId} />
        {canImpersonate && (
          <>
            <div className={"border-l border-white"}>&nbsp;</div>
            <Impersonate userId={userId} />
          </>
        )}
      </div>
    </>
  );
}

function ProcessButton({ collaboratorId }) {
  const permissionsProcess = [
    "kdix.actions.process_in_out.edit",
    "kdix.actions.process_in_out.edit.agency",
    "kdix.actions.process_in_out.edit.department",
    "kdix.actions.process_in_out.edit.service",
    "kdix.actions.process_in_out.edit.own",
  ];
  return (
    <PermissionChecker permissions={permissionsProcess}>
      <div className="cursor-pointer">
        <Dropdown
          label={
            <div className={"flex gap-2"}>
              <ProcessIcon className={"xl:w-6 xl:h-6 w-5 h-5"} />
              Processus
            </div>
          }
          arrow={false}
          childrenClassName="bg-gray-700 text-white shadow-lg z-10 mx-2"
          childrenStyle={{ minWidth: "280px", right: "2rem" }}
          relative={false}
        >
          <Link to={`/processus/entree/new/${collaboratorId}`}>
            <div
              className={"text-white flex p-4 hover:text-black hover:bg-white"}
            >
              <ArrowIcon fill={{ fill: "white", color: "white" }} />
              <span className={"ml-2"}>Processus d'arrivée</span>
            </div>
          </Link>
          <Link to={`/processus/sortie/new/${collaboratorId}`}>
            <div
              className={"text-white flex p-4 hover:text-black hover:bg-white"}
            >
              <ArrowIcon transform="rotate(180)" />
              <span className={"ml-2"}>Processus de départ</span>
            </div>
          </Link>
        </Dropdown>
      </div>
    </PermissionChecker>
  );
}

function Impersonate({ userId }) {
  const { setToken, setActions, setModules } = useContext(PermissionsContext);
  const [impersonate] = useImpersonateOnUser();

  const handleImpersonate = async (id) => {
    const { token, actions: userActions, modules } = await impersonate(id);
    setActions(JSON.stringify(userActions));
    setModules(JSON.stringify(modules));
    setToken(token);
    history.push("/");
    window.location.href = "/";
  };
  return (
    <div
      onClick={() => handleImpersonate(userId)}
      className={"flex gap-2 cursor-pointer"}
    >
      <ImpersonateIcon className={"xl:w-6 xl:h-6 w-5 h-5"} />
      Se connecter en tant que
    </div>
  );
}

export default Header;
