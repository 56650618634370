import { useInfiniteQuery, useQuery } from "react-query";
import queryWithToken, { findById } from "./index";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "collaborators";
//

export function useQueryCanBeEvaluate(filters) {
  const params = { "exists[evaluators]": true };
  const queryKeyEvaluator = "evaluators";
  return useQueryBaseEvaluators(filters, params, queryKeyEvaluator);
}

export function useQueryEvaluators(filters) {
  const params = { "exists[collaboratorsCanBeEvaluate]": true };
  const queryKeyEvaluator = "evaluators";
  return useQueryBaseEvaluators(filters, params, queryKeyEvaluator);
}

function useQueryBaseEvaluators(filters, params, queryKeyEvaluator) {
  return useInfiniteQuery(
    [ressourceKey, queryKeyEvaluator, filters],
    (key, route, filters, page) => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
        {
          method: "GET",
          params: {
            page: page ? page : null,
            pagination: true,
            itemsPerPage: 20,
            ...filters,
            ...params,
          },
        },
      );
    },
    {
      refetchOnWindowFocus: false,
      suspense: false,
      getFetchMore: (lastGroup) => {
        const nextUrl = lastGroup["hydra:view"]["hydra:next"];
        const match = /page=(\d+)/.exec(nextUrl);
        if (match) {
          return match[1];
        }

        return false;
      },
    },
  );
}

export function useQueryEvaluatorById(id, options) {
  return useQuery(id && [`${ressourceKey}/evaluator`, id], findById, {
    refetchOnWindowFocus: false,
    enabled: !!id,
    ...options,
  });
}
