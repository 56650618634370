import Block from "../Block";
import React from "react";
import { size } from "lodash-es";
import {
  useQueryCollaboratorHomeById,
  useUpdateCollaborator,
  useGetCollaboratorEvents,
} from "../../api/collaborator";
import { Link } from "react-router-dom";
import Button from "../Button";

export default function EventsBlock({ collaboratorId }) {
  const dtLastWeek = new Date();
  dtLastWeek.setHours(0, 0, 0, 0);
  dtLastWeek.setDate(dtLastWeek.getDate() - 60);

  const { data } = useQueryCollaboratorHomeById(collaboratorId, {
    "events.startDate[after]": dtLastWeek,
  });
  const lastRead = data?.lastEventsRead ?? null;

  const { data: events } = useGetCollaboratorEvents(collaboratorId);
  const nbEvents = size(events);

  const nbNewEvents = events
    ? size(
        events.filter((evt) => {
          return (
            lastRead !== null &&
            new Date(lastRead).getTime() < new Date(evt.createdAt).getTime()
          );
        }),
      )
    : 0;
  const [updateCollaborator] = useUpdateCollaborator();

  return (
    <Block className="text-center flex-grow bg-white">
      <Link
        to={`/rh/collaborateurs/${collaboratorId}/historique`}
        className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
      >
        <div
          className={`inline-flex bg-green-900 text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
        >
          {nbEvents}
        </div>
        {nbNewEvents && nbNewEvents > 0 ? (
          <span
            className="unread bg-red-700 rounded-full text-white text-xs absolute w-5 h-5 ml-1 -mt-3"
            style={{ marginLeft: "43px", marginTop: "5px" }}
          >
            &nbsp;
          </span>
        ) : null}
        <div className="mt-3 text-xl font-black">{`Nouveaux évènements`}</div>
        <Button
          className={`font-bold p-1 focus:outline-none text-white w-full block`}
          onClick={async () => {
            try {
              await updateCollaborator({
                id: collaboratorId,
                data: {
                  lastEventsRead: new Date(),
                },
              });
            } catch (error) {
              console.log("Impossible de maj le collaborateur ");
            }
          }}
        >
          Voir le détail
        </Button>
      </Link>
    </Block>
  );
}
