import {PermissionChecker, useCompanies, useDomain, usePermissionsChecker} from "../contexts/permissions";
import { downloadDocument, previewDocument } from "../api/organizationDocument";
import DocumentBrowser from "../components/DocumentBrowser";
import React from "react";
import Spinner from "../components/Spinner";
import { CollaboratorContext } from "../contexts/collaborator";
import { get } from "lodash-es";
import { Helmet } from "react-helmet";
import { useGetOrganizationFolders } from "../api/domain";
import { HeaderBar } from "../components/HeaderBar";

function OrganizationDocuments() {
  const permissionsView = ["kdix.actions.documents_link.view", "kdix.actions.documents_link.view_all"];
  const canViewAll = usePermissionsChecker({permissions: ["kdix.actions.documents_link.view_all"]});
  const { collaborator } = React.useContext(CollaboratorContext);
  const collaboratorId = get(collaborator, "data.id");
  const domainId = useDomain();
  const companies = useCompanies();

  React.useEffect(() => {
          var _paq = window._paq = window._paq || [];
          _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
      
          var u="//matomo.kdix.pockost.com/";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', '1']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);

    }, [])
    
  return (
    <PermissionChecker permissions={permissionsView}>
      <div>
        <Helmet>
          <title>Documents d'entreprise</title>
        </Helmet>
        <HeaderBar sticky={true} smaller={false}
         title={
          <>
            Documents d'entreprise
          </>
        }>
        </HeaderBar>

        <div>
          <div className="px-8 pb-48 mt-8">
            <React.Suspense fallback={<Spinner />}>
              <DocumentBrowser
                queryFolderHook={useGetOrganizationFolders.bind(null, domainId, canViewAll ? null : companies)}
                downloadDocumentFn={downloadDocument}
                previewDocumentFn={previewDocument}
                documentsLabel="organizationDocuments"
                objectId={collaboratorId}
                useExternalDocumentManagement={true}
                canViewAll={canViewAll}
              />
            </React.Suspense>
          </div>
        </div>
      </div>
    </PermissionChecker>
  );
}

export default OrganizationDocuments;
