import { filter, map, reduce } from "lodash-es";
import AbsenceLine from "./AbsenceLine";
import React from "react";
import WeekPlanningTable from "./WeekPlanningTable";
import MonthPlanningTable from "./MonthPlanningTable";
import QuarterPlanningTable from "./QuarterPlanningTable";
import { useQueryAbsences } from "../../api/absence";
import { useDesktop } from "../../hooks/useDesktop";
import {useDomain} from "../../contexts/permissions";

export function calcTotalDaysQuarter(days) {
  return reduce(
    days,
    (total, day) => {
      total += day.nbDays;
      return total;
    },
    0
  );
}

function getTotalDays(type, days) {
  let totalDays = 7;
  if (type === "month") totalDays = days.length * 7;
  if (type === "quarter") totalDays = calcTotalDaysQuarter(days);
  return totalDays;
}

export default function PlanningTable({
  type,
  collaborator,
  currentDay,
  days,
  absenceState,
}) {
  const currentDomainId = useDomain();
  const { data: absences } = useQueryAbsences(
      {
        ...getFiltersByType(currentDay, type),
        "domain": currentDomainId,
        "type.noPlanning": false,
      }
  );
  const { data: absencesRecurrent} = useQueryAbsences(
      {
        ...getFiltersByType(currentDay, type),
        "domain": currentDomainId,
        "recurrent": true,
      }
  );
  const absencesList = absences.concat(absencesRecurrent);
  const totalDays = getTotalDays(type, days);
  const findAbsences = filter(absencesList, (a) => {
    return a.collaborator?.["@id"] === collaborator["@id"];
  });
  const isDesktop = useDesktop();

  return (
    <div
      className={`weekPlanningGrid gridRepeat-${totalDays} bg-white odd:bg-gray-60 relative`}
    >
      <div
        className={`py-3 border-r border-gray-150 ${
          isDesktop ? "flex items-center gap-2" : ""
        }`}
      >
        <div className="text-sm font-bold">
          {collaborator.lastname} {collaborator.firstname}
        </div>
        {isDesktop && <div>-</div>}
        <div className="text-gray-600 text-xs">
          {collaborator.currentContract?.job?.label}
        </div>
      </div>
      {type === "week" && <WeekPlanningTable days={days} />}
      {type === "month" && <MonthPlanningTable days={days} />}
      {type === "quarter" && <QuarterPlanningTable days={days} />}
      {map(findAbsences, (findAbsence, key) => (
        <AbsenceLine
          key={key}
          absenceState={absenceState}
          maxDays={totalDays}
          findAbsence={findAbsence}
          type={type}
          days={days}
        />
      ))}
    </div>
  );
}

function getFiltersByType(currentDay, type) {
  if (type === "week") {
    const firstDayOfWeek = currentDay.clone().startOf("week");
    const endDayOfWeek = currentDay.clone().endOf("week");
    return {
      "endDate[after]": firstDayOfWeek.toDate(),
      "startDate[before]": endDayOfWeek.toDate(),
    };
  }
  if (type === "month") {
    const firstDayOfMonth = currentDay.clone().startOf("month").startOf("week");
    const endDayOfMonth = currentDay.clone().endOf("month").endOf("week");
    return {
      "endDate[after]": firstDayOfMonth.toDate(),
      "startDate[before]": endDayOfMonth.toDate(),
    };
  }
  if (type === "quarter") {
    const firstDayOfMonth = currentDay.clone().startOf("quarter");
    const endDayOfMonth = currentDay.clone().endOf("quarter");
    return {
      "endDate[after]": firstDayOfMonth.toDate(),
      "startDate[before]": endDayOfMonth.toDate(),
    };
  }
}
