import { queryCache, useInfiniteQuery, useMutation } from "react-query";
import { create, deleteById, find, findExport } from "./index";

import {flatMap} from "lodash-es";
import fileDownload from "js-file-download";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "home_messages";
//

export function useInfiniteQueryHomeMessages(filters) {
  const { data, ...rest } = useInfiniteQuery(
      [ressourceKey, { ...filters }],
    find,
    {
      refetchOnWindowFocus: false,
      getFetchMore: (lastGroup) => {
        const nextUrl = lastGroup["hydra:view"]["hydra:next"];
        const match = /page=(\d+)/.exec(nextUrl);
        if (match) {
          return match[1];
        }
        return false;
      },
    }
  );

  const messages = flatMap(data, (page) => page["hydra:member"]);

  return {
    ...rest,
    messages: messages,
  };
}

export function useCreateHomeMessage() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries([ressourceKey]);
    },
  });
}

export function useDeleteMessage() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export async function downloadHomeMessagesExport(filters) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, "export_home_messages.csv");
}
