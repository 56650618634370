import React from "react";
import { Helmet } from "react-helmet";

import Block from "../components/Block";
import GoBackBtn from "../components/GoBackBtn";
import CreateCollaboratorForm from "../forms/CreateCollaboratorForm";

function CreateCollaborator() {
  return (
    <div className="p-8 pb-0">
      <Helmet>
        <title>Création d'un collaborateur</title>
      </Helmet>
      <Block>
        <GoBackBtn to="/rh/collaborateurs" className="flex items-center">
          <div className="font-bold">Retour</div>
        </GoBackBtn>

        <div className="max-w-lg mx-auto">
          <div className="text-2xl font-bold mb-8 mt-8">
            Créer un collaborateur
          </div>
          <CreateCollaboratorForm />
        </div>
      </Block>
    </div>
  );
}

export default CreateCollaborator;
