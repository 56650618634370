import React from "react";
import { noop } from "lodash-es";
const styles = {
  default: {
    button: "bg-green-600",
  },
};

function LargeButtonAction({
  onClick = noop,
  title,
  customStyle = "default",
  Icon,
}) {
  const style = styles[customStyle];
  const [isSubmitting, setSubmitting] = React.useState(false);
  return (
    <div
      className={`${style["button"]} cursor-pointer rounded w-half sm:ml-4 ml-1 flex flex-col items-center md:w-largeBtn w-40 h-16 justify-around py-2 relative`}
      onClick={async () => {
        setSubmitting(true);
        await onClick();
        setSubmitting(false);
      }}
    >
      {isSubmitting ? (
        <div className={`absolute inset-0 mt-4`}>
          <div className="loader h-6 w-6 mx-auto"></div>
        </div>
      ) : (
        <>
          {Icon && <Icon width={"26px"} height={"26px"} />}
          <span className={"font-bold md:text-sm sm:text-md text-xxs"}>
            {title}
          </span>
        </>
      )}
    </div>
  );
}

export default LargeButtonAction;
