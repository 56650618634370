import Block from "../Block";
import React from "react";
import {
  useQueryCountYearWorkAccidents,
  useQueryLastWorkAccident,
} from "../../api/workAccident";
import { get } from "lodash-es";
import { getDaysBetweenDate } from "../../utils/date";
import { Link } from "react-router-dom";
import Button from "../Button";

function getNbDaysSinceAccident(dateAccident = null) {
  if (!dateAccident) {
    return "-";
  }
  const today = new Date();
  return getDaysBetweenDate(today, new Date(dateAccident));
}

function WorkAccidentBlock() {
  const { count: nbAccident } = useQueryCountYearWorkAccidents();
  const { data: lastWorkAccident } = useQueryLastWorkAccident();

  const nbDay = getNbDaysSinceAccident(get(lastWorkAccident, "date", null));
  const isNew = (nbDay <=7 ) ;

  return lastWorkAccident ? (
    <div className={"w-full md:w-1/3 p-2 h-64"}>
      <Block className="text-center flex-grow bg-white">
        <Link
          to={`/prevention/accidents-travail`}
          className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
        >
          <div
            className={`bg-purple-400 inline-flex text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
          >
            {nbAccident}
          </div>
          {isNew && (
            <span
              className="unread bg-red-700 rounded-full text-white text-xs absolute w-5 h-5 ml-1 -mt-3"
              style={{ marginLeft: "43px", marginTop: "5px" }}
            >
              &nbsp;
            </span>
          )}
          <div className="mt-3 text-xl font-black">
            {`Accident${nbAccident > 0 ? "s" : ""} du travail`}
          </div>
          <div className="mt-2 text-base font-light">
            {`${nbDay} jour${nbDay > 0 ? "s" : ""} sans accident du travail`}
          </div>
          <Button
            className={`font-bold p-1 focus:outline-none btn--outline--reversed w-full block`}
          >
            voir tout
          </Button>
        </Link>
      </Block>
    </div>
  ) : null;
}

export default WorkAccidentBlock;
