import React from "react";
import { Helmet } from "react-helmet";

import Block from "../../components/Block";
import GoBackBtn from "../../components/GoBackBtn";
import CreateAccreditationForm from "../../forms/CreateAccreditationForm";

function CreateAccreditation() {
  return (
    <div className="p-8 pb-0">
      <Helmet>
        <title>Création d'une habilitation</title>
      </Helmet>
      <Block>
        <GoBackBtn
          to="/rh/habilitations/catalogue"
          className="flex items-center"
        >
          <div className="font-bold">Retour</div>
        </GoBackBtn>

        <div className="max-w-lg mx-auto">
          <div className="text-2xl font-bold mb-8 mt-8">
            Créer une habilitation
          </div>
          <CreateAccreditationForm />
        </div>
      </Block>
    </div>
  );
}

export default CreateAccreditation;
