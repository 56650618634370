import React from "react";
import { Form, Formik } from "formik";
import { get, map, noop } from "lodash-es";
import { toast } from "react-toastify";
import Input from "../components/Input";
import Select from "../components/Select";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { useCreateOrUpdateUser, useDeleteUser } from "../api/user";
import { useQueryPermissions } from "../api/permissions";
import { PermissionChecker } from "../contexts/permissions";

export default function UserForm({
  user = null,
  readOnly = false,
  onSuccess = noop,
  profileSelected = null,
}) {
  const { data: permissions } = useQueryPermissions();
  const [updateUser, { error }] = useCreateOrUpdateUser();
  const [deleteUser] = useDeleteUser();
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          username: get(user, "username", ""),
          permission: user
            ? get(user, "permission[@id]", "") !== ""
              ? {
                  label: get(user, "permission.label", ""),
                  value: get(user, "permission[@id]"),
                }
              : null
            : profileSelected
            ? {
                label: profileSelected.label,
                value: profileSelected["@id"],
              }
            : null,
          password: "",
        }}
        onSubmit={async ({ permission, password, ...values }, actions) => {
          try {
            await updateUser({
              id: user ? user.id : null,
              data: {
                ...values,
                password: password === "" ? null : password,
                permission:
                  get(permission, "value", "") !== ""
                    ? get(permission, "value")
                    : null,
              },
            });
            onSuccess();
            toast.success("Mise à jour effectuée avec succès");
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <Input
                type="text"
                name="username"
                label="Nom d'utilisateur"
                readOnly={readOnly}
              />
              <Select
                name="permission"
                label="Profil"
                options={map(permissions, (item) => ({
                  label: item.label,
                  value: item["@id"],
                }))}
                isClearable
              />

              <Input
                type="password"
                name="password"
                label="Mot de passe"
                placeholder={
                  user
                    ? "Tapez ici pour changer le mot de passe"
                    : "Tapez ici le mot de passe"
                }
                readOnly={readOnly}
              />

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}
              <div className="flex gap-3">
                <Button
                  className={`btn mt-6 `}
                  readOnly={readOnly}
                  isSubmitting={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel={user ? "Enregistrer" : "Créer"}
                />
                <PermissionChecker
                  permissions={["kdix.actions.user.manage"]}
                  notAllowed={null}
                >
                  {user && (
                    <Button
                      type="button"
                      className={`btn mt-6  btn--error `}
                      readOnly={readOnly}
                      onClick={async () => {
                        const res = window.confirm(
                          "La suppression sera définitive"
                        );
                        if (res) {
                          await deleteUser(user.id);
                          onSuccess();
                        }
                      }}
                    >
                      Supprimer
                    </Button>
                  )}
                </PermissionChecker>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
