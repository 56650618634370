import { useQuery } from "react-query";
import queryWithToken from "./index";

import { get } from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "type_rentals";
//

export function useQueryTypeRentals() {
  const { data, ...rest } = useQuery(
    ressourceKey,
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false
        }
      }),
    {
      refetchOnWindowFocus: false
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest
  };
}
