import {
  useCreateTypeBadges,
  useDeleteTypeBadge,
  useUpdateTypeBadge,
  useQueryTypeBadges,
} from "../api/typeBadges";

import ManageResource from "../components/ManageResource";
import React from "react";

function BadgesAdmin() {
  return (
    <ManageResource
      resourceLabel="Badges"
      createLabel="Ajouter un badge"
      noElementsLabel="Aucun badge"
      createHook={useCreateTypeBadges}
      queryHook={useQueryTypeBadges}
      deleteHook={useDeleteTypeBadge}
      updateHook={useUpdateTypeBadge}
      fieldTitle="title"
      colorField="color"
      params={{
        title: {
          label: "Nom",
          type: "text",
          resourceValueKey: "title",
        },
        color: {
          label: "Couleur",
          type: "color",
          resourceValueKey: "color",
          initialValue: "#000",
        },
        hasAlerts: {
            label: "Alerte fin de badge",
            type: "checkbox",
            resourceValueKey: "hasAlerts",
            initialValue: false,
        },
      }}
    />
  );
}

export default BadgesAdmin;
