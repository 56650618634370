import React from "react";
import { Helmet } from "react-helmet";

import Block from "../../components/Block";
import GoBackBtn from "../../components/GoBackBtn";
import CreateFormationForm from "../../forms/Formation/CreateFormation";

function CreateFormation() {
  return (
    <div className="p-8 pb-0">
      <Helmet>
        <title>Création d'une formation</title>
      </Helmet>
      <Block>
        <GoBackBtn to="/rh/formations/catalogue" className="flex items-center">
          <div className="font-bold">Retour</div>
        </GoBackBtn>

        <div className="max-w-lg mx-auto">
          <div className="text-2xl font-bold mb-8 mt-8">
            Créer une formation
          </div>
          <CreateFormationForm />
        </div>
      </Block>
    </div>
  );
}

export default CreateFormation;
