import React, { useState } from "react";
import { flatMap, get, map, size } from "lodash-es";
import { useToggle } from "react-use";
import Alert from "../../components/Alert";
import ExpansionPanel from "../../components/ExpansionPanel";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import Spinner from "../../components/Spinner";
import { useDesktop } from "../../hooks/useDesktop";
import Modal from "../../components/Modal";
import { useQueryInfiniteLokoaRequestsPrevention } from "../../api/lokoaRequests";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import DatePicker from "react-datepicker";
import { getLocaleDateString } from "../../utils/date";
import {
  getFirstnameLastname,
  getFirstnameLastnameJob,
  getOnlyJob,
} from "../../utils/names";
import { HeaderBar } from "../../components/HeaderBar";
import InfoTooltip from "../../components/InfoTooltip";
import { CSVLink } from "react-csv";

function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}

function RequestPreventionMobileCard({ request }) {
  return (
    <ExpansionPanel
      key={request.id}
      title={getFirstnameLastnameJob(request, "createdBy")}
      subtitle={getLocaleDateString(request, "createdAt")}
    ></ExpansionPanel>
  );
}

function InlineBLockContentSortable({
  children,
  setOrderFilter,
  colName,
  className = "",
  style = {},
}) {
  return (
    <div
      className={`px-4 flex items-center inl ine-col-sortable ${className}`}
      style={style}
      onClick={() => {
        setOrderFilter((orderFilter) => {
          const order = orderFilter[`order[${colName}]`];
          return {
            [`order[${colName}]`]: order === "asc" ? "desc" : "asc",
          };
        });
      }}
    >
      {children}
    </div>
  );
}

function MessagesPreventionchantiersList({
  dateRange: { endDate, startDate },
}) {
  const readOnlyRequest = false;

  const filterDate =
    endDate !== null
      ? {
          "createdAt[after]": new Date(startDate),
          "createdAt[before]": new Date(endDate),
        }
      : {};
  const isDesktop = useDesktop();
  const [orderFilter, setOrderFilter] = useState({
    "order[createdAt]": "desc",
  });
  const { data, fetchMore, canFetchMore } =
    useQueryInfiniteLokoaRequestsPrevention({
      pagination: false,
      "requestType.label": "Prévention",
      ...filterDate,
      ...orderFilter,
    });

  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucun message chantier" />
      </div>
    );
  }
  const requests = flatMap(data, (page) => page["hydra:member"]);
  const gridTemplateColumn = "14% 8% 8% 35% 14% 21%";

  const csvHeaders = [
    { label: "Date", key: "dateFr" },
    { label: "Auteur", key: "authorFullName" },
    { label: "Emploi", key: "authorJob" },
    { label: "Site", key: "authorAgencies" },
    { label: "Numéro Projet", key: "idProject" },
    { label: "Projet", key: "noProject" },
    { label: "Message", key: "commentRequest" },
    { label: "Auteur réponse", key: "authorResponse" },
    { label: "Date réponse", key: "dateResponse" },
    { label: "Réponse", key: "commentResponse" },
  ];

  const csvData = map(requests, (item) => {
    const authorAgencies = map(
      item.createdBy.agencies,
      (agency) => agency.label,
    ).sort();
    return {
      ...item,
      dateFr: item.createdAt
        ? new Date(item.createdAt).toLocaleDateString()
        : "",
      authorFullName: getFirstnameLastname(item, "createdBy"),
      authorJob: getOnlyJob(item, "createdBy"),
      authorAgencies: authorAgencies.join("/"),
      idProject: item.lokoaProject.id,
      noProject: item.lokoaProject.label,
      commentRequest: item.message,
      authorResponse: item.lokoaRequestAnswers
        ? getFirstnameLastname(item, "lokoaRequestAnswers.0.createdBy")
        : "",
      dateResponse: item.lokoaRequestAnswers
        ? getLocaleDateString(item, "lokoaRequestAnswers.0.createdAt")
        : "",
      commentResponse: get(item, "lokoaRequestAnswers.0.message", ""),
    };
  });

  return (
    <div className="relative">
      <div className="absolute -top-16 py-4 right-0">
        <CSVLink
          filename="messages_chantier_prevention.csv"
          className="btn-export"
          headers={csvHeaders}
          data={csvData}
        >
          Exporter
        </CSVLink>
      </div>
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"createdBy"}
          >
            Auteur
          </InlineBLockContentSortable>
          <InlineBLockContent>Site</InlineBLockContent>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"createdAt"}
          >
            Date demande
          </InlineBLockContentSortable>
          <InlineBLockContent>Demande</InlineBLockContent>
          <InlineBLockContent>Réponse de</InlineBLockContent>
          <InlineBLockContent>Réponse</InlineBLockContent>
        </div>
      )}

      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {isDesktop
          ? map(requests, (node) => {
              const firstComment = get(
                node,
                "lokoaRequestAnswers.0.message",
                "",
              );

              return (
                <div
                  key={node.id}
                  className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2 cursor-pointer`}
                  style={{
                    gridTemplateColumns: gridTemplateColumn,
                  }}
                >
                  <InlineBLockContent>
                    {getFirstnameLastname(node, "createdBy")}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    <div className={"flex flex-col"}>
                      {map(node.createdBy.agencies, (agency, key) => (
                        <div key={key} className="mr-2">
                          {agency.label}
                        </div>
                      ))}
                    </div>
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {getLocaleDateString(node, "createdAt")}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    <InfoTooltip message={node.message} left onHover>
                      {node.message && (
                        <>
                          {node.message.substring(0, 100)}
                          {node.message.length > 100 ? "..." : ""}
                        </>
                      )}
                    </InfoTooltip>
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {firstComment && (
                      <>
                        {getFirstnameLastname(
                          node,
                          "lokoaRequestAnswers.0.createdBy",
                        )}
                      </>
                    )}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {firstComment && (
                      <>
                        Le{" "}
                        {getLocaleDateString(
                          node,
                          "lokoaRequestAnswers.0.createdAt",
                        )}{" "}
                        : {firstComment}
                      </>
                    )}
                  </InlineBLockContent>
                </div>
              );
            })
          : map(requests, (node) => (
              <RequestPreventionMobileCard
                key={node.id}
                request={node}
                readOnly={readOnlyRequest}
              />
            ))}
      </InfiniteScroll>
    </div>
  );
}

function MessagesPreventionchantiers() {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: null,
  });
  const [filtersOpen, toggleFilters] = useToggle(false);

  React.useEffect(() => {
    var _paq = (window._paq = window._paq || []);
    _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);

    var u = "//matomo.kdix.pockost.com/";
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setSiteId", "1"]);
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = u + "matomo.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Messages de prévention chantiers</title>
      </Helmet>
      <HeaderBar
        title={<>Messages de prévention chantiers</>}
        sticky={true}
        smaller={true}
      >
        <div></div>
      </HeaderBar>

      <div>
        <div className="px-8 mt-8 mb-4">
          <button
            className="flex items-center"
            onClick={() => toggleFilters(true)}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>
          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            isOpen={filtersOpen}
            onRequestClose={() => toggleFilters(false)}
          >
            <div className="-mx-4 px-5">
              <div className="font-black mb-3">Sélectionner une période</div>
              <hr className="my-2" />
              <div className="text-center">
                <DatePicker
                  selected={dateRange.startDate}
                  onChange={([startDate, endDate]) => {
                    setDateRange({
                      startDate: startDate,
                      endDate: endDate,
                    });
                  }}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  selectsRange
                  inline
                />
              </div>
            </div>

            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  setDateRange({
                    startDate: new Date(),
                    endDate: null,
                  });
                }}
              >
                Supprimer les filtres
              </div>
            </div>

            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <MessagesPreventionchantiersList dateRange={dateRange} />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default MessagesPreventionchantiers;
