import Input, { DateTimePickerInput } from "../components/Input";
import React from "react";

export default function Handicap({ readOnly }) {
  return (
    <div>
      <Input
        type="text"
        label="Taux en pourcentage"
        name="handicap.rate"
        readOnly={readOnly}
      />
      <DateTimePickerInput
        label="Date de la reconnaissance du handicap"
        name="handicap.dateRecognition"
        disabled={readOnly}
      />
      <DateTimePickerInput
        label="Fin de l'invalidité"
        name="handicap.delayInvalidity"
        disabled={readOnly}
      />
      <Input
        type="text"
        label="Commentaire"
        name="handicap.comment"
        readOnly={readOnly}
      />
    </div>
  );
}
