import { queryCache, useMutation, useQuery } from "react-query";

import queryWithToken from "./index";
import { toast } from "react-toastify";
import React from "react";
import mime from "mime-types";
import { get } from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "event_documents";
//

export function useGetDocument(id) {
  const [link, setLink] = React.useState(null);
  const { data: blob } = useQuery(id && [ressourceKey, id], () =>
    queryWithToken(
      `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/document`,
      {
        method: "GET",
        responseType: "blob",
      }
    )
  );

  React.useEffect(() => {
    if (blob) {
      let objectUrl = window.URL.createObjectURL(blob);
      setLink(objectUrl);
    }
  }, [blob]);

  return link;
}

export function useCreateDocument() {
  return useMutation(
    ({ file, eventId }) => {
      const data = new FormData();
      data.append("file", file);
      data.append("eventId", eventId);

      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
        {
          method: "POST",
          data,
        }
      );
    },
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de l'envoi");
      },
      onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
      },
      onSuccess: () => {
        queryCache.refetchQueries(ressourceKey);
        queryCache.refetchQueries("history");
      },
    }
  );
}
export function useDeleteEventDocument() {
  return useMutation(
    (id) =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}`, {
        method: "DELETE",
      }),
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de la suppression");
      },
      onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
      },
      onSuccess: () => {
        queryCache.refetchQueries(ressourceKey);
      },
    }
  );
}

export function useGetEventDocuments(id) {
  const { data, isFetching } = useQuery(
    id,
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: { data },
    isFetching,
  };
}

export function useQueryEventDocuments(filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export async function downloadEventDocument(
  id,
  label = "document",
  extension = "pdf"
) {
  const blob = await queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/document`,
    {
      method: "GET",
      responseType: "blob",
    }
  );
  if (blob) {
    const type = mime.lookup(extension);
    const file = new Blob([blob], { type });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}
