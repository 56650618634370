import { Helmet } from "react-helmet";
import React from "react";
import {useCollaboratorId, useDomain} from "../contexts/permissions";
import Spinner from "../components/Spinner";
import { HeaderBar } from "../components/HeaderBar";
import {useDesktop} from "../hooks/useDesktop";
import {ButtonMessage} from "./Collaborator/Messages";
import {get, map, sortBy} from "lodash-es";
import {useQueryLokoaRequests} from "../api/lokoaRequests";
import {useToggle} from "react-use";
import Block from "../components/Block";
import {useUpdateLokoaRequestAnswer} from "../api/lokoaRequestAnswers";

function LokoaRequests() {
  const collaboratorId = useCollaboratorId();
  const domainId = useDomain();
  const isDesktop = useDesktop();
  const [messageList, setMessageList] = React.useState("noAnswer");

  const noAnswersFilter = { domain: domainId, createdBy: collaboratorId, 'exists[lokoaRequestAnswers]': false };
  const unseenAnswersFilter = { domain: domainId, createdBy: collaboratorId, 'lokoaRequestAnswers.viewed': false, 'exists[lokoaRequestAnswers]': true };

  const { data: requestsWithoutAnswers } = useQueryLokoaRequests(noAnswersFilter);
  const { data: requestsWithUnseenAnswers } = useQueryLokoaRequests(unseenAnswersFilter);

  return (
    <div>
      <Helmet>
        <title>Demandes de chantier</title>
      </Helmet>
      <HeaderBar>Demandes de chantier</HeaderBar>
      <div
        className={"mx-auto px-0 sm:px-8 md:px-24 xl:px-0"}
      >
        <div className="px-8 mt-4">
          <React.Suspense fallback={<Spinner />}>
            {isDesktop ? (
                <div className="grid grid-cols-2 py-6">
                    <div className="text-lg text-center uppercase">
                        En attente
                    </div>
                    <div className="text-lg text-center uppercase">
                        Avec réponses non lues
                    </div>
                </div>
            ) : (
                <div className="py-3 flex justify-center gap-3">
                    <ButtonMessage
                        active={messageList === "noAnswer"}
                        onClick={() => {
                            setMessageList("noAnswer");
                        }}
                    >
                        En attente
                    </ButtonMessage>
                    <ButtonMessage
                        active={messageList === "unseenAnswer"}
                        onClick={() => {
                            setMessageList("unseenAnswer");
                        }}
                    >
                        Avec réponses non lues
                    </ButtonMessage>
                </div>
            )}

            <div className="xl:grid xl:grid-cols-2 xl:gap-5">
                <div className={!isDesktop && messageList === "noAnswer" ? "hidden" : ""}>
                    <React.Suspense fallback={<Spinner />}>
                        {map(requestsWithoutAnswers, (request, index) => {
                            return (
                                <SingleRequest
                                    key={index}
                                    request={request}
                                    isDesktop={isDesktop}
                                />
                            )
                        })}
                    </React.Suspense>
                </div>
                <div
                    className={!isDesktop && messageList === "unseenAnswer" ? "hidden" : ""}
                >
                    <React.Suspense fallback={<Spinner />}>
                        {map(requestsWithUnseenAnswers, (request, index) => (
                            <SingleRequest
                                key={index}
                                request={request}
                                hasAnswer={true}
                                isDesktop={isDesktop}
                            />
                        ))}
                    </React.Suspense>
                </div>
            </div>
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

function SingleRequest({request, hasAnswer = false, customStyle = "default"}) {
    const [on, toggle] = useToggle(false);
    const requestAnswers = sortBy(get(request, 'lokoaRequestAnswers', []), ['id']);
    const [updateHook] = useUpdateLokoaRequestAnswer();

    return (
        <div
            className={`bg-white shadow mb-4 xl:mb-3`}
        >
            <Block
                className={`${hasAnswer ? "hover:shadow-md" : ""}`}
                customStyle={`p-4`}
            >
                <div
                    className={`${hasAnswer ? "cursor-pointer" : ""}`}
                    onClick={() => {
                        toggle();
                    }}
                >
                    <div
                        className={`flex items-center border-b border-gray-100 pb-2 ${hasAnswer ? 'cursor-pointer' : ''}`}
                    >
                        <div className="w-full flex justify-between">
                            <div className="flex gap-6 items-center">
                                <div>
                                <span className="font-bold text-lg">
                                    {get(request, 'lokoaProject.label')}
                                </span>
                                    <div className="text-lg">{get(request, "createdBy.lastname").toUpperCase()} {get(request, "createdBy.firstname")}</div>
                                    <div className="text-gray-400">
                                        {new Date(get(request, "createdAt")).toLocaleDateString()}
                                    </div>
                                </div>
                            </div>

                            <div className="mr-8">
                            <span className="bg-blue-400 text-white text-xs px-2 py-1 rounded-full tracking-wide mr-1">
                                {get(request, 'requestType.label')}
                            </span>
                                {!hasAnswer ? (
                                    <span className="bg-orange-400 text-white text-xs px-2 py-1 rounded-full tracking-wide">
                                    En attente de réponse
                                </span>
                                ) : (
                                    <span className="bg-orange-400 text-white text-xs px-2 py-1 rounded-full tracking-wide">
                                    Réponse non lue
                                </span>
                                )}
                            </div>
                        </div>
                        {hasAnswer ? (
                            <div className="ml-auto">
                                <svg
                                    className={`transform fill-current text-green-600 ${
                                        on ? "rotate-180" : ""
                                    }`}
                                    width="20"
                                    height="12"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
                                    />
                                </svg>
                            </div>
                        ) : null}
                    </div>
                    <div className={`block`}>
                        <div className={`pt-4`}>
                            <div className="message-returns overflow-hidden">
                                {get(request, 'message')}
                            </div>
                        </div>
                    </div>
                </div>
                {map(requestAnswers, (answer) => {
                    return (
                        <Block
                            className={`${on ? '' : 'hidden'}`}
                            customStyle={`p-4`}
                            key={answer.id}
                        >
                            <div
                                className={`flex items-center border-b border-gray-100 pb-2`}
                            >
                                <div className="w-full flex justify-between">
                                    <div className="flex gap-6 items-center">
                                        <div>
                                            <div className="text-lg">{get(answer, "createdBy.lastname").toUpperCase()} {get(answer, "createdBy.firstname")}</div>
                                            <div className="text-gray-400">
                                                {new Date(get(answer, "createdAt")).toLocaleDateString()}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mr-8">
                                        {!get(answer, "viewed", false) ? (
                                            <span
                                                className="bg-orange-400 text-white text-xs px-2 py-1 rounded-full tracking-wide hover:bg-orange-700 cursor-pointer"
                                                onClick={async () => {
                                                    try {
                                                        await updateHook({
                                                            id: get(answer, "id"),
                                                            data: {
                                                                viewed: true,
                                                            },
                                                        });
                                                    } catch (error) {}
                                                }}
                                            >
                                                    Marquer comme lu
                                            </span>
                                        ) : (
                                            <span className="bg-green-700 text-white text-xs px-2 py-1 rounded-full tracking-wide">
                                                Lu
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className={`block`}>
                                <div className={`pt-4`}>
                                    <div className="message-returns overflow-hidden">
                                        {get(answer, 'message')}
                                    </div>
                                </div>
                            </div>
                        </Block>
                    );
                })}
            </Block>
        </div>
    );
}

export default LokoaRequests;
