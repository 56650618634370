import React from "react";
import { Form, Formik } from "formik";
import { filter, get, map, isEmpty } from "lodash-es";
import * as Yup from "yup";
import Input from "../components/Input";
import Button from "../components/Button";
import { useIsSilae } from "../contexts/permissions";

Yup.addMethod(Yup.array, "unique", function (propertyPath, mapper = (a) => a) {
  return this.test("unique", "", function (list) {
    const lowerCaseList = new Set(list.map(mapper));
    if (list.length === lowerCaseList.length) {
      //Pas besoin de partir dans le test si tout est ok
      return true;
    } else {
      //S'il y a un écart on regarde les lignes impactées
      const errors = [];
      list.forEach((item, index) => {
        const propertyValue = get(item, propertyPath, "").toLowerCase().trim();
        if (
          propertyValue !== "" &&
          filter(list, (elem) => {
            if (!elem[propertyPath]) {
              //On test déjà les éléments vides ailleurs, on les ignore donc ici
              return false;
            }
            return elem[propertyPath].toLowerCase().trim() === propertyValue;
          }).length > 1
        ) {
          errors.push(
            this.createError({
              path: `${this.path}[${index}].${propertyPath}`,
              message: "Ce champ doit être unique",
            })
          );
        }
      });
      if (!isEmpty(errors)) {
        //On remonte les erreurs à la fin pour être sûr d'avoir tout analysé
        throw new Yup.ValidationError(errors);
      }
    }
  });
});

export default function TypeContractCategoryForm({
  entity: typeCategory = null,
  onSubmit = () => {},
  readOnly = false,
  onCancel = () => {},
}) {
  const readOnlyFromSilae = useIsSilae();

  return (
    <div>
      <Formik
        initialValues={{
          label: get(typeCategory, "label", ""),
        }}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize
        validationSchema={Yup.object().shape({
          label: Yup.string().required("Requis"),
        })}
        onSubmit={async ({ ...values }, actions) => {
          if (readOnlyFromSilae) {
            return;
          }
          try {
            onSubmit({
              id: get(typeCategory, "id"),
              data: {
                ...values,
              },
            });

            actions.resetForm();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({
          isSubmitting,
          values,
          setFieldValue,
          resetForm,
          setFieldTouched,
        }) => {
          return (
            <Form>
              <div className="mb-4">
                <Input
                  readOnly={readOnly}
                  type="text"
                  name="label"
                  label="Nom de la catégorie"
                />
              </div>

              {readOnlyFromSilae ? null : typeCategory ? (
                <div className="mt-4 align-top">
                  <Button
                    className={`btn btn--sm`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    loaderClassName={`loader h-6 w-6 mx-auto`}
                    type="submit"
                    textLabel="Enregistrer"
                  />

                  <Button
                    className={`btn btn--sm bg-orange-500 hover:bg-orange-700 ml-4 align-top`}
                    type="button"
                    textLabel="Annuler"
                    onClick={() => {
                      onCancel();
                      resetForm();
                    }}
                  >
                    Annuler
                  </Button>
                </div>
              ) : (
                <div className="text-center">
                  <Button
                    className={`btn mt-6 inline-block`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="submit"
                    textLabel="Créer"
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
