import {
  useCreateTypeCalculationMethod,
  useDeleteTypeCalculationMethod,
  useUpdateTypeCalculationMethod,
  useQueryTypeCalculationMethods,
} from "../api/typeCalculationMethod";

import ManageResource from "../components/ManageResource";
import React from "react";
import { useIsSilae } from "../contexts/permissions";

function TypeCalculationMethodAdmin() {
  const isSilae = useIsSilae();
  return (
    <ManageResource
      resourceLabel="Types de rémunérations"
      noElementsLabel="aucun type de rémunération"
      createLabel="Ajouter un type de rémunération"
      createHook={useCreateTypeCalculationMethod}
      queryHook={useQueryTypeCalculationMethods}
      deleteHook={useDeleteTypeCalculationMethod}
      updateHook={useUpdateTypeCalculationMethod}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
      }}
      readOnly={isSilae}
    />
  );
}

export default TypeCalculationMethodAdmin;
