import React from "react";
import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Alert from "../components/Alert";
import Button from "../components/Button";
import UploadImage from "../components/UploadImage";
import { useCreateOrUpdateLogo, useQueryLogo } from "../api/logo";

export default function LogoForm() {
  const [updateLogo, { error: errorUpdate }] = useCreateOrUpdateLogo();
  const { data: logo } = useQueryLogo();
  return (
    <div>
      <Formik
        initialValues={{
          file: null,
        }}
        enableReinitialize
        validationSchema={Yup.object().shape({
          file: Yup.mixed().test(
            "fileFormat",
            "Formats autorisés: jpg, png, gif, jpeg, webm, pdf",
            (value) => {
              const authorized = [
                "image/jpeg",
                "image/png",
                "image/gif",
                "image/webm",
              ];
              return (
                value === null || (value && authorized.includes(value.type))
              );
            }
          ),
        })}
        onSubmit={async ({ file, ...values }, actions) => {
          try {
            if (file) {
              await updateLogo({ file });
              actions.setSubmitting(false);
            }
            toast.success("Le logo a bien été ajouté");
            actions.resetForm();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, setSubmitting, resetForm }) => {
          return (
            <Form>
              <div className="mb-4">
                <UploadImage
                  name={"file"}
                  label="Logo"
                  currentImage={logo?.contentUrl}
                />
              </div>
              {errorUpdate ? (
                <Alert
                  canBeHidden
                  type="error"
                  message={errorUpdate.title}
                  details={errorUpdate.description}
                />
              ) : null}

              <div className="text-center">
                <Button
                  className={`btn mt-6 inline-block`}
                  isSubmitting={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Enregistrer"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
