import { Link } from "react-router-dom";
import { ReactComponent as ArrowBackIcon } from "../svgs/arrow-back.svg";
import React from "react";
import { useDesktop } from "../hooks/useDesktop";

function ArrowBack({ isDesktop, isLeft = true }) {
  const classIcon = `${
    isDesktop ? "w-4 h-4" : "w-3 h-3"
  } fill-current text-white`;
  return (
    <div className={isLeft ? "mr-1" : "ml-1"}>
      <ArrowBackIcon
        className={`${classIcon} fill-current text-white ${
          isLeft ? "" : "transform rotate-180"
        }`}
      />
    </div>
  );
}

export default function LinkToVehicle({
  vehicle,
  isNext = false,
  className = "",
}) {
  const isDesktop = useDesktop();
  return (
    <Link
      to={`/rh/vehicules/${vehicle.id}`}
      className={`flex items-center text-sm ${className}`}
    >
      {!isNext && <ArrowBack isDesktop={isDesktop} />}
      {isDesktop ? (
        <div>
          <div className={isNext ? "text-right" : ""}>
            {isNext ? "Suivant" : "Précédent"}
          </div>
          <div>
            {vehicle.brand} {vehicle.model}
          </div>
        </div>
      ) : (
        <div>
          <div className={isNext ? "text-right" : ""}>
            {isNext ? "Suiv." : "Préc."}
          </div>
        </div>
      )}

      {isNext && <ArrowBack isDesktop={isDesktop} isLeft={false} />}
    </Link>
  );
}
