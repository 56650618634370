import React from "react";
import { ReactComponent as UploadIcon } from "../svgs/upload.svg";
import { useField } from "formik";

function DownloadOrUpload({
  downloadFn,
  replaceLabel = "Remplacer",
  uploadLabel = "Ajouter un fichier",
  uploadButtonLabel = "Envoyer",
  isSubmitting,
  submitButton = true,
  ...props
}) {
  const [file, setFile] = React.useState(null);
  const [field, meta, { setValue, setTouched }] = useField(props);

  return (
    <div className="text-center mb-8">
      <div>
        <div className="lg:flex items-center">
          <div className="flex-1 p-4 border-dashed border-2 border-gray-100 overflow-hidden relative bg-gray-50 hover:border-purple-500 hover:text-purple-500 rounded">
            <button
              type="button"
              className="w-full flex items-center font-bold focus:outline-none"
            >
              <UploadIcon className="fill-current h-6 w-6 mr-4" />
              <span>
                {file?.name && field?.value !== ""
                  ? file.name
                  : downloadFn
                  ? replaceLabel
                  : uploadLabel}
              </span>
            </button>
            <input
              {...field}
              {...props}
              type="file"
              value=""
              className="cursor-pointer absolute block py-2 px-4 w-full opacity-0 inset-0"
              onBlur={() => setTouched(true)}
              onChange={(e) => {
                setValue(e.target.files[0]);
                setFile(e.target.files[0]);
              }}
            />
          </div>
          {submitButton && (
            <div className="mt-4 lg:mt-0 lg:ml-4">
              <button type="submit" className="btn w-full lg:w-auto">
                {isSubmitting ? (
                  <div className="loader h-6 w-6 mx-auto"></div>
                ) : (
                  uploadButtonLabel
                )}
              </button>
            </div>
          )}
        </div>
        {meta.touched && meta.error ? (
          <p className="text-red-500 text-xs italic mt-2">{meta.error}</p>
        ) : null}
      </div>
    </div>
  );
}

export default DownloadOrUpload;
