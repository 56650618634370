import {
  PermissionChecker,
  usePermissionsChecker,
  useUseExternalDocumentManagement,
} from "../../contexts/permissions";
import {
  downloadDocument,
  previewDocument,
  useCreateDocument,
  useDeleteDocument,
} from "../../api/generalDocument";
import { useCreateFolder, useDeleteFolder } from "../../api/folders";
import { useGetCollaboratorFolders } from "../../api/collaborator";

import DocumentBrowser from "../../components/DocumentBrowser";
import React from "react";
import Spinner from "../../components/Spinner";
import { CollaboratorContext } from "../../contexts/collaborator";
import { get, map } from "lodash-es";

function Documents() {
  const permissionsView = [
    "kdix.actions.general_document.view",
    "kdix.actions.general_document.view.agency",
    "kdix.actions.general_document.view.department",
    "kdix.actions.general_document.view.service",
    "kdix.actions.general_document.view.own",
  ];
  const { collaborator } = React.useContext(CollaboratorContext);
  const idUser = get(collaborator, "data.linkedUser.id", null);
  const agencies = map(get(collaborator, "data.agencies", []), "@id");
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.general_document.edit",
      "kdix.actions.general_document.edit.agency",
      "kdix.actions.general_document.edit.department",
      "kdix.actions.general_document.edit.service",
      "kdix.actions.general_document.edit.own",
    ],
  });
  const collaboratorId = get(collaborator, "data.id");
  const useExternalDocumentManagement = useUseExternalDocumentManagement();

  React.useEffect(() => {
            var _paq = window._paq = window._paq || [];
            _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
       
            var u="//matomo.kdix.pockost.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    }, [])

  return (
    <PermissionChecker
      permissions={permissionsView}
      userId={idUser}
      agencies={agencies}
    >
      <React.Suspense fallback={<Spinner />}>
        <DocumentBrowser
          queryFolderHook={useGetCollaboratorFolders.bind(
            null,
            get(collaborator, "data.id")
          )}
          createFolderHook={useCreateFolder}
          deleteFolderHook={useDeleteFolder}
          downloadDocumentFn={downloadDocument}
          createDocumentHook={useCreateDocument}
          previewDocumentFn={previewDocument}
          deleteDocumentHook={useDeleteDocument}
          canCreate={canEdit}
          canEdit={canEdit}
          canDelete={canEdit}
          objectId={collaboratorId}
          useExternalDocumentManagement={useExternalDocumentManagement}
        />
      </React.Suspense>
    </PermissionChecker>
  );
}

export default Documents;
