import {
  useCreateCompany,
  useDeleteCompany,
  useQueryCompanies,
  useUpdateCompany,
} from "../api/companies";

import ManageResource from "../components/ManageResource";
import React from "react";

function CompaniesAdmin() {

  return (
    <ManageResource
      resourceLabel="sociétés"
      createLabel="Ajouter une société"
      createHook={useCreateCompany}
      queryHook={useQueryCompanies}
      deleteHook={useDeleteCompany}
      updateHook={useUpdateCompany}
      imageUpload={true}
      imageCompany={true}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
        lokoaUrl: {
          label: "Lien Saisie des temps",
          type: "text",
          resourceValueKey: "lokoaUrl",
        },
        lokoaGetTokenUrl: {
          label: "Lien de récupération du token Lokoa Biloba",
          type: "text",
          resourceValueKey: "lokoaGetTokenUrl",
        },
        insurancePhonenumber: {
          label: "N° téléphone assurance véhicule",
          type: "text",
          resourceValueKey: "insurancePhonenumber",
        },
        insuranceContract: {
          label: "N° contrat assurance véhicule",
          type: "text",
          resourceValueKey: "insuranceContract",
        },
      }}
    />
  );
}

export default CompaniesAdmin;
