import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import queryWithToken, {
  create,
  deleteById,
  find,
  findById,
  updateById,
  updateOrCreate,
} from "./index";

import { get } from "lodash-es";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "work";
//

const mutateDefaultOptions = {
  throwOnError: true,
  // When mutate is called:
  onMutate: () => {
    queryCache.cancelQueries(ressourceKey);
  },
};

export function useQueryWorkById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQueryWorks() {
  const { data, ...rest } = useQuery(
    ressourceKey,
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQueryWorksInfinite(filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    refetchOnWindowFocus: false,
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}

export function useCreateWork() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useUpdateWork() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useCreateOrUpdateWork() {
  return useMutation(
    updateOrCreate.bind(null, ressourceKey),
    mutateDefaultOptions,
  );
}

const addRemoveSkillDomainById = (key, { id, skillDomains, type }) => {
  if (type !== "add" && type !== "remove")
    throw new Error('Type must be "add" or "remove"');
  return queryWithToken(
    `${process.env.REACT_APP_API_URL}/${key}/skill_domain/${type}/${id}`,
    {
      method: "PATCH",
      data: {
        skill_domains_list: skillDomains,
      },
    },
  );
};

export function useAddRemoveSkillDomain() {
  return useMutation(addRemoveSkillDomainById.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries([ressourceKey]);
    },
  });
}

export function useDeleteWork() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useImportWorks() {
  return useMutation(
    ({ file, domain, objectId }) => {
      const data = new FormData();
      data.append("file", file);
      data.append("domainId", domain);

      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/import`,
        {
          method: "POST",
          data,
        },
      );
    },
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de l'envoi");
      },
      onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
      },
      onSuccess: () => {
        queryCache.refetchQueries(ressourceKey);
      },
    },
  );
}
