import React, { useState } from "react";
import { useDebounce } from "react-use";
import { Helmet } from "react-helmet";
import {Link, NavLink} from "react-router-dom";
import {
  PermissionChecker,
  usePermissionsChecker,
} from "../../contexts/permissions";
import { ReactComponent as PlusIcon } from "../../svgs/plus.svg";
import Spinner from "../../components/Spinner";
import { useDesktop } from "../../hooks/useDesktop";
import { useQueryFormationsInfinite } from "../../api/formation";
import { flatMap, get, map, size } from "lodash-es";
import Alert from "../../components/Alert";
import InfiniteScroll from "react-infinite-scroller";
import { ReactComponent as TrashIcon } from "../../svgs/trash.svg";
import { ReactComponent as EditIcon } from "../../svgs/pen.svg";
import { ReactComponent as DuplicateIcon } from "../../svgs/docs-entreprise.svg";
import Modal from "../../components/Modal";
import DeleteFormation from "../../forms/Formation/DeleteFormation";
import ExpansionPanel from "../../components/ExpansionPanel";
import CreateFormationForm from "../../forms/Formation/CreateFormation";
import { HeaderBar } from "../../components/HeaderBar";
import CustomLink from "../../components/CustomLink";
import DuplicateFormation from "../../forms/Formation/DuplicateFormation";
import InlineBLockContentSortable from "../../components/InlineBlockContentSortable";


function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}

function FormationMobileCard({ formation }) {
  return (
    <ExpansionPanel
      key={formation.id}
      title={`${formation.name}`}
      subtitle={get(formation, "licencePlate")}
    >
      <CreateFormationForm formation={formation} readOnly />
      <Link
        className="btn mt-6 w-full block text-center"
        to={`/rh/formations/formation/${formation.id}`}
      >
        Modifier
      </Link>
    </ExpansionPanel>
  );
}

function FormationsList({ query }) {
  const isDesktop = useDesktop();
  const [orderFilter, setOrderFilter] = useState({
    "order[name]": "asc",
  });
  const { data, fetchMore, canFetchMore } = useQueryFormationsInfinite({
    ...orderFilter,
    name: query,
  });
  const [modalDeleteFormation, setModalDeleteFormation] = useState(false);
  const [modalDuplicateFormation, setModalDuplicateFormation] = useState(false);
  const hasPermissionsManage = usePermissionsChecker({
    permissions: [
      "kdix.actions.formation.edit",
      "kdix.actions.formation.edit.agency",
      "kdix.actions.formation.edit.department",
      "kdix.actions.formation.edit.service",
      "kdix.actions.formation.edit.own",
    ],
  });

  const formations = flatMap(data, (page) => page["hydra:member"]);
  if (size(formations) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucune formation" />
      </div>
    );
  }
  const gridTemplateColumn = hasPermissionsManage
    ? "20% 17% 35% 8% 10% 10%"
    : "25% 22% 35% 8% 10%";
  return (
    <div className={`mt-8`}>
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"name"}
          >
          Nom
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"organization"}
          >
          Organisme
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"description"}
          >
          Description
          </InlineBLockContentSortable>
          <InlineBLockContent>Durée (h)</InlineBLockContent>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"location"}
          >
          Lieu
          </InlineBLockContentSortable>
          {hasPermissionsManage && (
            <InlineBLockContent className="justify-center">
              Actions
            </InlineBLockContent>
          )}
        </div>
      )}

      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {isDesktop
          ? map(formations, (node) => {
              console.log(node);
              return (
                <CustomLink
                  disabled={!hasPermissionsManage}
                  to={`/rh/formations/formation/${node.id}`}
                  key={node.id}
                  className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2`}
                  style={{
                    gridTemplateColumns: gridTemplateColumn,
                  }}
                >
                  <InlineBLockContent>{node.name}</InlineBLockContent>
                  <InlineBLockContent>{node.organization}</InlineBLockContent>
                  <InlineBLockContent>{node.description}</InlineBLockContent>
                  <InlineBLockContent className="justify-center">{node.duration}</InlineBLockContent>
                  <InlineBLockContent>{node.location}</InlineBLockContent>
                  {hasPermissionsManage && (
                    <InlineBLockContent className="justify-center">
                        <NavLink to={`/rh/formations/formation/${node.id}`} className="w-6 h-6 text-orange-400 fill-current mx-1">
                            <EditIcon className="w-6 h-6 text-orange-400 fill-current" />
                        </NavLink>
                        <button
                            className="text-blue-500 focus:outline-none focus:shadow-none mx-1"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setModalDuplicateFormation(node.id);
                            }}
                        >
                            <DuplicateIcon className="w-6 h-6 text-blue-500 fill-current" />
                        </button>
                        <button
                            className="text-red-500 focus:outline-none focus:shadow-none mx-1"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setModalDeleteFormation(node.id);
                            }}
                        >
                            <TrashIcon className="w-6 h-6 text-red-500 fill-current" />
                        </button>
                    </InlineBLockContent>
                  )}
                </CustomLink>
              );
            })
          : map(formations, (node) => (
              <FormationMobileCard key={node.id} formation={node} />
            ))}
      </InfiniteScroll>

      <Modal
        title="Supprimer une formation"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={modalDeleteFormation !== false}
        onRequestClose={() => setModalDeleteFormation(false)}
      >
        <div className="bg-white">
          <DeleteFormation
            id={modalDeleteFormation}
            onSuccess={() => setModalDeleteFormation(false)}
          />
        </div>
      </Modal>

      <Modal
          title="Dupliquer une formation"
          handleClose={(e) => {
              e.stopPropagation();
          }}
          isOpen={modalDuplicateFormation !== false}
          onRequestClose={() => setModalDuplicateFormation(false)}
      >
        <div className="bg-white">
          <DuplicateFormation
            id={modalDuplicateFormation}
            onSuccess={() => setModalDuplicateFormation(false)}
          />
        </div>
      </Modal>
    </div>
  );
}

function CatalogueFormations() {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query]
  );

  React.useEffect(() => {
            var _paq = window._paq = window._paq || [];
            _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
       
            var u="//matomo.kdix.pockost.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    }, [])

  return (
    <div>
      <Helmet>
        <title>Catalogue Formations</title>
      </Helmet>
      <HeaderBar
        sticky={true} smaller={true}
        title={
          <>
            Catalogue des formations
          </>
        }
        buttons={
          <PermissionChecker
            permissions={[
              "kdix.actions.formation.edit",
              "kdix.actions.formation.edit.agency",
              "kdix.actions.formation.edit.department",
              "kdix.actions.formation.edit.service",
              "kdix.actions.formation.edit.own",
            ]}
            notAllowed={null}
          >
            <Link
              to="/rh/formations/creation-formation"
              className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none"
            >
              <PlusIcon className="w-4 h-4" />
            </Link>
          </PermissionChecker>
        }
      >
        <input
          type="text"
          name="query"
          value={query}
          className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
          placeholder="Rechercher une formation"
          onChange={(e) => setQuery(e.target.value)}
        />
      </HeaderBar>

      <div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <FormationsList query={debouncedQuery} />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default CatalogueFormations;
