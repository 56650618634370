import { queryCache, useMutation } from "react-query";
import queryWithToken from "./index";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "formation_certificates";
//

export function useCreateFormationCertificate() {
  return useMutation(
    ({ file, affectedFormationId }) => {
      const data = new FormData();
      data.append("file", file);
      data.append("affectedFormationId", affectedFormationId);

      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
        {
          method: "POST",
          data,
        }
      );
    },
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de l'envoi");
      },
      onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
      },
      onSuccess: () => {
        queryCache.refetchQueries(ressourceKey);
        queryCache.refetchQueries("collaborators");
      },
    }
  );
}

export async function downloadFormationCertificate(
  id,
  label = "certificat_formation"
) {
  const blob = await queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/document`,
    {
      method: "GET",
      responseType: "blob",
    }
  );
  if (blob) {
    saveAs(blob, label);
  }
}
