import React from "react";
import { get } from "lodash-es";
import { getLocaleDateString } from "../../utils/date";
import { getFirstnameLastname } from "../../utils/names";

export function alertTitleContract(alert) {
    //Alerte de fin de période d'essai
    if (alert?.typeAlert?.classname?.attribute === "endDateProbation") {
        return {
            title: get(alert, "typeAlert.label", ""),
            subtitles: [
                {
                    label: "Date de rappel",
                    value: getLocaleDateString(alert, "reminderDate"),
                },
                {
                    label: "Collaborateur",
                    value: getFirstnameLastname(
                        alert,
                        "contract.collaborator"
                    ),
                },
                {
                    label: "Fin de période d'essai",
                    value: getLocaleDateString(alert, "contract.endDateProbation"),
                },
                {
                    label: "Emploi",
                    value: get(alert, "contract.job.label", ""),
                },
            ],
        };
    }

    //Alerte de fin de CDD
    return {
        title: get(alert, "typeAlert.label", ""),
        subtitles: [
            {
                label: "Date de rappel",
                value: getLocaleDateString(alert, "reminderDate"),
            },
            {
                label: "Collaborateur",
                value: getFirstnameLastname(
                    alert,
                    "contract.collaborator"
                ),
            },
            {
                label: "Fin de contrat",
                value: getLocaleDateString(alert, "contract.endDate"),
            },
            {
                label: "Emploi",
                value: get(alert, "contract.job.label", ""),
            },
        ],
    };
}

function AlertContractItem({ alert }) {
    return (
        <>
            {get(alert, "typeAlert.comment") && (
                <p>
                    <span className="font-bold">Commentaire : </span>
                    {get(alert, "typeAlert.comment")}
                </p>
            )}
        </>
    );
}

export default AlertContractItem;
