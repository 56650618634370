import * as Yup from "yup";

import { Field, Form, Formik } from "formik";
import { get, map, upperFirst, toUpper } from "lodash-es";

import Alert from "../components/Alert";
import Input from "../components/Input";
import React from "react";
import Select from "../components/Select";
import { useCreateCollaborator } from "../api/collaborator";
import { useHistory } from "react-router-dom";
import { useQueryPermissions } from "../api/permissions";
import Button from "../components/Button";
import { civilities } from "./formsUtils/civilities";
import RadioGroup from "../components/RadioGroup";
import { passwordRegex } from "../utils/password";
import { useQueryAgencies } from "../api/agencies";
import { useQueryCompanies } from "../api/companies";
import { useQueryServices } from "../api/services";
import { useQueryDepartments } from "../api/department";

const schema = Yup.object().shape({
  firstname: Yup.string().required("Requis"),
  lastname: Yup.string().required("Requis"),
  isUser: Yup.boolean().required(),
  socialSecurityNumber: Yup.string()
    .matches(
      /^([12][0-9]{2}(0[1-9]|1[0-2])(2[AB]|[0-9]{2})[0-9]{6}([0-9]{2})|7[0-9]{14})?$/,
      { message: "Numéro non valide", excludeEmptyString: true }
    )
    .required("Requis"),
  username: Yup.string().when("isUser", {
    is: true,
    then: Yup.string().required("Requis"),
    otherwise: Yup.string().min(0),
  }),
  agencies: Yup.array().when("isUser", {
    is: true,
    then: Yup.array().min(1, "au moins un site").required("Requis"),
    otherwise: Yup.array().min(0),
  }),
  departments: Yup.array().when("isUser", {
    is: true,
    then: Yup.array().min(1, "au moins un département").required("Requis"),
    otherwise: Yup.array().min(0),
  }),
  service: Yup.object().when("isUser", {
    is: true,
    then: Yup.object()
      .test({
        test: ({ value }) => value,
        message: "Requis",
      })
      .required("Requis"),
  }),
  password: Yup.string().when("isUser", {
    is: true,
    then: Yup.string()
      .matches(passwordRegex, {
        message:
          "Votre mot de passe doit contenir 8 caractères minimum et au moins : une majuscule, une minuscule, un chiffre et un caractère spécial",
      })
      .required("Requis"),
    otherwise: Yup.string().min(0),
  }),

  permission: Yup.object().when("isUser", {
    is: true,
    then: Yup.object()
      .test({
        test: ({ value }) => value,
        message: "Requis",
      })
      .required("Requis"),
  }),
});

function formatUsername(firstname = "", lastname = "") {
  return `${firstname}.${lastname}`
    .replaceAll("'", "")
    .replaceAll(" ", "-")
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
}

function prepareValues({
  isUser,
  firstname,
  lastname,
  username,
  civility,
  password,
  permission,
  socialSecurityNumber,
  service,
  departments,
  agencies,
  companies,
}) {
  const base = {
    firstname,
    lastname,
    civility,
    service: service.value,
    departments: map(departments, (a) => a.value),
    agencies: map(agencies, (a) => a.value),
    personalInformation: {
      socialSecurityNumber,
    },
    companies: map(companies, (a) => a.value),
  };
  if (isUser) {
    return {
      ...base,
      linkedUser: {
        username,
        password,
        permission: permission.value,
      },
    };
  }

  return base;
}

function CreateCollaborator() {
  const history = useHistory();
  const [createCollaborator, { error }] = useCreateCollaborator();
  const { data: permissions } = useQueryPermissions();
  const { data: agencies } = useQueryAgencies();
  const { data: companies } = useQueryCompanies();
  const { data: services } = useQueryServices();
  const { data: departments } = useQueryDepartments();

  return (
    <div>
      <Formik
        initialValues={{
          civility: "",
          firstname: "",
          socialSecurityNumber: "",
          lastname: "",
          isUser: true,
          permission: {
            label: "",
            value: "",
          },
          service: {
            label: "",
            value: "",
          },
          departments: [],
          agencies: [],
          companies: [],
          username: "",
          password: "",
        }}
        validationSchema={schema}
        onSubmit={async (values, actions) => {
          try {
            const response = await createCollaborator(prepareValues(values));

            actions.setSubmitting(false);

            if (!response.status && response.id) {
              history.push(`/rh/collaborateurs/${response.id}`);
            }
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => {
          return (
            <Form>
              <RadioGroup
                name="civility"
                label="Civilité"
                options={[
                  { label: "Monsieur", value: civilities.man },
                  { label: "Madame", value: civilities.woman },
                ]}
              />
              <Input
                type="text"
                name="firstname"
                label="Prénom"
                onChange={(e) => {
                  setFieldValue(
                    "username",
                    formatUsername(e.target.value, values.lastname)
                  );
                  setFieldValue("firstname", upperFirst(e.target.value));
                }}
              />
              <Input
                type="text"
                name="lastname"
                label="Nom"
                onChange={(e) => {
                  setFieldValue(
                    "username",
                    formatUsername(values.firstname, e.target.value)
                  );
                  setFieldValue("lastname", toUpper(e.target.value));
                }}
              />
              <div className="mb-8 flex justify-between items-center border-b border-black pb-6">
                <label
                  htmlFor="isUser"
                  className={`block text-black text-sm font-bold `}
                >
                  Souhaitez-vous que ce collaborateur ait accès à K-Dix ?
                </label>
                <Field
                  id="isUser"
                  type="checkbox"
                  name="isUser"
                  checked={values.isUser}
                />
              </div>
              {values.isUser ? (
                <>
                  <label
                    htmlFor="permission"
                    className={`block text-black text-sm font-bold `}
                  >
                    Choisir un profil
                  </label>
                  <Select
                    name="permission"
                    placeholder="Profil de l'utilisateur"
                    options={map(permissions, (permission) => ({
                      label: permission.label,
                      value: permission["@id"],
                    }))}
                    value={values.permission}
                    isLoading={!permissions}
                  />

                  <Input
                    type="text"
                    name="username"
                    label="Nom d'utilisateur"
                  />
                  <Input
                    type="text"
                    label="n° Sécurité sociale (avec clé)"
                    name="socialSecurityNumber"
                  />
                  <Input type="text" name="password" label="Mot de passe" />
                </>
              ) : (
                <Input
                  type="text"
                  label="n° Sécurité sociale (avec clé)"
                  name="socialSecurityNumber"
                />
              )}
              <Select
                label="Société"
                isMulti
                name="companies"
                options={map(companies, (node) => ({
                  label: node.label,
                  value: node["@id"],
                }))}
                value={values.companies}
                isLoading={!companies}
              />
              <Select
                label="Site(s)"
                isMulti
                name="agencies"
                options={map(agencies, (node) => ({
                  label: node.label,
                  value: node["@id"],
                }))}
                value={values.agencies}
                isLoading={!agencies}
              />
              <Select
                label="Département(s)/Pôle(s)"
                isMulti
                name="departments"
                options={map(departments, (node) => ({
                  label: node.label,
                  value: node["@id"],
                }))}
                value={values.departments}
                isLoading={!departments}
              />
              <Select
                label="Service"
                name="service"
                options={map(services, (node) => ({
                  label: node.code,
                  value: node["@id"],
                }))}
                value={values.service}
                isLoading={!services}
              />
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <div className="text-center">
                <Button
                  className={`btn my-8 px-8`}
                  disabled={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Créer"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CreateCollaborator;
