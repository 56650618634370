import React from "react";
import { Helmet } from "react-helmet";

import Block from "../../components/Block";
import GoBackBtn from "../../components/GoBackBtn";
import { Redirect, useParams } from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundary";
import VehicleProvider, { VehicleContext } from "../../contexts/vehicle";
import VehicleRentalForm from "../../forms/VehicleRentalForm";

function NewVehicleRental() {
  const { vehicle } = React.useContext(VehicleContext);
  return (
    <div className="p-8 pb-0">
      <Helmet>
        <title>Réattribuer le véhicule</title>
      </Helmet>
      <Block>
        <GoBackBtn
          to={`/rh/vehicules/${vehicle.data.id}`}
          className="flex items-center"
        >
          <div className="font-bold">Retour</div>
        </GoBackBtn>

        <div className="max-w-lg mx-auto">
          <div className="text-2xl font-bold mb-8 mt-8">Louer</div>
          <VehicleRentalForm vehicle={vehicle.data} error={vehicle.error} />
        </div>
      </Block>
    </div>
  );
}

function NewVehicleRentalPage() {
  let { id } = useParams();
  id = parseInt(id);
  if (isNaN(id)) {
    return <Redirect to="/rh/vehicules" />;
  }
  return (
    <ErrorBoundary fallback="Récupération du véhicule impossible">
      <VehicleProvider id={id}>
        <NewVehicleRental />
      </VehicleProvider>
    </ErrorBoundary>
  );
}

export default NewVehicleRentalPage;
