import { queryCache, useMutation } from "react-query";
import { deleteById, updateOrCreate, findExport } from "./index";
import fileDownload from "js-file-download";


// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "permission_actions";
//
type FiltersType = {
  "order[permission.label]"?: string | null;
  "order[action.module.label]"?: string | null;
  "order[action.label]"?: string | null;
};

export function useCreateOrUpdatePermissionAction() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("permissions");
    },
  });
}

export function useDeletePermissionAction() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: (data) => {
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("permissions");
    },
  });
}

export async function downloadPermissionExport(filters?: FiltersType) {
  const data = await findExport(ressourceKey,filters);
  fileDownload(data, "export_permissions.csv");
}
