import { find, get, map } from "lodash-es";
import {
  useCreateAgency,
  useDeleteAgency,
  useQueryAgencies,
  useUpdateAgency,
} from "../api/agencies";

import ManageResource from "../components/ManageResource";
import React from "react";
import { useGetCountries } from "../api";

function AgenciesAdmin() {
  const { data: countries } = useGetCountries({
    pagination: false,
    page: null,
  });
  return (
    <ManageResource
      resourceLabel="sites"
      createLabel="Ajouter un site"
      createHook={useCreateAgency}
      queryHook={useQueryAgencies}
      deleteHook={useDeleteAgency}
      updateHook={useUpdateAgency}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
        email: {
          label: "E-mail",
          type: "email",
          resourceValueKey: "email",
        },
        address: {
          street: {
            label: "Rue",
            type: "text",
            resourceValueKey: "address.street",
          },
          complement: {
            label: "Complément d'adresse",
            type: "text",
            resourceValueKey: "address.complement",
          },
          city: {
            label: "Ville",
            type: "text",
            resourceValueKey: "address.city",
          },
          postCode: {
            label: "Code postal",
            type: "text",
            resourceValueKey: "address.postCode",
          },
          country: {
            label: "Pays",
            type: "select",
            resourceValueKey: "address.country",
            options: map(countries, (node) => ({
              label: node.name,
              value: get(node, "@id"),
            })),
            transformResourceValue: (value) => {
              const country = find(
                countries,
                (country) => country["@id"] === value
              );
              return {
                label: country?.name || "",
                value: country?.["@id"] || "",
              };
            },
          },
        },
      }}
    />
  );
}

export default AgenciesAdmin;
