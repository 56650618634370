import { differenceBy, map, noop } from "lodash-es";

import React from "react";
import ReactSelect from "react-select";
import { useField } from "formik";
import { DisplayCheckbox } from "./Checkbox";
import Spinner from "./Spinner";

const customStyles = {
  clearIndicator: (provided) => ({ ...provided }),
  container: (provided) => ({
    ...provided,
  }),
  control: (provided, state) => {
    return {
      ...provided,
      background: "transparent",
      boxShadow: state.isFocused ? null : null,
      border: 0,
      borderRadius: 0,
      borderBottom: state.isDisabled
        ? "1px solid rgb(222,222,222)"
        : "1px solid currentColor",
      "&:hover": {
        borderColor: null,
      },
    };
  },
  singleValue: (provided) => ({
    ...provided,
    color: "currentColor",
  }),
  multiValue: (provided) => ({
    ...provided,
    "&:first-of-type": {
      marginLeft: 0,
    },
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    display: state.isDisabled ? "none" : "flex",
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    paddingRight: state.isDisabled ? "6px" : "3px",
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: state.isDisabled ? "none" : "flex",
  }),
  valueContainer: (provided) => ({ ...provided, padding: 0 }),
  option: (provided) => ({ ...provided, color: "#000" }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

function SelectError({ errors }) {
  if (typeof errors === "string") {
    return <p className="text-red-500 text-xs italic mt-2">{errors}</p>;
  }
  return map(errors, (error, index) => (
    <p key={index} className="text-red-500 text-xs italic mt-2">
      {error}
    </p>
  ));
}

export function DisplaySelect({
  label,
  className,
  color = "black",
  style = {},
  onChange = noop,
  options,
  value = false,
  ...props
}) {
  return (
    <div className={`${className ? className : "mb-8"}`}>
      <label className={`block text-${color} text-sm font-bold`}>{label}</label>
      <ReactSelect
        styles={{ ...customStyles, ...style }}
        menuPosition={"absolute"}
        menuShouldScrollIntoView={false}
        menuPortalTarget={document.body}
        {...props}
        placeholder={
          props.isDisabled
            ? ""
            : props.placeholder
            ? props.placeholder
            : "Sélectionner..."
        }
        options={
          props.isLoading
            ? [
                ...options,
                {
                  value: "",
                  label: "Chargement ...",
                },
              ]
            : options
        }
        onChange={(value) => {
          onChange(value);
        }}
        value={value}
        loadingMessage={() => "Chargement..."}
      />
    </div>
  );
}

function Select({
  label,
  className,
  color = "black",
  style = {},
  onChange = noop,
  options,
  value = false,
  allowSelectAll,
  ...props
}) {
  const [field, meta, { setValue, setTouched }] = useField(props);
  let allSelected =
    options.length > 0 && differenceBy(options, value, "value").length === 0;
  return (
    <div className={`${className ? className : "mb-8"}`}>
      <label
        className={`block ${
          meta.touched && meta.error ? "text-red-500" : `text-${color}`
        } text-sm font-bold`}
      >
        {label}
      </label>
      <ReactSelect
        styles={{ ...customStyles, ...style }}
        {...props}
        placeholder={
          props.isDisabled
            ? ""
            : props.placeholder
            ? props.placeholder
            : "Sélectionner..."
        }
        options={
          props.isLoading
            ? [
                ...options,
                {
                  value: "",
                  label: "Chargement ...",
                },
              ]
            : options
        }
        value={value !== false ? value : field.value}
        onBlur={() => setTouched(true)}
        onChange={(value, { action }) => {
          if (action === "clear") {
            props.isMulti
              ? setValue([])
              : setValue({
                  value: "",
                  label: "Sélectionner ...",
                });
          } else {
            setValue(value || undefined);
          }
          if (action === "remove-value" && value === null && props.isMulti) {
            setValue([]);
          }
          onChange(value, { action });
        }}
        noOptionsMessage={({ inputValue }) => {
          return inputValue !== ""
            ? "Aucun élément pour cette recherche"
            : "Aucun élément";
        }}
        loadingMessage={() => "Chargement..."}
      />
      {allowSelectAll && !props.isDisabled ? (
        <span className="input-group-append">
          {props.isLoading ? (
            <Spinner small={true} />
          ) : (
            <DisplayCheckbox
              label="Tout sélectionner"
              name="Tout sélectionner"
              id="selectAll"
              checked={allSelected}
              className="mb-4"
              onChange={() => {
                if (allSelected) {
                  setValue(
                    props.resetSelectAll
                      ? [
                          ...props.resetSelectAll,
                          ...differenceBy(
                            differenceBy(value, options, "value"),
                            props.resetSelectAll,
                            "value"
                          ),
                        ]
                      : differenceBy(value, options, "value")
                  );
                  onChange(props.options);
                } else {
                  if (options) {
                    setValue([
                      ...value,
                      ...differenceBy(options, value, "value"),
                    ]);
                  } else {
                    setValue(undefined);
                  }
                  onChange(props.options);
                }
              }}
              readOnly={props.isLoading}
            />
          )}
        </span>
      ) : null}
      {meta.touched && meta.error ? <SelectError errors={meta.error} /> : null}
    </div>
  );
}

export default Select;
