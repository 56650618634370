import React from "react";
import MessageForm from "../forms/MessageForm";
import Block from "../components/Block";

export default function SendMessage() {
  return (
    <div className="p-8 pb-24 flex flex-col">
      <div className="p-8 pb-24">
        <Block>
          <div className="font-bold text-xl leading-tight mb-8">
            Envoyer un message
          </div>
          <Block>
            <MessageForm />
          </Block>
        </Block>
      </div>
    </div>
  );
}
