import React from "react";
import Radio from "./Radio";

export default function Export({
  label,
  children,
  setActive,
  active,
  hasFilters = true,
}) {
  return (
    <div className="bg-white shadow md:w-2/3 w-full">
      <div className="bg-gray-75 p-4 font-bold text-2xl">
        <div>{label}</div>
        {hasFilters && (
          <div className="flex">
            <Radio
              label="Actif"
              type="checkbox"
              name="type"
              value={1}
              className="pr-8"
              checked={active === 1}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  setActive(1);
                }
              }}
            />
            <Radio
              label="Inactif"
              type="checkbox"
              name="type"
              value={0}
              className="pr-8"
              checked={active === 0}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  setActive(0);
                }
              }}
            />
            <Radio
              label="Tous"
              type="checkbox"
              name="type"
              value={2}
              checked={active === 2}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  setActive(2);
                }
              }}
            />
          </div>
        )}
      </div>
      <div>{children}</div>
    </div>
  );
}
