import React from "react";

import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import Alert from "../../components/Alert";
import Input from "../../components/Input";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import { useCreateOrUpdateFormation } from "../../api/formation";
import * as Yup from "yup";
import { usePermissionsChecker } from "../../contexts/permissions";

const schema = Yup.object().shape({
  name: Yup.string().required("Requis"),
  duration: Yup.string(),
  description: Yup.string().required("Requis"),
});

function CreateFormation({ formation = null, readOnly = false }) {
  const history = useHistory();
  const [createOrUpdate, { error }] = useCreateOrUpdateFormation();
  const canCreate = usePermissionsChecker({
    permissions: [
      "kdix.actions.formation.edit",
      "kdix.actions.formation.edit.agency",
      "kdix.actions.formation.edit.department",
      "kdix.actions.formation.edit.service",
      "kdix.actions.formation.edit.own",
    ],
  });
  return (
    <div>
      <Formik
        initialValues={{
          name: get(formation, "name", ""),
          duration: get(formation, "duration", ""),
          description: get(formation, "description", ""),
          category: get(formation, "category", ""),
          organization: get(formation, "organization", ""),
          location: get(formation, "location", ""),
        }}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={async (values, actions) => {
          try {
            const response = await createOrUpdate({
              id: get(formation, "id", null),
              data: {
                ...values,
              },
            });

            actions.setSubmitting(false);

            if (!response.status && response.id) {
              history.push(`/rh/formations/catalogue`);
            }
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Input
                type="text"
                name="name"
                label="Nom"
                readOnly={readOnly || !canCreate}
              />
              <Input
                type="text"
                name="organization"
                label="Organisme"
                readOnly={readOnly || !canCreate}
              />
              <Input
                type="text"
                name="duration"
                label="Durée (en nombre d'heures)"
                readOnly={readOnly || !canCreate}
              />
              <Input
                type="text"
                name="location"
                label="Lieu"
                readOnly={readOnly || !canCreate}
              />
              <Input
                type="text"
                name="description"
                label="Description"
                readOnly={readOnly || !canCreate}
              />
              <Input
                type="text"
                name="category"
                label="Catégorie"
                readOnly={readOnly || !canCreate}
              />

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}
              {!readOnly && canCreate && (
                <div className="text-center">
                  <Button
                    className={`btn my-8 px-8`}
                    //readOnly={readOnly}
                    disabled={isSubmitting}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="submit"
                    textLabel={formation ? "Enregistrer" : "Créer"}
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CreateFormation;
