import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import queryWithToken, {
  create,
  deleteById,
  find,
  findById,
  findExport,
  updateById,
  updateOrCreate,
} from "./index";

import { get } from "lodash-es";
import fileDownload from "js-file-download";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "messages";
//

export function useQueryMessageById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQueryMessagesReceived(filters, otherKey = ressourceKey) {
  const { data, ...rest } = useQuery(
    otherKey,
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/received`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            ...filters,
          },
        }
      ),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQueryInfiniteMessagesReceived(filters) {
  return useInfiniteQuery(
    [`${ressourceKey}_received`, filters],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/received`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            itemsPerPage: 15,
            ...filters,
          },
        }
      ),
    {
      getFetchMore: (lastGroup) => {
        const nextUrl = lastGroup["hydra:view"]["hydra:next"];
        const match = /page=(\d+)/.exec(nextUrl);
        if (match) {
          return match[1];
        }

        return false;
      },
    }
  );
}

export function useQueryMessages(filters, otherKey = ressourceKey) {
  const { data, ...rest } = useQuery(
    otherKey,
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQueryInfiniteMessages(filters) {
  return useInfiniteQuery(
    [ressourceKey, { ...filters, itemsPerPage: 15 }],
    find,
    {
      getFetchMore: (lastGroup) => {
        const nextUrl = lastGroup["hydra:view"]["hydra:next"];
        const match = /page=(\d+)/.exec(nextUrl);
        if (match) {
          return match[1];
        }

        return false;
      },
    }
  );
}

export function useQueryInfiniteMessagesPrevention(filters) {
  return useInfiniteQuery(
    [`${ressourceKey}/prevention`, { ...filters, itemsPerPage: 15 }],
    find,
    {
      getFetchMore: (lastGroup) => {
        const nextUrl = lastGroup["hydra:view"]["hydra:next"];
        const match = /page=(\d+)/.exec(nextUrl);
        if (match) {
          return match[1];
        }

        return false;
      },
    }
  );
}

export function useCreateMessage() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useUpdateMessage() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("messages_unapproved");
      queryCache.refetchQueries("messages_received");
    },
  });
}

export function useReadByUserMessage() {
  return useMutation(
    (id) =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/patch_read_by`,
        {
          method: "PATCH",
          data: {},
        }
      ),
    {
      onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
      },
      onSuccess: () => {
        queryCache.refetchQueries(ressourceKey);
        queryCache.refetchQueries("messages_unapproved");
        queryCache.refetchQueries("messages_received");
      },
    }
  );
}

export function useCreateOrUpdateMessage() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("messages_unapproved");
    },
  });
}

export function useDeleteMessage() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: (data) => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export async function downloadMessagesExport(filters) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, "export_messages.csv");
}
