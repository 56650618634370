import { isFinite, size } from "lodash-es";

import Block from "./Block";
import InfoTooltip from "./InfoTooltip";
import { PermissionChecker } from "../contexts/permissions";
import React from "react";
//import { ReactComponent as StormIcon } from "../svgs/storm.svg";
//import { ReactComponent as SunCloudIcon } from "../svgs/sun-cloud.svg";
//import { ReactComponent as SunIcon } from "../svgs/sun.svg";
//import { ReactComponent as RainIcon } from "../svgs/rain.svg";
import StormIcon from "../images/storm.png";
import RainIcon from "../images/rain.png";
import SunCloudIcon from "../images/cloud.png";
import SunIcon from "../images/sun.png";
import VariationBetweenYear from "./VariationBetweenYear";
import percentRound from "percent-round";
import { useGetuserMood } from "../api/user";

function prepareData(data = {}) {
  return percentRound([
    data.Soleil || 0,
    data.Nuage || 0,
    data.Pluie || 0,
    data.Orage || 0,
  ]);
}

function WeatherStat({ currentYear, previousYear }) {
  if (!isFinite(currentYear)) return null;

  return (
    <>
      <div className="mt-2">{currentYear}%</div>
      {isFinite(previousYear) && previousYear !== 0 ? (
        <VariationBetweenYear
          previous={previousYear || 0}
          next={currentYear || 0}
        />
      ) : null}
    </>
  );
}

function WeatherDisplay({
  currentYear,
  previousYear,
  participationPercentage,
  title = "Météo",
  connections = null,
  tooltip,
}) {
  const [Soleil, Nuage, Pluie, Orage] = prepareData(currentYear);
  const [previousSoleil, previousNuage, previousPluie, previousOrage] =
    prepareData(previousYear);

  return (
    <Block>
      <PermissionChecker
        permissions={[
          "kdix.actions.mood.view",
          "kdix.actions.mood.view.agency",
          "kdix.actions.mood.view.department",
          "kdix.actions.mood.view.service",
          "kdix.actions.mood.view.own",
        ]}
        notAllowed={null}
      >
        <div className="relative text-2xl font-bold text-center mb-0">
          {title}
          {tooltip ? (
            <div className="absolute right-0 top-0 -mt-1 -mr-4 ">
              <InfoTooltip message={tooltip} />
            </div>
          ) : null}
        </div>
        {connections && (
          <>
            <div className="relative text-lg font-semibold text-center mb-0">
              Nombre de connexions de l'année (glissante) en cours :{" "}
              {connections.currentYear}
            </div>
            <div className="relative text-lg font-semibold text-center mb-0">
              Nombre de connexions de l'année (glissante) précédente :{" "}
              {connections.lastYear}
            </div>
          </>
        )}

        {participationPercentage ? (
          <div className="text-center mb-3">
            {participationPercentage}% de participation
          </div>
        ) : null}

        <div className="flex justify-center mt-3 xl:px-24 lg:px-16">
          <div
            className={`flex items-center flex-col flex-1  p-2 sm:p-4 font-bold text-purple-400 text-center border-r border-purple-400`}
          >
            <div>
              <img
		          src={SunIcon}
                          alt="Soleil"
		          className="w-14 h-14 mx-auto"
		        />
            </div>
            <WeatherStat previousYear={previousSoleil} currentYear={Soleil} />
          </div>
          <div
            className={`flex items-center flex-col flex-1  p-2 sm:p-4 font-bold text-purple-400 text-center border-r border-purple-400`}
          >
            <div>
               <img
		          src={SunCloudIcon}
                          alt="Nuages"
		          className="w-14 h-14 mx-auto"
		        />
            </div>
            <WeatherStat previousYear={previousNuage} currentYear={Nuage} />
          </div>
          <div
            className={`flex items-center flex-col flex-1  p-2 sm:p-4 font-bold text-purple-400 text-center border-r border-purple-400`}
          >
            <div>
               <img
		          src={RainIcon}
                          alt="Pluie"
		          className="w-14 h-14 mx-auto"
		        />
            </div>
            <WeatherStat previousYear={previousPluie} currentYear={Pluie} />
          </div>
          <div
            className={`flex items-center flex-col flex-1  p-2 sm:p-4 font-bold text-purple-400 text-center`}
          >
            <div>
              <img
		          src={StormIcon}
                          alt="Orage"
		          className="w-14 h-14 mx-auto"
		        />
            </div>
            <WeatherStat previousYear={previousOrage} currentYear={Orage} />
          </div>
        </div>
      </PermissionChecker>
    </Block>
  );
}

export const WeatherYear = React.memo(({ tooltip, userId, connections }) => {
  const { data: moods } = useGetuserMood(userId);
  if (!moods && size(moods) <= 0) return null;

  return (
    <WeatherDisplay
      tooltip={tooltip}
      title="Météo du collaborateur sur une année glissante"
      label=""
      connections={connections}
      currentYear={moods}
    />
  );
});

export const WeatherCompany = React.memo(
  ({ moods, previousMoods, connections }) => {
    if (!moods && size(moods) <= 0) return null;

    return (
      <WeatherDisplay
        title="Météo de l'entreprise depuis un an"
        currentYear={moods}
        previousYear={previousMoods}
        connections={connections}
      />
    );
  },
);
