import React from "react";
import {
  useCreateOrUpdateTypeContractCategory,
  useDeleteTypeContractCategory,
  useQueryTypeContractCategories,
} from "../api/typeContractCategory";
import TypeContractCategoryForm from "../forms/TypeContractCategoryForm";
import { useIsSilae } from "../contexts/permissions";
import ManageResource from "../components/ManageResource";

export default function TypeContractCategoryAdmin() {
  const isSilae = useIsSilae();
  return (
    <ManageResource
      resourceLabel="Types de catégories"
      createLabel="Ajouter un type de catégorie"
      createHook={useCreateOrUpdateTypeContractCategory}
      queryHook={useQueryTypeContractCategories}
      deleteHook={useDeleteTypeContractCategory}
      updateHook={useCreateOrUpdateTypeContractCategory}
      SpecificForm={TypeContractCategoryForm}
      canDelete={true}
      readOnly={isSilae}
    />
  );
}
