import React from "react";
import { Helmet } from "react-helmet";

import Block from "../../components/Block";
import GoBackBtn from "../../components/GoBackBtn";
import CreateFormationForm from "../../forms/Formation/CreateFormation";
import { Redirect, useParams } from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundary";
import FormationProvider, { FormationContext } from "../../contexts/formation";
import Alert from "../../components/Alert";
import { get } from "lodash-es";

function FormationError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération de l'habilitation"
        details={get(error, "description")}
      />
    </div>
  );
}

function EditFormation() {
  const { formation, error } = React.useContext(FormationContext);

  if (error) {
    return <FormationError error={error} />;
  }

  return (
    <div className="p-8 pb-0">
      <Helmet>
        <title>Modifier une formation</title>
      </Helmet>
      <Block>
        <GoBackBtn to="/rh/formations/catalogue" className="flex items-center">
          <div className="font-bold">Retour</div>
        </GoBackBtn>

        <div className="max-w-lg mx-auto">
          <div className="text-2xl font-bold mb-8 mt-8">
            Modifier une formation
          </div>
          <CreateFormationForm formation={get(formation, "data", null)} />
        </div>
      </Block>
    </div>
  );
}

function EditFormationPage() {
  let { id } = useParams();
  id = parseInt(id);
  if (isNaN(id)) {
    return <Redirect to="/rh/formations/catalogue" />;
  }
  return (
    <ErrorBoundary fallback="Récupération de la formation impossible">
      <FormationProvider id={id}>
        <EditFormation />
      </FormationProvider>
    </ErrorBoundary>
  );
}

export default EditFormationPage;
