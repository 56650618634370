import React, { useState } from "react";
import { flatMap, get, map, pull, size } from "lodash-es";
import { useDebounce, useMap, useToggle } from "react-use";
import Alert from "../../components/Alert";
import ExpansionPanel from "../../components/ExpansionPanel";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import Spinner from "../../components/Spinner";
import { useDesktop } from "../../hooks/useDesktop";
import Modal from "../../components/Modal";
import { useQueryInfiniteRequestsPrevention } from "../../api/requests";
import RequestForm from "../../forms/RequestForm";
import { useUserId } from "../../contexts/permissions";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import DatePicker from "react-datepicker";
import CheckboxGroup from "../../components/CheckboxGroup";
import { getLocaleDateString } from "../../utils/date";
import {
  getFirstnameLastname,
  getFirstnameLastnameJob,
  getOnlyJob,
} from "../../utils/names";
import { HeaderBar } from "../../components/HeaderBar";
import { useQueryPermissions } from "../../api/permissions";
import InfoTooltip from "../../components/InfoTooltip";
import { CSVLink } from "react-csv";

function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}

function RequestPreventionMobileCard({ request, readOnly }) {
  return (
    <ExpansionPanel
      key={request.id}
      title={getFirstnameLastnameJob(request, "createdBy.collaborator")}
      subtitle={getLocaleDateString(request, "createdAt")}
    >
      <RequestForm readOnly={readOnly} request={request} />
    </ExpansionPanel>
  );
}

function InlineBLockContentSortable({
  children,
  setOrderFilter,
  colName,
  className = "",
  style = {},
}) {
  return (
    <div
      className={`px-4 flex items-center inl ine-col-sortable ${className}`}
      style={style}
      onClick={() => {
        setOrderFilter((orderFilter) => {
          const order = orderFilter[`order[${colName}]`];
          return {
            [`order[${colName}]`]: order === "asc" ? "desc" : "asc",
          };
        });
      }}
    >
      {children}
    </div>
  );
}

function RequestsPreventionList({
  query,
  dateRange: { endDate, startDate },
  filters,
}) {
  const userProfilesPermissions = useQueryPermissions({
    users: useUserId().toString(),
  })?.data.map((profile) => {
    return profile.id;
  });
  const readOnlyRequest = false;

  const filterDate =
    endDate !== null
      ? {
          "createdAt[after]": new Date(startDate),
          "createdAt[before]": new Date(endDate),
        }
      : {};
  const isDesktop = useDesktop();
  const [orderFilter, setOrderFilter] = useState({
    "order[createdAt]": "desc",
  });
  const { data, fetchMore, canFetchMore } = useQueryInfiniteRequestsPrevention({
    pagination: false,
    orSearch_author_fullname: query,
    "type.slug": "prevention",
    ...filterDate,
    ...orderFilter,
    ...filters,
  });
  const [currentRequest, setCurrentRequest] = React.useState(null);

  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucune demande de prévention" />
      </div>
    );
  }
  const requests = flatMap(data, (page) => page["hydra:member"]);
  const gridTemplateColumn = "12% 10% 8% 35% 30% 5%";

  const csvHeaders = [
    { label: "Date", key: "dateFr" },
    { label: "Auteur", key: "authorFullName" },
    { label: "Site", key: "authorAgencies" },
    { label: "Emploi", key: "authorJob" },
    { label: "Matricule", key: "authorRegistrationNum" },
    { label: "Type demande", key: "typeRequest" },
    { label: "Message", key: "messageFix" },
    { label: "Commentaire", key: "comment" },
    { label: "Traité", key: "approvedString" },
  ];

  const csvData = map(requests, (item) => {
    const authorAgencies = map(
      item.createdBy.collaborator.agencies,
      (agency) => agency.label,
    );
    const uniqueAgencies = authorAgencies.filter(
      (x, i) => authorAgencies.indexOf(x) === i,
    );

    return {
      ...item,
      dateFr: item.createdAt
        ? new Date(item.createdAt).toLocaleDateString()
        : "",
      authorFullName: getFirstnameLastname(item, "createdBy.collaborator"),
      authorAgencies: uniqueAgencies.join("/"),
      authorJob: getOnlyJob(item, "createdBy.collaborator"),
      authorRegistrationNum: get(
        item,
        "createdBy.collaborator.registrationNumber",
        "",
      ),
      typeRequest: get(item, "type.label", ""),
      approvedString: item.approved ? "Oui" : "Non",
      comment: get(item, "comments.0.comment", "").replaceAll('"', ""),
      messageFix: item.message.replaceAll('"', ""),
    };
  });

  return (
    <div className="relative">
      <div className="absolute -top-16 py-4 right-0">
        <CSVLink
          filename="demandes_prevention.csv"
          className="btn-export"
          headers={csvHeaders}
          data={csvData}
        >
          Exporter
        </CSVLink>
      </div>
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"createdBy.username"}
          >
            Auteur
          </InlineBLockContentSortable>
          <InlineBLockContent>Site</InlineBLockContent>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"createdAt"}
          >
            Date
          </InlineBLockContentSortable>
          <InlineBLockContent>Message</InlineBLockContent>
          <InlineBLockContent>Commentaire</InlineBLockContent>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName={"approved"}
          >
            Traité
          </InlineBLockContentSortable>
        </div>
      )}

      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {isDesktop
          ? map(requests, (node) => {
              const firstComment = get(node, "comments.0.comment", "");
              const createdByAgencies = map(
                node.createdBy.collaborator.agencies,
                (agency) => agency.label,
              );
              // const uniqAgencies = createdByAgencies.filter((x, i) => createdByAgencies.indexOf(x) === i);
              const uniqAgencies =
                size(createdByAgencies) > 1
                  ? "MULTISITES"
                  : size(createdByAgencies) === 1
                  ? createdByAgencies[0]
                  : "";
              return (
                <div
                  onClick={() => {
                    setCurrentRequest(node);
                  }}
                  key={node.id}
                  className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2 cursor-pointer`}
                  style={{
                    gridTemplateColumns: gridTemplateColumn,
                  }}
                >
                  <InlineBLockContent>
                    {getFirstnameLastname(node, "createdBy.collaborator")}
                  </InlineBLockContent>
                  <InlineBLockContent>{uniqAgencies}</InlineBLockContent>
                  <InlineBLockContent>
                    {getLocaleDateString(node, "createdAt")}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    <InfoTooltip message={node.message} left onHover>
                      {node.message && (
                        <>
                          {node.message.substring(0, 60)}
                          {node.message.length > 60 ? "..." : ""}
                        </>
                      )}
                    </InfoTooltip>
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {firstComment && (
                      <InfoTooltip message={firstComment} left onHover>
                        {firstComment.substring(0, 60)}
                        {firstComment.length > 60 ? "..." : ""}
                      </InfoTooltip>
                    )}
                  </InlineBLockContent>
                  <InlineBLockContent>
                    {node.approved ? "Oui" : "Non"}
                  </InlineBLockContent>
                </div>
              );
            })
          : map(requests, (node) => (
              <RequestPreventionMobileCard
                key={node.id}
                request={node}
                readOnly={readOnlyRequest}
              />
            ))}
      </InfiniteScroll>
      <Modal
        title="Demande de prévention"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={!!currentRequest}
        onRequestClose={() => setCurrentRequest(null)}
      >
        <RequestForm
          request={currentRequest}
          readOnly={
            !userProfilesPermissions.includes(
              get(currentRequest, "type.assignedPermission.id", null),
            ) || get(currentRequest, "approved", false)
          }
        />
      </Modal>
    </div>
  );
}

function RequestsPrevention() {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: null,
  });
  const [filtersOpen, toggleFilters] = useToggle(false);
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({});
  const filtersList = [
    {
      label: "Traité",
      key: "approved",
      options: [
        {
          label: "Oui",
          id: "true",
        },
        {
          label: "Non",
          id: "false",
        },
      ],
    },
  ];

  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query],
  );

  React.useEffect(() => {
    var _paq = (window._paq = window._paq || []);
    _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);

    var u = "//matomo.kdix.pockost.com/";
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setSiteId", "1"]);
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = u + "matomo.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Demandes de prévention</title>
      </Helmet>
      <HeaderBar
        title={<>Demandes de prévention</>}
        smaller={true}
        sticky={true}
      >
        <input
          type="text"
          name="query"
          value={query}
          className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
          placeholder="Rechercher une demande de prévention"
          onChange={(e) => setQuery(e.target.value)}
        />
      </HeaderBar>

      <div>
        <div className="px-8 mt-8 mb-4">
          <button
            className="flex items-center"
            onClick={() => toggleFilters(true)}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>
          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            isOpen={filtersOpen}
            onRequestClose={() => toggleFilters(false)}
          >
            <div className="-mx-4 px-5">
              <div className="font-black mb-3">Sélectionner une période</div>
              <hr className="my-2" />
              <div className="text-center">
                <DatePicker
                  selected={dateRange.startDate}
                  onChange={([startDate, endDate]) => {
                    setDateRange({
                      startDate: startDate,
                      endDate: endDate,
                    });
                  }}
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  selectsRange
                  inline
                />
              </div>
              {map(filtersList, ({ key, label, options }) => {
                return (
                  <CheckboxGroup
                    key={key}
                    label={label}
                    options={options}
                    className="py-4 odd:bg-purple-50 -mx-4 px-5"
                    selected={get(filters, key, [])}
                    onAdd={(option) => {
                      setFilter(key, [...get(filters, key, []), option]);
                    }}
                    onRemove={(option) => {
                      const newFilters = pull(get(filters, key, []), option);
                      if (size(newFilters) === 0) {
                        removeFilter(key);
                        return;
                      }
                      setFilter(key, newFilters);
                    }}
                  />
                );
              })}
            </div>

            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  resetFilters();
                  setDateRange({
                    startDate: new Date(),
                    endDate: null,
                  });
                }}
              >
                Supprimer les filtres
              </div>
            </div>

            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <RequestsPreventionList
              query={debouncedQuery}
              dateRange={dateRange}
              filters={filters}
            />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default RequestsPrevention;
