import React from "react";
import { Helmet } from "react-helmet";

import Block from "../../components/Block";
import GoBackBtn from "../../components/GoBackBtn";
import CreateAccreditationForm from "../../forms/CreateAccreditationForm";
import { Redirect, useParams } from "react-router-dom";
import ErrorBoundary from "../../components/ErrorBoundary";
import AccreditationProvider, {
  AccreditationContext
} from "../../contexts/accreditation";
import Alert from "../../components/Alert";
import { get } from "lodash-es";

function AccreditationError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération de l'habilitation"
        details={get(error, "description")}
      />
    </div>
  );
}

function EditAccreditation() {
  const { accreditation, error } = React.useContext(AccreditationContext);

  if (error) {
    return <AccreditationError error={error} />;
  }

  return (
    <div className="p-8 pb-0">
      <Helmet>
        <title>Modifier une habilitation</title>
      </Helmet>
      <Block>
        <GoBackBtn
          to="/rh/habilitations/catalogue"
          className="flex items-center"
        >
          <div className="font-bold">Retour</div>
        </GoBackBtn>

        <div className="max-w-lg mx-auto">
          <div className="text-2xl font-bold mb-8 mt-8">
            Modifier une habilitation
          </div>
          <CreateAccreditationForm
            accreditation={get(accreditation, "data", null)}
          />
        </div>
      </Block>
    </div>
  );
}

function EditAccreditationPage() {
  let { id } = useParams();
  id = parseInt(id);
  if (isNaN(id)) {
    return <Redirect to="/rh/habilitations/catalogue" />;
  }
  return (
    <ErrorBoundary fallback="Récupération de l'habilitation impossible">
      <AccreditationProvider id={id}>
        <EditAccreditation />
      </AccreditationProvider>
    </ErrorBoundary>
  );
}

export default EditAccreditationPage;
