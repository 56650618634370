import React from "react";
import { useDesktop } from "../../hooks/useDesktop";
import { Helmet } from "react-helmet";
import { get } from "lodash-es";
import { HeaderBar } from "../../components/HeaderBar";
import { useQueryWorkById } from "../../api/works";
import GoBackBtn from "../../components/GoBackBtn";
import { useParams } from "react-router-dom";
import WorkForm, { WorkAddDomainForm } from "../../forms/WorkForm";
import { PermissionChecker } from "../../contexts/permissions";
import Dropdown from "../../components/Dropdown";
import Modal from "../../components/Modal";
import { ReactComponent as PlusIcon } from "../../svgs/plus.svg";

export default function WorkDetail() {
  const { id } = useParams();
  const isDesktop = useDesktop();
  const { data: work } = useQueryWorkById(id);
  return (
    <div>
      <Helmet>
        <title>{get(work, "label", "Création d'une fiche de poste")}</title>
      </Helmet>
      <HeaderBar buttons={<CreateDropdown work={work} />}>
        {get(work, "label", "Création d'une fiche de poste")}
      </HeaderBar>
      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="px-8 mt-8 mb-4">
          <div>
            <div className={"w-20 mb-10"}>
              <GoBackBtn to={`/rh/fiches_poste`} className="flex items-center">
                <div className="font-bold">Retour</div>
              </GoBackBtn>
            </div>
            <WorkForm work={work} />
          </div>
        </div>
      </div>
    </div>
  );
}

function CreateDropdown({ work }) {
  const permissionsProcess = ["kdix.actions.skill_evaluation.edit_create"];
  const [modalAddDomainOpen, setAddDomainOpen] = React.useState(false);
  if (!work) return null;
  return (
    <PermissionChecker permissions={permissionsProcess}>
      <div className="cursor-pointer">
        <Dropdown
          label={
            <div className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none z-20">
              <PlusIcon className="w-4 h-4" />
            </div>
          }
          arrow={false}
          childrenClassName="bg-gray-700 text-white shadow-lg z-40 mx-2 text-base"
          childrenStyle={{ minWidth: "280px", right: "3rem", top: "6rem" }}
          relative={false}
        >
          <div
            className={"text-white flex p-4 hover:text-black hover:bg-white"}
            onClick={() => {
              setAddDomainOpen(true);
            }}
          >
            <span>Ajouter un domaine de compétence</span>
          </div>
        </Dropdown>
      </div>
      <ImportWorksModal
        setIsOpen={setAddDomainOpen}
        modalIsOpen={modalAddDomainOpen}
        work={work}
      />
    </PermissionChecker>
  );
}

function ImportWorksModal({ modalIsOpen, setIsOpen, work }) {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title={"Ajouter un domaine de compétence"}
      >
        <WorkAddDomainForm work={work} onSuccess={() => setIsOpen(false)} />
      </Modal>
    </>
  );
}
