import React from "react";
import HistoryItem from "./HistoryItem";
import { ReactComponent as PreventionIcon } from "../../svgs/prevention.svg";
import CreateWorkAccidentForm from "../../forms/CreateWorkAccidentForm";
import Spinner from "../Spinner";
import { get } from "lodash-es";

export default function WorkAccidentHistoryItem({
  workAccident = {},
  date,
  isLeft,
}) {
  return (
    <HistoryItem
      title={
        get(workAccident, "type.label", "") !== ""
          ? `Accident du travail - ${get(workAccident, "type.label", "")}`
          : "Accident du travail"
      }
      Icon={PreventionIcon}
      backgroundIcon="bg-red-950"
      date={`${date ? new Date(date).toLocaleDateString() : ""}`}
      itemId={workAccident.id}
      isToggle={true}
      textColor={"text-red-950"}
      isLeft={isLeft}
    >
      <React.Suspense fallback={<Spinner />}>
        <CreateWorkAccidentForm workAccident={workAccident} readOnly={true} />
      </React.Suspense>
    </HistoryItem>
  );
}
