import React from "react";
import Request from "../components/Request";
import { Helmet } from "react-helmet";
import { useDesktop } from "../hooks/useDesktop";
import { HeaderBar } from "../components/HeaderBar";

export default function SendRequest() {
   const isDesktop = useDesktop();
         
  return (
          
     <div>
      <Helmet>
        <title>Envoyer une demande</title>
      </Helmet>
      <HeaderBar>Envoyer une demande</HeaderBar>     
      <div className="p-8 pb-24">
          <div>
            <Request isSidebarOpen={true} isDesktop={isDesktop} keepOpen={true} />
          </div>
      </div>
    </div>
  );
}
