import React, { useContext, useState } from "react";
import { useQueryDomains } from "../api/domain";
import { get, map } from "lodash-es";
import { useUpdateUser } from "../api/user";
import PermissionsContext, { useUserId } from "../contexts/permissions";
import { toast } from "react-toastify";
import { refreshToken } from "../api";
import Block from "../components/Block";
import Alert from "../components/Alert";
import ExpansionPanel from "../components/ExpansionPanel";
import { ReactComponent as ArrowRightSideIcon } from "../svgs/arrow-right-side.svg";
import DomainForm from "../forms/DomainForm";
import DeleteDomain from "../forms/DeleteDomain";
import Modal from "../components/Modal";
import LogoForm from "../forms/LogoForm";

function GoToDomain({ domain }) {
  const [updateUser] = useUpdateUser();
  const userId = useUserId();
  const { setToken } = useContext(PermissionsContext);
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <div className={"mr-2"}>
      <div
        onClick={async (e) => {
          if (isLoading || !domain.active) return;
          e.stopPropagation();
          e.preventDefault();
          try {
            setIsLoading(true);
            await updateUser({
              id: userId,
              data: { domain: domain["@id"] },
            });
            const token = await refreshToken();
            setToken(token);
            setIsLoading(false);
            toast.success("Changement de domaine effectué");
          } catch (error) {
            map(get(error, "violations"), (e) => {
              console.log("error", e.message);
            });
          }
        }}
        className={`h-12 w-12 rounded-full ${
          domain.active ? "bg-green-600" : "bg-gray-300"
        } inline-flex relative items-center justify-center mr-1`}
      >
        {isLoading ? (
          <div>
            <div className="loader h-6 w-6 mx-auto" />
          </div>
        ) : (
          <ArrowRightSideIcon className="fill-current text-white w-6 h-6" />
        )}
      </div>
    </div>
  );
}

export default function DomainAdmin() {
  const [modalDelete, setModalDelete] = useState(false);
  const { data, error } = useQueryDomains({
    "order[subDomainName]": "asc",
  });
  if (error) {
    return (
      <Alert
        type="error"
        message={get(error, "title", "Erreur")}
        details={get(
          error,
          "description",
          `Récupération des domaines impossible`
        )}
      />
    );
  }

  return (
    <>
      <div className="p-8 pb-24">
        <Block>
          <div className="font-bold text-xl leading-tight mb-8">
            Administration déploiement
          </div>
          <Block>
            <div className="font-bold text-lg leading-tight mb-8">
              Ajouter un logo sur la page de login (tous domaines)
            </div>
            <LogoForm />
          </Block>
          <Block>
            <div className="font-bold text-lg leading-tight mb-8">
              Ajouter un domaine
            </div>
            <DomainForm />
          </Block>
          {map(data, (domain, index) => {
            return (
              <ExpansionPanel
                key={index}
                title={domain.subDomainName}
                buttons={<GoToDomain domain={domain} />}
                customStyle={domain.active ? "default" : "gray100"}
              >
                <DomainForm
                  domain={domain}
                  deleteDomain={(domain) => {
                    setModalDelete({
                      domainTitle: domain.subDomainName,
                      domainId: domain.id,
                    });
                    window.scrollTo(0, 0);
                  }}
                />
              </ExpansionPanel>
            );
          })}
        </Block>
        <Modal
          title={`Supprimer le domaine "${modalDelete.domainTitle}"`}
          handleClose={(e) => {
            e.stopPropagation();
          }}
          isOpen={modalDelete !== false}
          onRequestClose={() => setModalDelete(false)}
        >
          <div className="bg-white">
            <DeleteDomain
              id={modalDelete.domainId}
              onSuccess={() => setModalDelete(false)}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}
