import {
  useCreateStatusContract,
  useDeleteStatusContract,
  useQueryStatusContracts,
  useUpdateStatusContract,
} from "../api/statusContracts";

import ManageResource from "../components/ManageResource";
import React from "react";
import { useIsSilae } from "../contexts/permissions";

function ContractStatusAdmin() {
  const isSilae = useIsSilae();
  return (
    <ManageResource
      resourceLabel="Statut de contrat"
      createLabel="Ajouter un Statut de contrat"
      createHook={useCreateStatusContract}
      queryHook={useQueryStatusContracts}
      deleteHook={useDeleteStatusContract}
      updateHook={useUpdateStatusContract}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
      }}
      readOnly={isSilae}
    />
  );
}

export default ContractStatusAdmin;
