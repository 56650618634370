import queryWithToken, {
  create,
  deleteById,
  findById,
  findExport,
  updateById,
  updateOrCreate,
} from "./index";
import { queryCache, useMutation, useQuery } from "react-query";
import fileDownload from "js-file-download";
import { get } from "lodash-es";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "absences";
//

const mutateDefaultOptions = {
  throwOnError: true,
  // When mutate is called:
  onMutate: () => {
    queryCache.cancelQueries(ressourceKey);
  },
};

export function useQueryAbsenceById(id) {
  return useQuery(id && [ressourceKey, id], findById, {
    refetchOnWindowFocus: false,
  });
}

export function useQueryAbsences(filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}/missings`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useCreateAbsence() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: (newAbsence = null) => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("history");
      queryCache.refetchQueries("work_accidents");
      toast.success("La demande d'absence a bien été créée");
    },
  });
}

export function useUpdateAbsence() {
  return useMutation(updateById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries([ressourceKey]);
      queryCache.refetchQueries("history");
    },
  });
}
function transitionQuery({ id, transition, commentAbsenceEvent, exceptional }) {
  return queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/${transition}`,
    {
      method: "PATCH",
      data: {
        commentAbsenceEvent: commentAbsenceEvent,
        exceptional: exceptional,
      },
    }
  );
}

export function useTransitionAbsence() {
  return useMutation(transitionQuery, {
    ...mutateDefaultOptions,
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries([ressourceKey]);
      queryCache.refetchQueries("history");
      queryCache.refetchQueries("absences_to_prevalidate");
      queryCache.refetchQueries("absences_to_validate");
    },
  });
}

export function useCreateOrUpdateAbsence() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    ...mutateDefaultOptions,
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("history");
      queryCache.refetchQueries("work_accidents");
    },
  });
}

export function useDeleteAbsence(resetHistory = false) {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      if (resetHistory) {
        queryCache.refetchQueries("history");
      }
    },
    onError: () => {
      toast.error("Erreur lors de la suppression");
    },
  });
}

export async function downloadAbsenceExport(filters) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, "export_absences.csv");
}
