import React, { useRef } from "react";
import { useClickAway, useToggle } from "react-use";

function Dropdown({
  label,
  image,
  arrow = false,
  children,
  relative = true,
  childrenClassName,
  childrenStyle,
}) {
  const ref = useRef(null);
  const [isOpen, toggle] = useToggle(false);
  useClickAway(ref, () => {
    toggle(false);
  });

  if (!children) return null;

  return (
    <div ref={ref} className={`ml-auto ${relative ? "relative" : ""}`}>
      <button
        type="button"
        className="flex items-center text-white focus:outline-none"
        onClick={() => toggle()}
      >
        {image ? (
          <img
            className="rounded-full h-6 w-6 object-cover"
            src="//placehold.it/50x50"
            alt="Avatar profil"
          />
        ) : null}
        <span className="mx-2 text-sm">{label}</span>
        {arrow ? (
          <span>
            <svg
              width="9"
              height="5"
              viewBox="0 0 9 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.95 0L4.5 3.10811L1.05 0L0 0.945946L4.5 5L9 0.945946L7.95 0Z"
                fill="white"
              />
            </svg>
          </span>
        ) : null}
      </button>
      {isOpen ? (
        <div 
          onClick={() => toggle()}
          style={childrenStyle}
          className={`absolute ${
            childrenClassName
              ? childrenClassName
              : "right-0 top-auto w-64 bg-white shadow-lg mt-2 py-2"
          }`}
        >
          {children}
        </div>
      ) : null}
    </div>
  );
}

export default Dropdown;
