import VehicleProvider, { VehicleContext } from "../../contexts/vehicle";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { get, map } from "lodash-es";

import Alert from "../../components/Alert";
import { ReactComponent as ArrowRightIcon } from "../../svgs/arrow-right.svg";
import Documents from "./Documents";
import ErrorBoundary from "../../components/ErrorBoundary";
import General from "./General";
import Header from "./Header";
import { Helmet } from "react-helmet";
import Historique from "./Historique";
import { PermissionChecker } from "../../contexts/permissions";
import React from "react";
import Spinner from "../../components/Spinner";

function PageVehicleHeader() {
  const { vehicle } = React.useContext(VehicleContext);
  let title = "Chargement du véhicule...";

  if (get(vehicle, "data.id")) {
    title = `${vehicle.data.brand} ${vehicle.data.model}`;
  }

  React.useEffect(() => {
            var _paq = window._paq = window._paq || [];
            _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
       
            var u="//matomo.kdix.pockost.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    }, [])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Header
        vehicleId={get(vehicle, "data.id")}
        brand={get(vehicle, "data.brand")}
        model={get(vehicle, "data.model")}
        licencePlate={get(vehicle, "data.licencePlate")}
        active={get(vehicle, "data.active")}
      />
    </>
  );
}

function NavBarItem({ to, label, permissions, userId }) {
  return (
    <PermissionChecker
      permissions={permissions}
      location={label}
      userId={userId}
    >
      <NavLink
        to={to}
        className="inline-block focus:outline-none border-b-4 border-transparent px-4 py-2 whitespace-no-wrap"
        activeClassName="border-purple-600 font-bold"
      >
        {label}
      </NavLink>
    </PermissionChecker>
  );
}

function NavBar() {
  let { url } = useRouteMatch();
  const permissionsRead = [
    "kdix.actions.vehicle.view",
    "kdix.actions.vehicle.view.agency",
    "kdix.actions.vehicle.view.department",
    "kdix.actions.vehicle.view.service",
    "kdix.actions.vehicle.view.own",
  ];
  const { vehicle } = React.useContext(VehicleContext);
  const userId = get(vehicle, "data.collaborator.linkedUser.id");
  const agency = [get(vehicle, "data.agency[@id]", null)];

  const showArrows = true;
  const config = [
    {
      to: `${url}/general`,
      label: "Général",
      permissions: permissionsRead,
      userId,
    },
    {
      to: `${url}/historique`,
      label: "Historique",
      permissions: permissionsRead,
      agencies: agency,
      userId,
    },
    {
      to: `${url}/documents`,
      label: "Documents",
      agencies: agency,
      permissions: permissionsRead,
      userId,
    },
  ];
  const navRef = React.useRef(null);

  return (
    <div className="relative mb-6 bg-purple-200 -mx-8 px-8">
      {showArrows ? (
        <ArrowRightIcon className="absolute left-0 top-0 w-2 h-5 fill-current text-purple-700 mt-3 ml-3 transform rotate-180" />
      ) : null}
      <div ref={navRef} className="flex overflow-x-auto">
        {map(config, (item, index) => (
          <NavBarItem key={index} {...item} />
        ))}
      </div>
      {showArrows ? (
        <ArrowRightIcon className="absolute right-0 top-0 w-2 h-5 fill-current text-purple-700 mt-3 mr-3" />
      ) : null}
    </div>
  );
}

function VehicleError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération du véhicule"
        details={get(error, "description")}
      />
    </div>
  );
}

function Vehicle() {
  let { url } = useRouteMatch();
  const { error } = React.useContext(VehicleContext);

  if (error) {
    return <VehicleError error={error} />;
  }

  return (
    <div className="pb-24">
      <PageVehicleHeader />
      <div className="px-8">
        <NavBar />
        <div className="relative">
          <ErrorBoundary fallback="Erreur lors de l'accès au véhicule">
            <React.Suspense fallback={<Spinner />}>
              <Switch>
                <Route path={`${url}/general`}>
                  <General />
                </Route>
                <Route path={`${url}/historique`}>
                  <Historique />
                </Route>
                <Route path={`${url}/documents`}>
                  <Documents />
                </Route>
                <Route path={`${url}`}>
                  <Redirect to={`${url}/general`} />
                </Route>
              </Switch>
            </React.Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}

function VehiclePage() {
  let { id } = useParams();
  id = parseInt(id);
  if (isNaN(id)) {
    return <Redirect to="/rh/vehicules" />;
  }
  return (
    <ErrorBoundary fallback="Récupération du véhicule impossible">
      <VehicleProvider id={id}>
        <Vehicle />
      </VehicleProvider>
    </ErrorBoundary>
  );
}

export default VehiclePage;
