import { DisplayInput } from "./Input";
import { get } from "lodash-es";
import React from "react";

function ContractInfosLight({ contract }) {
  return (
    <div>
      <DisplayInput
        label="Catégorie"
        value={get(contract, "data.category.label", "")}
      />
      
      <div className={"flex flex-wrap"}>
      <div className={"w-full lg:w-1/3 lg:pr-2"}>
      <DisplayInput
        label="Coefficient"
        value={get(contract, "data.coefficient", "")}
      />
      </div>
      
      <div className={"w-full lg:w-1/3 lg:px-2"}>
      <DisplayInput
        label="Niveau"
        value={get(contract, "data.level", "")}
      />
      </div>
      
      <div className={"w-full lg:w-1/3 lg:pl-2"}>
      <DisplayInput
        label="Position"
        value={get(contract, "data.position", "")}
      />
      </div>
      </div>
      
    </div>
  );
}

export default ContractInfosLight;
