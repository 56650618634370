import { ReactComponent as FileIcon } from "../../svgs/download-file.svg";
import React from "react";

export default function FileDownloadLine({ downloadFn, label }) {
  const [isLoading, setLoading] = React.useState(false);
  return (
    <>
      <label className="block text-black text-sm font-bold mb-3">{label}</label>
      <button
        disabled={isLoading}
        onClick={() => {
          setLoading(true);
          downloadFn().then(() => {
            setLoading(false);
          });
        }}
        className={`${
          isLoading ? "text-gray-500 cursor-pointer" : ""
        } border-black border-b
           w-full py-2 leading-tight flex items-center`}
      >
        {isLoading ? (
          <div>
            <div className="loader h-4 w-4 mr-4 mx-auto" />
          </div>
        ) : (
          <FileIcon className="fill-current text-black h-4 w-4 mr-4" />
        )}
        <span>Télécharger le document</span>
      </button>
    </>
  );
}
