import { DisplayInput } from "../Input";
import HistoryItem from "./HistoryItem";
import React from "react";
import { ReactComponent as RequestIcon } from "../../svgs/request.svg";
import { get, map } from "lodash-es";
import {getFirstnameLastname} from "../../utils/names";
import {useQueryLokoaRequestAnswers} from "../../api/lokoaRequestAnswers";

function LokoaRequestHistoryItem({ request, date, isLeft }) {
  const createdAt = new Date(get(request, "createdAt")).toLocaleDateString();
  const project = get(request, "lokoaProject");
  const type = get(request, "requestType");
  const answers = useQueryLokoaRequestAnswers({lokoaRequest: request.id}).data;

  return (
    <HistoryItem
      title={
        `Demande chantier - ${project.label}`
      }
      Icon={RequestIcon}
      backgroundIcon="bg-purple-600"
      date={`${date ? new Date(date).toLocaleDateString() : ""}`}
      subTitle={createdAt}
      itemId={request.id}
      isToggle={true}
      textColor={"text-purple-600"}
      isLeft={isLeft}
    >
      <div className={"flex flex-wrap"}>
        <div className={"w-full lg:w-1/2 lg:pr-2"}>
          <DisplayInput
            label="Type de demande"
            value={get(type, "label")}
          />
        </div>
      </div>

      <DisplayInput label="Message" value={get(request, "message")} />
      <div className="pb-6">
        {map(answers,
          ({ createdAt, message, createdBy }, index) => (
            <div
              key={index}
              className="py-3 border-b border-gray-200 last:border-b-0"
            >
              <div className="font-bold py-1">
                {getFirstnameLastname(createdBy)}
                <span className="text-sm italic font-normal pl-1">
                  {new Date(createdAt).toLocaleDateString()}
                </span>
              </div>
              <div>{message}</div>
            </div>
          )
        )}
      </div>
    </HistoryItem>
  );
}

export default LokoaRequestHistoryItem;
