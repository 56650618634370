import {
  useCreateTypeWorkAccidentLocation,
  useDeleteTypeWorkAccidentLocation,
  useQueryTypeWorkAccidentLocation,
  useUpdateTypeWorkAccidentLocation
} from "../api/typeWorkAccidentLocation";

import ManageResource from "../components/ManageResource";
import React from "react";

function TypeWorkAccidentLocationAdmin() {
  return (
    <ManageResource
      resourceLabel="Types de lieux - Accident"
      createLabel="Ajouter un type de lieu - Accident"
      createHook={useCreateTypeWorkAccidentLocation}
      queryHook={useQueryTypeWorkAccidentLocation}
      deleteHook={useDeleteTypeWorkAccidentLocation}
      updateHook={useUpdateTypeWorkAccidentLocation}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label"
        }
      }}
    />
  );
}

export default TypeWorkAccidentLocationAdmin;
