import Block from "../Block";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { get, map, size } from "lodash-es";
import { useGetUserAlerts } from "../../api/user";

function AlertsBlock({ userIri, userId }) {
  const filters = { archived: [false], active: [true] };
  const { data: alerts } = useGetUserAlerts(userId, filters);
  const nbAlerts = size(alerts);
  const nbNewAlerts = size(
    alerts.filter((a) => {
      const consulted = map(get(a, "consultedBy"), "@id");
      return !consulted.includes(userIri);
    })
  );
  return (
    <>
      <Block className="text-center flex-grow bg-white">
        <Link
          to={`/alertes`}
          className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
        >
          <div
            className={`bg-green-400 inline-flex text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
          >
            {nbAlerts}
          </div>
          {nbNewAlerts ? (
            <span
              className="unread bg-red-700 rounded-full text-white text-xs absolute w-5 h-5 ml-1 -mt-3"
              style={{ marginLeft: "43px", marginTop: "5px" }}
            >
              &nbsp;
            </span>
          ) : null}
          <div className="mt-3 text-xl font-black">
            {`Alerte${nbAlerts > 1 ? "s" : ""}`}
          </div>
          <div className="mt-2 text-base font-light">
            {`dont ${nbNewAlerts} nouvelle${nbNewAlerts > 1 ? "s" : ""}`}
          </div>
          <Button
            className={`font-bold p-1 focus:outline-none text-white w-full block`}
          >
            voir tout
          </Button>
        </Link>
      </Block>
    </>
  );
}

export default AlertsBlock;
