import { Field, useFormikContext } from "formik";
import { get, map, size } from "lodash-es";

import Input, { DateTimePickerInput } from "../components/Input";
import React from "react";
import Select from "../components/Select";
import { useGetCountries } from "../api.js";
import { useQueryNationalities } from "../api/nationalities";
import {
  PermissionChecker,
  usePermissionsChecker,
  useUseExternalDocumentManagement,
} from "../contexts/permissions";
import DownloadOrUpload from "../components/DownloadOrUpload";
import { downloadDocument } from "../api/idCard";
import FileDownloadLine from "../components/Button/FileDownloadLine";
import {downloadResidencePermitDocument} from "../api/residencePermitDocument";

function PersonalInfos({ personalInformations }) {
  //const isSilae = useIsSilae();
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.personal_information.edit",
      "kdix.actions.personal_information.edit.agency",
      "kdix.actions.personal_information.edit.department",
      "kdix.actions.personal_information.edit.service",
      "kdix.actions.personal_information.edit.own",
    ],
  });

  const { data: nationalities } = useQueryNationalities({
    pagination: false,
    page: null,
  });

  const { data: countries } = useGetCountries({
    pagination: false,
    page: null,
  });
  const { values, isSubmitting } = useFormikContext();

  const permissionsView = [
    "kdix.actions.personal_information.view",
    "kdix.actions.personal_information.view.agency",
    "kdix.actions.personal_information.view.department",
    "kdix.actions.personal_information.view.service",
    "kdix.actions.personal_information.view.own",
  ];
  const collaborator = get(personalInformations, "collaborator", null);
  const idUser = get(collaborator, "data.linkedUser.id", null);
  const useExternalDocumentManagement = useUseExternalDocumentManagement();

  return (
    <div>
      <Input
        type="text"
        label="Lieu de naissance"
        name="birthPlace"
        readOnly={readOnly}
      />
      <Select
        label="Pays de naissance"
        name="birthCountry"
        options={map(countries, (node) => ({
          label: node.name,
          value: get(node, "@id"),
        }))}
        value={values.birthCountry}
        isDisabled={readOnly}
        isLoading={size(countries) <= 0}
      />
      <Select
        label="Nationalité(s)"
        isMulti
        name="nationalities"
        options={map(nationalities, (node) => ({
          label: node.name,
          value: get(node, "@id"),
        }))}
        value={values.nationalities}
        isDisabled={readOnly}
        isLoading={size(nationalities) <= 0}
      />
      <Input
        type="text"
        label="n° Sécurité sociale"
        name="socialSecurityNumber"
        readOnly={readOnly}
      />
      <Input
        type="text"
        label="n° Adhérent Mutuelle"
        name="healthInsuranceNumber"
      />
      <DateTimePickerInput name="affilitationDate" label="Date d'affiliation" />

      <div className="mb-8 flex justify-between items-center border-b border-black pb-6">
        <label
          htmlFor="residencePermit"
          className={`block text-black text-sm font-bold `}
        >
          Titre de séjour
        </label>
        <Field
          id="residencePermit"
          type="checkbox"
          name="residencePermit"
          checked={values.residencePermit}
        />
      </div>

      <Input
        type="text"
        label="N° Permis de séjour"
        name="residentPermitNumber"
        readOnly={readOnly}
      />
      <DateTimePickerInput
        name="residencePermitExpirationDate"
        label="Date de fin de validité"
        readOnly={readOnly}
      />
      <PermissionChecker permissions={permissionsView} userId={idUser}>
        {true !== personalInformations.residencePermit && personalInformations.idCard?.id && (
          <div className="w-full mb-5">
            <FileDownloadLine
              label="Carte d'identité"
              downloadFn={downloadDocument.bind(
                null,
                personalInformations.idCard.id,
                personalInformations.idCard.label
              )}
            />
          </div>
        )}
        {true === personalInformations.residencePermit && personalInformations.residencePermitDocument?.id && (
            <div className="w-full mb-5">
                <FileDownloadLine
                    label="Permis de séjour"
                    downloadFn={downloadResidencePermitDocument.bind(
                        null,
                        personalInformations.residencePermitDocument.id,
                        personalInformations.residencePermitDocument.label
                    )}
                />
            </div>
        )}
      </PermissionChecker>
      {!useExternalDocumentManagement && (
        <DownloadOrUpload
          id="idCard"
          name="idCard"
          accept="image/png, image/jpeg, application/pdf"
          isSubmitting={isSubmitting}
          uploadLabel="Ajouter"
          submitButton={false}
          downloadFn={!!get(personalInformations, "idCard.id", false)}
        />
      )}
    </div>
  );
}

export default PersonalInfos;
