import queryWithToken, { findById, updateById } from "./index";
import { queryCache, useMutation, useQuery } from "react-query";
import { get } from "lodash-es";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "absence_notifications";
//

export function useQueryAbsenceNotificationById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQueryAbsenceNotifications(filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useUpdateAbsenceNotification() {
  return useMutation(updateById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      toast.success("Notification modifiée avec succès");
    },
  });
}
