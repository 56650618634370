import * as Yup from "yup";
import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import Alert from "../components/Alert";
import React from "react";
import { usePermissionsChecker } from "../contexts/permissions";
import { useUpdateCollaborator } from "../api/collaborator";
import { toast } from "react-toastify";
import Button from "../components/Button";
import SelectCollaborator from "../components/SelectCollaborator";
import { getFirstnameLastnameJob } from "../utils/names";

const validation = Yup.object().shape({});

export default function AbsenceCollaboratorForm({ collaborator }) {
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.collaborator.edit",
      "kdix.actions.collaborator.edit.agency",
      "kdix.actions.collaborator.edit.department",
      "kdix.actions.collaborator.edit.service",
      "kdix.actions.collaborator.edit.own",
    ],
  });
  const [updateCollaborator, { error }] = useUpdateCollaborator();
  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={validation}
        initialValues={{
          validators: map(collaborator.validators, (validator) => {
            //Si on peut se valider soit même, le collab n'est pas sérialisé à ce niveau,
            // on récupère les infos au niveau valideur
            if (typeof validator === "string") {
              if (validator === get(collaborator, "@id", "")) {
                return {
                  value: get(collaborator, "@id", ""),
                  label: collaborator
                    ? getFirstnameLastnameJob(collaborator)
                    : "",
                };
              }
            } else {
              return {
                value: validator["@id"],
                label: getFirstnameLastnameJob(validator),
              };
            }
          }),
          preValidators: map(collaborator.prevalidators, (preValidator) => {
            //Si on peut se valider soit même, le collab n'est pas sérialisé à ce niveau,
            // on récupère les infos au niveau valideur
            if (typeof preValidator === "string") {
              if (preValidator === get(collaborator, "@id", "")) {
                return {
                  value: get(collaborator, "@id", ""),
                  label: collaborator
                    ? getFirstnameLastnameJob(collaborator)
                    : "",
                };
              }
            } else {
              return {
                value: preValidator["@id"],
                label: getFirstnameLastnameJob(preValidator),
              };
            }
          }),
        }}
        onSubmit={async (values, actions) => {
          try {
            await updateCollaborator({
              id: collaborator.id,
              data: {
                validators: map(
                  values.validators,
                  (validator) => validator.value
                ),
                prevalidators: map(
                  values.preValidators,
                  (preValidator) => preValidator.value
                ),
              },
            });
            toast.success("Mise à jour effectuée avec succès");
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <SelectCollaborator
                type="text"
                name="validators"
                label="Valideur"
                value={values.validators}
                isDisabled={readOnly}
                isMulti
              />
              <SelectCollaborator
                type="text"
                name="preValidators"
                label="Pré Valideur"
                value={values.preValidators}
                isDisabled={readOnly}
                isMulti
              />

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 ml-auto block`}
                readOnly={readOnly}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
