import { reduce } from "lodash-es";

export function getListPermissionsClickable() {
  const listPermissions = [
    "kdix.actions.personal_information.view",
    "kdix.actions.additional_information.view",
    "kdix.actions.general_document.view",
    "kdix.actions.collaborator.activity.view",
    "kdix.actions.collaborator.history.view",
    "kdix.actions.confidential_document.view",
  ];

  return reduce(
    listPermissions,
    (result, value) => [
      ...result,
      value,
      `${value}.agency`,
      `${value}.department`,
      `${value}.service`,
    ],
    []
  );
}
