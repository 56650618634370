import { Form, Formik } from "formik";
import {
  GLOVES_SIZES,
  SHIRT_SIZES,
  SHOE_SIZES,
  TROUSER_SIZES,
} from "../graphql";
import { get, map } from "lodash-es";

import Alert from "../components/Alert";
import React from "react";
import Select from "../components/Select";
import { useQuery } from "@apollo/react-hooks";
import { usePermissionsChecker } from "../contexts/permissions";
import { useUpdateClothSize } from "../api/clothSize";
import { toast } from "react-toastify";
import Button from "../components/Button";

export default function ClothingSizesForm({ collaborator }) {
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.collaborator.edit",
      "kdix.actions.collaborator.edit.agency",
      "kdix.actions.collaborator.edit.department",
      "kdix.actions.collaborator.edit.service",
      "kdix.actions.collaborator.edit.own",
    ],
  });
  const shoeSizes = useQuery(SHOE_SIZES);
  const trouserSizes = useQuery(TROUSER_SIZES);
  const shirtSizes = useQuery(SHIRT_SIZES);
  const gloveSizes = useQuery(GLOVES_SIZES);

  const [updateClothSize, { error }] = useUpdateClothSize();

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          shoeSize: {
            label: get(collaborator, "clothSize.shoeSize.size", ""),
            value: get(collaborator, "clothSize.shoeSize.@id", ""),
          },
          trouserSize: {
            label: get(collaborator, "clothSize.trouserSize.size", ""),
            value: get(collaborator, "clothSize.trouserSize.@id", ""),
          },
          shirtSize: {
            label: get(collaborator, "clothSize.shirtSize.size", ""),
            value: get(collaborator, "clothSize.shirtSize.@id", ""),
          },
          gloveSize: {
            label: get(collaborator, "clothSize.gloveSize.size", ""),
            value: get(collaborator, "clothSize.gloveSize.@id", ""),
          },
        }}
        onSubmit={async (values, actions) => {
          const payload = {
            collaborator: get(collaborator, "@id"),
            gloveSize: values.gloveSize.value || null,
            shoeSize: values.shoeSize.value || null,
            trouserSize: values.trouserSize.value || null,
            shirtSize: values.shirtSize.value || null,
          };

          try {
            await updateClothSize({
              id: get(collaborator, "clothSize.id"),
              data: payload,
            });

            toast.success("Mise à jour effectuée avec succès");
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ values, isSubmitting }) => {
          return (
            <Form>
              {shoeSizes ? (
                <Select
                  label="Pointure Chaussures"
                  name="shoeSize"
                  options={map(
                    get(shoeSizes, "data.shoeSizes.edges"),
                    ({ node }) => ({
                      label: node.size,
                      value: node.id,
                    })
                  )}
                  value={values.shoeSize}
                  isDisabled={readOnly}
                  isLoading={!shoeSizes.data}
                />
              ) : null}
              {trouserSizes ? (
                <Select
                  label="Taille Pantalon"
                  name="trouserSize"
                  options={map(
                    get(trouserSizes, "data.trouserSizes.edges"),
                    ({ node }) => ({
                      label: node.size,
                      value: node.id,
                    })
                  )}
                  value={values.trouserSize}
                  isDisabled={readOnly}
                  isLoading={!trouserSizes.data}
                />
              ) : null}
              {shirtSizes ? (
                <Select
                  label="Taille Polo / Chemise / Veste"
                  name="shirtSize"
                  options={map(
                    get(shirtSizes, "data.shirtSizes.edges"),
                    ({ node }) => ({
                      label: node.size,
                      value: node.id,
                    })
                  )}
                  value={values.shirtSize}
                  isDisabled={readOnly}
                  isLoading={!shirtSizes.data}
                />
              ) : null}
              {gloveSizes ? (
                <Select
                  label="Taille Gants"
                  name="gloveSize"
                  options={map(
                    get(gloveSizes, "data.gloveSizes.edges"),
                    ({ node }) => ({
                      label: node.size,
                      value: node.id,
                    })
                  )}
                  value={values.gloveSize}
                  isDisabled={readOnly}
                  isLoading={!gloveSizes.data}
                />
              ) : null}

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 ml-auto block`}
                readOnly={readOnly}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
