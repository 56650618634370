import { queryCache, useMutation, useQuery } from "react-query";
import queryWithToken, { findById } from "./index";

import { get } from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "logos";
//

export function useQueryJobById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQueryLogo() {
  const { data, ...rest } = useQuery(
    ressourceKey,
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member.0"),
    ...rest,
  };
}

export function useCreateOrUpdateLogo() {
  return useMutation(
    ({ file }) => {
      const data = new FormData();
      data.append("file", file);
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
        {
          method: "POST",
          data,
        }
      );
    },
    {
      throwOnError: true,
      onSuccess: (data) => {
        queryCache.refetchQueries(ressourceKey);
        queryCache.refetchQueries(["logos"]);
      },
    }
  );
}
