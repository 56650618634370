import Input from "../components/Input";
import React from "react";
import { useIsSilae, usePermissionsChecker } from "../contexts/permissions";

export default function BankingInformations() {
  const isSilae = useIsSilae();
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.collaborator.edit",
      "kdix.actions.collaborator.edit.agency",
      "kdix.actions.collaborator.edit.department",
      "kdix.actions.collaborator.edit.service",
    ],
  });

  return (
    <div>
      <Input type="text" label="Banque" name="bankName" readOnly={readOnly} />
      <Input
        type="text"
        label="IBAN"
        name="iban"
        readOnly={readOnly || isSilae}
      />
      <Input
        type="text"
        label="BIC"
        name="bic"
        readOnly={readOnly || isSilae}
      />
    </div>
  );
}
