import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { HeaderBar } from "../../components/HeaderBar";
import {
  useQueryEvaluationById,
  useSaveEvaluation,
} from "../../api/evaluations";
import Spinner from "../../components/Spinner";
import { getFirstnameLastname, getOnlyWork } from "../../utils/names";
import EvaluationStep from "../../components/Evaluation/EvaluationStep";
import { filter, get } from "lodash-es";
import { useQueryEvaluationScores } from "../../api/evaluationSkillScores";
import { Form, Formik } from "formik";
import Button from "../../components/Button";
import { useCollaboratorIri } from "../../contexts/permissions";
import { useHistory } from "react-router";
import Textarea from "../../components/TextArea";

export default function EvaluationPage() {
  const { id } = useParams();
  const [currentStep, setCurrentStep] = useState(1);
  const { data: evaluation } = useQueryEvaluationById(id);
  const skillDomains = evaluation?.collaborator?.work?.skillDomains
    ? evaluation?.collaborator?.work?.skillDomains
    : {};
  const nbSteps = skillDomains.length;
  const currentCollaboratorIri = useCollaboratorIri();

  const isAnEvaluator =
    evaluation.collaborator["@id"] !== currentCollaboratorIri;

  const history = useHistory();
  const lastStep = nbSteps === currentStep;
  const firstStep = currentStep === 1;
  const goNextStep = () => setCurrentStep((s) => s + 1);
  const goPrevStep = () => setCurrentStep((s) => s - 1);
  const goBackIfLastStep = () => {
    if (lastStep) {
      history.push(
        `/rh/collaborateurs/${get(
          evaluation,
          "collaborator.id",
          "",
        )}/evaluations`,
      );
    }
  };

  function getInitialSkills(skillDomains, data) {
    const result = {};
    skillDomains.forEach((d) => {
      d.skills.forEach((skill) => {
        result[skill.id] = {
          level: null,
          comment: "",
          id: skill.id,
          skillDomain: d.id,
        };
      });
    });
    data.forEach((d) => {
      result[d.skill.id] = {
        ...result[d.skill.id],
        level: d.score,
        comment: d.comment,
      };
    });
    return result;
  }
  const [saveEvaluation] = useSaveEvaluation();
  const { data } = useQueryEvaluationScores({
    evaluation: evaluation["@id"],
    collaborator: currentCollaboratorIri,
    isEvaluator: isAnEvaluator,
  });
  const currentSkillDomain = skillDomains[currentStep - 1];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const initialValues = {
    skills: getInitialSkills(skillDomains, data),
    comment: isAnEvaluator
      ? get(evaluation, "commentEvaluator", "")
      : get(evaluation, "comment", ""),
  };
  let filledSkills = true;

  return (
    <div>
      <React.Suspense fallback={<Spinner />}>
        <Helmet>
          <title>
            Evaluation {getFirstnameLastname(evaluation.collaborator)}
          </title>
        </Helmet>
        <HeaderBar
          smaller
          title={`Evaluation ${getFirstnameLastname(
            evaluation.collaborator,
          )} :
            domaine ${currentStep}/${nbSteps}`}
        >
          <span className="text-xl">
            Poste : {getOnlyWork(evaluation.collaborator)}
          </span>
        </HeaderBar>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={async (values, actions) => {
            try {
              const emptyValues = filter(
                values.skills,
                (skill) =>
                  (skill.level === null ) &&
                  skill.skillDomain === currentSkillDomain.id,
              );
             
             // return if no choice for a skill
              if (emptyValues.length>0) {
                toast.error("Veuillez faire un choix pour chaque compétence.");
                filledSkills = false;
                return;
              }
              else {
                 filledSkills = true;

                // return if no changes
                if (JSON.stringify(values) === JSON.stringify(initialValues)) {
                  return;
                }
                const newValues = filter(
                  values.skills,
                  (skill) =>
                    (skill.level !== null || skill.comment !== "") &&
                    skill.skillDomain === currentSkillDomain.id,
                );

                await saveEvaluation({
                  id: evaluation.id,
                  data: {
                    skills: newValues,
                    comment: values.comment,
                  },
                });
              }
              actions.setSubmitting(false);
            } catch (e) {
              console.log(e);
              actions.setSubmitting(false);
            }
          }}
        >
          {({ submitForm, errors, isSubmitting }) => {
            return (
              <Form>
                <div className={"px-0 sm:px-8 md:px-24 xl:px-0 "}>
                  <div className="px-12 mt-8 mb-4">
                    <EvaluationStep
                      evaluation={evaluation}
                      skillDomain={currentSkillDomain}
                      errors={errors}
                    />
                    {lastStep && (
                      <Textarea
                        divClassName="mb-4"
                        label="Commentaire général"
                        name={`comment`}
                      />
                    )}
                    <div className="flex justify-between">
                      {!firstStep ? (
                        <Button
                          type="button"
                          isSubmitting={isSubmitting}
                          disabled={isSubmitting}
                          onClick={() => {
                            submitForm().then(() => {
                              goPrevStep();
                            });
                          }}
                        >
                          <span className="mr-4 font-bold">{"<"}</span>
                          Précédent
                        </Button>
                      ) : (
                        <div></div>
                      )}
                      <Button
                        isForm
                        type="button"
                        isSubmitting={isSubmitting}
                        disabled={isSubmitting}
                        onClick={() => {
                          submitForm().then(() => {
                              if(filledSkills === true) goBackIfLastStep();
                          });
                        }}
                        textLabel={lastStep ? "Terminer" : "Enregistrer"}
                      />
                      {!lastStep ? (
                        <Button
                          type="button"
                          isSubmitting={isSubmitting}
                          disabled={isSubmitting}
                          onClick={() => {
                            submitForm().then(() => {
                                if(filledSkills === true) goNextStep();
                             });
                          }}
                        >
                          Suivant<span className="ml-4 font-bold">{">"}</span>
                        </Button>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </React.Suspense>
    </div>
  );
}
