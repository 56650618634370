import { PermissionChecker } from "../../contexts/permissions";
import React, { useRef, useState } from "react";
import { filter, get, map, reduce } from "lodash-es";
import { useClickAway, useList, useToggle } from "react-use";
import Alert from "../../components/Alert";
import { DisplayCheckbox } from "../../components/Checkbox";
import { VehicleContext } from "../../contexts/vehicle";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import Modal from "../../components/Modal";
import { useGetVehicleHistory } from "../../api/vehicle";
import ActionsHistoryVehicle from "../../components/ActionsHistoryVehicle";
import CreateEventVehicle from "../../forms/CreateEventVehicle";
import Block from "../../components/Block";
import { ReactComponent as CrossIcon } from "../../svgs/cross.svg";



function Filters({ AvailableFilters, toggleClose, list, push, remove, clear }) {
  return (
    <Block>
      <div onClick={toggleClose} className={"ml-auto cursor-pointer w-4"}>
        <CrossIcon width={"27px"} height={"27px"} />
      </div>
      <div className={"flex flex-wrap px-4"}>
        {map(AvailableFilters, (filter, key) => {
          return (
            <DisplayCheckbox
              key={key}
              label={key}
              type="checkbox"
              name={key}
              className={"md:w-1/3 w-full"}
              value={filter}
              checked={list.includes(filter)}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  push(filter);
                } else {
                  const pos = list.indexOf(filter);
                  remove(pos);
                }
              }}
            />
          );
        })}
      </div>

      <div>
        <div
          className="mt-3 underline cursor-pointer"
          onClick={() => {
            clear();
          }}
        >
          Supprimer les filtres
        </div>
      </div>
      <div className={"text-center"}>
        <button
          className="btn mt-5 w-full"
          style={{ maxWidth: "398px" }}
          type="button"
          onClick={() => {
            toggleClose();
          }}
        >
          Appliquer les filtres
        </button>
      </div>
    </Block>
  );
}

function ButtonDropdown({ children, className = "", label }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef(null);
  const permissionsEdit = [
    "kdix.actions.vehicle.edit",
    "kdix.actions.vehicle.edit.agency",
    "kdix.actions.vehicle.edit.department",
    "kdix.actions.vehicle.edit.service",
    "kdix.actions.vehicle.edit.own",
  ];

  useClickAway(ref, () => {
    setTimeout(() => {
      setDropdownOpen(false);
    }, 250);
  });
  return (
    <PermissionChecker permissions={permissionsEdit}>
      <div className="relative">
        <button
          ref={ref}
          className={className}
          onClick={(e) => {
            setDropdownOpen(!dropdownOpen);
          }}
        >
          {label}
        </button>
        {dropdownOpen ? (
          <div className="absolute right-0 top-auto w-64 bg-gray-700 text-white shadow-lg mt-2 divide-y-0 divide-gray-400 z-10">
            {children}
          </div>
        ) : null}
      </div>
    </PermissionChecker>
  );
}

function filterHistoryByTypes(items, types) {
  if (!types || !types.length) return items;

  return filter(items, (item) => types.includes(item.type));
}

function mapLabelToType(type) {
  let key = "";
  switch (type) {
    case "App\\Entity\\VehicleAttributionHistory":
      key = "Attributions";
      break;
    case "App\\Entity\\Rental":
      key = "Locations";
      break;
    case "App\\Entity\\VehicleEvent":
      key = "Actions";
      break;
    default:
      break;
  }
  return key;
}

function Historique() {
  const [list, { push, remove, clear }] = useList([]);
  const [filterOn, filterToggle] = useToggle(false);
  const [modalEventOpen, setModalEventOpen] = useState(false);
  const [modalFilters, setModalFiltersOpen] = useState(false);
  const {
    vehicle: { data: vehicle },
  } = React.useContext(VehicleContext);

  const { history, error } = useGetVehicleHistory(vehicle?.id);

  const AvailableFilters = reduce(
    history,
    (unique, next) => {
      const key = mapLabelToType(next.type);
      if (!key || unique[next.type]) {
        return unique;
      }

      return {
        ...unique,
        [key]: next.type,
      };
    },
    {}
  );


  const datePurchase = get(vehicle, "datePurchase");

  if (error) {
    return (
      <div className="my-2">
        <Alert
          type="error"
          message={get(error, "title")}
          details={get(error, "description")}
        />
      </div>
    );
  }

  return (
    <>
      <div
        className={"xl:w-11/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={{ maxWidth: "1400px" }}
      >
        <div className="flex justify-between mb-6 items-center">
          <button
            className="flex items-center focus:outline-none"
            onClick={filterToggle}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>

          <ButtonDropdown
            label="+"
            className="btn text-3xl p-0 w-12 h-12 flex items-center justify-center leading-none focus:outline-none focus:shadow-none"
          >
            <div
              className="px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              onClick={() => setModalEventOpen(true)}
            >
              Créer une action
            </div>
          </ButtonDropdown>
	
        </div>
        <div className={`${filterOn ? "block" : "hidden"}`}>
          <Filters
            AvailableFilters={AvailableFilters}
            list={list}
            push={push}
            remove={remove}
            clear={clear}
            currentFilter={modalFilters}
            toggleClose={filterToggle}
          />
        </div>
        <div>
          <ActionsHistoryVehicle
            datePurchase={datePurchase}
            items={filterHistoryByTypes(history, list)}
          />
        </div>
        <Modal
          title="Créer une action"
          handleClose={(e) => {
            e.stopPropagation();
          }}
          isOpen={modalEventOpen}
          onRequestClose={() => setModalEventOpen(false)}
        >
          <CreateEventVehicle
            vehicleIRI={get(vehicle, "@id")}
            onSuccess={() => setModalEventOpen(false)}
          />
        </Modal>
        <Modal
          title="Filtres"
          handleClose={(e) => {
            e.stopPropagation();
            setModalFiltersOpen(false);
          }}
          isOpen={modalFilters}
          onRequestClose={() => setModalFiltersOpen(false)}
        >
          <div>
            {map(AvailableFilters, (filter, key) => {
              return (
                <DisplayCheckbox
                  key={key}
                  label={key}
                  type="checkbox"
                  name={key}
                  value={filter}
                  checked={list.includes(filter)}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      push(filter);
                    } else {
                      const pos = list.indexOf(filter);
                      remove(pos);
                    }
                  }}
                />
              );
            })}
          </div>

          <div>
            <div
              className="mt-3 underline cursor-pointer"
              onClick={() => {
                clear();
              }}
            >
              Supprimer les filtres
            </div>
          </div>

          <button
            className="btn mt-5 w-full"
            type="button"
            onClick={() => setModalFiltersOpen(false)}
          >
            Appliquer les filtres
          </button>
        </Modal>
      </div>
    </>
  );
}

export default Historique;
