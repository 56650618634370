import SalaryForm from "../../forms/SalaryForm";
import { ReactComponent as FileIcon } from "../../svgs/file.svg";
import HistoryItem from "./HistoryItem";
import React from "react";
import { get } from "lodash-es";

export default function SalaryHistoryItem({ salary = {}, isLeft }) {
  return (
    <HistoryItem
      title={
        get(salary, "type.label", "") !== ""
          ? `Salaire - ${get(salary, "type.label", "")}`
          : "Salaire"
      }
      Icon={FileIcon}
      backgroundIcon="bg-blue-750"
      date={salary.date ? new Date(salary.date).toLocaleDateString() : ""}
      itemId={salary.id}
      isToggle={true}
      textColor={"text-blue-750"}
      isLeft={isLeft}
    >
      <SalaryForm
        salary={salary}
        contractIRI={get(salary, "contract.@id")}
        readOnly
      />
    </HistoryItem>
  );
}
