import React from "react";

export default function InlineBLockContentSortable({
  children,
  setOrderFilter,
  colName,
  className = "",
}) {
  return (
    <div
      className={`px-4 flex items-center cursor-pointer ${className}`}
      onClick={() => {
        setOrderFilter((orderFilter) => {
          const order = orderFilter[`order[${colName}]`];
          return {
            [`order[${colName}]`]: order === "asc" ? "desc" : "asc",
          };
        });
      }}
    >
      {children}
    </div>
  );
}

export function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}
