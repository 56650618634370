import React, {useContext, useState} from "react";
import { Helmet } from "react-helmet";
import { HeaderBar } from "../components/HeaderBar";
import moment from "moment";
import "moment/locale/fr";
import {flatMap, get, isEmpty, keys, map, omit, pull, size, without} from "lodash-es";
import {
  useQueryCollaboratorById,
  useQueryCollaboratorsListForAbsences,
} from "../api/collaborator";
import {
  WeekPlanningHead,
  WeekPlanningSwitchLabel,
} from "../components/Planning/WeekPlanningTable";
import {
  MonthPlanningHead,
  MonthPlanningSwitchLabel,
} from "../components/Planning/MonthPlanningTable";
import {
  QuarterPlanningHead,
  QuarterPlanningSwitchLabel,
} from "../components/Planning/QuarterPlanningTable";
import {useDebounce, useMap, useToggle} from "react-use";
import { ReactComponent as SearchIcon } from "../svgs/search.svg";
import Spinner from "../components/Spinner";
import PlanningTable from "../components/Planning/PlanningTable";
import {
  ButtonPreviousNext,
  SelectTimeGroup,
} from "../components/Planning/ButtonsPlanning";
import InfiniteScroll from "react-infinite-scroller";
import { planningTypes } from "../utils/planningType";
import DropdownCheckboxGroup from "../components/DropdownCheckboxGroup";
import {
  testScopeSuperior,
  useCollaboratorId,
  useMaxPermissionChecker,
} from "../contexts/permissions";
import { absenceStatus } from "../utils/absenceStatus";
import LegendItem from "../components/Planning/LegendItem";
import { testIsPortrait, useDesktop, useMobile } from "../hooks/useDesktop";
import { ReactComponent as RotatePhoneIcon } from "../svgs/rotate-phone.svg";
import Button from "../components/Button";
import {ReactComponent as FilterIcon} from "../svgs/filters.svg";
import Modal from "../components/Modal";
import CheckboxGroup from "../components/CheckboxGroup";
import {useQueryCompanies} from "../api/companies";
import {useQueryAgencies} from "../api/agencies";
import {useQueryDepartments} from "../api/department";
import {useQueryServices} from "../api/services";
import {CollaboratorFiltersContext} from "../contexts/collaboratorFilters";
moment.locale("fr");

function generateFiltersList(collaborator, maxPermissionAgence, services, departments, agencies, companies) {
  if (!collaborator) return [];
  const filtersList = [];

  if (
    collaborator.service?.["@id"] &&
    testScopeSuperior(maxPermissionAgence, "service")
  ) {
    filtersList.push({
      label: "Service",
      key: "service",
      options: map(services, (node) => ({
        label: node.code,
        value: node["@id"],
        id: node["@id"],
      })),
    });
  }

  if (
    collaborator.department?.["@id"] &&
    testScopeSuperior(maxPermissionAgence, "department")
  ) {
    filtersList.push({
      label: "Département",
      key: "department",
      options: map(departments, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    });
  }

  if (
    collaborator.agencies?.[0]?.["@id"] &&
    testScopeSuperior(maxPermissionAgence, "agency")
  ) {
    filtersList.push({
      label: "Site",
      key: "agencies",
      options: map(agencies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    });

    filtersList.push({
      label: "Sociétés",
      key: "companies",
      options: map(companies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    });
  }

  return filtersList;
}

export default function PlanningAbsences() {
  const [planningType, setPlanningType] = useState(planningTypes.WEEK);
  const [setFiltersGroups] = useState({});
  const [absenceState, setAbsenceState] = useState([]);
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const userCollaborator = useCollaboratorId();
  const { data: collaborator } = useQueryCollaboratorById(userCollaborator);
  const maxPermissionAgence = useMaxPermissionChecker({
    permission: "kdix.actions.absence.view",
  });
  const { data: services } = useQueryServices();
  const { data: departments } = useQueryDepartments();
  const { data: agencies } = useQueryAgencies();
  const { data: companies } = useQueryCompanies();
  const filtersList = generateFiltersList(collaborator, maxPermissionAgence, services, departments, agencies, companies);
  const [FiltersOpen, toggleFilters] = useToggle(false);
  const { collaboratorsFilters } = useContext(
      CollaboratorFiltersContext
  );
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap(collaboratorsFilters);

  const isDesktop = useDesktop();
  const isMobile = useMobile();
  const isPortrait = testIsPortrait();
  const [,] = useDebounce(
      () => {
        setDebouncedQuery(query);
      },
      500,
      [query]
  );

  React.useEffect(() => {
            var _paq = window._paq = window._paq || [];
            _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
            _paq.push(['trackPageView']);
            _paq.push(['enableLinkTracking']);
       
            var u="//matomo.kdix.pockost.com/";
            _paq.push(['setTrackerUrl', u+'matomo.php']);
            _paq.push(['setSiteId', '1']);
            var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
            g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    }, [])

  return (
    <div>
      <Helmet>
        <title>Planning des absences</title>
      </Helmet>
      {isPortrait && isMobile && (
        <div className="fixed bg-gray-600 inset-0 z-60 text-white opacity-90 flex justify-center items-center max-h-screen text-center flex-col gap-4">
          <RotatePhoneIcon className="w-24 h-24 fill-current" />
          <div>
            Pour une lisibilité optimale, veuillez placer votre smartphone à
            l’horizontale.
          </div>
        </div>
      )}
      <HeaderBar noShadow>Planning des absences</HeaderBar>
      <div className="shadow px-8 flex bg-white">
        <div className="flex items-center justify-center border-r border-gray-150 py-6 pr-6">
          <DropdownCheckboxGroup
            label="Etat"
            name="etat"
            options={[
              {
                label: "Abs. validées",
                value: absenceStatus.WORKFLOW_STATUS_VALIDATED,
              },
              {
                label: "Abs. en attente",
                value: absenceStatus.WORKFLOW_STATUS_CREATED,
              },
              { label: "Sans validation", value: "without_validation" },
            ]}
            onChange={(checked, { value }) => {
              if (checked) {
                setAbsenceState((prevState) => [...prevState, value]);
              } else {
                setAbsenceState((prevState) => without(prevState, value));
              }
            }}
          />
        </div>
        {!isEmpty(filtersList) && !(
          <div className="flex items-center justify-center border-r border-gray-150 p-6">
            <DropdownCheckboxGroup
              label="Groupe de collaborateurs"
              name="groupCollaborators"
              options={filtersList}
              onChange={(checked, { value }) => {
                if (checked) {
                  setFiltersGroups((prevState) => ({ ...prevState, ...value }));
                } else {
                  setFiltersGroups((prevState) =>
                    omit(prevState, keys(value)[0])
                  );
                }
              }}
            />
          </div>
        )}
        {!isEmpty(filtersList) && (
            <div className="flex items-center justify-center border-r border-gray-150 p-6">
              <button
                  className="flex items-center"
                  onClick={() => toggleFilters(true)}
              >
                <FilterIcon />
                <span className="ml-4">Filtres</span>
              </button>
              <Modal
                  title="Filtres"
                  handleClose={(e) => {
                    e.stopPropagation();
                    toggleFilters(false);
                  }}
                  isOpen={FiltersOpen}
                  onRequestClose={() => toggleFilters(false)}
                  className="outline-none w-full max-w-full lg:max-w-xl max-h-full overflow-visible overflow-y-auto"
              >
                <div className="-mx-4 px-5">
                  {map(filtersList, ({ key, label, options }) => {
                    return (
                        <CheckboxGroup
                            key={key}
                            label={label}
                            options={options}
                            className="py-4 odd:bg-purple-50 -mx-4 px-5"
                            selected={get(filters, key, [])}
                            onAdd={(option) => {
                              setFilter(key, [...get(filters, key, []), option]);
                            }}
                            onRemove={(option) => {
                              const newFilters = pull(get(filters, key, []), option);
                              if (size(newFilters) === 0) {
                                removeFilter(key);
                                return;
                              }
                              setFilter(key, newFilters);
                            }}
                        />
                    );
                  })}
                </div>
                <div>
                  <div
                      className="mt-3 underline cursor-pointer"
                      onClick={() => {
                        resetFilters();
                      }}
                  >
                    Supprimer les filtres
                  </div>
                </div>

                <button
                    className="btn mt-5 w-full"
                    type="button"
                    onClick={() => toggleFilters(false)}
                >
                  Appliquer les filtres
                </button>
              </Modal>
            </div>
        )}
        <div className={"flex-1 flex items-center p-6"}>
          <div className="border-b border-gray-150 focus:border-red w-full flex items-center gap-4">
            <SearchIcon className="w-5 h-5 fill-current text-gray-600" />
            <input
              type="text"
              name="query"
              value={query}
              className="mb-0 appearance-none bg-transparent placeholder-gray-600 text-gray-600 w-full py-2 focus:outline-none"
              placeholder="Rechercher un collaborateur"
              onChange={(e) => setQuery(e.target.value)}
            />
          </div>
        </div>
        {isDesktop && (
          <div className={"flex-grow flex items-center justify-end py-6"}>
            <SelectTimeGroup
              onChange={(e) => {
                setPlanningType(e.target.value);
              }}
              name="planningType"
              value={planningType}
              options={[
                { label: "Semaine", value: planningTypes.WEEK },
                { label: "Mois", value: planningTypes.MONTH },
                { label: "Trimestre", value: planningTypes.QUARTER },
              ]}
            />
          </div>
        )}
      </div>
      <div className="p-12">
        {!isDesktop && (
          <div className={"planningSwitchLegend"}>
            <SelectTimeGroup
              onChange={(e) => {
                setPlanningType(e.target.value);
              }}
              name="planningType"
              value={planningType}
              options={[
                { label: "Semaine", value: planningTypes.WEEK },
                { label: "Mois", value: planningTypes.MONTH },
                { label: "Trimestre", value: planningTypes.QUARTER },
              ]}
            />
          </div>
        )}
        <div>
          <React.Suspense fallback={<Spinner />}>
            <Planning
              debouncedQuery={debouncedQuery}
              type={planningType}
              filters={filters}
              absenceState={absenceState}
            />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

function getDaysByType(currentDay, type) {
  const days = [];
  if (type === planningTypes.WEEK) {
    const firstDayOfWeek = currentDay.clone().startOf("week");
    for (let i = 0; i < 7; i++) {
      const day = moment(firstDayOfWeek).add(i, "d");
      days.push({ label: day.format("ddd DD/MM"), date: day });
    }
  } else if (type === planningTypes.MONTH) {
    const firstDayOfMonth = currentDay.clone().startOf("month").startOf("week");
    const endDayOfMonth = currentDay.clone().endOf("month").endOf("week");
    const nbWeeks = endDayOfMonth.diff(firstDayOfMonth, "weeks", true);
    for (let i = 0; i < nbWeeks; i++) {
      const day = moment(firstDayOfMonth).add(i, "w");
      days.push({
        label: `Du ${day.format("DD/MM/YY")} au ${day
          .endOf("week")
          .format("DD/MM/YY")}`,
        date: day,
      });
    }
  } else if (type === planningTypes.QUARTER) {
    const firstDayQuarter = currentDay.clone().startOf("quarter");
    for (let i = 0; i < 3; i++) {
      const day = moment(firstDayQuarter).add(i, "M");
      days.push({
        label: day.format("MMMM"),
        date: day,
        nbDays: day.daysInMonth(),
      });
    }
  }
  return days;
}

function Planning({ debouncedQuery, type, filters, absenceState }) {
  const [currentDay, setCurrentDay] = useState(moment());
  const days = getDaysByType(currentDay, type);
  const isDesktop = useDesktop();
  const {
    data,
    fetchMore,
    canFetchMore,
    isFetching,
  } = useQueryCollaboratorsListForAbsences(
    {
      "active": true,
      "order[lastname]": "asc",
      orSearch_fullname: debouncedQuery,
      ...filters,
    },
    false
  );
  const collaborators = flatMap(data, (page) => page["hydra:member"]);

  if (!collaborators) return null;

  return (
    <div>
      <div className="planningSwitchLegend">
        <div className="flex items-center">
          <ButtonPreviousNext
            text="<"
            onClick={() => {
              setCurrentDay((prevState) => prevState.clone().subtract(1, type));
            }}
          />
          {type === planningTypes.WEEK && (
            <WeekPlanningSwitchLabel days={days} />
          )}
          {type === planningTypes.MONTH && (
            <MonthPlanningSwitchLabel days={days} />
          )}
          {type === planningTypes.QUARTER && (
            <QuarterPlanningSwitchLabel days={days} />
          )}
          <ButtonPreviousNext
            text=">"
            onClick={() => {
              setCurrentDay((prevState) => prevState.clone().add(1, type));
            }}
          />
        </div>
        <div className="flex items-center gap-6">
          <LegendItem bgColor="bg-purple-400" label="Sans validation" />
          <LegendItem bgColor="bg-green-900" label="Abs. Validée" />
          <LegendItem bgColor="bg-green-600" label="Abs. en attente" />
        </div>
      </div>

      {type === planningTypes.WEEK && <WeekPlanningHead days={days} />}
      {type === planningTypes.MONTH && <MonthPlanningHead days={days} />}
      {type === planningTypes.QUARTER && <QuarterPlanningHead days={days} />}

      <div>
        <React.Suspense fallback={<Spinner />}>
          {isDesktop ? (
            <InfiniteScroll
              pageStart={1}
              initialLoad={false}
              loadMore={() => {
                fetchMore();
              }}
              hasMore={canFetchMore !== false}
              loader={
                <div key={0} className=" relative">
                  <Spinner />
                </div>
              }
            >
              {map(collaborators, (collaborator, index) => {
                return (
                  <PlanningTable
                    absenceState={absenceState}
                    type={type}
                    collaborator={collaborator}
                    currentDay={currentDay}
                    days={days}
                    key={index}
                  />
                );
              })}
            </InfiniteScroll>
          ) : (
            <>
              {map(collaborators, (collaborator, index) => {
                return (
                  <PlanningTable
                    absenceState={absenceState}
                    type={type}
                    collaborator={collaborator}
                    currentDay={currentDay}
                    days={days}
                    key={index}
                  />
                );
              })}
              {canFetchMore !== false && (
                <Button
                  className="mt-4"
                  onClick={() => {
                    fetchMore();
                  }}
                  isSubmitting={isFetching}
                >
                  Charger Plus
                </Button>
              )}
            </>
          )}
        </React.Suspense>
      </div>
    </div>
  );
}
