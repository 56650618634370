import { Link } from "react-router-dom";
import { ReactComponent as ArrowBackIcon } from "../svgs/arrow-back.svg";
import Avatar from "./Avatar";
import { get } from "lodash-es";
import React from "react";

function ArrowBack({ isDesktop, isLeft = true }) {
  const classIcon = `${
    isDesktop ? "w-4 h-4" : "w-3 h-3"
  } fill-current text-white`;
  return (
    <div className={isLeft ? "mr-1" : "ml-1"}>
      <ArrowBackIcon
        className={`${classIcon} fill-current text-white ${
          isLeft ? "" : "transform rotate-180"
        }`}
      />
    </div>
  );
}

export default function LinkToCollaborator({
  collaborator,
  isNext = false,
  className = "",
  isDesktop,
  currentTabCollab = "",
}) {
  return (
    <Link
      to={`/rh/collaborateurs/${collaborator.id}/${currentTabCollab}`}
      className={`flex items-center text-sm ${className}`}
    >
      {!isNext && (
        <>
          <ArrowBack isDesktop={isDesktop} />
          {isDesktop && (
            <Avatar
              size="w-6 h-6"
              className="mr-1"
              avatar={get(collaborator, "avatar.contentUrl")}
            />
          )}
        </>
      )}
      {isDesktop ? (
        <div>
          <div className={isNext ? "text-right" : ""}>
            {isNext ? "Suivant" : "Précédent"}
          </div>
          <div>
            {collaborator.firstname} {collaborator.lastname}
          </div>
        </div>
      ) : (
        <div>
          <div className={isNext ? "text-right" : ""}>
            {isNext ? "Suiv." : "Préc."}
          </div>
        </div>
      )}

      {isNext && (
        <>
          {isDesktop && (
            <Avatar
              size="w-6 h-6"
              className="ml-1"
              avatar={get(collaborator, "avatar.contentUrl")}
            />
          )}
          <ArrowBack isDesktop={isDesktop} isLeft={false} />
        </>
      )}
    </Link>
  );
}
