import React from "react";
import { Helmet } from "react-helmet";
import { map, size } from "lodash-es";
import Spinner from "../components/Spinner";
import { useDesktop } from "../hooks/useDesktop";
import Block from "../components/Block";
import Alert from "../components/Alert";
import { HeaderBar } from "../components/HeaderBar";
import { useQueryOrganizationDocuments } from "../api/organizationDocument";
import {
  useCollaboratorId,
  usePermissionsChecker,
} from "../contexts/permissions";
import ExternalLink from "../components/ExternalLink";
import { useQueryCollaboratorById } from "../api/collaborator";

function limitStringSize(string) {
  if (typeof string !== "string") {
    return null;
  }

  if (string.length > 70) {
    return string.substring(0, 70) + "...";
  }

  return string;
}

function NewOrganizationDocuments() {
  const isDesktop = useDesktop();

  const { data: currentUserCollaborator } = useQueryCollaboratorById(
    useCollaboratorId(),
  );
  const canViewAll = usePermissionsChecker({
    permissions: ["kdix.actions.documents_link.view_all"],
  });

  const { data } = useQueryOrganizationDocuments({
    "order[idDoc]": "DESC",
    pagination: true,
    itemsPerPage: 10,
    page: 1,
  });

  const filtered = data.filter((document) => {
    if (canViewAll || !document.company || document.company === "") {
      return true;
    }

    if (!currentUserCollaborator?.companies) {
      return false;
    }

    if (
      currentUserCollaborator.companies.filter((company) => {
        return company.label.toLowerCase() === document.company?.toLowerCase();
      }).length > 0
    ) {
      return true;
    }

    return false;
  });

  console.log(filtered);
  return (
    <div>
      <Helmet>
        <title>Derniers documents entreprise</title>
      </Helmet>
      <HeaderBar>Derniers documents entreprise</HeaderBar>
      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="px-8 mb-48 mt-4">
          <React.Suspense fallback={<Spinner />}>
            {size(filtered) > 0 ? (
              <Block>
                {map(filtered, (document, index) => {
                  const date = new Date(document?.date);

                  return (
                    <ExternalLink to={document?.publicLink}>
                      <div key={index} className={`pb-2`}>
                        <div
                          className={`${
                            index === 0 ? "" : "border-t-2 border-solid pt-2"
                          }`}
                        >
                          <div className={"font-bold uppercase"}>
                            {document?.comment
                              ? limitStringSize(document?.comment)
                              : null}
                          </div>
                          <div>
                            {document?.date
                              ? date.toLocaleDateString("fr")
                              : null}{" "}
                            {document?.extension
                              ? "(Fichier ." +
                                document?.extension.toUpperCase() +
                                ")"
                              : null}
                          </div>
                        </div>
                      </div>
                    </ExternalLink>
                  );
                })}
              </Block>
            ) : (
              <div className="mt-12">
                <Alert type="warning" message="Aucun document" />
              </div>
            )}
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}
export default NewOrganizationDocuments;
