import { queryCache, useMutation, useQuery } from "react-query";
import queryWithToken, { create, deleteById, updateById } from "./index";
import { get } from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "type_work_accidents";
//

export function useQueryTypeWorkAccident() {
  const { data, ...rest } = useQuery(
    ressourceKey,
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false
        }
      }),
    {
      refetchOnWindowFocus: false
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest
  };
}

export function useCreateTypeWorkAccident() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: data => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    }
  });
}

export function useUpdateTypeWorkAccident() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    }
  });
}

export function useDeleteTypeWorkAccident() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    }
  });
}
