import React from "react";

import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import Alert from "../components/Alert";
import Input from "../components/Input";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import { useCreateOrUpdateAccreditation } from "../api/accreditation";
import * as Yup from "yup";
import { usePermissionsChecker } from "../contexts/permissions";

const schema = Yup.object().shape({
  label: Yup.string().required("Requis"),
  description: Yup.string().required("Requis"),
});

function CreateAccreditationForm({ accreditation = null, readOnly = false }) {
  const history = useHistory();
  const [createOrUpdate, { error }] = useCreateOrUpdateAccreditation();
  const canCreate = usePermissionsChecker({
    permissions: [
      "kdix.actions.accreditation.manage",
      "kdix.actions.accreditation.manage.agency",
      "kdix.actions.accreditation.manage.department",
      "kdix.actions.accreditation.manage.service",
      "kdix.actions.accreditation.manage.own",
    ],
  });
  return (
    <div>
      <Formik
        initialValues={{
          label: get(accreditation, "label", ""),
          description: get(accreditation, "description", ""),
        }}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={async (values, actions) => {
          try {
            const response = await createOrUpdate({
              id: get(accreditation, "id", null),
              data: {
                ...values,
              },
            });

            actions.setSubmitting(false);

            if (!response.status && response.id) {
              history.push(`/rh/habilitations/catalogue`);
            }
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <Input
                type="text"
                name="label"
                label="Nom"
                readOnly={readOnly || !canCreate}
              />
              <Input
                type="text"
                name="description"
                label="Description"
                readOnly={readOnly || !canCreate}
              />

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}
              {!readOnly && canCreate && (
                <div className="text-center">
                  <Button
                    className={`btn my-8 px-8`}
                    //readOnly={readOnly}
                    disabled={isSubmitting}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="submit"
                    textLabel={accreditation ? "Enregistrer" : "Créer"}
                  />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CreateAccreditationForm;
