import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import queryWithToken, { find, findExport, updateById } from "./index";
import { get } from "lodash-es";
import fileDownload from "js-file-download";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "requests";
//

export function useUpdateRequests() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(["users", "assigned_requests"]);
      queryCache.refetchQueries([ressourceKey]);
    },
  });
}

export function useQueryRequests(filter, postRessourceKey = "") {
  const { data, ...rest } = useQuery(
    `${ressourceKey}${postRessourceKey}`,
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filter,
        },
      }),
    {
      refetchOnWindowFocus: false,
      initialStale: true,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQueryInfiniteRequests(filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}

export function useQueryInfiniteRequestsPrevention(filters) {
  return useInfiniteQuery(["requests/prevention", filters], find, {
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}


export async function downloadRequestsExport(filters) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, "export_demandes.csv");
}
