import {
  useCreateTypeAlert,
  useDeleteTypeAlert,
  useQueryTypeAlerts,
  useUpdateTypeAlert,
} from "../api/typeAlerts";
import { useQueryAlertClassnames } from "../api/alertClassnames";
import { useQueryPermissions } from "../api/permissions";
import ManageResource from "../components/ManageResource";
import React from "react";
import { find, get, map, intersectionBy } from "lodash-es";
import * as Yup from "yup";
import AlertSilencePeriodAdmin from "../components/AlertSilencePeriodAdmin";

const validation = Yup.object().shape({
  label: Yup.string().nullable(),
  delay: Yup.number()
    .integer()
    .required("Requis")
    .min(1, "Le nombre de jours minimum est 1")
    .max(365, "Le nombre de jours maximum est 365"),
  classname: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
});

function AlertsAdmin() {
  const { data: alertsClassnames } = useQueryAlertClassnames();
  const { data: permissions } = useQueryPermissions();

  return (
    <>
      <ManageResource
          resourceLabel="Alertes"
          createLabel="Ajouter une alerte"
          noElementsLabel="Aucune alerte"
          denominationLabel="Texte de l'alerte"
          createHook={useCreateTypeAlert}
          queryHook={useQueryTypeAlerts}
          deleteHook={useDeleteTypeAlert}
          updateHook={useUpdateTypeAlert}
          toastMessage="Alerte créée avec succès"
          validation={validation}
          params={{
            classname: {
              label: "Catégorie d'alerte",
              type: "select",
              resourceValueKey: "classname",
              initialValue: { label: "", value: "" },
              options: map(alertsClassnames, (node) => ({
                label: node.label,
                value: get(node, "@id"),
              })),
              transformResourceValue: (value) => {
                const alertsClassname = find(
                    alertsClassnames,
                    (alertsClassname) => alertsClassname["@id"] === value["@id"]
                );
                return {
                  label: alertsClassname?.label || "",
                  value: alertsClassname?.["@id"] || "",
                };
              },
            },
            label: {
              label: "Texte de l'alerte",
              type: "text",
              resourceValueKey: "label",
            },
            delay: {
              label: "Alerte en jour avant la date d'échéance",
              type: "number",
              resourceValueKey: "delay",
            },
            associatedPermissions: {
              label: "Type de profil recevant l'alerte",
              type: "select",
              resourceValueKey: "associatedPermissions",
              name: "associatedPermissions",
              initialValue: [],
              isMulti: true,
              options: map(permissions, (node) => ({
                label: node.label,
                value: get(node, "@id"),
              })),
              transformResourceValue: (value) => {
                return map(intersectionBy(permissions, value, "@id"), (item) => ({
                  label: item.label,
                  value: item["@id"],
                }));
              },
            },
          }}
          childrenTop={<AlertSilencePeriodAdmin />}
      />
    </>
  );
}

export default AlertsAdmin;
