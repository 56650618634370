import React from "react";
import { get } from "lodash-es";
import { getLocaleDateString } from "../../utils/date";
import { getFirstnameLastnameJob } from "../../utils/names";

export function alertTitleAbsence(alert) {
  return {
    title: get(alert, "typeAlert.label", ""),
    subtitles: [
      {
        label: "Date de rappel",
        value: getLocaleDateString(alert, "reminderDate"),
      },
      {
        label: "Collaborateur",
        value: getFirstnameLastnameJob(
          alert,
          "absence.collaborator"
        ),
      },
    ],
  };
}

function AlertAbsenceItem({ alert }) {
  return (
    <>
      {alert.absence?.endDate ? (
        <p className="mt-8">
          <span className="font-bold">
            Date de reprise :
          </span>{" "}
          {getLocaleDateString(alert, "absence.endDate")}
        </p>
      ) : null}
      {get(alert, "typeAlert.comment") && (
        <p>
          <span className="font-bold">Commentaire : </span>
          {get(alert, "typeAlert.comment")}
        </p>
      )}
    </>
  );
}

export default AlertAbsenceItem;
