import { PermissionChecker } from "../../contexts/permissions";

import Alert from "../../components/Alert";
import BadgesForm from "../../forms/BadgesForm";
import ClothingSizesForm from "../../forms/ClothingSizesForm";
import { CollaboratorContext } from "../../contexts/collaborator";
import CollaboratorGeneralInfosForm from "../../forms/CollaboratorGeneralInfosForm";
import ExpansionPanel from "../../components/ExpansionPanel";
import React from "react";
import Spinner from "../../components/Spinner";
import { get } from "lodash-es";
import { useDesktop } from "../../hooks/useDesktop";
import AccreditationCollaboratorForm from "../../forms/AccreditationCollaboratorForm";
import FormationCollaboratorForm from "../../forms/Formation/FormationCollaboratorForm";
import CollaboratorContractualInformationForm from "../../forms/CollaboratorContractualInformationForm";

function General() {
  const isDesktop = useDesktop();
  const { collaborator } = React.useContext(CollaboratorContext);
  const permissionsView = [
    "kdix.actions.collaborator.view",
    "kdix.actions.collaborator.view.agency",
    "kdix.actions.collaborator.view.department",
    "kdix.actions.collaborator.view.service",
    "kdix.actions.collaborator.view.own",
  ];
  const idUser = get(collaborator, "data.linkedUser.id", null);

  return (
    <PermissionChecker permissions={permissionsView} userId={idUser}>
      <div className="xl:grid xl:grid-cols-3 grid-flow-row-dense grid-rows-1 xl:gap-3">
        <div>
          <ExpansionPanel title="Informations Générales" open={isDesktop}>
            <CollaboratorGeneralInfosForm collaborator={collaborator.data} />
          </ExpansionPanel>
            <ExpansionPanel title="Formations" open={isDesktop}>
              <FormationCollaboratorForm collaborator={collaborator.data} />
            </ExpansionPanel>
            <Alert
              className={" mb-4 xl:mb-3"}
              details={
                <div>
                  Pour accéder à mon compte de formation (CPF), cliquez sur le
                  lien suivant{" "}
                  <a
                    href="https://www.moncompteformation.gouv.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-green-600 hover:text-green-800"
                  >
                    moncompteformation.gouv.fr
                  </a>
                </div>
              }
            />
        </div>

        <div>
          <PermissionChecker permissions={permissionsView}>
            <ExpansionPanel
              title={"Informations Contractuelles"}
              open={isDesktop}
            >
              <CollaboratorContractualInformationForm
                collaborator={collaborator.data}
              />
            </ExpansionPanel>
          </PermissionChecker>

          {isDesktop ? (
            <ExpansionPanel title="Badge" open={isDesktop}>
              <React.Suspense fallback={<Spinner />}>
                <BadgesForm collaborator={collaborator.data} />
              </React.Suspense>
            </ExpansionPanel>
          ) : null}
        </div>
        <div>
          <div>
              <ExpansionPanel title="Habilitations" open={isDesktop}>
                <AccreditationCollaboratorForm
                  collaborator={collaborator.data}
                />
              </ExpansionPanel>
            {isDesktop ? (
              <ExpansionPanel title="Taille vestimentaire" open={isDesktop}>
                <ClothingSizesForm collaborator={collaborator.data} />
              </ExpansionPanel>
            ) : null}
          </div>
        </div>
      </div>

      {!isDesktop ? (
        <ExpansionPanel title="Badges" open={isDesktop}>
          <React.Suspense fallback={<Spinner />}>
            <BadgesForm collaborator={collaborator.data} />
          </React.Suspense>
        </ExpansionPanel>
      ) : null}
      {!isDesktop ? (
        <ExpansionPanel title="Taille vestimentaire" open={isDesktop}>
          <ClothingSizesForm collaborator={collaborator.data} />
        </ExpansionPanel>
      ) : null}
    </PermissionChecker>
  );
}

export default General;
