import Modal from "./Modal";
import React from "react";
import ExternalLink from "./ExternalLink";

export default function HelpModal({ helpModalOpen, setHelpModalOpen }) {
  return (
    <Modal
      title={`Bien démarrer sur K-Dix`}
      handleClose={(e) => {
        e.stopPropagation();
      }}
      isOpen={helpModalOpen}
      onRequestClose={() => setHelpModalOpen(false)}
    >
      <p>Ci-dessous, plusieurs guides pour vous aider dans K-Dix :</p>
      <div className="mt-5 px-5">
        <ul>
          <HelpLine
            url="https://drive.google.com/file/d/12z9t-9KWTIHbuTBUpANyRhgPW2bF2hGl/view"
            title="Changez votre mot de passe"
          />
          <HelpLine
            url="https://drive.google.com/file/d/1ltmps_kvdyO6EJ0DKbJ7TGkpb77zhyFt/view"
            title="Créer des favoris"
          />
          <HelpLine
            url="https://drive.google.com/file/d/1S3gWq-parrDmw_nSHnvXUSAdcSieUXS8/view"
            title="Envoyer des messages entre collaborateurs"
          />
          <HelpLine
            url="https://drive.google.com/file/d/1sscFPRL4hhVkpY92gWNVqV0ajfouX9gD/view"
            title="Liste des collaborateurs"
          />
          <HelpLine
            url="https://drive.google.com/file/d/1BuQ8SUjh0sQo-uazFX_hgzouXAAL4kM9/view"
            title="Vous avez la parole"
          />
        </ul>
      </div>
    </Modal>
  );
}

function HelpLine({ url, title }) {
  return (
    <li>
      <ExternalLink to={url} className="text-green-700">
        - {title}
      </ExternalLink>
    </li>
  );
}
