import { create, deleteById, updateById, updateOrCreate } from "./index";
import { queryCache, useMutation } from "react-query";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "vehicle_events";
//

const mutateDefaultOptions = {
  throwOnError: true,
  // When mutate is called:
  onMutate: (newEvent = null) => {
    queryCache.cancelQueries(ressourceKey);
  }
};

export function useCreateEventVehicle() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: (newEventVehicle = null) => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: data => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("history");
    }
  });
}

export function useUpdateEventVehicle() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: (newEventVehicle = null) => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("history");
    }
  });
}

export function useCreateOrUpdateEventVehicle() {
  return useMutation(
    updateOrCreate.bind(null, ressourceKey),
    mutateDefaultOptions
  );
}

export function useDeleteEventVehicle(resetHistory = false) {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      if (resetHistory) {
        queryCache.refetchQueries("history");
      }
    }
  });
}
