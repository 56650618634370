import React, { useState } from "react";
import { get, map } from "lodash-es";

import {
  PermissionChecker,
  usePermissionsChecker,
} from "../../contexts/permissions";
import { CollaboratorContext } from "../../contexts/collaborator";
import ExpansionPanel from "../../components/ExpansionPanel";
import Modal from "../../components/Modal";
import ContractForm from "../../forms/ContractForm";
import { useDesktop } from "../../hooks/useDesktop";
import { useQueryContracts } from "../../api/contract";
import SalaryForm from "../../forms/SalaryForm";
import { useQuerySalarysbyIds } from "../../api/salary";
import { getLocaleDateString } from "../../utils/date";

function SalaryList({ salaries, canEdit = false }) {
  const { data } = useQuerySalarysbyIds(map(salaries, (s) => s.id));
  return map(data, (salary, index) => (
    <div key={salary.id}>
      <ExpansionPanel
        title={`Salaire ${
          salary.date
            ? `en date du ${getLocaleDateString(salary, "date")}`
            : "antérieur"
        }`}
      >
        <SalaryForm
          salary={salary}
          contractIRI={salary.contract}
          readOnly={!canEdit}
        />
      </ExpansionPanel>
    </div>
  ));
}

function Contrat() {
  const isDesktop = useDesktop();
  const { currentContract, collaborator } = React.useContext(
    CollaboratorContext
  );
  const collaboratorIri = get(collaborator, "data['@id']");
  const now = new Date();
  now.setHours(23, 59, 59, 999);
  const { data: contracts } = useQueryContracts({
    collaborator: collaboratorIri,
    "startDate[before]": now,
    "order[startDate]": "DESC",
  });
  const [modalContractOpen, setModalContractOpen] = useState(false);
  const [modalSalaryOpen, setModalSalaryOpen] = useState(false);
  const salaries = contracts.reduce(
    (acc, value) => [...acc, ...value.salaries],
    []
  );
  const contractsHistory = contracts.filter(
    (contract) => get(contract, "@id") !== get(currentContract, "data['@id']")
  );
  const currentContractSalaries = get(
    currentContract,
    "data.salaries",
    []
  ).sort((a, b) => (a.date > b.date ? -1 : 1));
  const pastSalaries = currentContractSalaries.filter(
    (salary) => new Date(salary.date).getTime() < now.getTime()
  );
  let currentSalary;
  if (pastSalaries.length > 0) {
    currentSalary = get(pastSalaries, "[0]", null);
  } else {
    currentSalary = get(
      currentContractSalaries,
      [currentContractSalaries.length - 1],
      null
    );
  }
  //Pas le courant et pas les futurs
  const salariesToDisplay = salaries.filter(
    (salary) =>
      get(salary, "@id") !== get(currentSalary, "@id") &&
      new Date(salary.date).getTime() < now.getTime()
  );
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.personal_information.edit",
      "kdix.actions.personal_information.edit.agency",
      "kdix.actions.personal_information.edit.department",
      "kdix.actions.personal_information.edit.service",
      "kdix.actions.personal_information.edit.own",
    ],
  });
  return (
    <PermissionChecker
      permissions={[
        "kdix.actions.contract_salary.view",
        "kdix.actions.contract_salary.view.agency",
        "kdix.actions.contract_salary.view.department",
        "kdix.actions.contract_salary.view.service",
        "kdix.actions.contract_salary.view.own",
        "kdix.actions.personal_information.edit",
        "kdix.actions.personal_information.edit.agency",
        "kdix.actions.personal_information.edit.department",
        "kdix.actions.personal_information.edit.service",
        "kdix.actions.personal_information.edit.own",
      ]}
    >
      <div
        className={`xl:grid xl:grid-cols-3 grid-flow-row-dense grid-rows-1 xl:gap-2`}
      >
        <div>
          <ExpansionPanel title="Contrat" open={isDesktop}>
            {canEdit && (
              <>
                <div className="flex items-end">
                  <div
                    className="btn flex items-center justify-center leading-none focus:outline-none focus:shadow-none ml-auto"
                    onClick={() => setModalContractOpen(true)}
                  >
                    Ajouter un contrat
                  </div>
                </div>
                <hr className={"my-8"} />
              </>
            )}

            <div className="text-xl font-bold mb-4">Contrat courant</div>
            <ContractForm
              contract={currentContract.data}
              collaboratorIRI={collaboratorIri}
            />
            <hr className={"my-8"} />
            <div className="text-xl font-bold mb-4">
              Historique des contrats
            </div>
            {map(contractsHistory, (contractHistory) => (
              <ExpansionPanel
                key={contractHistory.id}
                title={
                  <span>
                    Terminé{" "}
                    {contractHistory.endDate
                      ? `le ${getLocaleDateString(contractHistory, "endDate")}`
                      : ""}
                  </span>
                }
              >
                <ContractForm
                  contract={contractHistory}
                  collaboratorIRI={collaboratorIri}
                  readOnly={!canEdit}
                />
              </ExpansionPanel>
            ))}
          </ExpansionPanel>
        </div>
        <div>
          <div>
            <ExpansionPanel title="Salaire" open={isDesktop}>
              {canEdit && (
                <>
                  <div className="flex items-end">
                    <div
                      className="btn flex items-center justify-center leading-none focus:outline-none focus:shadow-none ml-auto"
                      onClick={() => setModalSalaryOpen(true)}
                    >
                      Ajouter un salaire
                    </div>
                  </div>
                  <hr className={"my-8"} />
                </>
              )}

              <div className="text-xl font-bold mb-4">Salaire courant</div>
              <SalaryForm
                salary={currentSalary}
                contractIRI={get(currentContract, "data.@id")}
              />
              <hr className={"my-8"} />
              <div className="text-xl font-bold mb-4">
                Historique des salaires
              </div>
              <SalaryList salaries={salariesToDisplay} canEdit={canEdit} />
            </ExpansionPanel>
          </div>
        </div>
      </div>

      <Modal
        title="Ajouter un contrat"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={modalContractOpen}
        onRequestClose={() => setModalContractOpen(false)}
      >
        <div className="bg-white">
          <ContractForm
            collaboratorIRI={collaboratorIri}
            onSuccess={() => setModalContractOpen(false)}
          />
        </div>
      </Modal>

      <Modal
        title="Ajouter un salaire"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={modalSalaryOpen}
        onRequestClose={() => setModalSalaryOpen(false)}
      >
        <div className="bg-white">
          <SalaryForm
            contractIRI={get(currentContract, "data['@id']")}
            handleSubmit={() => setModalSalaryOpen(false)}
          />
        </div>
      </Modal>
    </PermissionChecker>
  );
}

export default Contrat;
