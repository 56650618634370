import {
  useCreateTypeReason,
  useDeleteTypeReason,
  useQueryTypeReasons,
  useUpdateTypeReason,
} from "../api/typeReasons";

import ManageResource from "../components/ManageResource";
import React from "react";

function TypeReasonsAdmin() {
  return (
    <ManageResource
      resourceLabel="Évolutions salariales"
      noElementsLabel="Aucune évolution salariale"
      createLabel="Ajouter un type d'évolution salariale"
      createHook={useCreateTypeReason}
      queryHook={useQueryTypeReasons}
      deleteHook={useDeleteTypeReason}
      updateHook={useUpdateTypeReason}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
      }}
    />
  );
}

export default TypeReasonsAdmin;
