import React from "react";
import { useUserId } from "../contexts/permissions";
import PasswordForm from "../forms/PasswordForm";
export default function UserPage() {
  const userId = useUserId();
  return (
    <div className="p-8 pb-24 flex flex-col">
      <PasswordForm userId={userId} />
    </div>
  );
}
