import React from "react";
import { useField } from "formik";
import { noop } from "lodash-es";

function Toggle({
  name,
  value,
  checked,
  onChange,
  label,
  className = "",
  readOnly = false,
  submitting = false,
}) {
  return (
    <label
      className={`flex cursor-pointer py-3 ${className} toogleCheckbox`}
      onClick={(e) => {
        if (readOnly) {
          e.preventDefault();
        }
      }}
    >
      <input
        type="checkbox"
        {...{ value, name }}
        checked={checked}
        onChange={onChange}
        className="hidden"
        readOnly={readOnly || submitting}
      />
      <span className={`slider ${submitting ? "submitting" : ""}`}>
        <span className="round" />
      </span>
      {label && <span className="select-none">{label}</span>}
    </label>
  );
}

export function ToggleField({
  label,
  className = "",
  readOnly = false,
  onClick = noop,
  ...props
}) {
  const [field, meta] = useField(props);
  return (
    <div className={`${className ? className : "mb-8"}`}>
      <label
        className={`flex items-center cursor-pointer`}
        onClick={(e) => {
          if (readOnly) {
            e.preventDefault();
          }
          onClick();
        }}
      >
        <div className="py-3 toogleCheckbox">
          <input
            type="checkbox"
            {...field}
            checked={field.value}
            className={`hidden`}
            readOnly={readOnly}
          />
          <span
            className={`slider ${
              readOnly ? "opacity-25 cursor-not-allowed" : ""
            }`}
          >
            <span className="round" />
          </span>
        </div>
        <span className="select-none pl-3">{label}</span>
      </label>
      {meta.touched && meta.error ? (
        <p className="text-red-500 text-xs italic mt-2">{meta.error}</p>
      ) : null}
    </div>
  );
}

export default Toggle;
