import queryWithToken, {
  create,
  deleteById,
  findById,
  updateById,
  updateOrCreate
} from "./index";
import { queryCache, useMutation, useQuery } from "react-query";
import { get } from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "type_vehicle_events";
//

const mutateDefaultOptions = {
  throwOnError: true,
  // When mutate is called:
  onMutate: (newEvent = null) => {
    queryCache.cancelQueries(ressourceKey);
  }
};

export function useQueryTypeEventVehicleById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQueryTypeEventVehicles() {
  const { data, ...rest } = useQuery(
    ressourceKey,
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false
        }
      }),
    {
      refetchOnWindowFocus: false
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest
  };
}

export function useCreateTypeEventVehicle() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: (newTypeEventVehicle = null) => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: data => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("history");
    }
  });
}

export function useUpdateTypeEventVehicle() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: (newTypeEventVehicle = null) => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("history");
    }
  });
}

export function useCreateOrUpdateTypeEventVehicle() {
  return useMutation(
    updateOrCreate.bind(null, ressourceKey),
    mutateDefaultOptions
  );
}

export function useDeleteTypeEventVehicle(resetHistory = false) {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
      if (resetHistory) {
        queryCache.refetchQueries("history");
      }
    }
  });
}
