import {
  useCreateTypeProcessOut,
  useDeleteTypeProcessOut,
  useQueryTypeProcessOut,
  useUpdateTypeProcessOut,
} from "../api/typeProcessOut";

import ManageResource from "../components/ManageResource";
import React from "react";
import * as Yup from "yup";
import { usePermissionsChecker } from "../contexts/permissions";

function TypeProcessOutAdmin() {
  const validation = Yup.object().shape({
    label: Yup.string().required("Requis"),
  });
  const canDelete = usePermissionsChecker({
    permissions: ["kdix.actions.alert_badge.edit"],
  });
  return (
    <ManageResource
      resourceLabel="Types de fins de contrats"
      noElementsLabel="Aucun type de fin de contrat"
      createLabel="Ajouter un type de fin de contrat"
      createHook={useCreateTypeProcessOut}
      queryHook={useQueryTypeProcessOut}
      deleteHook={useDeleteTypeProcessOut}
      updateHook={useUpdateTypeProcessOut}
      validationSchema={validation}
      canDelete={canDelete}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
      }}
      readOnly={!canDelete}
    />
  );
}

export default TypeProcessOutAdmin;
