import React from "react";
import { toast } from "react-toastify";
import {get, map} from "lodash-es";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import DownloadOrUpload from "../components/DownloadOrUpload";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { useImportLokoaIdentifiers } from "../api/lokoaIdentifiers";

export default function LokoaImportForm({ domain = null }) {
  const [importLokoaIdentifiers, { error }] = useImportLokoaIdentifiers();

  const validation = Yup.object().shape({
    file: Yup.mixed().test(
      "fileFormat",
      "Formats autorisés: csv",
      (value) => {
        const authorized = ["text/csv"];
        return (
          value === undefined || (value && authorized.includes(value.type))
        );
      }
    ),
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          file: ""
        }}
        validationSchema={validation}
        onSubmit={async ({ file, ...values }, actions) => {
          try {
            let importDocuments = null;
            if (file) {
              importDocuments = await importLokoaIdentifiers({
                file: file,
                domain: domain
              });
            }
            actions.setSubmitting(false);
            actions.resetForm();
            actions.resetForm();

            if (file) {
              toast.success(
                  `Identifiants Lokoa importés avec succès : (${importDocuments?.numberImported} identifiants)`
              );
            } else {
              toast.error('Aucun fichier uploadé');
            }
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div className="mt-5">
                <div>Importer des identifiants Lokoa (format CSV)</div>
                <DownloadOrUpload
                  name="file"
                  accept="text/csv"
                  uploadLabel="Uploader un fichier"
                  isSubmitting={isSubmitting}
                  submitButton={false}
                />
              </div>

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 block w-full`}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Importer"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
