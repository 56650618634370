import React from "react";
import { get } from "lodash-es";
import { getLocaleDateString } from "../../utils/date";
import { getFirstnameLastnameJob } from "../../utils/names";

export function alertTitleVehicle(alert) {
  return {
    title: `${get(alert, "typeAlert.label", "")} : ${get(
      alert,
      "vehicleEvent.type.label",
      ""
    )}`,
    subtitles: [
      {
        label: "Date de rappel",
        value: getLocaleDateString(alert, "reminderDate"),
      },
      {
        label: "Prochain évènement véhicule",
        value: `${
          get(alert, "vehicleEvent.dateNextEventOfThisType")
            ? getLocaleDateString(alert, "vehicleEvent.dateNextEventOfThisType")
            : ""
        }`,
      },
      {
        label: "Conducteur",
        value: getFirstnameLastnameJob(
          alert,
          "vehicleEvent.vehicle.collaborator"
        ),
      },
      {
        label: "Plaque d'immatriculation",
        value: get(alert, "vehicleEvent.vehicle.licencePlate", ""),
      },
    ],
  };
}

function AlertVehicleItem({ alert }) {
  return (
    <>
      {get(alert, "typeAlert.comment") && (
        <p>
          <span className="font-bold">Commentaire : </span>
          {get(alert, "typeAlert.comment")}
        </p>
      )}
    </>
  );
}

export default AlertVehicleItem;
