import React, { useState } from "react";
import { map } from "lodash-es";
import ExpansionPanel from "../ExpansionPanel";
import { DisplayInput } from "../Input";
import Button from "../Button";
import { ReactComponent as TrashIcon } from "../../svgs/trash.svg";
import { useAddRemoveSkillDomain } from "../../api/works";

export default function WorkSkillDomainsList({ work }) {
  if (!work) return null;
  return (
    <div>
      {map(work.skillDomains, (skillDomain) => (
        <ExpansionPanel
          className={"mt-2"}
          key={skillDomain.id}
          title={skillDomain.label}
          buttons={
            <DeleteButton workId={work.id} skillDomainId={skillDomain.id} />
          }
        >
          <div>
            <DisplayInput
              label="Domaine de compétence"
              value={skillDomain.label}
              className={"flex-1 mr-8 mb-4 flex-grow-3"}
            />
            {map(skillDomain.skills, (s, index) => (
              <DisplayInput
                key={index}
                label={`Compétence ${index + 1}`}
                value={s.label}
                className={"flex-1 mr-8 mb-4 flex-grow-3"}
              />
            ))}
          </div>
        </ExpansionPanel>
      ))}
    </div>
  );
}

function DeleteButton({ workId, skillDomainId }) {
  const [removeSkillDomain] = useAddRemoveSkillDomain();
  const [isDeleting, setIsDeleting] = useState();
  return (
    <div className={"mr-2"}>
      <Button
        type="button"
        superClassName={`w-8 h-8 rounded-full flex justify-center items-center focus:outline-none bg-red-600`}
        isSubmitting={isDeleting}
        loaderClassName={`loader h-6 w-6 mx-auto`}
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          const res = window.confirm("La suppression sera définitive");
          if (res) {
            setIsDeleting(true);
            await removeSkillDomain({
              id: workId,
              skillDomains: [skillDomainId],
              type: "remove",
            });
          }
        }}
      >
        <TrashIcon className="w-4 h-4 fill-current text-white" />
      </Button>
    </div>
  );
}
