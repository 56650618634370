import * as Yup from "yup";
import { Form, Formik } from "formik";
import {forEach, get, map} from "lodash-es";

import Alert from "../components/Alert";
import React from "react";
import Select from "../components/Select";
import Input, { DateTimePickerInput } from "../components/Input";
import { usePermissionsChecker } from "../contexts/permissions";
import {useUpdateCollaborator} from "../api/collaborator";
import { toast } from "react-toastify";
import Button from "../components/Button";
import { useQueryAgencies } from "../api/agencies";
import { useQueryServices } from "../api/services";
import { useQueryDepartments } from "../api/department";
import {useQueryCompanies} from "../api/companies";
import {useCreateOrUpdateEvent} from "../api/events";
import {useQueryPermissions} from "../api/permissions";
import {useUpdateUserPermission} from "../api/user";

import { getDaysBetweenDate, getMonthsBetweenDate, getYearsBetweenDate } from "../utils/date";

const validation = Yup.object().shape({
  agencies: Yup.array().min(1, "au moins un site").required("Requis"),
});

export default function CollaboratorContractualInformationForm({
  collaborator
}) {
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.personal_information.edit",
      "kdix.actions.personal_information.edit.agency",
      "kdix.actions.personal_information.edit.department",
      "kdix.actions.personal_information.edit.service",
      "kdix.actions.personal_information.edit.own",
    ],
  });

  const { data: companies } = useQueryCompanies();
  const { data: agencies } = useQueryAgencies();
  const { data: services } = useQueryServices();
  const { data: departments } = useQueryDepartments();
  const { data: permissions } = useQueryPermissions({
      isAdmin: false
  });

  const [updateCollaborator, { error }] = useUpdateCollaborator();
  const [updateUserPermission] = useUpdateUserPermission();
  const [createEvent] = useCreateOrUpdateEvent();

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          hiredate: get(collaborator, "hiredate")
            ? new Date(get(collaborator, "hiredate"))
            : null,
          senioritydate: get(collaborator, "senioritydate")
            ? (getDaysBetweenDate(new Date(), new Date(get(collaborator, "senioritydate")))<30 ? getDaysBetweenDate(new Date(), new Date(get(collaborator, "senioritydate")))+" jour(s)"
            : (getMonthsBetweenDate(new Date(), new Date(get(collaborator, "senioritydate")))<12 ? getMonthsBetweenDate(new Date(), new Date(get(collaborator, "senioritydate")))+ " mois"
            : getYearsBetweenDate(new Date(), new Date(get(collaborator, "senioritydate"))) + " an(s)"))
            : "",
          companies: map(
              get(collaborator, "companies"),
              (node) => ({
                  label: node.label,
                  value: node["@id"],
              }),
              []
          ),
          agencies: map(
            get(collaborator, "agencies"),
            (node) => ({
              label: node.label,
              value: node["@id"],
            }),
            []
          ),
          service: {
            label: get(collaborator, "service.code", ""),
            value: get(collaborator, "service.@id", ""),
          },
          departments: map(
              get(collaborator, "departments"),
              (node) => ({
                  label: node.label,
                  value: node["@id"],
              }),
              []
          ),
          departmentsParent: map(
              get(collaborator, "departments"),
              (node) => ({
                  label: node.parent ? node.parent.label : '',
                  value: node.parent ? node.parent["@id"] : '',
              }),
              []
          ),
          permission: {
              label: get(collaborator, "linkedUser.permission.label", ""),
              value: get(collaborator, "linkedUser.permission.@id", ""),
          },
        }}
        validationSchema={validation}
        onSubmit={async (values, actions) => {
          try {
            const payloadCollaborator = {
              companies: values.companies.map((a) => a.value),
              agencies: values.agencies.map((a) => a.value),
              service: values.service.value || null,
              departments: values.departments.map((a) => a.value),
              hiredate: values.hiredate,
            };

            await updateCollaborator({
              id: collaborator.id,
              data: payloadCollaborator,
            });

            await updateUserPermission({
                id: collaborator.linkedUser.id,
                data: {
                    permission: values.permission.value
                },
            });

            //On créé un évent "changement de site" si le collaborateur était dans un autre site avant
            if (collaborator.agencies.length > 0 && payloadCollaborator.agencies.length > 0) {
                //On ne doit créer l'évent que s'il y a une différence entre les anciens et nouveux sites
                let isValid = false;
                let comment = 'Ancien(s) site(s) '

                forEach(collaborator.agencies, (agency, key) => {
                    //Si on a retiré un site, l'évent doit être créé
                    if (!payloadCollaborator.agencies.find(element => element === agency['@id'])) {
                        isValid = true;
                    }
                    if (key > 0) {
                        comment = comment + ", ";
                    }
                    comment = comment + agency.label;
                })
                comment = comment + " - Nouveau(x) site(s) ";
                forEach(payloadCollaborator.agencies, (agency, key) => {
                    //Si on a ajouté un nouveau site, l'évent doit être créé
                    if (!collaborator.agencies.find(element => element['@id'] === agency)) {
                        isValid = true;
                    }
                    if (key > 0) {
                        comment = comment + ", ";
                    }
                    const label = agencies.find(element => element['@id'] === agency).label;
                    comment = comment + label;
                })
                if (payloadCollaborator.agencies.length === 0) {
                    comment = comment + "Aucun";
                }

                //On ne créé l'évent que si il y a eu un ajout ou suppression de site
                if (isValid) {
                    await createEvent({
                        id: null,
                        data: {
                            label: 'Changement de site',
                            comment: comment,
                            collaborators: [collaborator['@id']],
                            startDate: new Date(),
                        },
                    });
                }
            }

            toast.success("Mise à jour effectuée avec succès");
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, setFieldValue, submitCount }) => {
          return (
            <Form>
               <DateTimePickerInput
                name="hiredate"
                label="Date d'embauche"
                readOnly={readOnly}
              />
              <Input
                type="text"
                name="senioritydate"
                label="Ancienneté"
                readOnly={true}
              />
              <Select
                  label="Société"
                  isMulti
                  name="companies"
                  options={map(companies, (node) => ({
                      label: node.label,
                      value: node["@id"],
                  }))}
                  value={values.companies}
                  isDisabled={readOnly}
                  isLoading={!companies}
              />
              <Select
                label="Site"
                isMulti
                name="agencies"
                options={map(agencies, (node) => ({
                  label: node.label,
                  value: node["@id"],
                }))}
                value={values.agencies}
                isDisabled={readOnly}
                isLoading={!agencies}
              />
              <Select
                label="Départements/Pôle"
                isMulti
                name="departments"
                options={map(departments, (node) => ({
                  label: node.label,
                  value: node["@id"],
                }))}
                value={values.departments}
                isDisabled={readOnly}
                isLoading={!departments}
              />
              <Select
                label="Service"
                name="service"
                options={map(services, (node) => ({
                  label: node.code,
                  value: node["@id"],
                }))}
                value={values.service}
                isDisabled={readOnly}
                isLoading={!services}
              />
              <Select
                  label="Profil"
                  name="permission"
                  options={map(permissions, (node) => ({
                      label: node.label,
                      value: node["@id"],
                  }))}
                  value={values.permission}
                  isDisabled={readOnly || values.permission?.isAdmin}
                  isLoading={!permissions}
              />
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}
              <Button
                className={`btn mt-6 ml-auto block`}
                readOnly={readOnly}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
