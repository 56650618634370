import * as React from "react";

import { useCollaboratorId, usePermissionsChecker } from "./permissions";

import Alert from "../components/Alert";
import ErrorBoundary from "../components/ErrorBoundary";
import Spinner from "../components/Spinner";
import { get } from "lodash-es";
import { useQueryAffectedAccreditationById } from "../api/affectedAccreditation";
import { useQueryCollaboratorById } from "../api/collaborator";
export const AffectedAccreditationContext = React.createContext({
  affectedAccreditation: null,
});

function AffectedAccreditationError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération de l'habilité"
        details={get(error, "data.hydra:description")}
      />
    </div>
  );
}

function AffectedAccreditationProvider({ id, children }) {
  const hasPermissions = usePermissionsChecker({
    permissions: [
      "kdix.actions.accreditation.manage",
      "kdix.actions.accreditation.manage.agency",
      "kdix.actions.accreditation.manage.department",
      "kdix.actions.accreditation.manage.service",
      "kdix.actions.accreditation.manage.own",
    ],
  });
  const userCollaborator = useCollaboratorId();
  const { data: collaborator } = useQueryCollaboratorById(userCollaborator);
  const full =
    hasPermissions ||
    get(collaborator, "affectedAccreditation.id", null) === +id;
  const {
    status: statusAffectedAccreditation,
    error: errorAffectedAccreditation,
    data: affectedAccreditation,
  } = useQueryAffectedAccreditationById(full && id);

  if (errorAffectedAccreditation) {
    return <AffectedAccreditationError error={errorAffectedAccreditation} />;
  }

  return (
    <AffectedAccreditationContext.Provider
      value={{
        affectedAccreditation: {
          data: affectedAccreditation || {},
          status: statusAffectedAccreditation,
          error: errorAffectedAccreditation,
        },
      }}
    >
      {children}
    </AffectedAccreditationContext.Provider>
  );
}

export default (props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<Spinner />}>
      <AffectedAccreditationProvider {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
