import { Form, Formik } from "formik";
import { get } from "lodash-es";
import Alert from "../components/Alert";
import Button from "../components/Button";
import React from "react";
import { formErrorHandler } from "../utils/formErrorHandler";
import { useDeleteAccreditation } from "../api/accreditation";

function DeleteAccreditation({ id, onSuccess = () => {} }) {
  const [deleteAccreditation, { error }] = useDeleteAccreditation();

  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values, actions) => {
        try {
          const response = await deleteAccreditation(id);
          actions.setSubmitting(false);
          onSuccess(response);
        } catch (error) {
          formErrorHandler(error, actions);
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            Êtes-vous sûr de vouloir supprimer cette habilitation ?
            {error ? (
              <div className="my-2">
                <Alert
                  type="error"
                  message={get(error, "title")}
                  details={get(error, "description")}
                />
              </div>
            ) : null}
            <div className="flex justify-center">
              <Button
                className={`btn mt-6 block`}
                readOnly={false}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Supprimer l'habilitation"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DeleteAccreditation;
