import Input, { DateTimePickerInput } from "../Input";
import HistoryItem from "../HistoryItem/HistoryItem";
import React, { useState } from "react";
import { ReactComponent as CalendrierIcon } from "../../svgs/calendrier-vide.svg";
import { get, map } from "lodash-es";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import Alert from "../Alert";
import EditButton from "../HistoryItem/EditButton";
import { useDeleteRental, useUpdateRental } from "../../api/rental";
import { ListTypeRental } from "../../forms/formsUtils/ListTypeRental";

function RentalHistoryItem({ rental, date, isLeft }) {
  const [isEditing, setIsEditing] = useState(false);
  const [update, { error }] = useUpdateRental();
  const [deleteRental, { status }] = useDeleteRental();
  if (status === "success") return null;
  return (
    <Formik
      initialValues={{
        startDate: get(rental, "startDate")
          ? new Date(get(rental, "startDate"))
          : "",
        endDate: get(rental, "endDate") ? new Date(get(rental, "endDate")) : "",
        mileage: get(rental, "mileage"),
        iriRental: get(rental, "type[@id]"),
      }}
      onSubmit={async ({ startDate, endDate, mileage, iriRental }, actions) => {
        try {
          await update({
            id: rental.id,
            data: {
              startDate: startDate ? startDate : null,
              endDate: endDate ? endDate : null,
              mileage: parseInt(mileage, 10),
              type: iriRental,
            },
          });
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
        } finally {
          actions.setSubmitting();
          setIsEditing(false);
          toast.success("Mise à jour effectuée avec succès");
        }
      }}
    >
      {({ isSubmitting, resetForm, setSubmitting, values, setFieldValue }) => {
        return (
          <Form>
            <HistoryItem
              title={
                get(rental, "type.label", "") !== ""
                  ? `Location - ${get(rental, "type.label", "")}`
                  : "Location"
              }
              Icon={CalendrierIcon}
              backgroundIcon="bg-purple-500"
              date={`${date ? new Date(date).toLocaleDateString() : ""}`}
              itemId={rental.id}
              isToggle={true}
              textColor={"text-purple-500"}
              isLeft={isLeft}
            >
              <div className={"flex flex-wrap"}>
                <div className={"w-full lg:w-1/2 lg:pr-2"}>
                  <DateTimePickerInput
                    fullWidth={true}
                    label="Date de début du contrat"
                    name="startDate"
                    readOnly={!isEditing}
                  />
                  <DateTimePickerInput
                    fullWidth={true}
                    label="Date de fin du contrat"
                    name="endDate"
                    readOnly={!isEditing}
                  />
                </div>
                <div className={"w-full lg:w-1/2 lg:pl-2"}>
                  <Input
                    label="Kilométrage"
                    name="mileage"
                    readOnly={!isEditing}
                  />
                  <ListTypeRental
                    iriRental={values.iriRental}
                    setIriRental={(value) => setFieldValue("iriRental", value)}
                    readOnly={!isEditing}
                    type={"location"}
                  />
                </div>
              </div>

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <EditButton
                onDelete={() => {
                  setSubmitting(true);
                  deleteRental(rental.id);
                }}
                isSubmitting={isSubmitting}
                isEditing={isEditing}
                onCancel={() => {
                  resetForm();
                  setIsEditing(!isEditing);
                }}
                permissionsEdit={[
                  "kdix.actions.vehicle.edit",
                  "kdix.actions.vehicle.edit.agency",
                  "kdix.actions.vehicle.edit.department",
                  "kdix.actions.vehicle.edit.service",
                  "kdix.actions.vehicle.edit.own",
                ]}
                onClick={() => {
                  setIsEditing(!isEditing);
                }}
              />
            </HistoryItem>
          </Form>
        );
      }}
    </Formik>
  );
}

export default RentalHistoryItem;
