import { queryCache, useMutation, useQuery } from "react-query";

import React from "react";
import queryWithToken from "./index";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "id_cards";
//

export function useCreateOrUpdateIdCard() {
  return useMutation(
    ({ file, personalInformationId }) => {
      const data = new FormData();
      data.append("file", file);
      data.append("personalInformationId", personalInformationId);
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
        {
          method: "POST",
          data,
        }
      );
    },
    {
      throwOnError: true,
      onSuccess: (data) => {
        queryCache.refetchQueries(ressourceKey);
        queryCache.refetchQueries(["personal_informations"]);
      },
    }
  );
}

export function useGetIdCard(id) {
  const [link, setLink] = React.useState(null);
  const { data: blob } = useQuery(id && [ressourceKey, id], () =>
    queryWithToken(`${process.env.REACT_APP_API_URL}/id_cards/${id}`, {
      method: "GET",
      responseType: "blob",
    })
  );

  React.useEffect(() => {
    if (blob) {
      let objectUrl = window.URL.createObjectURL(blob);
      setLink(objectUrl);
    }
  }, [blob]);

  return link;
}

export function useGetIdCardDocuments(id) {
  const { data, isFetching } = useQuery(
    id,
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: [data],
    isFetching,
  };
}

export async function downloadDocument(id, label = "document") {
  const blob = await queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/document`,
    {
      method: "GET",
      responseType: "blob",
    }
  );
  if (blob) {
    saveAs(blob, label);
  }
}

export function useDeleteDocument() {
  return useMutation(
    (id) =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}`, {
        method: "DELETE",
      }),
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de la suppression");
      },
      onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
      },
      onSuccess: () => {
        queryCache.refetchQueries(ressourceKey);
      },
    }
  );
}
