import * as Sentry from "@sentry/browser";

import React from "react";

export default class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { error: error };
  }
  componentDidCatch(error) {
    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(error);
    }
  }
  render() {
    if (this.state.error) {
      if (typeof this.props.fallback === "function") {
        return this.props.fallback(this.state.error?.message);
      } else if (this.props.fallback) {
        return this.props.fallback;
      }
      return <div className="p-16 text-center text-2xl">Error</div>;
    }
    return this.props.children;
  }
}
