import {
  useCreateTypeContract,
  useDeleteTypeContract,
  useQueryTypeContracts,
  useUpdateTypeContract,
} from "../api/typeContracts";

import ManageResource from "../components/ManageResource";
import React from "react";

function TypeContractsAdmin() {
  return (
    <ManageResource
      resourceLabel="Types de contrats"
      createLabel="Ajouter un type de contrat"
      createHook={useCreateTypeContract}
      queryHook={useQueryTypeContracts}
      deleteHook={useDeleteTypeContract}
      updateHook={useUpdateTypeContract}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
      }}
    />
  );
}

export default TypeContractsAdmin;
