import * as React from "react";

import { usePermissionsChecker } from "./permissions";

import Alert from "../components/Alert";
import ErrorBoundary from "../components/ErrorBoundary";
import Spinner from "../components/Spinner";
import { get } from "lodash-es";
import { useQueryWorkAccidentById } from "../api/workAccident";
import { useQueryCollaboratorById } from "../api/collaborator";
export const WorkAccidentContext = React.createContext({
  workAccident: null,
  collaborator: null,
});

function WorkAccidentError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération de l'accident"
        details={get(error, "data.hydra:description")}
      />
    </div>
  );
}

function WorkAccidentProvider({ id, children }) {
  const hasPermissions = usePermissionsChecker({
    permissions: [
      "kdix.actions.work_accident.view",
      "kdix.actions.work_accident.view.agency",
      "kdix.actions.work_accident.view.department",
      "kdix.actions.work_accident.view.service",
      "kdix.actions.work_accident.view.own",
    ],
  });
  const {
    status: statusWorkAccident,
    error: errorWorkAccident,
    data: workAccident,
  } = useQueryWorkAccidentById(hasPermissions && id);

  const {
    status: statusCollaborator,
    error: errorCollaborator,
    data: collaborator,
  } = useQueryCollaboratorById(
    hasPermissions && get(workAccident, "victim.id", null)
  );

  if (errorWorkAccident) {
    return <WorkAccidentError error={errorWorkAccident} />;
  }
  return (
    <WorkAccidentContext.Provider
      value={{
        workAccident: {
          data: workAccident || {},
          status: statusWorkAccident,
          error: errorWorkAccident,
        },
        collaborator: {
          data: collaborator || {},
          status: statusCollaborator,
          error: errorCollaborator,
        },
      }}
    >
      {children}
    </WorkAccidentContext.Provider>
  );
}

export default (props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<Spinner />}>
      <WorkAccidentProvider {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
