import Block from "../Block";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { useCountUserAbsenceNotifications } from "../../api/user";

function AbsenceBlock({ userId }) {
  const { data: leaves } = useCountUserAbsenceNotifications(userId, {
    viewed: false,
    read: false,
  });

  return (
    <>
      <Block className="text-center flex-grow bg-white">
        <Link
          to={`absences`}
          className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
        >
          <div
            className={`bg-pink-50 inline-flex text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
          >
            {leaves}
          </div>
          {leaves ? (
            <span
              className="unread bg-red-700 rounded-full text-white text-xs absolute w-5 h-5 ml-1 -mt-3"
              style={{ marginLeft: "43px", marginTop: "5px" }}
            >
              &nbsp;
            </span>
          ) : null}
          <div className="mt-3 text-xl font-black">{`Congés et Absences`}</div>

          <div className="mt-2 text-base font-light">&nbsp;</div>

          <Button
            className={`font-bold p-1 focus:outline-none text-black bg-purple-500 text-white w-full block`}
          >
            voir tout
          </Button>
        </Link>
      </Block>
    </>
  );
}

export default AbsenceBlock;
