import { find } from "lodash";

//needle: any | any[], haystack: any[]
export function findInList(needle, haystack) {
  if (Array.isArray(needle)) {
    return haystack.filter((e) => {
      return needle.indexOf(e.value) !== -1;
    });
  } else {
    return find(haystack, (e) => {
      return e.value === needle;
    });
  }
}
