import {get, noop} from "lodash-es";
import {Form, Formik} from "formik";
import Input from "../components/Input";
import React from "react";
import * as Yup from "yup";
import Checkbox from "../components/Checkbox";

const validateNewProfile = Yup.object().shape({
    label: Yup.string().required("Requis"),
});

export default function CreateProfile({onSubmit = noop, duplicate = false, isEditing = false, values = null}) {
    return (
        <div className="mt-8">
            <Formik
                initialValues={{
                    id: get(values, "id", null),
                    label: get(values, "label", ""),
                    isAdmin: get(values, "isAdmin", false),
                }}
                validationSchema={validateNewProfile}
                onSubmit={onSubmit}
            >
                {({isSubmitting}) => {
                    return (
                        <Form>
                            <div className="mb-8 items-start">
                                <Input
                                    name="label"
                                    placeholder="Nom du profil"
                                    className="mb-4 w-full mr-4"
                                />
                                <Checkbox
                                    label="Profil administrateur"
                                    name="isAdmin"
                                    className="mb-4 w-full mr-4"
                                />
                                <button
                                    type="submit"
                                    className="w-full sm:w-auto mt-4 sm:mt-0 flex-no-shrink btn btn--sm rounded-none whitespace-no-wrap"
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? (
                                        <div className="loader h-8 w-8 mx-auto"/>
                                    ) : duplicate ? (
                                        "Dupliquer un profil"
                                    ) : isEditing ? (
                                        "Modifier ce profil"
                                    ) : (
                                        "Créer un nouveau profil"
                                    )}
                                </button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
