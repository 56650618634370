import { Form, Formik } from "formik";
import { get } from "lodash-es";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import React from "react";
import { formErrorHandler } from "../../utils/formErrorHandler";
import {useCreateOrUpdateFormation, useQueryFormationById} from "../../api/formation";

function DuplicateFormation({ id, onSuccess = () => {} }) {
  const {data: formation} = useQueryFormationById(id);
  const [createOrUpdate, { error }] = useCreateOrUpdateFormation();

  return (
    <Formik
      initialValues={{
          name: "Copie de " + get(formation, "name", ""),
          duration: get(formation, "duration", ""),
          description: get(formation, "description", ""),
          category: get(formation, "category", ""),
          organization: get(formation, "organization", ""),
          location: get(formation, "location", ""),
      }}
      onSubmit={async (values, actions) => {
        try {
          const response = await createOrUpdate({
              id: get(null),
              data: {
                  ...values,
              },
          });
          actions.setSubmitting(false);
          onSuccess(response);
        } catch (error) {
          formErrorHandler(error, actions);
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            Êtes-vous sûr de vouloir dupliquer cette formation ?
            {error ? (
              <div className="my-2">
                <Alert
                  type="error"
                  message={get(error, "title")}
                  details={get(error, "description")}
                />
              </div>
            ) : null}
            <div className="flex justify-center">
              <Button
                className={`btn mt-6 block`}
                readOnly={false}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Dupliquer la formation"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DuplicateFormation;
