import { Form, Formik } from "formik";
import { get } from "lodash-es";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import React from "react";
import { formErrorHandler } from "../../utils/formErrorHandler";
import { useDeleteFormation } from "../../api/formation";

function DeleteFormation({ id, onSuccess = () => {} }) {
  const [deleteFormation, { error }] = useDeleteFormation();

  return (
    <Formik
      initialValues={{}}
      onSubmit={async (values, actions) => {
        try {
          const response = await deleteFormation(id);
          actions.setSubmitting(false);
          onSuccess(response);
        } catch (error) {
          formErrorHandler(error, actions);
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            Êtes-vous sûr de vouloir supprimer cette formation ?
            {error ? (
              <div className="my-2">
                <Alert
                  type="error"
                  message={get(error, "title")}
                  details={get(error, "description")}
                />
              </div>
            ) : null}
            <div className="flex justify-center">
              <Button
                className={`btn mt-6 block`}
                readOnly={false}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Supprimer la formation"
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default DeleteFormation;
