import React, { useState } from "react";
import { useDebounce } from "react-use";
import { Helmet } from "react-helmet";
import { HeaderBar } from "../../components/HeaderBar";
import Spinner from "../../components/Spinner";
import { useQuerySkillsInfinite } from "../../api/skills";
import { useQuerySkillDomains } from "../../api/skillDomains";
import { reduce, flatMap, map, size, times } from "lodash-es";
import Alert from "../../components/Alert";
import { useDesktop } from "../../hooks/useDesktop";
import InlineBLockContentSortable, {
  InlineBLockContent,
} from "../../components/InlineBlockContentSortable";
import InfiniteScroll from "react-infinite-scroller";
import ExpansionPanel from "../../components/ExpansionPanel";
import ClassicFilters from "../../components/Filter/ClassicFilters";
import { ReactComponent as ListIcon } from "../../svgs/ressources-humaines.svg";
import InfoTooltip from "../../components/InfoTooltip";
import StarScore from "../../components/StarScore";
import { useQueryWorks } from "../../api/works";
import Modal from "../../components/Modal";
import { useQueryEvaluationScores } from "../../api/evaluationSkillScores";
import { getFirstnameLastname } from "../../utils/names";

function calcTotalCollaboratorsWork(works) {
  return reduce(
    works,
    (total, work) => {
      total += work.collaborators ? size(work.collaborators) : 0;
      return total;
    },
    0,
  );
}

function SkillsList({ query, filters }) {
  const [orderFilter, setOrderFilter] = useState({ "order[label]": "asc" });
  const { data, fetchMore, canFetchMore } = useQuerySkillsInfinite({
    orSearch_fullname: query,
    ...orderFilter,
    ...filters,
  });
  const [modalListSkillId, setModalListSkillId] = useState(null);

  const isDesktop = useDesktop();

  const skills = flatMap(data, (page) => page["hydra:member"]);
  if (size(skills) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="Aucune compétence" />
      </div>
    );
  }

  const gridTemplateColumn = `24% 24% 20% 9% 15% 8%`;

  return (
    <div className="relative">
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="skillDomain.label"
          >
            Domaine
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="label"
          >
            Nom de la compétence
          </InlineBLockContentSortable>
          <InlineBLockContent>Poste(s)</InlineBLockContent>
          <InlineBLockContent>
            Nombre de collaborateurs à ce poste
          </InlineBLockContent>
          <InlineBLockContent>Niveau moyen</InlineBLockContent>
          <InlineBLockContent>Voir Liste</InlineBLockContent>
        </div>
      )}
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {map(skills, (node) => {
          const nbCollaborators = calcTotalCollaboratorsWork(
            node.skillDomain.works,
          );
          return isDesktop ? (
            <div
              key={node.id}
              className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2`}
              style={{
                gridTemplateColumns: gridTemplateColumn,
              }}
            >
              <InlineBLockContent>{node.skillDomain.label}</InlineBLockContent>
              <InlineBLockContent>{node.label}</InlineBLockContent>
              <InlineBLockContent>
                <div className={"flex flex-col"}>
                  {map(node.skillDomain.works, (work, key) => (
                    <div key={key} className="mr-2">
                      {work.label}
                    </div>
                  ))}
                </div>
              </InlineBLockContent>
              <InlineBLockContent>
                <div className={"flex flex-col"}>{nbCollaborators}</div>
              </InlineBLockContent>
              <InlineBLockContent>
                <StarScore score={node.averageLevel} />
              </InlineBLockContent>
              <InlineBLockContent className="flex justify-center gap-2">
                <ViewButton
                  id={node["@id"]}
                  toggleModal={setModalListSkillId}
                />
              </InlineBLockContent>
            </div>
          ) : (
            <WorkMobileCard work={node} />
          );
        })}
      </InfiniteScroll>
      <Modal
        title="Liste des collaborateurs à ce poste"
        className="outline-none w-full max-w-full lg:max-w-xl max-h-full overflow-visible overflow-y-auto"
        handleClose={(e) => {
          e.stopPropagation();
          setModalListSkillId(null);
        }}
        isOpen={modalListSkillId !== null}
        onRequestClose={() => setModalListSkillId(null)}
      >
        {modalListSkillId !== null && (
          <React.Suspense fallback={<Spinner />}>
            <ListModal id={modalListSkillId} />
          </React.Suspense>
        )}
      </Modal>
    </div>
  );
}

function ViewButton({ id, toggleModal }) {
  return (
    <>
      <InfoTooltip
        message={"Voir les collaborateurs à ce poste"}
        onHover={true}
      >
        <button
          type="button"
          onClick={() => toggleModal(id)}
          className="w-8 h-8 rounded-full flex justify-center items-center focus:outline-none bg-green-700"
        >
          <ListIcon className="w-4 h-4 fill-current text-white" />
        </button>
      </InfoTooltip>
    </>
  );
}

function ListModal({ id }) {
  const { data } = useQueryEvaluationScores({
    skill: id,
    isEvaluator: false,
    "evaluation.status": "closed",
  });
  return data.length === 0 ? (
    <div>Aucun collaborateur n'a été évalué pour cette compétence</div>
  ) : (
    <div className="grid grid-cols-2 gap-y-3">
      {map(data, (evaluationSkill) => (
        <React.Fragment key={evaluationSkill.id}>
          <div>{getFirstnameLastname(evaluationSkill.collaborator)}</div>
          <div>
            <StarScore score={evaluationSkill.score} />
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

function WorkMobileCard({ work }) {
  return <ExpansionPanel key={work.id} title={work.label}></ExpansionPanel>;
}

const getFiltersList = (skillDomains, works) => [
  {
    label: "Domaine de compétence",
    key: "skillDomain",
    options: map(skillDomains, (node) => ({
      label: node.label,
      value: node["@id"],
      id: node["@id"],
    })),
  },
  {
    label: "Poste",
    key: "skillDomain.works",
    options: map(works, (node) => ({
      label: node.label,
      value: node["@id"],
      id: node["@id"],
    })),
  },
  {
    label: "Niveau supérieur ou égal à",
    key: "averageLevel[gte]",
    unique: true,
    options: times(5, (n) => ({ label: n + 1, value: n + 1, id: n + 1 })),
  },
  {
    label: "Niveau inférieur ou égal à",
    key: "averageLevel[lte]",
    unique: true,
    options: times(5, (n) => ({ label: n + 1, value: n + 1, id: n + 1 })),
  },
];

export default function SkillsMapping() {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query],
  );

  const [filters, setFilters] = useState({});
  return (
    <div>
      <Helmet>
        <title>Cartographie des compétences</title>
      </Helmet>
      <HeaderBar
        sticky={true}
        smaller={true}
        title={<>Cartographie des compétences</>}
      >
        <input
          type="text"
          name="query"
          value={query}
          className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
          placeholder="Rechercher une compétence ou un domaine ou un poste"
          onChange={(e) => setQuery(e.target.value)}
        />
      </HeaderBar>
      <div>
        <SkillMappingFilters setFilters={setFilters} />
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <SkillsList query={debouncedQuery} filters={filters} />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

function SkillMappingFilters({ setFilters }) {
  const { data: skillDomains } = useQuerySkillDomains();
  const { data: works } = useQueryWorks();
  const filtersList = getFiltersList(skillDomains, works);

  return (
    <div className="px-8 mt-8 mb-4">
      <ClassicFilters
        scroll
        filtersList={filtersList}
        onChange={(filters) => {
          setFilters(filters);
        }}
      />
    </div>
  );
}
