import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import queryWithToken, {
  create,
  deleteById,
  find,
  findById,
  updateById,
  updateOrCreate,
} from "./index";

import { get } from "lodash-es";
import { toast } from "react-toastify";
import mime from "mime-types";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "evaluations";
//

const mutateDefaultOptions = {
  throwOnError: true,
  // When mutate is called:
  onMutate: () => {
    queryCache.cancelQueries(ressourceKey);
  },
};

export function useQueryEvaluationById(id) {
  return useQuery(id && [ressourceKey, id], findById, {
    refetchOnWindowFocus: false,
  });
}

export function useQueryEvaluations(filters = {}) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filters],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQueryEvaluationsInfinite(filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    refetchOnWindowFocus: false,
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}

export function useCreateEvaluation() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useUpdateEvaluation() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: () => {
      toast.success("Mise à jour effectuée avec succès");
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useCreateOrUpdateEvaluation() {
  return useMutation(
    updateOrCreate.bind(null, ressourceKey),
    mutateDefaultOptions,
  );
}

export function useDeleteEvaluation() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
    onError: (error) => {
      toast.error(
        "Erreur lors de la suppression : (" + error.description + ")",
      );
    },
  });
}

const saveEvaluation = (key, { id, data }) => {
  return queryWithToken(`${process.env.REACT_APP_API_URL}/${key}/save/${id}`, {
    method: "PATCH",
    data: data,
  });
};

export function useSaveEvaluation() {
  return useMutation(saveEvaluation.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      toast.success("Mise à jour effectuée avec succès");
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries([ressourceKey]);
      queryCache.refetchQueries(["evaluation_skill_scores"]);
    },
  });
}

export async function downloadEvaluationPdf(
  id,
  label = "evaluations",
  extension = "pdf",
) {
  const blob = await queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/pdf`,
    {
      method: "GET",
      responseType: "blob",
    },
  );
  if (blob) {
    const type = mime.lookup(extension);
    const file = new Blob([blob], { type });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}
export function useDeleteWorkAccidentDocument(resetHistory = false) {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}
