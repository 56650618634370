import { useInfiniteQuery, useQuery } from "react-query";
import queryWithToken, { findById } from "./index";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "collaborators";
//

export function useQueryValidators(filters, preValidators) {
  const params = preValidators
    ? { "exists[collaboratorsCanBePreValidate]": true }
    : { "exists[collaboratorsCanBeValidate]": true };
  const queryKeyValidator = preValidators ? "prevalidators" : "validators";
  return useInfiniteQuery(
    [ressourceKey, queryKeyValidator, filters],
    (key, route, filters, page) => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/list`,
        {
          method: "GET",
          params: {
            page: page ? page : null,
            pagination: true,
            itemsPerPage: 20,
            ...filters,
            ...params,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
      suspense: false,
      getFetchMore: (lastGroup) => {
        const nextUrl = lastGroup["hydra:view"]["hydra:next"];
        const match = /page=(\d+)/.exec(nextUrl);
        if (match) {
          return match[1];
        }

        return false;
      },
    }
  );
}

export function useQueryValidatorById(id, options) {
  return useQuery(id && [`${ressourceKey}/validator`, id], findById, {
    refetchOnWindowFocus: false,
    enabled: !!id,
    ...options,
  });
}
