import React from "react";
import moment from "moment";
import "moment/locale/fr";
import { map, times } from "lodash-es";
moment.locale("fr");

export function MonthPlanningSwitchLabel({ days }) {
  return (
    <span className="capitalize">
      {days[1].date.format("MMMM")} {days[1].date.format("YYYY")}
    </span>
  );
}

export default function MonthPlanningTable({ days }) {
  return (
    <>
      {map(days, (day, index) => (
        <div
          className="border-r border-gray-150 col-span-7 grid grid-cols-7"
          key={index}
        >
          {times(7, (i) => (
            <div className="py-3 border-r border-gray-100" key={i} />
          ))}
        </div>
      ))}
    </>
  );
}

export function MonthPlanningHead({ days }) {
  return (
    <div
      className={`weekPlanningGrid planningHeadContainer gridRepeat-${days.length}`}
    >
      <div className="border-gray-150 border-r" />
      {map(days, (day, index) => (
        <div className="planningHead" key={index}>
          {day.label}
        </div>
      ))}
    </div>
  );
}
