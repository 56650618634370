import Block from "../Block";
import React from "react";
import { useQueryCollaboratorHomeById } from "../../api/collaborator";

export default function HolidaysBlock({ collaboratorId }) {
  const { data } = useQueryCollaboratorHomeById(collaboratorId);
  const nbHolidaysN = parseFloat(data?.nbHolidaysN ?? 0);
  const plural = nbHolidaysN > 0 ? "s" : "";
  return nbHolidaysN >= 0 ? (
    <div className={"w-full md:w-1/3 p-2 h-64 flex-1"}>
      <Block className="text-center flex-grow bg-white">
        <div
          className={`bg-purple-400 inline-flex text-white text-2xl rounded-full font-bold w-16 h-16 items-center justify-center mb-4`}
        >
          {nbHolidaysN.toLocaleString("fr-FR")}
        </div>
        <div className="mt-3 text-xl font-black">
          {`Jour${plural} de congé${plural} restant${plural}`}
        </div>
      </Block>
    </div>
  ) : null;
}
