import * as React from "react";

import { useCollaboratorId, usePermissionsChecker } from "./permissions";

import Alert from "../components/Alert";
import ErrorBoundary from "../components/ErrorBoundary";
import Spinner from "../components/Spinner";
import { get } from "lodash-es";
import { useQueryAccreditationById } from "../api/accreditation";
import { useQueryCollaboratorById } from "../api/collaborator";
export const AccreditationContext = React.createContext({
  accreditation: null,
});

function AccreditationError(error) {
  return (
    <div className="mb-48">
      <Alert
        type="error"
        message="Erreur lors de la récupération de l'habilitation"
        details={get(error, "data.hydra:description")}
      />
    </div>
  );
}

function AccreditationProvider({ id, children }) {
  const hasPermissions = usePermissionsChecker({
    permissions: [
      "kdix.actions.accreditation.manage",
      "kdix.actions.accreditation.manage.agency",
      "kdix.actions.accreditation.manage.department",
      "kdix.actions.accreditation.manage.service",
      "kdix.actions.accreditation.manage.own",
    ],
  });
  const userCollaborator = useCollaboratorId();
  const { data: collaborator } = useQueryCollaboratorById(userCollaborator);
  const full =
    hasPermissions || get(collaborator, "accreditation.id", null) === +id;

  const {
    status: statusAccreditation,
    error: errorAccreditation,
    data: accreditation,
  } = useQueryAccreditationById(full && id);

  if (errorAccreditation) {
    return <AccreditationError error={errorAccreditation} />;
  }

  return (
    <AccreditationContext.Provider
      value={{
        accreditation: {
          data: accreditation || {},
          status: statusAccreditation,
          error: errorAccreditation,
        },
      }}
    >
      {children}
    </AccreditationContext.Provider>
  );
}

export default (props) => (
  <ErrorBoundary>
    <React.Suspense fallback={<Spinner />}>
      <AccreditationProvider {...props} />
    </React.Suspense>
  </ErrorBoundary>
);
