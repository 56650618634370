import {
  useCreateTypeEventVehicle,
  useDeleteTypeEventVehicle,
  useQueryTypeEventVehicles,
  useUpdateTypeEventVehicle
} from "../api/typeEventVehicle";

import ManageResource from "../components/ManageResource";
import React from "react";

function TypeActionAdmin() {
  return (
    <ManageResource
      resourceLabel="Types d'actions"
      createLabel="Ajouter un type d'action"
      createHook={useCreateTypeEventVehicle}
      queryHook={useQueryTypeEventVehicles}
      deleteHook={useDeleteTypeEventVehicle}
      updateHook={useUpdateTypeEventVehicle}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label"
        }
      }}
    />
  );
}

export default TypeActionAdmin;
