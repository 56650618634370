import React from "react";
import Spinner from "./Spinner";

function Block({
  className,
  children,
  customStyle = "bg-white p-4 shadow mb-4 xl:mb-3 h-full",
}) {
  return (
    <React.Suspense fallback={<Spinner />}>
      <div className={`${customStyle} ${className ? className : ""}`}>
        {children}
      </div>
    </React.Suspense>
  );
}

export default Block;
