import { queryCache, useMutation, useQuery } from "react-query";
import queryWithToken, {
  deleteById,
  duplicateById,
  findById,
  updateOrCreate,
} from "./index";
import { get } from "lodash-es";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "skill_domains";

export function useQuerySkillDomains(filter) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filter],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filter,
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQuerySkillDomainsNames(filter) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filter],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}/listnames`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          ...filter,
        },
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQuerySkillDomainsById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useCreateOrUpdateSkillDomain() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useDeleteSkillDomain() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
    onError: (error) => {
      toast.error("Erreur lors de la suppression : " + error.description);
    },
  });
}

export function useDuplicateSkillDomain() {
  return useMutation(duplicateById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
    onError: (error) => {
      toast.error(
        "Erreur lors de la duplication : (" + error.description + ")",
      );
    },
  });
}

export function useImportSkillDomains() {
  return useMutation(
    ({ file, domain, objectId }) => {
      const data = new FormData();
      data.append("file", file);
      data.append("domainId", domain);

      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/import`,
        {
          method: "POST",
          data,
        },
      );
    },
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de l'envoi");
      },
      onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
      },
      onSuccess: () => {
        queryCache.refetchQueries(ressourceKey);
      },
    },
  );
}
