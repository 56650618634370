/**
 * Transforme un object avec plusieurs niveau en un seul en fusionnant les clefs
 * exemple {a:{b:'toto',c:'tata'}} devient {'a.b':'toto,'a.c':'tata'}
 * @param obj
 * @param keySeparator
 */
export function toFlatPropertyMap(obj, keySeparator = ".") {
  const flattenRecursive = (obj, parentProperty, propertyMap = {}) => {
    for (const [key, value] of Object.entries(obj)) {
      const property = parentProperty
        ? `${parentProperty}${keySeparator}${key}`
        : key;
      if (value && typeof value === "object") {
        flattenRecursive(value, property, propertyMap);
      } else {
        propertyMap[property] = value;
      }
    }
    return propertyMap;
  };
  return flattenRecursive(obj);
}
