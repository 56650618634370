import React, { ReactChild, ReactChildren } from "react";

type HeaderBarSpecialProps = {
  buttons?: JSX.Element;
  title?: string | JSX.Element;
  children: string | ReactChild | ReactChildren;
  noShadow?: boolean;
  smaller?: boolean;
  sticky?: boolean;
};
type HeaderBarProps = { buttons?: JSX.Element } & HeaderBarSpecialProps;

export function HeaderBar({
  title,
  children,
  buttons,
  noShadow,
  smaller = false,
  sticky = false,
}: HeaderBarProps) {
  const isSearchable = !!title && !!children;

  return (
    <div
      className={`bg-purple-600 ${
        noShadow ? "" : "shadow"
      } p-8 text-white  relative flex flex-col justify-center ${
        isSearchable && !smaller ? "h-64" : !smaller ? "text-3xl font-bold" : ""
      } ${sticky ? "sticky top-topHeader always-front" : ""}`}
    >
      {!isSearchable && children}
      {!isSearchable && title}
      {isSearchable && (
        <>
          <div className="text-3xl font-bold mb-5">{title}</div>
          <div>{children}</div>
        </>
      )}
      {buttons}
    </div>
  );
}
