import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import { toast } from "react-toastify";
import Input from "../components/Input";
import Alert from "../components/Alert";
import Button from "../components/Button";
import React from "react";
import { useUpdateUser } from "../api/user";
import Block from "../components/Block";
import * as Yup from "yup";
import { passwordRegex } from "../utils/password";
import { GoBackBtnHistory } from "../components/GoBackBtn";

export default function PasswordForm({ userId }) {
  const [updateUser, { error }] = useUpdateUser();
  const validation = Yup.object().shape({
    password: Yup.string()
      .matches(passwordRegex, {
        message:
          "Votre mot de passe doit contenir 8 caractères minimum et au moins : une majuscule, une minuscule, un chiffre et un caractère spécial",
      })
      .required("Requis"),
    confirm: Yup.string()
      .required("Requis")
      .test(
        "match",
        "le mot de passe doit être identique dans les deux champs",
        function (confirm) {
          return confirm === this.parent.password;
        }
      ),
  });
  return (
    <div className="p-8 pb-24">
      <Block>
        <GoBackBtnHistory className="flex items-center">
          <div className="font-bold">Retour</div>
        </GoBackBtnHistory>

        <Block className="mt-4">
          <div className="font-bold text-xl leading-tight mb-8">
            Changement du mot de passe
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              password: "",
              confirm: "",
            }}
            validationSchema={validation}
            onSubmit={async (values, actions) => {
              try {
                await updateUser({
                  id: userId,
                  data: {
                    password: values.password,
                  },
                });
                toast.success("Mise à jour effectuée avec succès");
              } catch (error) {
                map(get(error, "violations"), (e) => {
                  actions.setFieldError(e.propertyPath, e.message);
                });
              }
              actions.setSubmitting(false);
            }}
          >
            {({ isSubmitting }) => {
              return (
                <Form>
                  <Input
                    type="password"
                    label="Mot de passe"
                    name="password"
                    autoComplete="new-password"
                    placeholder="Tapez ici pour changer le mot de passe"
                  />
                  <Input
                    type="password"
                    label="Mot de passe"
                    name="confirm"
                    placeholder="Confirmer le mot de passe"
                  />
                  {error ? (
                    <div className="my-2">
                      <Alert
                        type="error"
                        message={get(error, "title")}
                        details={get(error, "description")}
                      />
                    </div>
                  ) : null}

                  <div className="text-center">
                    <Button
                      className={`btn mt-6 inline-block`}
                      isSubmitting={isSubmitting}
                      isForm={true}
                      type="submit"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Block>
      </Block>
    </div>
  );
}
