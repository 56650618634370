import { Helmet } from "react-helmet";
import React from "react";
import { useUserId, useUserIri } from "../contexts/permissions";
import Spinner from "../components/Spinner";
import MessagesListBlock from "../components/Home/MessagesListBlock";
//import { useDesktop } from "../hooks/useDesktop";
import { HeaderBar } from "../components/HeaderBar";

function Messages({ toValidate }) {
  const userId = useUserId();
  const userIri = useUserIri();
  //const isDesktop = useDesktop();
  return (
    <div>
      <Helmet>
        <title>Messages</title>
      </Helmet>
      <HeaderBar>Messages {toValidate ? " à valider" : ""}</HeaderBar>
      <div
        className={toValidate ? "xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 " : "mx-auto px-0 sm:px-8 md:px-24 xl:px-0"}
        //style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className="px-8 mt-4">
          <React.Suspense fallback={<Spinner />}>
            {userId && (
              <MessagesListBlock
                userId={userId}
                userIri={userIri}
                isPersonal={!toValidate}
                toValidate={toValidate}
              />
            )}
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}
export default Messages;
