import {
  useInfiniteQuery,
  useQuery,
} from "react-query";
import {
  find,
  findById,
} from "./index";
import {flatMap} from "lodash-es";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "collaborator_rtts";
//

export function useQueryCollaboratorRttById(id, options) {
  return useQuery(id && [ressourceKey, id], findById, {
    refetchOnWindowFocus: false,
    enabled: !!id,
    ...options,
  });
}

export function useQueryCollaboratorRtts(filters, refresh = true) {
  const { data, ...rest } = useInfiniteQuery([ressourceKey, filters], find, {
    refetchOnWindowFocus: refresh,
    suspense: refresh,
    getFetchMore: (lastGroup) => {
      if (!lastGroup["hydra:view"] || !lastGroup["hydra:next"]) {
        return false;
      }

      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });

  const collaboratorRtts = flatMap(data, (page) => page["hydra:member"]);

  return {
    ...rest,
    data: collaboratorRtts,
  };
}