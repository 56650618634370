import BountyForm from "../../forms/BountyForm";
import { ReactComponent as BountyIcon } from "../../svgs/bounty.svg";
import HistoryItem from "./HistoryItem";
import React from "react";
import { get } from "lodash-es";

export default function BountyHistoryItem({ bounty = {}, date, isLeft }) {
  return (
    <HistoryItem
      title={
        get(bounty, "type.label", "") !== ""
          ? `${get(bounty, "type.label", "")} - ${get(bounty, "amount", "0")}€`
          : "Prime"
      }
      Icon={BountyIcon}
      backgroundIcon="bg-pink-300"
      date={`${date ? new Date(date).toLocaleDateString() : ""}`}
      itemId={bounty.id}
      isToggle={true}
      textColor={"text-pink-300"}
      isLeft={isLeft}
    >
      <BountyForm
        bounty={bounty}
        collaboratorIRI={get(bounty, "collaborator.@id")}
      />
    </HistoryItem>
  );
}
