import {
  useCreateTypeWeeklyWorkingDuration,
  useDeleteTypeWeeklyWorkingDuration,
  useUpdateTypeWeeklyWorkingDuration,
  useQueryTypeWeeklyWorkingDurations,
} from "../api/typeWeeklyWorkingDuration";

import ManageResource from "../components/ManageResource";
import React from "react";

function TypeWeeklyWorkingDurationsAdmin() {
  return (
    <ManageResource
      resourceLabel="Durée hebdomadaire"
      noElementsLabel="Aucune durée hebdomadaire"
      createLabel="Ajouter une durée "
      createHook={useCreateTypeWeeklyWorkingDuration}
      queryHook={useQueryTypeWeeklyWorkingDurations}
      deleteHook={useDeleteTypeWeeklyWorkingDuration}
      updateHook={useUpdateTypeWeeklyWorkingDuration}
      params={{
        label: {
          label: "Nom",
          type: "text",
          resourceValueKey: "label",
        },
      }}
    />
  );
}

export default TypeWeeklyWorkingDurationsAdmin;
