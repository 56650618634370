import React from "react";
import moment from "moment";
import "moment/locale/fr";
import { map } from "lodash-es";
moment.locale("fr");

export function WeekPlanningSwitchLabel({ days }) {
  return (
    <span>
      Semaine {days[0].date.format("WW")} (du {days[0].date.format("DD/MM")} au{" "}
      {days[6].date.format("DD/MM")})
    </span>
  );
}

export default function WeekPlanningTable({ days }) {
  return (
    <>
      {map(days, (day, index) => (
        <div className="py-3 border-r border-gray-150" key={index} />
      ))}
    </>
  );
}

export function WeekPlanningHead({ days }) {
  return (
    <div className="weekPlanningGrid planningHeadContainer">
      <div className="border-gray-150 border-r" />
      {map(days, (day, index) => (
        <div className="planningHead" key={index}>
          {day.label}
        </div>
      ))}
    </div>
  );
}
