import { queryCache, useMutation } from "react-query";
import { create } from "./index";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "request_comments";
//

export function useCreateRequestComment() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onError: () => {
      toast.error("Erreur lors de l'ajout de commentaire'");
    },
    onSuccess: data => {
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("requests");
      queryCache.refetchQueries("history");
    }
  });
}
