export const scopesLabel = {
  all: "Tous",
  agency: "Site",
  department: "Département",
  service: "Service",
  own: "Soi-même",
  null: "Aucun",
};

export const scopeOptions = [
  { value: "all", label: scopesLabel.all },
  { value: "agency", label: scopesLabel.agency },
  { value: "department", label: scopesLabel.department },
  { value: "service", label: scopesLabel.service },
  { value: "own", label: scopesLabel.own },
  { value: null, label: scopesLabel.null },
];
