import { Link } from "react-router-dom";
import React from "react";

export default function CustomLink({ disabled, to, children, ...props }) {
  if (!disabled) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }
  return <div {...props}>{children}</div>;
}
