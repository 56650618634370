import React from "react";
import { Form, Formik } from "formik";
import { get, map } from "lodash-es";
import Alert from "../components/Alert";
import Input, { DateTimePickerInput } from "../components/Input";
import { usePermissionsChecker } from "../contexts/permissions";
import { useUpdateVehicle } from "../api/vehicle";
import { toast } from "react-toastify";
import Button from "../components/Button";
import Select from "../components/Select";
import { useQueryTypeVehicle } from "../api/typeVehicle";
import * as Yup from "yup";

function prepareValues({ type, dateTrafficRelease, ...values }) {
  return {
    type: type.value,
    dateTrafficRelease: dateTrafficRelease ? dateTrafficRelease : null,
    ...values,
  };
}
const validation = Yup.object().shape({
  brand: Yup.string().required("Requis"),
  type: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
  model: Yup.string().required("Requis"),
  numberOfSeats: Yup.number().integer().min(0),
  engineCylinder: Yup.number().integer().min(0),
  dateTrafficRelease: Yup.date().nullable(),
  insuranceValue: Yup.number().min(0),
  owner: Yup.string().required("Requis"),
  licencePlate: Yup.string().required("Requis"),
  fiscalPower: Yup.number().integer().min(0),
  pneumaticReference: Yup.string(),
  gazCardNumber: Yup.string(),
  frameNumber: Yup.string(),
  badgeNumber: Yup.string(),
});

function VehicleGeneralInfosForm({ vehicle }) {
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.vehicle.edit",
      "kdix.actions.vehicle.edit.agency",
      "kdix.actions.vehicle.edit.department",
      "kdix.actions.vehicle.edit.service",
      "kdix.actions.vehicle.edit.own",
    ],
  });
  const [updateVehicle, { error }] = useUpdateVehicle();
  const { data: typesVehicle } = useQueryTypeVehicle();
  const type = get(vehicle, "type", false);

  return (
    <div>
      <Formik
        enableReinitialize
        validationSchema={validation}
        initialValues={{
          brand: get(vehicle, "brand", ""),
          type: type
            ? {
                label: type.label,
                value: type["@id"],
              }
            : {
                label: "",
                value: "",
              },
          model: get(vehicle, "model", ""),
          numberOfSeats: get(vehicle, "numberOfSeats", 0),
          engineCylinder: get(vehicle, "engineCylinder", 0),
          dateTrafficRelease: get(vehicle, "dateTrafficRelease")
            ? new Date(get(vehicle, "dateTrafficRelease"))
            : "",
          insuranceValue: get(vehicle, "insuranceValue", 0),
          owner: get(vehicle, "owner", ""),
          licencePlate: get(vehicle, "licencePlate", ""),
          fiscalPower: get(vehicle, "fiscalPower", 0),
          pneumaticReference: get(vehicle, "pneumaticReference", ""),
          gazCardNumber: get(vehicle, "gazCardNumber", ""),
          frameNumber: get(vehicle, "frameNumber", ""),
          code: get(vehicle, "code", ""),
          badgeNumber: get(vehicle, "badgeNumber", ""),
        }}
        onSubmit={async (values, actions) => {
          try {
            await updateVehicle({
              id: vehicle.id,
              data: prepareValues(values),
            });
            toast.success("Mise à jour effectuée avec succès");
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, submitCount }) => {
          return (
            <Form>
              <>
                <Select
                  name="type"
                  isDisabled={readOnly}
                  label="Type de véhicule *"
                  options={map(typesVehicle, (typeVehicle) => ({
                    label: typeVehicle.label,
                    value: typeVehicle["@id"],
                  }))}
                  value={values.type.value !== "" ? values.type : null}
                  isLoading={!typesVehicle}
                />
              </>
              <Input
                type="text"
                readOnly={readOnly}
                name="brand"
                label="Marque *"
                value={values.brand}
              />
              <Input
                type="text"
                readOnly={readOnly}
                name="model"
                label="Modèle *"
                value={values.model}
              />
              <Input
                type="number"
                readOnly={readOnly}
                name="numberOfSeats"
                label="Nombre de places"
                min={0}
                value={values.numberOfSeats}
              />
              <Input
                type="number"
                readOnly={readOnly}
                name="engineCylinder"
                label="Cylindrée"
                min={0}
                value={values.engineCylinder}
              />
              <DateTimePickerInput
                fullWidth={true}
                readOnly={readOnly}
                name="dateTrafficRelease"
                label="Date d'entrée dans la flotte"
                value={values.dateTrafficRelease}
              />
              <span className="inputSymbolEuro">
                <Input
                  type="number"
                  readOnly={readOnly}
                  name="insuranceValue"
                  label="Valeur assurance"
                  min={0}
                  step={0.01}
                  value={values.insuranceValue}
                />
              </span>
              <Input
                type="text"
                readOnly={readOnly}
                name="owner"
                label="Propriétaire *"
                value={values.owner}
              />
              <Input
                type="text"
                readOnly={readOnly}
                name="licencePlate"
                label="Immatriculation *"
                value={values.licencePlate}
              />
              <Input
                type="number"
                readOnly={readOnly}
                min={0}
                name="fiscalPower"
                label="Puissance fiscale"
                value={values.fiscalPower}
              />
              <Input
                type="text"
                readOnly={readOnly}
                name="pneumaticReference"
                label="Référence des pneumatiques"
                value={values.pneumaticReference}
              />
              <Input
                type="text"
                readOnly={readOnly}
                name="gazCardNumber"
                label="Numéro carte carburant"
                value={values.gazCardNumber}
              />
              <Input
                type="text"
                readOnly={readOnly}
                name="frameNumber"
                label="Numéro de châssis"
                value={values.frameNumber}
              />
              <Input
                type="text"
                name="code"
                label="Code"
                readOnly={readOnly}
                value={values.code}
              />
              <Input
                type="text"
                readOnly={readOnly}
                name="badgeNumber"
                label="Numéro de badge"
                value={values.badgeNumber}
              />
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 w-full block`}
                readOnly={readOnly}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default VehicleGeneralInfosForm;
