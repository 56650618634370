import {
  useCreateJob,
  useDeleteJob,
  useQueryJobs,
  useUpdateJob,
} from "../api/jobs";

import ManageResource from "../components/ManageResource";
import React from "react";
import { useIsSilae } from "../contexts/permissions";

function JobsAdmin() {
  const isSilae = useIsSilae();
  return (
    <ManageResource
      resourceLabel="emplois"
      createLabel="Ajouter un Emploi"
      createHook={useCreateJob}
      queryHook={useQueryJobs}
      deleteHook={useDeleteJob}
      updateHook={useUpdateJob}
      params={{
        label: {
          label: "Emploi",
          type: "text",
          resourceValueKey: "label",
        },
      }}
      readOnly={isSilae}
    />
  );
}

export default JobsAdmin;
