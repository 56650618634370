import React, { useState } from "react";
import { flatMap, map, size } from "lodash-es";
import { useDebounce } from "react-use";
import Alert from "../../components/Alert";
import ExpansionPanel from "../../components/ExpansionPanel";
import { Helmet } from "react-helmet";
import { ReactComponent as PlusIcon } from "../../svgs/plus.svg";
import Spinner from "../../components/Spinner";
import Modal from "../../components/Modal";
import ValidatorForm from "../../forms/ValidatorForm";
import { getFirstnameLastnameJob } from "../../utils/names";
import { useQueryValidators } from "../../api/validator";
import InfiniteScroll from "react-infinite-scroller";
import {
  usePermissionsChecker,
} from "../../contexts/permissions";

function getNameValidator(preValidatorPage, suffix = "") {
  return preValidatorPage ? "pré-valideur" + suffix : "valideur" + suffix;
}

function ValidatorMobileCard({ validator, isPreValidator = false, isreadonly = false }) {
  return (
    <ExpansionPanel
      key={validator.id}
      title={getFirstnameLastnameJob(validator)}
    >
      {({ isOpen }) => (
        <React.Suspense fallback={<Spinner />}>
          <ValidatorForm
            validator={validator}
            preValidatorForm={isPreValidator}
            isOpen={isOpen}
            readOnly={isreadonly}
          />
        </React.Suspense>
      )}
    </ExpansionPanel>
  );
}

function ValidatorsList({ query, preValidatorPage = false, readonly = false }) {
  const { data, fetchMore, canFetchMore, isFetching } = useQueryValidators(
    {
      "order[lastname]": "asc",
      orSearch_fullname: query,
      pagination: true,
      itemsPerPage: 20,
      active: "true",
    },
    preValidatorPage,
  );
  if (size(data) <= 0 && isFetching) {
    return (
      <div key={0} className=" relative">
        <Spinner />
      </div>
    );
  }
  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert
          type="warning"
          message={`aucun ${getNameValidator(preValidatorPage)}`}
        />
      </div>
    );
  }
  const validators = flatMap(data, (page) => page["hydra:member"]);
  return (
    <>
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {map(validators, (node) => (
          <ValidatorMobileCard
            key={node.id}
            validator={node}
            isPreValidator={preValidatorPage}
            isreadonly={readonly}
          />
        ))}
      </InfiniteScroll>
    </>
  );
}

function CreateValidatorModal({ isPreValidator = false }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <div
        className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <PlusIcon className="w-4 h-4" />
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title={`Création d'un ${getNameValidator(isPreValidator)}`}
      >
        <ValidatorForm
          preValidatorForm={isPreValidator}
          onSuccess={() => {
            setIsOpen(false);
          }}
          isModal
        />
      </Modal>
    </>
  );
}

function ValidatorAdminPage({ preValidatorPage = false }) {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");
  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query],
  );
  
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.user.manage",
    ],
  });

  return (
    <div>
      <Helmet>
        <title>Administration {getNameValidator(preValidatorPage, "s")}</title>
      </Helmet>
      <div className="bg-purple-600 text-white shadow">
        <div className="relative px-8">
          <div className="py-5 h-64 flex flex-col justify-center">
            <div className="text-3xl leading-none font-bold mb-5">
              Administration
              <br />
              des {getNameValidator(preValidatorPage, "s")}
            </div>
            <div>
              <input
                type="text"
                name="query"
                value={query}
                className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
                placeholder={`Rechercher un ${getNameValidator(
                  preValidatorPage,
                )}`}
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
          </div>
          {canEdit && (
                  <CreateValidatorModal isPreValidator={preValidatorPage} />
           )}
        </div>
      </div>

      <div>
        <div className="px-8 mb-48 mt-16">
          <React.Suspense fallback={<Spinner />}>
            <ValidatorsList
              query={debouncedQuery}
              preValidatorPage={preValidatorPage}
              readonly={!canEdit}
            />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default ValidatorAdminPage;
