import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import queryWithToken, {
  deleteById,
  find,
  findById,
  findExport,
  updateOrCreate,
} from "./index";
import { toast } from "react-toastify";
import { get, isEmpty } from "lodash-es";
import fileDownload from "js-file-download";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "work_accidents";
//

export function useQueryWorkAccidentById(id) {
  return useQuery(id && [ressourceKey, id], findById, {
    refetchOnWindowFocus: false,
  });
}

export function useQueryWorkAccidentsInfinite(filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    refetchOnWindowFocus: false,
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}

function useQueryOneWorkAccident(filters = {}) {
  const { data, ...rest } = useQuery(
    [`${ressourceKey}`, { ...filters, page: 1, itemsPerPage: 1 }],
    find,
    {
      refetchOnWindowFocus: false,
    }
  );
  let workAccident = null;
  if (!isEmpty(get(data, "hydra:member", []))) {
    workAccident = data["hydra:member"][0];
  }
  return {
    data: workAccident,
    ...rest,
  };
}

export function useQueryLastWorkAccident() {
  return useQueryOneWorkAccident({ "order[date]": "desc" });
}

export function useQueryLastCreatedWorkAccident() {
  return useQueryOneWorkAccident({ "order[createdAt]": "desc" });
}

export function useQueryCountYearWorkAccidents() {
  const currentYear = new Date().getFullYear();
  const dateFilters = {
    "date[after]": new Date(currentYear, 0, 1, 0, 0, 0),
    "date[before]": new Date(currentYear, 11, 31, 23, 59, 59),
  };
  const { data, ...rest } = useQuery(
    `${ressourceKey}_count`,
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}/count`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
          differentSearch_accepted: "false",
          ...dateFilters,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    count: get(data, "hydra:totalItems", 0),
    ...rest,
  };
}

export function useCreateOrUpdateWorkAccident() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    onSuccess: () => {
      toast.success("Mise à jour effectuée avec succès");
      queryCache.refetchQueries(ressourceKey);
    },
    throwOnError: true,
  });
}

export function useDeleteWorkAccident() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export async function downloadWorkAccidentExport(
  filters,
  filename = "export_accidents"
) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, filename + ".csv");
}
