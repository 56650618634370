import { get, map } from "lodash-es";
import React from "react";
import { useUpdateVehicle } from "../api/vehicle";
import { toast } from "react-toastify";
import { useCreateVehicleAttributionHistory } from "../api/vehicleAttributionHistory";
import VehicleAllocationFormBase from "./VehicleAllocationFormBase";
import { getFirstnameLastnameJob } from "../utils/names";

function prepareValues({ collaborator, agency }) {
  return {
    collaborator: collaborator.value ? collaborator.value : null,
    agency: agency.value ? agency.value : null,
  };
}
function prepareValuesAttribution({ collaborator, agency, date }, vehicleId) {
  return {
    collaborator: collaborator.value ? collaborator.value : null,
    agency: agency.value ? agency.value : null,
    date: date,
    vehicle: vehicleId,
  };
}

function NewVehicleAllocationForm({ vehicle }) {
  const [updateVehicle, { error }] = useUpdateVehicle();
  const [createVehicleAH] = useCreateVehicleAttributionHistory();
  const currentCollaborator = get(vehicle, "collaborator", false);
  const currentAgency = get(vehicle, "agency", false);
  return (
    <VehicleAllocationFormBase
      initialValues={{
        collaborator: currentCollaborator
          ? {
              label: getFirstnameLastnameJob(
                { currentCollaborator },
                "currentCollaborator"
              ),
              value: currentCollaborator["@id"],
            }
          : {
              label: "",
              value: "",
            },
        agency: currentAgency
          ? {
              label: `${currentAgency.label}`,
              value: currentAgency["@id"],
            }
          : {
              label: "",
              value: "",
            },
        date: currentCollaborator ? (get(vehicle, "lastVehicleAttributionHistory.date")
          ? new Date(get(vehicle, "lastVehicleAttributionHistory.date"))
          : "") : "",
      }}
      error={error}
      onSubmit={async (values, actions) => {
        try {
          await updateVehicle({
            id: vehicle.id,
            data: prepareValues(values),
          });
          await createVehicleAH(
            prepareValuesAttribution(values, vehicle["@id"])
          );
          toast.success("Mise à jour effectuée avec succès");
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
        }
        actions.setSubmitting(false);
      }}
    />
  );
}

export default NewVehicleAllocationForm;
