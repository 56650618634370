import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import {
  filter,
  find,
  isEmpty,
  map,
  size,
  noop,
  forEach,
  orderBy,
  lowerCase,
  uniqBy,
} from "lodash-es";
import Alert from "./Alert";
import { ReactComponent as FileIcon } from "../svgs/file.svg";
import { ReactComponent as FolderIcon } from "../svgs/folder.svg";
import Modal from "./Modal";
import React, {useState} from "react";
import { ReactComponent as TrashIcon } from "../svgs/trash.svg";
import { useSet } from "react-use";
import DownloadOrUpload from "./DownloadOrUpload";
import InfoTooltip from "./InfoTooltip";
import {useCollaboratorId, useIsAdmin} from "../contexts/permissions";
import {useQueryCollaboratorById} from "../api/collaborator";

function generateBreadcrumb(data, current) {
  if (!current) return ["home"];
  if (!data[current]?.parent) return ["home", current];
  return [...generateBreadcrumb(data, data[current].parent?.["@id"]), current];
}

const GRID_TEMPLATE_COLLAB = "30px 40% auto 8rem 40%";
const GRID_TEMPLATE_ORGANIZATION = "30px 75% auto 8rem";

function FileRow({
  label,
  comment,
  date,
  type,
  Icon,
  handleClick,
  handleDelete,
  className,
  isLoading,
  canDelete,
  useExternalDocumentManagement = false,
  organizationDocument,
}) {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isDeleted, setIsDeleted] = React.useState(false);

  if (isDeleted) return null;

  return (
    <div
      className={`flex items-center border-b border-gray-200 uppercase hover:bg-gray-100 leading-tight ${className}`}
    >
      <>
        <div
          className={`flex items-center p-2 flex-1 cursor-pointer w-full ${
            useExternalDocumentManagement ? "grid" : ""
          }`}
          style={
            useExternalDocumentManagement
              ? organizationDocument !== "organizationDocuments" ? {
                  gridTemplateColumns: GRID_TEMPLATE_COLLAB,
                } : { gridTemplateColumns: GRID_TEMPLATE_ORGANIZATION }
              : {}
          }
          onClick={handleClick}
        >
          <div className="mr-4">
            {isDeleting || isLoading ? (
              <div className="loader h-4 w-4 mx-auto"></div>
            ) : (
              Icon
            )}
          </div>
          {!useExternalDocumentManagement && (
              <div className="py-2">
                <InfoTooltip
                    message={label}
                    left
                    onHover
                    parentClass="w-full"
                    childClass="w-full truncate"
                >
                  {label}
                </InfoTooltip>
              </div>
          )}
          {useExternalDocumentManagement && (
            <>
              <div className="py-2 px-4">
               {comment && (
                <InfoTooltip
                    message={comment}
                    onHover
                    parentClass="w-full"
                    childClass="w-full truncate"
                >
                  {comment}
                </InfoTooltip>
               )}
              </div>
              <div className="py-2 px-4">
                {date && new Date(date).toLocaleDateString()}
              </div>
              <div className="py-2 px-4">{type}</div>
              { organizationDocument !== "organizationDocuments" ? (
              <div className="py-2">
                <InfoTooltip
                    message={label}
                    onHover
                    parentClass="w-full"
                    childClass="w-full truncate"
                >
                  {label}
                </InfoTooltip>
              </div>
            ) : null }
            </>
          )}
        </div>
        {canDelete && handleDelete && !useExternalDocumentManagement ? (
          <div className="ml-auto pr-4">
            <button
              type="button"
              onClick={async () => {
                const res = window.confirm("La suppression sera définitive");
                if (!res) return;
                try {
                  setIsDeleting(true);
                  await handleDelete();
                  setIsDeleted(true);
                } catch (error) {
                  setIsDeleting(false);
                }
              }}
            >
              <TrashIcon className="w-6 h-6 fill-current text-red-500 hover:text-red-600" />
            </button>
          </div>
        ) : null}
      </>
    </div>
  );
}

function Row({
  label,
  Icon,
  nbFiles,
  handleClick,
  handleDelete,
  children,
  className,
  isLoading,
  canDelete,
  useExternalDocumentManagement = false,
}) {
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isDeleted, setIsDeleted] = React.useState(false);
  if (isDeleted) return null;

  return (
    <div
      className={`flex items-center border-b border-gray-200 uppercase hover:bg-gray-100 leading-tight ${className}`}
    >
      <>
        <div
          className="flex items-center p-2 flex-1 cursor-pointer"
          onClick={handleClick}
        >
          <div className="mr-4">
            {isDeleting || isLoading ? (
              <div className="loader h-4 w-4 mx-auto"></div>
            ) : (
              Icon
            )}
          </div>

          {children ? children : <div className="py-2">{label}</div>}
        </div>
        {children ? null : (
          <div className="ml-auto pr-4">
            <div className="lowercase cursor-pointer font-semibold text-gray-300">
              {nbFiles} fichier{nbFiles > 1 ? "s" : ""}
            </div>
            {canDelete && handleDelete && !useExternalDocumentManagement ? (
              <button
                type="button"
                onClick={async () => {
                  const res = window.confirm("La suppression sera définitive");
                  if (!res) return;
                  try {
                    setIsDeleting(true);
                    await handleDelete();
                    setIsDeleted(true);
                  } catch (error) {
                    setIsDeleting(false);
                  }
                }}
              >
                <TrashIcon className="w-6 h-6 fill-current text-red-500 hover:text-red-600" />
              </button>
            ) : null}
          </div>
        )}
      </>
    </div>
  );
}

function CreateFolder({ visible, handleCancel, handleSubmit }) {
  const [error, setErrror] = React.useState(null);
  if (!visible) return null;

  return (
    <Formik
      validationSchema={Yup.object().shape({
        label: Yup.string().required("Requis"),
      })}
      initialValues={{
        label: "",
      }}
      onSubmit={async (values, actions) => {
        try {
          await handleSubmit(values.label);
        } catch (error) {
          setErrror(error);
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form className={`${!visible ? "hidden" : ""}`}>
            <Row
              className="bg-white hover:bg-transparent"
              Icon={
                isSubmitting ? (
                  <div className="loader h-4 w-4 mx-auto"></div>
                ) : (
                  <FolderIcon className="w-4 h-4" />
                )
              }
            >
              <Field
                type="text"
                name="label"
                className="appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none focus:border-red"
                autoFocus
              />
              {!isSubmitting ? (
                <>
                  <button
                    className="btn btn--sm btn--error mr-2"
                    type="button"
                    onClick={handleCancel}
                  >
                    Annuler
                  </button>
                  <button className="btn btn--sm" type="submit">
                    Créer
                  </button>
                </>
              ) : null}
            </Row>
            {!isSubmitting && error ? (
              <Alert
                type="error"
                title={"Erreur lors de la création du dossier"}
                details={error.description}
              />
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
}

function CreateFile({ folderId, handleSubmit, objectId }) {
  const [error, setErrror] = React.useState(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  if (!folderId || !objectId) return null;

  return (
    <div>
      <div
        className="cursor-pointer hover:text-purple-600"
        onClick={() => setIsOpen(true)}
      >
        Ajouter un fichier
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        title="Ajout d'un fichier"
      >
        <Formik
          validationSchema={Yup.object().shape({
            file: Yup.mixed().test(
              "fileFormat",
              "Formats autorisés: jpg, png, gif, jpeg, webm, pdf, docx",
              (value) => {
                const authorized = [
                  "application/pdf",
                  "application/docx",
                  "image/jpeg",
                  "image/png",
                  "image/gif",
                  "image/webm",
                ];
                return value && authorized.includes(value.type);
              }
            ),
          })}
          initialValues={{
            file: null,
          }}
          onSubmit={async (values, actions) => {
            try {
              await handleSubmit({
                file: values.file,
                folderId,
                objectId,
              });
              actions.setSubmitting(false);
              setIsOpen(false);
            } catch (error) {
              setErrror(error);
              actions.setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <DownloadOrUpload
                  name="file"
                  accept="application/pdf, application/docx, image/jpeg, image/png, image/gif, image/webm"
                  isSubmitting={isSubmitting}
                  submitButton={false}
                />

                {!isSubmitting && error ? (
                  <Alert
                    type="error"
                    title={"Erreur lors de l'envoi du fichier"}
                    details={error.description}
                  />
                ) : null}

                <button className={`btn mt-6 w-full block`} type="submit">
                  {isSubmitting ? (
                    <div className="loader h-8 w-8 mx-auto"></div>
                  ) : (
                    "Envoyer"
                  )}
                </button>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}

function getAllDocuments(data) {
  let documents = [];

  forEach(data, (folder) => {
    if (folder.children.length > 0) {
      documents = [...documents, ...getAllDocuments(folder.children)];
    }
    documents = [...documents, ...folder.organizationDocuments];
  })

  return uniqBy(documents, 'id');
}

function filterDocumentsByQuery(documents, query) {
  return filter(documents, (document) => {
    if (document.comment.toUpperCase().indexOf(query.toUpperCase()) > -1) {
      return true;
    }
    if (document.label.toUpperCase().indexOf(query.toUpperCase()) > -1) {
      return true;
    }
    return false;
  })
}

function addSubfolders(folder) {
  let subfolderData = {};

  forEach(folder.children, (subfolder) => {
    const subfolderId = subfolder['@id'];

    subfolderData = {
      ...subfolderData,
      [subfolderId]: {
        ...subfolder,
        parent: {
          '@id': folder['@id'],
          parent: folder.parent
        }
      }
    }

    subfolderData = {
      ...subfolderData,
      ...addSubfolders(subfolder)
    }
  })

  return subfolderData;
}

function expandDataWithChildren(data) {
  let expandedData = {
    ...data
  };

  forEach(data, (folder) => {
    expandedData = {
      ...expandedData,
      ...addSubfolders(folder)
    }
  })

  return expandedData;
}

export default function DocumentBrowser({
  queryFolderHook,
  createFolderHook = () => [noop],
  deleteFolderHook = () => [noop],
  createDocumentHook = () => [noop],
  deleteDocumentHook = () => [noop],
  downloadDocumentFn,
  documentsLabel = "documents",
  canCreate = false,
  canDelete = false,
  objectId = false,
  useExternalDocumentManagement = false,
  canViewAll = false,
}) {
  const [orderFilter, setOrderFilter] = React.useState({
    element: "createdAt",
    order: "desc",
  });
  const { data, isFetching } = queryFolderHook();
  const [createFolder] = createFolderHook();
  const [createDocument] = createDocumentHook();

  const [isCreatingFolder, setIsCreatingFolder] = React.useState(false);

  const [deleteFolder] = deleteFolderHook();
  const [deleteDocument] = deleteDocumentHook();

  const [
    ,
    { add: addDownload, remove: removeDownload, has: hasDownload },
  ] = useSet(new Set([]));

  const {data: currentUserCollaborator} = useQueryCollaboratorById(useCollaboratorId());
  const [currentFolderId, setCurrentFolderId] = React.useState(null);
  const dataExpanded = expandDataWithChildren(data);
  const currentFolder = find(dataExpanded, (folder) => {
    return folder["@id"] === currentFolderId;
  });
  const currentPath = generateBreadcrumb(dataExpanded, currentFolderId);
  const isAdmin = useIsAdmin();
  const [queryText, setQueryText] = useState("");
  const [query, setQuery] = useState("");

  let allDocumentsFiltered = null;
  if (query && query !== "") {
    var allDocuments = documentsLabel !== "organizationDocuments" ? (currentFolder?.[documentsLabel] || []) : getAllDocuments(data);
    allDocumentsFiltered = filterDocumentsByQuery(allDocuments, query);
  }

  const currentDocuments = allDocumentsFiltered !== null ? allDocumentsFiltered : (isAdmin || documentsLabel !== "organizationDocuments" || !currentUserCollaborator) ? (currentFolder?.[documentsLabel] || []) :
      filter(currentFolder?.[documentsLabel], (document) => {
        if (canViewAll || !document.company || document.company === "") {
          return true;
        }

        if (!currentUserCollaborator?.companies) {
          return false;
        }

        if (currentUserCollaborator.companies.filter((company) => {
          return company.label.toLowerCase() === document.company?.toLowerCase();
        }).length > 0) {
          return true;
        }

        return false;
      }) || [];
  const currentDocumentsSorted =
    orderFilter.element && orderFilter.order
      ? orderBy(
          currentDocuments,
          [
            (obj) => {
              if (
                orderFilter?.element === "createdAt" ||
                orderFilter?.element === "date"
              ) {
                return new Date(obj[orderFilter.element]);
              } else {
                return lowerCase(obj[orderFilter.element] ?? "");
              }
            },
          ],
          [orderFilter.order]
        )
      : currentDocuments;

  const changeOrder = (orderLabel) => {
    setOrderFilter((prevState) => {
      if (prevState.element === orderLabel) {
        return {
          element: orderLabel,
          order: prevState.order === "asc" ? "desc" : "asc",
        };
      } else {
        return { element: orderLabel, order: "asc" };
      }
    });
  };

  let currentSubfolders;
  if (!currentFolderId) {
    // pas de dossier en cours, on affiche la racine (les dossiers sans parent)
    currentSubfolders = filter(data, (d) => !d.parent);
  } else {
    currentSubfolders = currentFolder?.children || [];
  }
  React.useEffect(() => {
    setIsCreatingFolder(false);
    return () => {};
  }, [currentFolderId, setIsCreatingFolder]);

  return (
    <div>
    {documentsLabel === "organizationDocuments" ? (
      <input
          id={"doc-search-query-input"}
          type="text"
          name="query"
          value={undefined}
          className="mb-0 appearance-none bg-transparent border-b border-white w-full leading-tight focus:outline-none focus:border-red border-b"
          placeholder="Chercher un fichier"
          onChange={(e) => {
            setQueryText(e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setQuery(queryText);
            }
          }}
      />
    ) : null}
      <div className="flex justify-between border-b border-gray-200">
        <div className="hidden lg:flex items-center py-3 leading-none opacity-75 text-sm">
          {map(currentPath, (step, index, arr) => {
            return (
              <div
                className="flex items-center cursor-pointer uppercase hover:text-purple-600"
                key={index}
                onClick={() => {
                  const queryInput = document.getElementById("doc-search-query-input");
                  queryInput.value = null;
                  setCurrentFolderId(step === "home" ? null : step);
                  setQuery("");
                  setQueryText("");
                }}
              >
                <div>{step === "home" ? "accueil" : dataExpanded[step]?.label}</div>
                {index + 1 !== arr.length ? (
                  <svg
                    className="w-3 h-3 text-black mx-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 477.175 477.175"
                  >
                    <path d="M360.731 229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1 0s-5.3 13.8 0 19.1l215.5 215.5-215.5 215.5c-5.3 5.3-5.3 13.8 0 19.1 2.6 2.6 6.1 4 9.5 4 3.4 0 6.9-1.3 9.5-4l225.1-225.1c5.3-5.2 5.3-13.8.1-19z" />
                  </svg>
                ) : null}
              </div>
            );
          })}
        </div>
        <div className="flex items-center text-sm mt-2 ml-auto">
          {canCreate && !useExternalDocumentManagement ? (
            <>
              <div
                className="p-2 hover:text-purple-600 cursor-pointer"
                onClick={() => {
                  setIsCreatingFolder(true);
                }}
              >
                Ajouter un dossier
              </div>
              {currentFolderId && currentFolder.id ? (
                <div className="p-2">
                  <CreateFile
                    folderId={currentFolder.id}
                    handleSubmit={createDocument}
                    objectId={objectId}
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>

      <div className="relative">
        {isFetching ? (
          <div
            className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center"
            style={{ minHeight: 50 }}
          ></div>
        ) : null}

        {!size(currentSubfolders) && !size(currentDocuments) ? (
          <div className="py-4">Ce dossier est vide</div>
        ) : (
          ""
        )}
        <CreateFolder
          visible={isCreatingFolder}
          handleSubmit={async (value) => {
            await createFolder({
              parent: currentFolderId,
              label: value,
            });
            setIsCreatingFolder(false);
          }}
          handleCancel={() => {
            setIsCreatingFolder(false);
          }}
          canDelete={canDelete}
        />
        {useExternalDocumentManagement && !isEmpty(currentDocuments) && (
          <div
            className={`flex  flex-1 items-center border-b text-white divide-white bg-gray-500 shadow uppercase leading-tight top-collaborators z-30`}
          >
            <div
              className="flex items-center p-2 grid flex-1 "
              style={
                documentsLabel !== "organizationDocuments" ? {
                  gridTemplateColumns: GRID_TEMPLATE_COLLAB,
                } : { gridTemplateColumns: GRID_TEMPLATE_ORGANIZATION }
              }
            >
              <div />
              <div
                  className="py-2 px-4 cursor-pointer"
                  onClick={() => changeOrder("comment")}
              >
                {documentsLabel !== "organizationDocuments" ? "Commentaire" : "Nom du fichier" }
              </div>
              <div
                className="py-2 px-4 cursor-pointer"
                onClick={() => changeOrder("date")}
              >
                Date
              </div>
              <div
                className="py-2 px-4 cursor-pointer"
                onClick={() => changeOrder("extension")}
              >
                Type
              </div>
              {documentsLabel !== "organizationDocuments" ? (
              <div
                  className="py-2 cursor-pointer"
                  onClick={() => changeOrder("label")}
              >
                Nom du fichier
              </div>
              ) : null }
            </div>
          </div>
        )}
        {map(currentSubfolders, (item) => {
          const nbFiles = calculNbFiles(item, documentsLabel, currentUserCollaborator, (isAdmin || canViewAll));
          return query === "" && (!useExternalDocumentManagement || nbFiles > 0) ? (
            <Row
              label={item.label}
              key={item.id ? item.id : item['@id'] ? item['@id'] : item.label}
              Icon={<FolderIcon className="w-4 h-4" />}
              handleClick={() => {
                setCurrentFolderId(item["@id"]);
              }}
              nbFiles={nbFiles}
              handleDelete={() => deleteFolder(item.id)}
              canDelete={canDelete}
              useExternalDocumentManagement={useExternalDocumentManagement}
            />
          ) : null;
        })}
        {map(currentDocumentsSorted, (item) => {
          const isFetching = hasDownload(item.id);
          return (
            <FileRow
              label={item.label}
              date={item.date}
              comment={item.comment}
              type={item.extension}
              key={item.id}
              Icon={<FileIcon className="w-4 h-4 fill-current text-black" />}
              isLoading={isFetching}
              handleClick={async () => {
                if (!isFetching) {
                  addDownload(item.id);
                  await downloadDocumentFn(item.id, item.label, item.extension);
                  removeDownload(item.id);
                }
              }}
              handleDelete={() => deleteDocument(item.id)}
              canDelete={canDelete}
              useExternalDocumentManagement={useExternalDocumentManagement}
              organizationDocument={documentsLabel}
            />
          );
        })}
      </div>

      <div
        className={`mr-4 cursor-pointer flex items-center text-purple-600 hover:text-purple-800 mt-6 ${
          !currentFolderId || currentFolderId === "home" ? "opacity-0" : ""
        }`}
        onClick={() =>
          setCurrentFolderId(currentFolder?.parent?.["@id"] || null)
        }
      >
        <svg
          className="w-4 h-4 fill-current mr-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 492 492"
        >
          <path d="M464.344 207.418l.768.168H135.888l103.496-103.724c5.068-5.064 7.848-11.924 7.848-19.124 0-7.2-2.78-14.012-7.848-19.088L223.28 49.538c-5.064-5.064-11.812-7.864-19.008-7.864-7.2 0-13.952 2.78-19.016 7.844L7.844 226.914C2.76 231.998-.02 238.77 0 245.974c-.02 7.244 2.76 14.02 7.844 19.096l177.412 177.412c5.064 5.06 11.812 7.844 19.016 7.844 7.196 0 13.944-2.788 19.008-7.844l16.104-16.112c5.068-5.056 7.848-11.808 7.848-19.008 0-7.196-2.78-13.592-7.848-18.652L134.72 284.406h329.992c14.828 0 27.288-12.78 27.288-27.6v-22.788c0-14.82-12.828-26.6-27.656-26.6z" />
        </svg>
        <span>Revenir au dossier parent</span>
      </div>
    </div>
  );
}

function calculNbFiles(folder, documentsLabel, currentUserCollaborator, isAdmin) {
  let result = 0;

  if (documentsLabel === "organizationDocuments" && null !== currentUserCollaborator && !isAdmin) {
    result += size(
        folder[documentsLabel].filter((document) => {
          if (!document.company || document.company === "") {
            return true;
          }

          if (!currentUserCollaborator?.companies) {
            return false;
          }

          if (currentUserCollaborator.companies.filter((company) => {
            return company.label.toLowerCase() === document.company?.toLowerCase();
          }).length > 0) {
            return true;
          }

          return false;
        })
    );
  } else {
    result += size(folder[documentsLabel]);
  }

  if (folder.children?.length > 0) {
    forEach(folder.children, (f) => {
      result += calculNbFiles(f, documentsLabel, currentUserCollaborator, isAdmin);
    });
  }
  return result;
}
