import { queryCache, useMutation, useQuery } from "react-query";
import queryWithToken, {
  create,
  deleteById,
  findById,
  findExport,
  updateById,
  updateOrCreate,
} from "./index";

import { get } from "lodash-es";
import { toast } from "react-toastify";
import fileDownload from "js-file-download";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "medical_visits";
//

export function useQueryMedicalVisitById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQueryMedicalVisits() {
  const { data, ...rest } = useQuery(
    [ressourceKey],
    () =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "GET",
        params: {
          page: null,
          pagination: false,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useGetMedicalVisitsDocuments(id) {
  const { data, isFetching } = useQuery(
    id,
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/medical_visit_certificates/${id}`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  return {
    data: [data],
    isFetching,
  };
}

export function useCreateMedicalVisit() {
  return useMutation(create.bind(null, ressourceKey), {
    onMutate: () => {
      queryCache.cancelQueries(ressourceKey);
    },
    onError: () => {
      toast.error("Erreur lors de la création");
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
      queryCache.refetchQueries("personal_informations");
    },
  });
}

export function useUpdateMedicalVisit() {
  return useMutation(updateById.bind(null, ressourceKey), {
    onSuccess: () => {
      queryCache.refetchQueries(ressourceKey);
    },
    onMutate: (newContract = null) => {
      queryCache.cancelQueries(ressourceKey);
      queryCache.refetchQueries("personal_informations");
    },
    onError: () => {},
  });
}

export function useCreateOrUpdateMedicalVisit() {
  return useMutation(updateOrCreate.bind(null, ressourceKey), {
    onError: () => {
      toast.error("Erreur lors de la création");
    },
    onSuccess: () => {
      toast.success("Mise à jour effectuée avec succès");
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useDeleteMedicalVisit() {
  return useMutation(deleteById.bind(null, ressourceKey), {
    onError: () => {
      toast.error("Erreur lors de la suppression");
    },
    onSuccess: () => {
      toast.success("Suppression effectuée avec succès");
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export async function downloadMedicalVisitExport(filters) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, "export_visites_medicales.csv");
}
