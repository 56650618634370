import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  usePermissionsChecker,
} from "../contexts/permissions";
import { get, map, noop, pull } from "lodash-es";
import * as Yup from "yup";
import {
  dateDay,
  dateDifference,
} from "../regex/date";
import {Form, Formik} from "formik";
import Select from "../components/Select";
import {
  DateTimePickerInput,
} from "../components/Input";
import Button from "../components/Button";
import TextArea from "../components/TextArea";
import { useHistory } from "react-router-dom";
import {useCreateOrUpdateRecurrentAbsencesAggregation} from "../api/recurrentAbsencesAggregations";

const types = [
  { label: "Toutes les semaines", value: "weekly" },
  { label: "Toutes les 3 semaines", value: "triweekly" },
  { label: "Tous les mois", value: "monthly" },
  { label: "Semaines paires", value: "evenweeks" },
  { label: "Semaines impaires", value: "oddweeks" },
];

const singlePeriodTypes = {
  weekly: 'days',
  evenweeks: 'evenweeks',
  oddweeks: 'oddweeks',
}

const periods = [
  { label: "2 premières semaines", value: "firsttwo" },
  { label: "3 premières semaines", value: "firstthree" },
];

//On utilise des valeurs commençant à 0 pour le Lundi plutôt que ce que retournerai un getDay() sur une date, car les absences
//commencent un Lundi et ce sera plus simple à gérer en PHP
const days = [
  { label: "Lu", value: 0 },
  { label: "Ma", value: 1 },
  { label: "Mer", value: 2 },
  { label: "Je", value: 3 },
  { label: "Ve", value: 4 },
  { label: "Sa", value: 5 },
  { label: "Di", value: 6 },
];

const days2 = [
  { label: "Lu", value: 0.5 },
  { label: "Ma", value: 1.5 },
  { label: "Mer", value: 2.5 },
  { label: "Je", value: 3.5 },
  { label: "Ve", value: 4.5 },
  { label: "Sa", value: 5.5 },
  { label: "Di", value: 6.5 },
];

export default function RecurrentAbsenceForm({
  collaboratorIRI,
  recurrentAbsencesAggregation = null,
  onSuccess = noop,
  pushRecurrentAbsence = noop,
  readOnly: parentReadOnly = false,
  updateAt = noop,
}) {
  const [createRecurrentAbsence] = useCreateOrUpdateRecurrentAbsencesAggregation();
  const [setHasErrorsWithoutPath] = useState(false);
  const readOnly =
    !usePermissionsChecker({
      permissions: [
        "kdix.actions.absence.edit",
        "kdix.actions.absence.edit.agency",
        "kdix.actions.absence.edit.department",
        "kdix.actions.absence.edit.service",
        "kdix.actions.absence.edit.own",
      ],
    }) || parentReadOnly;
  const history = useHistory();

  const validation = Yup.object().shape({
    type: Yup.string().required("Requis"),
    label: Yup.string().required("Requis"),
    startDate: Yup.date()
      .required("Requis")
        .test("date-match", "La date de début doit forcément être un lundi", function (
            value
        ) {
          return dateDay(value) === 1;
        }),
    endDate: Yup.date()
      .required("Requis")
      .test("date-match", "Doit être au moins 7 jours après la date de début", function (
        value
      ) {
        return dateDifference(this.parent.startDate, value) >= 7;
      }),
  });

  let initStartHour = new Date();
  if (recurrentAbsencesAggregation && recurrentAbsencesAggregation.startHour) {
    initStartHour.setHours(
      Math.floor(recurrentAbsencesAggregation.startHour / 60),
        recurrentAbsencesAggregation.startHour % 60
    );
  }
  let initEndHour = new Date();
  if (recurrentAbsencesAggregation && recurrentAbsencesAggregation.endHour) {
    initEndHour.setHours(
      Math.floor(recurrentAbsencesAggregation.endHour / 60),
        recurrentAbsencesAggregation.endHour % 60
    );
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          type: get(recurrentAbsencesAggregation, "type", ""),
          startDate: get(recurrentAbsencesAggregation, "startDate", ""),
          endDate: get(recurrentAbsencesAggregation, "endDate", ""),
          label: get(recurrentAbsencesAggregation, "label", ""),
          period: get(recurrentAbsencesAggregation, "period", ""),
          days: get(recurrentAbsencesAggregation, "days", []),
        }}
        validationSchema={validation}
        onSubmit={async ({ file, ...values }, actions) => {
          try {
            const newRecurrentAbsence = await createRecurrentAbsence({
              id: get(recurrentAbsencesAggregation, "id", null),
              data: {
                startDate: values.startDate,
                endDate: values.endDate,
                type: values.type.value,
                label: values.label,
                days: values.days ? values.days : null,
                period: singlePeriodTypes.hasOwnProperty(values.type.value) ? singlePeriodTypes[values.type.value] : values.period.value,
                collaborator: collaboratorIRI,
              },
            });

            actions.setSubmitting(false);
            actions.resetForm();
            onSuccess();
            if (get(recurrentAbsencesAggregation, "id", null)) {
              updateAt({
                ...newRecurrentAbsence,
              });
            } else {
              pushRecurrentAbsence({
                ...newRecurrentAbsence,
              });
              actions.resetForm();
            }

            toast.success(
              get(recurrentAbsencesAggregation, "id", null)
                ? "Récurrence d'absence modifiée"
                : "La demande d'absence a bien été créée"
            );
            history.goBack();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              if (e.propertyPath) {
                actions.setFieldError(e.propertyPath, e.message);
              } else {
                setHasErrorsWithoutPath(true);
              }
            });
            if (!error.violations) {
              setHasErrorsWithoutPath(true);
            }
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, setFieldValue, isValid }) => {
          let daysArray = values.days;

          return (
            <Form>
              <TextArea
                  label="Description"
                  name="label"
                  textareaClassName="comment"
                  value={values.label}
                  disabled={readOnly}
              />
              <Select
                label="Type de récurrence"
                name="type"
                options={map(types, (type) => ({
                  label: type.label,
                  value: type.value,
                }))}
                value={values.type}
                isDisabled={readOnly}
              />
              {!!values?.type?.value && singlePeriodTypes.hasOwnProperty(values?.type?.value) && (
                  <div className={`weekRecurrentGrid gridRepeat-8 bg-white relative text-center mb-8`}>
                    <div className="text-sm font-bold">
                    </div>
                    <>
                      {map(days, (day, index) => (
                          <div className="py-3" key={index}>{day.label}</div>
                      ))}
                    </>
                    <div className="text-sm font-bold self-center">
                      Matin
                    </div>
                    <>
                      {map(days, (day, index) => (
                          <div className={`py-3 border border-gray-150 m-px hover:bg-blue-300 cursor-pointer h-12
                          ${daysArray.includes(day.value) ? 'bg-blue-400 text-white' : 'bg-blue-200'}`}
                               key={index}
                               onClick={() => {
                                 if (daysArray.includes(day.value)) {
                                   pull(daysArray, day.value);
                                 } else {
                                   daysArray.push(day.value);
                                 }
                                 setFieldValue("days", daysArray);
                               }}
                          >
                            {daysArray.includes(day.value) ? ('✗') : ''}
                          </div>
                      ))}
                    </>
                    <div className="text-sm font-bold self-center">
                      Après-Midi
                    </div>
                    <>
                      {map(days2, (day, index) => (
                          <div className={`py-3 border border-gray-150 m-px hover:bg-blue-300 cursor-pointer h-12
                          ${daysArray.includes(day.value) ? 'bg-blue-400 text-white' : 'bg-blue-200'}`}
                               key={index}
                               onClick={() => {
                                 if (daysArray.includes(day.value)) {
                                   pull(daysArray, day.value);
                                 } else {
                                   daysArray.push(day.value);
                                 }
                                 setFieldValue("days", daysArray);
                               }}
                          >
                            {daysArray.includes(day.value) ? ('✗') : ''}
                          </div>
                      ))}
                    </>
                  </div>
              )}
              {!!values?.type?.value && !singlePeriodTypes.hasOwnProperty(values?.type?.value) && (
                  <Select
                      name="period"
                      options={values?.type?.value === 'triweekly' ? [periods[0]] :
                          map(periods, (period) => ({
                            label: period.label,
                            value: period.value,
                          }
                          ))}
                      value={values.period}
                      isDisabled={readOnly}
                  />
              )}
              <DateTimePickerInput
                name="startDate"
                label="Date de début"
                fullWidth={true}
                readOnly={readOnly}
                onChange={(value) => {
                  //En attendant la gestion à l'heure/tiers journée on force les heures de début et fin à la journée
                  value.setHours(2, 0, 0, 0);
                  setFieldValue("startDate", value);
                }}
              />
              <DateTimePickerInput
                  name="endDate"
                  label="Date de fin"
                  fullWidth={true}
                  readOnly={readOnly}
                  onChange={(value) => {
                      //En attendant la gestion à l'heure/tiers journée on force les heures de début et fin à la journée
                      value.setHours(2, 0, 0, 0);
                      setFieldValue("endDate", value);
                  }}
              />

              <Button
                className={`btn mt-6 block w-full`}
                readOnly={readOnly}
                disabled={!isValid}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel={
                  get(recurrentAbsencesAggregation, "id", null)
                    ? "Modifier la récurrence"
                    : "Créer la récurrence"
                }
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
