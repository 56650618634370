import React from "react";

import { useDesktop } from "../hooks/useDesktop";
import {useQueryDomainLinks} from "./domainLinks";
import {useDomain} from "../contexts/permissions";
import { map } from "lodash-es";
import Block from "../components/Block";
import ExternalLink from "../components/ExternalLink";
import Button from "../components/Button";
import InfoTooltip from "../components/InfoTooltip";

function MyLinks() {
  const isDesktop = useDesktop();
  const domainId = useDomain();
  const {data : links} = useQueryDomainLinks({
      domain: domainId,
  });

  const [items] = React.useState(links)

  return (
      <div
          className="p-8 pb-24 flex flex-col xl:w-8/12 mx-auto"
          style={isDesktop ? { maxWidth: "900px" } : {}}
      >
              <div className="list-link" style={{
                  "user-select": "none",
                  "display": "grid",
                  "grid-template-columns": "1fr 1fr 1fr",
                  "grid-gap": "16px",
                  "flex-wrap": "wrap"
              }}>
              {map(items, (linkData) => {
                  return (
                          <InfoTooltip
                              message={linkData.description}
                              onHover={!!linkData.description}
                          >
                          <div className={"w-full p-2 h-64"}>
                              <Block className="text-center flex-grow bg-white">
                                  <div className="flex flex-col flex-auto justify-between flex-grow h-full ">
                                      <div className="flex flex-col items-center">
                                          {linkData.icon ? (
                                              <>
                                                  <div className="w-20 h-20">
                                                      <img
                                                          src={linkData.icon ? linkData.icon.contentUrl : "//placehold.it/160x160"}
                                                          className={"w-full h-full object-center object-cover rounded-full"}
                                                          alt=""
                                                      />
                                                  </div>
                                              </>
                                          ) : null}
                                          <div className="mt-3 text-xl font-black">
                                              {linkData.label}
                                          </div>
                                      </div>
                                      <ExternalLink
                                          to={ `${linkData.linkUrl}` }
                                      >
                                          <Button
                                              className={`font-bold p-1 focus:outline-none text-black bg-purple-500 text-white w-full block`}
                                          >
                                              Aller
                                          </Button>
                                      </ExternalLink>
                                  </div>
                              </Block>
                          </div>
                          </InfoTooltip>
                  )
              })}
              </div>
      </div>
  );
}

export default MyLinks;
