import { get, reduce } from "lodash-es";
import { queryCache, useMutation, useQuery } from "react-query";

import queryWithToken from "./index";
import { toast } from "react-toastify";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "vehicle_folders";
//

export function useQueryFolders() {
  const { data, isFetching } = useQuery(
    ressourceKey,
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        }
      );
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    data: reduce(
      get(data, "hydra:member", []),
      (obj, next) => {
        return {
          ...obj,
          [next["@id"]]: next,
        };
      },
      {}
    ),
    isFetching,
  };
}

export function useCreateFolder() {
  return useMutation(
    (data) =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}`, {
        method: "POST",
        data: data,
      }),
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de la création");
      },
      onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
      },
      onSuccess: (data) => {
        queryCache.refetchQueries(ressourceKey);
      },
    }
  );
}
export function useDeleteFolder() {
  return useMutation(
    (id) =>
      queryWithToken(`${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}`, {
        method: "DELETE",
      }),
    {
      throwOnError: true,
      onError: () => {
        toast.error("Erreur lors de la suppression");
      },
      onMutate: () => {
        queryCache.cancelQueries(ressourceKey);
      },
      onSuccess: (data) => {
        queryCache.refetchQueries(ressourceKey);
      },
    }
  );
}
