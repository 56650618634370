import React from "react";
import moment from "moment";
import "moment/locale/fr";
import { map, times } from "lodash-es";
import { calcTotalDaysQuarter } from "./PlanningTable";
moment.locale("fr");

export function QuarterPlanningSwitchLabel({ days }) {
  return <span>Trimestre {days[0].date.format("Q YYYY")}</span>;
}

export default function QuarterPlanningTable({ days }) {
  return (
    <>
      {map(days, (day, index) => (
        <div
          className={`border-r border-gray-150 col-span-${day.nbDays} grid grid-cols-${day.nbDays}`}
          key={index}
        >
          {times(day.nbDays, (i) => (
            <div className="py-3 border-r border-gray-100" key={i} />
          ))}
        </div>
      ))}
    </>
  );
}

export function QuarterPlanningHead({ days }) {
  const totalDays = calcTotalDaysQuarter(days);
  return (
    <div
      className={`weekPlanningGrid planningHeadContainer gridRepeat-${totalDays}`}
    >
      <div className="border-gray-150 border-r" />
      {map(days, (day, index) => (
        <div
          className={`planningHead capitalize col-span-${day.nbDays}`}
          key={index}
        >
          {day.label}
        </div>
      ))}
    </div>
  );
}
